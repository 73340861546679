import React, { PureComponent } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  CartesianGrid,
  LabelList,
} from "recharts"
import CHART_SETTINGS from "./chartSettings"
import {
  tooltipFormatterFactory,
  getTooltipLabelFormatter,
  legendFormatter,
  getXTickFormatter,
  yTickFormatter,
  xTickValueToDate,
  getCategoryAttributeName,
} from "../chartHelpers"
import { ChartProps, ChartState } from "./types"
import withInteractiveLegend from "./withInteractiveLegend"
import { formatCurrency } from "../formatters"
import { ContextMenu, Menu, MenuItem } from "@blueprintjs/core"

import schemes from "../colorScheme"
import "./recharts.module.css"

const DetailedCursor = (props: any) => {
  const { x, y, width, height, fill, stroke } = props

  return (
    <rect
      fill={fill}
      stroke={stroke}
      x={x}
      y={y}
      width={width}
      height={height}
      onClick={() =>
        props.onCursorClick(xTickValueToDate(props.payload[0].payload.name))
      }
      style={{ cursor: "pointer" }}
    />
  )
}

type LabelProps = {
  x?: any
  y?: any
  value?: any
  height?: any
  width?: any
}

export const renderCustomListContent = (props: LabelProps) => {
  const MIN_HEIGHT = 20
  const SYMBOL_SIZE = 6

  const { x, y, width, height, value } = props
  const formattedValue = yTickFormatter(value)

  const shouldDisplayLabel = () => {
    // not enought height to fit label inside
    if (Math.abs(height) < MIN_HEIGHT) return false

    // not enough width to fit label inside
    if (!formattedValue || formattedValue.length * SYMBOL_SIZE > width)
      return false

    return true
  }

  return shouldDisplayLabel() ? (
    <text
      x={x}
      y={y}
      dy={height / 2 + 5}
      dx={width / 2}
      fill="#fff"
      fontSize={12}
      textAnchor="middle"
    >
      {formattedValue}
    </text>
  ) : (
    <></>
  )
}

class StackedBarChartImpl extends PureComponent<ChartProps, ChartState> {
  formatter = () =>
    tooltipFormatterFactory({
      useDecimal: this.props.useDecimal,
      groupedSegmentNames: this.props.groupedSegmentNames,
      withPercent: this.props.withPercent,
      category: this.props.category,
    })

  onCursorClick = (date: string) => {
    this.props.onClick(date, this.props.showOnlyKeys, this.props.dontShowKeys)
  }

  onBarClick = (payload: { name: number }) => {
    this.props.onClick(
      xTickValueToDate(payload.name),
      this.props.showOnlyKeys,
      this.props.dontShowKeys
    )
  }

  detailedTooltipProps = () => {
    if (this.props.detailed) {
      return {
        cursor: <DetailedCursor onCursorClick={this.onCursorClick} />,
      }
    } else {
      return {}
    }
  }

  detailedBarProps = () => {
    if (this.props.detailed) {
      return {
        style: { cursor: "pointer" },
        onClick: this.onBarClick,
      }
    } else {
      return {}
    }
  }

  getDataPoint = (attr: string, data: ChartProps["data"][0]) => {
    const categoryAttr = getCategoryAttributeName(this.props.category)

    return categoryAttr ? data[categoryAttr][attr] : data.values[attr]
  }

  yAxisPadding = (onlyPositives: boolean, onlyNegatives: boolean) => {
    return {
      top: onlyNegatives ? 0 : CHART_SETTINGS.yAxisBarPadding.top,
      bottom: onlyPositives ? 0 : CHART_SETTINGS.yAxisBarPadding.bottom,
    }
  }

  tooltipWithTotalSum = (unixSeconds: number) => {
    const dataPoint = this.props.data.find(
      (point) => point.name === unixSeconds
    )
    const labelFormatter = getTooltipLabelFormatter(this.props.resolution)
    const labelFormatted = labelFormatter(unixSeconds)

    if (dataPoint) {
      const categoryAttr = getCategoryAttributeName(this.props.category)
      const values = Object.keys(dataPoint[categoryAttr])
        .filter((key) => !this.props.isHidden(key))
        .map((key) => dataPoint[categoryAttr][key])

      if (values.length > 0) {
        return `${labelFormatted}: ${formatCurrency(
          values.reduce((a, b) => a + b)
        )}`
      }
    } else {
      console.warn(
        `Data point at ${unixSeconds} is missing, total sum will not be shown`
      )
    }

    return labelFormatted
  }

  onContextMenu = (rechartEvent: any, index: number, mouseEvent: any) => {
    mouseEvent.preventDefault()

    const menu = React.createElement(
      Menu,
      {}, // empty props
      React.createElement(MenuItem, {
        onClick: () => {
          const expandedData = Object.keys(rechartEvent.payload.values).map(
            (attr) => ({ name: attr, value: rechartEvent.payload.values[attr] })
          )
          this.props.setExpandedDataPoint({
            time: rechartEvent.time,
            data: expandedData,
          })
        },
        text: "Show this values on horizontal bar chart",
        icon: "horizontal-bar-chart",
      })
    )
    ContextMenu.show(menu, {
      left: mouseEvent.clientX,
      top: mouseEvent.clientY,
    })
  }

  itemSorter = (item: any) => {
    const category = getCategoryAttributeName(this.props.category)

    return -item.payload[category][item.name]
  }

  render() {
    const scheme = this.props.scheme || schemes.main()
    const categoryAttributeName = getCategoryAttributeName(this.props.category)

    const dataPoints = this.props.data
      .map((dataPoint) =>
        this.props.segmentAttrs.map(
          (segmentAttr) => dataPoint[categoryAttributeName][segmentAttr]
        )
      )
      .flat()

    const onlyPositives = dataPoints.every((point) => point >= 0)
    const onlyNegatives = dataPoints.every((point) => point <= 0)

    return (
      <>
        <ResponsiveContainer width="100%" aspect={1.9}>
          <BarChart
            data={this.props.data}
            stackOffset="sign"
            margin={CHART_SETTINGS.margin}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              tickFormatter={
                getXTickFormatter(
                  this.props.data.length,
                  this.props.resolution
                ) as any
              }
            />
            <YAxis
              axisLine={false}
              padding={this.yAxisPadding(onlyPositives, onlyNegatives)}
              scale="linear"
              tickFormatter={yTickFormatter}
              orientation="right"
              tickLine={false}
            />
            <Tooltip
              {...this.detailedTooltipProps()}
              itemSorter={this.itemSorter}
              formatter={this.formatter()}
              labelFormatter={this.tooltipWithTotalSum}
            />
            {this.props.showLegend && (
              <Legend
                formatter={legendFormatter(this.props.groupedSegmentNames)}
                onClick={this.props.onLegendClick}
              />
            )}
            <ReferenceLine y={0} stroke="#000" />
            {this.props.segmentAttrs.map((attr) => (
              <Bar
                {...this.detailedBarProps()}
                key={attr}
                hide={this.props.isHidden(attr)}
                animationDuration={CHART_SETTINGS.animationDuration}
                name={attr}
                dataKey={(data) => this.getDataPoint(attr, data)}
                fill={scheme.next()}
                stackId="stack"
                onContextMenu={this.onContextMenu}
              >
                {this.props.slideMode && (
                  <LabelList
                    content={renderCustomListContent}
                    dataKey={(data: any) => this.getDataPoint(attr, data)}
                    position="inside"
                  />
                )}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  }
}

const StackedBarChart = withInteractiveLegend(StackedBarChartImpl)
export default StackedBarChart

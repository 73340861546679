import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useUsers = (): [Types.Api.User[], boolean, () => void] => {
  const [users, setUsers] = useState<Types.Api.User[]>([])
  const [loading, setLoading] = useState(false)

  const userContext = useContext(UserContext)

  const fetchUsers = useCallback(() => {
    setLoading(true)
    userContext
      .fetch("/users")
      .then((data) => setUsers(data.items))
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({
          message: "We could not fetch users from your account",
        })
      })
      .finally(() => setLoading(false))
  }, [userContext])

  useEffect(() => {
    fetchUsers()
  }, [userContext, fetchUsers])

  return [users, loading, fetchUsers]
}

export default useUsers

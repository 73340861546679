import React from "react"
import { Dialog, Classes, Intent, Button } from "@blueprintjs/core"
import Config from "./config"

const ConfirmDialog = ({ title, onConfirm, onCancel, isOpen, children }) => (
  <Dialog
    icon="warning-sign"
    title={title}
    isOpen={isOpen}
    onClose={onCancel}
    canOutsideClickClose={Config.canOutsideClickCloseDialog}
  >
    <div className={Classes.DIALOG_BODY}>{children}</div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button
          text="Cancel"
          onClick={onCancel}
          intent={Intent.NONE}
          outlined
          large
        />
        <Button
          text="Delete"
          onClick={onConfirm}
          intent={Intent.DANGER}
          large
        />
      </div>
    </div>
  </Dialog>
)

export default ConfirmDialog

import React, { useState, useContext, useEffect, useCallback } from "react"
import { Intent } from "@blueprintjs/core"
import pluralize from "pluralize"
import InvoiceItemsTable from "./InvoiceItemsTable"
import { Section } from "./Content"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import DistillerFormContainer from "./DistillerFormContainer"
import { useHistory } from "react-router-dom"

type InvoiceItemsContainerType = {
  onAllDistillersAdded: () => void
}

type CurrentPageInfoType = {
  total_count: number
  current_range?: string
  next?: string
  prev?: string
}

type SearchParamsType = {
  processing_status: string
  next?: string
  sort_by?: string
}

const InvoiceItemsContainer = ({
  onAllDistillersAdded,
}: InvoiceItemsContainerType) => {
  const helpText =
    "Some of your invoices could not be imported and will not be accounted in your metrics. This is usually caused by unrecognised line item name appearing on the invoice. You can review those invoices below and add import rule for each line item name."

  const [invoiceItems, setInvoiceItems] = useState([])
  const [currentPageInfo, setCurrentPageInfo] = useState<CurrentPageInfoType>({
    total_count: 0,
  })
  const userContext = useContext(UserContext)

  const [distillerFromOpen, setDistillerFromOpen] = useState(false)
  const [selectedLineItemName, setSelectedLineItemName] = useState<string>("")
  const history = useHistory()

  const onOpenDistillerAddDialog = useCallback(
    (lineItemName) => {
      setSelectedLineItemName(lineItemName)
      setDistillerFromOpen(true)
    },
    [setDistillerFromOpen, setSelectedLineItemName]
  )

  const onClose = () => setDistillerFromOpen(false)

  const onSubmit = (distiller: Types.Api.Distiller) => {
    userContext
      .fetch(`/distillers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(distiller),
      })
      .then(() => {
        AppToaster.show({
          timeout: 2000,
          intent: "success",
          message: "Rule added.",
        })
        fetchInvoiceItems()
      })
      .catch((error) => {
        if (error.response.status === 409) {
          AppToaster.showError({ message: "Rule already present" })
        } else {
          AppToaster.showError({ message: "Failed to create rule" })
          console.log(error)
        }
      })
  }

  const fetchInvoiceItems = useCallback(
    (cursor?: string | null, sortBy?: string) => {
      setInvoiceItems([])
      const searchParams: SearchParamsType = {
        processing_status: "distillation_failed",
      }
      if (cursor) searchParams.next = cursor
      if (sortBy) searchParams.sort_by = sortBy

      userContext
        .fetch(
          `/invoice_items?${new URLSearchParams(searchParams).toString()}`,
          {
            method: "GET",
          }
        )
        .then((data) => {
          setInvoiceItems(data.items)
          const { total_count, current_range, next, prev } = data
          if (total_count === 0) {
            onAllDistillersAdded()
            history.push("/")
            AppToaster.show({
              timeout: 30000,
              intent: Intent.SUCCESS,
              message: "Your invoices are now being processed",
            })
          }

          setCurrentPageInfo({ total_count, current_range, next, prev })
        })
        .catch((error) => {
          console.warn(error)
          AppToaster.showError({
            message: "We could not get invoices, try refreshing the page",
          })
        })
    },
    [userContext, history, onAllDistillersAdded]
  )

  useEffect(fetchInvoiceItems, [fetchInvoiceItems])

  const onSort = useCallback(
    (sortBy) => {
      if (sortBy.length > 0) {
        const { id, desc } = sortBy[0]
        fetchInvoiceItems(null, `${id}:${desc ? "desc" : "asc"}`)
      }
    },
    [fetchInvoiceItems]
  )

  const onNextPage = useCallback(() => {
    fetchInvoiceItems(currentPageInfo.next)
  }, [fetchInvoiceItems, currentPageInfo])

  const onPrevPage = useCallback(() => {
    fetchInvoiceItems(currentPageInfo.prev)
  }, [fetchInvoiceItems, currentPageInfo])

  return (
    <>
      <DistillerFormContainer
        isOpen={distillerFromOpen}
        lineItemName={selectedLineItemName}
        onClose={onClose}
        onSubmit={onSubmit}
      />
      <Section
        title="Rejected invoices"
        helpText={helpText}
        subtitle={`${currentPageInfo.total_count} ${pluralize(
          "invoice",
          currentPageInfo.total_count
        )}`}
      >
        <InvoiceItemsTable
          invoiceItems={invoiceItems}
          onSort={onSort}
          onNextPage={currentPageInfo.next && onNextPage}
          onPrevPage={currentPageInfo.prev && onPrevPage}
          range={currentPageInfo.current_range}
          onOpenDistillerAddDialog={onOpenDistillerAddDialog}
        />
      </Section>
    </>
  )
}

export default InvoiceItemsContainer

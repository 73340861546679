import { Callout } from "@blueprintjs/core"
import React from "react"
import { FieldErrors, get } from "react-hook-form"

interface ValidationErrorProps<T = any> {
  errors: FieldErrors<T>
  name: string
}

const ValidationError = <T,>({ errors, name }: ValidationErrorProps<T>) => {
  const error = get(errors, name)

  if (!error || !error.message) return <></>

  return (
    <div style={{ padding: "10px 0" }}>
      <Callout intent="danger">{error.message}</Callout>
    </div>
  )
}

export default ValidationError

import React, { useState, useCallback, useEffect, useContext } from "react"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

export const withCustomerFormValidation = (WrappedComponent) => (props) => {
  const [customerFormData, setCustomerFormData] = useState(
    props.customerFormData
  )

  useEffect(
    () => setCustomerFormData(props.customerFormData),
    [props.customerFormData]
  )

  const onCustomerFormFieldChange = useCallback(
    (value, fieldKey) => {
      switch (fieldKey) {
        case "customer name":
          setCustomerFormData({ ...customerFormData, name: value })
          break
        default:
          console.log(`Unknow field in new subscription form: ${fieldKey}`)
      }
    },
    [customerFormData]
  )

  const onCustomerSegmentChange = useCallback(
    (segment, value) => {
      const newValue = value.trim() === "" ? null : value.trim()
      const otherSegments = customerFormData.segments.filter(
        (exisingSegment) => exisingSegment.segment.name !== segment.name
      )
      const newSegmentValue = { segment: segment, value: newValue }

      const nextSegmentValues = otherSegments.concat([newSegmentValue])
      setCustomerFormData({ ...customerFormData, segments: nextSegmentValues })
    },
    [customerFormData]
  )

  const canSubmitCustomer = () => customerFormData && customerFormData.name

  return (
    <WrappedComponent
      {...props}
      onCustomerSegmentChange={onCustomerSegmentChange}
      onCustomerFormFieldChange={onCustomerFormFieldChange}
      customerFormData={customerFormData}
      setCustomerFormData={setCustomerFormData}
      canSubmitCustomer={canSubmitCustomer()}
    />
  )
}

const FetchCustomerSegments = (WrappedComponent, props) => {
  const userContext = useContext(UserContext)
  const [customerSegments, setCustomerSegments] = useState([])

  useEffect(() => {
    userContext
      .fetch("/customer_segments")
      .then((data) => setCustomerSegments(data.items))
      .catch(() => {
        AppToaster.showError({
          message: "We could not get segments, try refreshing the page",
        })
      })
  }, [userContext])

  return <WrappedComponent {...props} customerSegments={customerSegments} />
}

export const withCustomerSegments = (WrappedComponent) => {
  return (props) => FetchCustomerSegments(WrappedComponent, props)
}

import React, { useContext, useEffect, useState, useMemo } from "react"
import { UserContext } from "./UserContext"
import { useForm } from "react-hook-form"
import {
  ApiValidationError,
  ApiValidationErrors,
  Group,
  Serie,
  UserSection,
} from "./userDefinedSection/types"
import {
  FilterField,
  FilterValue,
} from "./userDefinedSection/FilterFieldSelect"
import { Button } from "@blueprintjs/core"
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom"
import SeriesListForm from "./userDefinedSection/SeriesListForm"
import { Section } from "./Content"
import { fetchReport } from "./BusinessReportHelper"
import ErrorBoundary from "./ErrorBoundary"
import { LoadingState } from "./NonIdealState"

import css from "./userDefinedSection/newSection.module.css"
import ValidationError from "./userDefinedSection/ValidationError"
import useSectionDefinitions from "./api/useSectionDefinitions"
import SelectValue, { ValueObject } from "./components/SelectValue"

interface MatchParams {
  secret: string
  sectionId: string
}

type NewSectionContainerProps = RouteComponentProps<MatchParams>

const NewSectionContainer = (props: NewSectionContainerProps) => {
  const userContext = useContext(UserContext)
  const [inputFilters, setInputFilters] = useState<FilterField[]>([])
  const [inputValues, setInputValues] = useState<FilterValue[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState<Types.Api.Report>()
  const sectionsDefinitions = useSectionDefinitions()
  const [segmentId, setSegmentId] = useState<string>()
  const history = useHistory()

  const possibleSegments = useMemo(() => {
    if (!sectionsDefinitions) return []

    return sectionsDefinitions.find((section) => section.id === "user_filtered")
      ?.segmenters
  }, [sectionsDefinitions])

  const useFormMethods = useForm<UserSection>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      groups: [],
      series: [],
    },
  })

  const {
    getValues,
    setError,
    clearErrors,
    reset,
    formState,
    formState: { errors },
  } = useFormMethods

  useEffect(() => {
    userContext
      .fetch("/user_filtered_config", { method: "GET" })
      .then((data) => {
        setInputFilters(data.filters)
        setInputValues(data.values)
      })
  }, [userContext])

  useEffect(() => {
    setLoading(true)
    fetchReport(props.match.params.secret, null, false, false, userContext)
      .then((businessReport) => setReport(businessReport))
      .finally(() => setLoading(false))
  }, [props.match.params.secret, userContext])

  const section = useMemo(() => {
    if (!report || !props.match.params.sectionId) return

    return report.sections.find(
      (section: any) => section.id === props.match.params.sectionId
    ) as any
  }, [report, props.match.params.sectionId])

  useEffect(() => {
    if (section) {
      const firstSegment = section.segments[0]
      setSegmentId(firstSegment?.id)
    }
  }, [section])

  useEffect(() => {
    if (!report || !section) return

    const series = section.config.series.map((serie: Serie) => {
      const group: Group = section.config.groups.find(
        (group: Group) => group.name === serie.definition?.group_name
      )
      const serieGroups = group?.definition.length > 0 ? [group] : []
      return { ...serie, groups: serieGroups }
    })

    reset({ groups: [], series: series })
  }, [report, section, reset])

  const formDataToApi = () => {
    const values = getValues()

    const groups: Group[] = []
    const series = values.series.map((serie) => {
      if (serie.groups && serie.groups.length > 0) {
        groups.push(...serie.groups)
      }

      delete serie["groups"]
      return serie
    })

    return {
      definition_id: "user_filtered",
      config: {
        groups: groups,
        series: series,
      },
      segment_ids: segmentId ? [segmentId] : [defaultTotalSegmentId()],
    }
  }

  const defaultTotalSegmentId = () =>
    possibleSegments?.find((item) => item.is_total)?.id

  const navigateToReport = () => {
    history.push(`/reports/${report?.secret}`)
  }

  const updateSection = () => {
    setSubmitting(true)
    clearErrors()

    userContext
      .fetch(`/business_report/sections/${props.match.params.sectionId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...section, ...formDataToApi() }),
      })
      .then(navigateToReport)
      .catch(setServerErrors)
      .finally(() => setSubmitting(false))
  }

  const createSection = () => {
    setSubmitting(true)
    clearErrors()

    userContext
      .fetch(`/business_report/${report?.id}/sections`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formDataToApi()),
      })
      .then(navigateToReport)
      .catch(setServerErrors)
      .finally(() => setSubmitting(false))
  }

  const setServerErrors = (error: any) => {
    if (error.response.status === 422 || error.response.status === 400) {
      error.response.json().then(setBackendValidationErrors)
    }
  }

  const setBackendValidationErrors = (errors: ApiValidationErrors) => {
    errors.errors.forEach((err) => {
      if (
        err.source.pointer.includes("series") ||
        err.source.pointer.includes("groups")
      ) {
        setSeriesAndGroupsError(err)
      } else {
        setGenericError(err)
      }
    })
  }

  const setSeriesAndGroupsError = (error: ApiValidationError) => {
    const variable: "series" | "groups" = error.source.pointer
      .substring("#/config.".length)
      .replaceAll("/", ".")
      .split(".")
      .slice(0, 2)
      .join(".") as `series`
    setError(variable, {
      type: "custom",
      message: error.detail || error.title || error.code || error.status,
    })
  }

  const setGenericError = (error: ApiValidationError) => {
    setError("groups", {
      type: "custom",
      message: error.detail || error.title || error.code || error.status,
    })
  }

  const onSubmit = () => {
    if (section) {
      updateSection()
    } else {
      createSection()
    }
  }

  const renderActionButtons = () => {
    return (
      <div className={css.displayRow}>
        <Button
          large
          minimal
          onClick={navigateToReport}
          text="Cancel"
          intent="danger"
        />
        <Button
          large
          outlined
          intent="primary"
          onClick={onSubmit}
          text={section ? "Update section" : "Create section"}
          loading={submitting}
          disabled={!formState.isValid}
        />
      </div>
    )
  }

  const onSegmentationSelected = (value: ValueObject) => {
    setSegmentId(value.id)
  }

  return (
    <ErrorBoundary errorMsg="Something went wrong and we could not parse configuration of this section. Please get in touch.">
      {loading && <LoadingState />}
      {!loading && (
        <Section
          title={section ? "Update section" : "Create chart"}
          subtitle="Define groups of customers and calculations that should be displayed on the chart"
          actionButton={renderActionButtons()}
        >
          <ValidationError errors={errors} name="groups" />
          <SeriesListForm
            inputFilters={inputFilters}
            useFormMethods={useFormMethods}
            values={inputValues}
          />
        </Section>
      )}
      {!loading && (
        <Section
          title="Section segmentation"
          subtitle="You can segment created section by one of your segments"
        >
          <div className={css.segmentations}>
            <div className={css.selectSegmentation}>
              <SelectValue
                activeItem={possibleSegments?.find((item) =>
                  segmentId
                    ? item.id === segmentId
                    : item.id === defaultTotalSegmentId()
                )}
                items={possibleSegments || []}
                onItemSelect={onSegmentationSelected}
                placeholder="Select segmentation (optional)"
                labelKey="name"
                searchBy={["name"]}
                groupBy=""
              />
            </div>
          </div>
        </Section>
      )}
    </ErrorBoundary>
  )
}

export default withRouter(NewSectionContainer)

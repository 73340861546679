import React, { useContext, useEffect, useState } from "react"
import {
  useParams,
  useHistory,
  withRouter,
  RouteComponentProps,
} from "react-router-dom"
import { UserContext } from "./UserContext"
import { FailureState, LoadingState } from "./NonIdealState"

type Params = {
  secret: string
}

const BusinessReportGroupContainer = (props: RouteComponentProps<Params>) => {
  const userContext = useContext(UserContext)
  const secret = useParams<Params>().secret
  const currency = new URLSearchParams(props.location.search).get("currency")
  const [failure, setFailure] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams()
    params.set("secret", secret)
    if (currency) {
      params.set("currency", currency)
    }

    const url = `/business_report_groups/by_secret?${params.toString()}`
    userContext
      .fetch(url, {
        method: "GET",
      })
      .then((data) => {
        let page_url = `/reports/${data.latest_report.secret}`
        if (currency) {
          page_url += `?currency=${currency}`
        }
        history.push(page_url)
      })
      .catch((error) => {
        console.warn(error)
        setFailure(true)
      })
  }, [userContext, history, secret, currency])

  return failure ? (
    <FailureState title="Uh :( we failed to fetch report" inDialog={false} />
  ) : (
    <LoadingState withLogo={false} inDialog={false} />
  )
}

export default withRouter(BusinessReportGroupContainer)

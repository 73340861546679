import React, { useState } from "react"
import { Label, InputGroup, H2, Callout, Intent } from "@blueprintjs/core"
import { apiUrl } from "./apiHelpers"
import WiggleButton from "./WiggleButton"
import Logo from "./Logo"

import css from "./signSection.module.css"

export const EMAIL_REGEXP = /^[^@]+@[^@]+$/i

const ResetPasswordStart = () => {
  const [email, setEmail] = useState("")
  const [isFinished, setIsFinished] = useState(false)

  const onEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const onSubmit = () => {
    const params = {
      user: {
        email: email,
      },
    }
    return fetch(apiUrl("/users/password"), {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    }).then(() => {
      setIsFinished(true)
    })
  }

  return (
    <form className={css.main}>
      <div className={css.center}>
        <Logo />
      </div>
      <div className={css.center}>
        <H2>Reset password</H2>
      </div>
      <div>
        <Label>
          Email
          <InputGroup
            large
            type="text"
            value={email}
            onChange={onEmailChange}
          />
        </Label>
        <WiggleButton
          type="submit"
          rightIcon="arrow-right"
          text="Send email with instructions"
          onClick={onSubmit}
          disabled={email.match(EMAIL_REGEXP) === null}
        />
      </div>
      {isFinished && (
        <Callout intent={Intent.PRIMARY}>
          Check your inbox for reset instructions
        </Callout>
      )}
    </form>
  )
}

export default ResetPasswordStart

import React, { SyntheticEvent } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import classnames from "classnames"
import { Callout, Intent, Button, Spinner } from "@blueprintjs/core"

import sectionStyles from "./businessReportSection.module.css"
import "./BusinessReportStatusSection.css"

interface BusinessReportStatusSectionProps extends RouteComponentProps<any> {
  recalculationStatus: Types.Api.BusinessReportRecalculationStatus
  freshnessStatus: Types.Api.BusinessReportFreshnessStatus
  onRefreshClick: (event: SyntheticEvent<HTMLElement>) => void
  hasEditRights: boolean
  promptToDataImport: boolean
}

const BusinessReportStatusSection = ({
  recalculationStatus,
  freshnessStatus,
  onRefreshClick,
  hasEditRights,
  promptToDataImport,
  history,
}: BusinessReportStatusSectionProps) => {
  const calculationIsInProgress = () => {
    return recalculationStatus === "recalculating"
  }

  const shouldShowRefreshCallout = () => {
    return (
      recalculationStatus !== "recalculating" && freshnessStatus === "stale"
    )
  }

  const navigateToConnectData = () => {
    history.push("/settings")
  }

  return calculationIsInProgress() ||
    shouldShowRefreshCallout() ||
    promptToDataImport ? (
    <div className={classnames(sectionStyles.content, "calloutContainer")}>
      {calculationIsInProgress() && (
        <Callout intent={Intent.WARNING} icon={null} className="calloutContent">
          <Spinner
            size={Spinner.SIZE_SMALL}
            intent={Intent.WARNING}
            className="spinner"
          />
          New data has arrived and this report is being recalculated. It will be
          refreshed automatically.
        </Callout>
      )}
      {shouldShowRefreshCallout() && (
        <Callout intent={Intent.PRIMARY} icon={null} className="calloutContent">
          {hasEditRights && (
            <Button
              className="calloutCta"
              outlined
              onClick={onRefreshClick}
              icon="refresh"
              intent={Intent.PRIMARY}
            >
              Refresh the report
            </Button>
          )}
          Data may be outdated.
        </Callout>
      )}
      {promptToDataImport && (
        <Callout intent={Intent.WARNING} icon={null} className="calloutContent">
          <Button
            className="calloutCta"
            outlined
            onClick={navigateToConnectData}
            icon="import"
            intent={Intent.WARNING}
          >
            Connect data
          </Button>
          Import data with CSV importer or integrate with Stripe and refresh
          report to see your results
        </Callout>
      )}
    </div>
  ) : (
    <></>
  )
}

export default withRouter(BusinessReportStatusSection)

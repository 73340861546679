import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useSegmenterTypes = () => {
  const [types, setTypes] = useState<Types.Api.SegmenterType[]>([])

  const userContext = useContext(UserContext)

  const fetchTypes = useCallback(() => {
    userContext
      .fetch("/segmenter_definitions/types")
      .then((data) => setTypes(data.items))
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({
          message: "Could not get possible segmenter types",
        })
      })
  }, [userContext])

  useEffect(() => {
    fetchTypes()
  }, [userContext, fetchTypes])

  return types
}

export default useSegmenterTypes

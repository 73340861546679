import React, { useState, useCallback } from "react"

const DistillerFormValidationImpl = (WrappedComponent, props) => {
  const [formData, setFormData] = useState({})

  const onFormFieldChange = useCallback(
    (value, fieldKey) => {
      switch (fieldKey) {
        case "line_name":
          setFormData({ ...formData, line_name: value })
          break
        case "duration":
          setFormData({ ...formData, duration: value })
          break
        case "product_key":
          setFormData({ ...formData, product_key: value })
          break
        case "item_type":
          setFormData({ ...formData, item_type: value })
          break
        case "issue_date_treatment":
          setFormData({ ...formData, issue_date_treatment: value })
          break
        case "matcher":
          setFormData({ ...formData, matcher: value })
          break
        default:
          console.log(`Unknown field in new distiller form: ${fieldKey}`)
      }
    },
    [formData]
  )

  const canSubmit = () => {
    return (
      formData.line_name &&
      formData.line_name.length > 0 &&
      formData.duration &&
      formData.product_key
    )
  }

  return (
    <WrappedComponent
      onFormFieldChange={onFormFieldChange}
      formData={formData}
      setFormData={setFormData}
      canSubmit={canSubmit()}
      {...props}
    />
  )
}

export const withDistillerFormValidation = (WrappedComponent) => {
  return (props) => DistillerFormValidationImpl(WrappedComponent, props)
}

import React from "react"
import ReactCohortGraph from "react-cohort-graph"
import fnsParse from "date-fns/parse"
import fnsAddMonths from "date-fns/addMonths"
import fnsFormat from "date-fns/format"
import { formatCurrency, formatMonthYearISO } from "./formatters"
import { Icon, Position, Tooltip } from "@blueprintjs/core"
import css from "./Cohort.module.css"

const Cohort = ({ data, shadeColor, onClick, referenceValue, resolution }) => {
  // `key` is a workaround for ReactCohortGraph not re-rendering DOM when `data` changes - reasons are unknown yet.
  const DISPLAY_DATE_FORMAT = "MMM yy"
  const PARSING_DATE_FORMAT = "yyyy-MM-dd"

  const formattedLink = (cohortDateString, cohortIndex, displayValue) => {
    const cohortDate = fnsParse(
      cohortDateString,
      DISPLAY_DATE_FORMAT,
      new Date()
    )

    // adjusting for reference value
    const detailsForMonth =
      referenceValue && !referenceValue.detailed ? cohortIndex - 1 : cohortIndex

    const date = fnsAddMonths(cohortDate, detailsForMonth)
    const formattedCohortDate = fnsFormat(cohortDate, PARSING_DATE_FORMAT)
    const formattedDate = fnsFormat(date, PARSING_DATE_FORMAT)

    if (onClick) {
      const referenceCellValue = referenceValue
        ? data[cohortDateString][0]
        : null
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            onClick(formattedDate, formattedCohortDate, referenceCellValue)
          }
        >
          {" "}
          {displayValue}{" "}
        </span>
      )
    }

    return displayValue
  }

  const cellFormatter = (cellDefintion) => {
    return formattedLink(
      cellDefintion.valueFor,
      cellDefintion.index,
      cellDefintion.percent + " %"
    )
  }

  const labelValueToUTCDate = (labelValue) => {
    const cohortDate = fnsParse(labelValue, DISPLAY_DATE_FORMAT, new Date())
    return new Date(
      Date.UTC(
        cohortDate.getFullYear(),
        cohortDate.getMonth(),
        cohortDate.getDate()
      )
    )
  }

  const quarterLabelFormatter = (labelDefinition) => {
    const isoDate = labelValueToUTCDate(labelDefinition.value)
    return formatMonthYearISO(isoDate.toISOString(), "quarter")
  }

  const yearLabelFormatter = (labelDefinition) => {
    const isoDate = labelValueToUTCDate(labelDefinition.value)
    return formatMonthYearISO(isoDate.toISOString(), "year")
  }

  const labelFormatter = (labelDefinition) => {
    if (resolution === "quarter" && labelDefinition.isLabel) {
      return quarterLabelFormatter(labelDefinition)
    }

    if (resolution === "year" && labelDefinition.isLabel) {
      return yearLabelFormatter(labelDefinition)
    }

    if (
      labelDefinition.isTotal &&
      (!referenceValue || referenceValue.detailed)
    ) {
      return formattedLink(labelDefinition.valueFor, 0, labelDefinition.value)
    }

    return formatCurrency(labelDefinition.value)
  }

  const headerFormatterWithReferenceValue = (headerDefinition) => {
    if (headerDefinition.isHeaderValue) {
      return referenceValue.kind === "currency"
        ? formatCurrency(headerDefinition.value)
        : headerDefinition.value
    } else if (headerDefinition.index === 0) {
      return referenceValue.label
    } else {
      // index prop is missing for the first column of cohort
      return headerDefinition.index
        ? `Month ${headerDefinition.index - 2}`
        : "Months"
    }
  }

  const yearOrQuarterHeaderFormatter = (headerDefinition, resolution) => {
    const resolutionLabel = resolution[0].toUpperCase() + resolution.slice(1)
    if (!headerDefinition.isHeaderValue) {
      if (headerDefinition.index == undefined) {
        return resolutionLabel
      } else if (headerDefinition.index) {
        // index prop is missing for the first column of cohort
        if (headerDefinition.index - 2 == 0) {
          return (
            <>
              {resolutionLabel[0]}
              {"0 "}
              {tooltip(
                `This is total MRR on the last day of the ${resolution} from the cohort's customers.`
              )}
            </>
          )
        }

        return `${resolutionLabel[0]}${headerDefinition.index - 2}`
      } else {
        return (
          <>
            {resolutionLabel[0]}0 Intial{" "}
            {tooltip(
              `This is the sum of new MRR for all customers acquired during the ${resolution}. This amount includes the customers that churned before end of this initial ${resolution}.`
            )}
          </>
        )
      }
    }

    return headerDefinition.value
  }

  const tooltip = (content) => (
    <Tooltip
      position={Position.TOP}
      openOnTargetFocus={false}
      popoverClassName={css.infoTooltip}
      content={content}
    >
      <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
    </Tooltip>
  )

  const headerFormatter = () => {
    if (resolution === "quarter") {
      return (headerDefinition) =>
        yearOrQuarterHeaderFormatter(headerDefinition, "quarter")
    } else if (resolution === "year") {
      return (headerDefinition) =>
        yearOrQuarterHeaderFormatter(headerDefinition, "year")
    } else if (referenceValue) {
      return headerFormatterWithReferenceValue
    } else {
      return null
    }
  }

  return (
    <ReactCohortGraph
      key={JSON.stringify(data)}
      data={{ months: data }}
      cellFormatter={cellFormatter}
      labelFormatter={labelFormatter}
      headerFormatter={headerFormatter()}
      showHeaderValues
      toggleValues
      dataType="months"
      shadeColor={shadeColor}
      wrapperStyles={{
        maxHeight: "600px",
        overflow: "auto",
        border: "1px solid rgb(241, 241, 241)",
      }}
      headerCellStyles={{ position: "sticky", top: 0 }}
      scrollableTablePartStyles={{ overflow: null }}
      scrollableTableContentStyles={{ width: null }}
      fixedTablePartStyles={{ position: "sticky", top: 0, left: 0, zIndex: 5 }}
      tableHeadingStyles={{ borderTop: null }}
      tableStyles={{ borderLeft: null }}
    />
  )
}

export default Cohort

import React, { useState } from "react"
import { Button, ButtonGroup } from "@blueprintjs/core"

export const SubscriptionItemTypes = {
  Recurring: {
    KEY: "recurring",
    LABEL: "Recurring",
    TOOLTIP: "Recurring payment",
    SHORTHAND: "R",
  },
  Metered: {
    KEY: "metered",
    LABEL: "Metered",
    TOOLTIP: "Metered payment",
    SHORTHAND: "M",
  },
  OneTime: {
    KEY: "one_time",
    LABEL: "One-time",
    TOOLTIP: "One-time payment",
    SHORTHAND: "O",
  },
  Freemium: {
    KEY: "freemium",
    LABEL: "Freemium",
    TOOLTIP: "Freemium",
    SHORTHAND: "F",
  },
}

type onSwitchType = (key: string) => void

type SubscriptionItemTypeSwitcherType = {
  itemKey: string
  onSwitch: onSwitchType
  fill?: boolean
}

export const SubscriptionItemTypeSwitcher = ({
  itemKey,
  onSwitch,
  fill,
}: SubscriptionItemTypeSwitcherType) => {
  const [selectedKey, setSelectedKey] = useState(
    itemKey || SubscriptionItemTypes.Recurring.KEY
  )

  const onItemKeySelected = (key: string) => {
    setSelectedKey(key)
    onSwitch(key)
  }

  return (
    <ButtonGroup fill={fill}>
      <Button
        active={selectedKey === SubscriptionItemTypes.Recurring.KEY}
        onClick={() => onItemKeySelected(SubscriptionItemTypes.Recurring.KEY)}
        outlined
        text={SubscriptionItemTypes.Recurring.LABEL}
        small
      />
      <Button
        active={selectedKey === SubscriptionItemTypes.Metered.KEY}
        onClick={() => onItemKeySelected(SubscriptionItemTypes.Metered.KEY)}
        outlined
        text={SubscriptionItemTypes.Metered.LABEL}
        small
      />
      <Button
        active={selectedKey === SubscriptionItemTypes.OneTime.KEY}
        onClick={() => onItemKeySelected(SubscriptionItemTypes.OneTime.KEY)}
        outlined
        text={SubscriptionItemTypes.OneTime.LABEL}
        small
      />
      <Button
        active={selectedKey === SubscriptionItemTypes.Freemium.KEY}
        onClick={() => onItemKeySelected(SubscriptionItemTypes.Freemium.KEY)}
        outlined
        text={SubscriptionItemTypes.Freemium.LABEL}
        small
      />
    </ButtonGroup>
  )
}

export default SubscriptionItemTypeSwitcher

import { AppToaster } from "./AppToaster"

export const writeToClipboard = (text: string, message: string) => {
  navigator.clipboard.writeText(text).then(() =>
    AppToaster.showSuccess({
      icon: "clipboard",
      message: message,
      timeout: 3000,
    })
  )
}

import Config from "./config"

export const apiUrl = (destination: string) => {
  return process.env.NODE_ENV === "development"
    ? `/proxy${destination}`
    : `${process.env.REACT_APP_API_URL}${destination}`
}

export const prefixWithVersion = (url: string, version?: number) => {
  if (url.match(/^\/?v\d+/)) {
    throw new Error("Already versioned url given")
  }

  const desiredVersion = version || Config.apiVersion
  return `/v${desiredVersion}${url}`
}

export const websocketUrl = (token?: string) => {
  const url = `${process.env.REACT_APP_CABLE_URL}?version=${Config.apiVersion}`
  return token ? `${url}&token=${token}` : url
}

type SignupFailedResponse = {
  errors: {
    [field: string]: string[]
  }
}

export const apiErrorsToMessages = (failedResponse: SignupFailedResponse) => {
  return Object.keys(failedResponse.errors).flatMap((field) => {
    const messages = failedResponse.errors[field]
    const fieldName = field.replace("_", " ")
    return messages.map((message) => `${fieldName} ${message}`)
  })
}

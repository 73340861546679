import React, { useEffect } from "react"
import { H3, Label, InputGroup, TextArea } from "@blueprintjs/core"
import { useForm, Controller } from "react-hook-form"
import ProductInput from "./ProductInput"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "./withHookFormHelpers"
import SubscriptionItemTypeSwitcher, {
  SubscriptionItemTypes,
} from "./SubscriptionItemTypeSwitcher"
import ButtonSwitcher, { ButtonIndex } from "./ButtonSwitcher"

import formCss from "./forms.module.css"

export enum IssueDateTreatmentType {
  StartedAt = "started_at",
  EndedAt = "ended_at",
}

export type DistillerFormDataType = {
  line_name: string
  item_type: string
  issue_date_treatment: IssueDateTreatmentType
  matcher: string
  product_key?: string
  duration?: string
}

export type DistillerFormOnFieldChangeFunctionType = (
  value: string | null,
  fieldKey: string
) => void

type DistillerFormType = InjectedHookHelpersProps<DistillerFormDataType> & {
  onFieldChange: DistillerFormOnFieldChangeFunctionType
  defaults: DistillerFormDataType
}

type FormItemType = {
  key: string
  value: string
}

const mergedDefaults = (defaults: DistillerFormDataType) => ({
  ...defaults,
  product_key: "",
  matcher: "equal",
  issue_date_treatment: IssueDateTreatmentType.StartedAt,
})

const DistillerForm = ({
  onFieldChange,
  defaults,
  fieldIntent,
  validationErrorMsg,
}: DistillerFormType) => {
  const ISSUE_DATE_TREATMENT_KEY = "issue_date_treatment"
  const {
    register,
    formState: { errors },
    control,
    reset,
  } = useForm<DistillerFormDataType>({
    mode: "all",
    defaultValues: mergedDefaults(defaults),
    reValidateMode: "onChange",
  })

  useEffect(() => {
    reset(mergedDefaults(defaults))
  }, [defaults, reset])

  const handleProductInputChange = (value: string, field: any) => {
    if (value && value.length > 0) {
      field.onChange(value)
      onFieldChange(value, "product_key")
    } else {
      field.onChange(null)
      onFieldChange(null, "product_key")
    }
  }

  const productInputRender = ({ field }: any) => {
    return (
      <ProductInput
        onItemSelect={(item: FormItemType) =>
          handleProductInputChange(item.value, field)
        }
        defaultValue={field.value}
        intent={fieldIntent(errors, "product_key")}
      />
    )
  }

  const switchIssueTreatmentDate = (index: number) => {
    const value =
      ButtonIndex.LEFT === index
        ? IssueDateTreatmentType.StartedAt
        : IssueDateTreatmentType.EndedAt
    onFieldChange(value, ISSUE_DATE_TREATMENT_KEY)
  }

  return (
    <div className={formCss.body}>
      <H3 className={formCss.title}>Import rule</H3>
      <Label>
        Line name
        <TextArea
          name="line_name"
          disabled
          fill
          large
          onChange={(event) =>
            onFieldChange(
              errors.line_name ? null : event.target.value,
              "line_name"
            )
          }
          inputRef={
            register("line_name", {
              required: "Line name is required.",
              min: {
                value: 1,
                message: "Minimal value is 1",
              },
            }).ref
          }
          intent={fieldIntent(errors, "line_name")}
          autoComplete="off"
        />
        {validationErrorMsg(errors, "line_name")}
      </Label>
      <Label>
        Subscription type <br />
        <SubscriptionItemTypeSwitcher
          itemKey={SubscriptionItemTypes.Recurring.KEY}
          onSwitch={(key: string) => onFieldChange(key, "item_type")}
        />
      </Label>
      <Label>
        Invoice recognition <br />
        <ButtonSwitcher
          leftText="Up-front"
          rightText="Post-fact"
          onSwitch={switchIssueTreatmentDate}
          fill={false}
          selectedIndex={
            defaults[ISSUE_DATE_TREATMENT_KEY] ===
            IssueDateTreatmentType.StartedAt
              ? ButtonIndex.LEFT
              : ButtonIndex.RIGHT
          }
        />
      </Label>
      <Label>
        Product name
        <Controller
          control={control}
          name="product_key"
          render={productInputRender}
          rules={{ required: "Product name is required" }}
        />
        {validationErrorMsg(errors, "product_key")}
      </Label>
      <Label>
        Duration (months)
        <InputGroup
          name="duration"
          fill
          large
          onChange={(event) =>
            onFieldChange(
              errors.duration ? null : event.target.value,
              "duration"
            )
          }
          inputRef={
            register("duration", {
              required: "Duration is required.",
              pattern: {
                value: /^\d+$/,
                message: "Value should be a number ex. 12",
              },
            }).ref
          }
          intent={fieldIntent(errors, "duration")}
          autoComplete="off"
        />
        {validationErrorMsg(errors, "duration")}
      </Label>
    </div>
  )
}

export default withHookFormHelpers(DistillerForm)

import React, { useContext } from "react"
import { useHistory, useParams } from "react-router-dom"
import { UserContext } from "./UserContext"

const SignInViaToken = () => {
  const history = useHistory()
  const userContext = useContext(UserContext)
  const { jwtToken } = useParams()

  userContext
    .performSignInViaToken(jwtToken)
    .then(() => history.push("/settings"))
    .catch((error) => {
      console.warn(error)
      history.push("/sign_in")
    })

  return <div>Signing in via token...</div>
}

export default SignInViaToken

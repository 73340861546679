import React, { useState, useContext } from "react"
import {
  Dialog,
  Button,
  Intent,
  Classes,
  InputGroup,
  Label,
} from "@blueprintjs/core"
import { useForm } from "react-hook-form"
import { DataSource } from "./Settings"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import withHookFormHelpter, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import Config from "../config"

type FakturowniaDataSourceFormType = {
  fakturownia_api_token: string
  fakturownia_subdomain: string
}

type FakturowniaDataSourceProps =
  InjectedHookHelpersProps<FakturowniaDataSourceFormType> & {
    integration: Types.Api.Integration
    onSuccess: (data: any) => void
  }

const FakturowniaDataSource = ({
  integration,
  onSuccess,
  validationErrorMsg,
  fieldIntent,
}: FakturowniaDataSourceProps) => {
  const userContext = useContext(UserContext)
  const [dialogOpen, setDialogOpen] = useState(false)

  const defaultValues = () => ({
    fakturownia_api_token: integration.configuration?.fakturownia_api_token,
    fakturownia_subdomain: integration.configuration?.fakturownia_subdomain,
  })

  const {
    formState: { errors },
    getValues,
    register,
    trigger,
    formState,
    reset,
  } = useForm<FakturowniaDataSourceFormType>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues(),
  })

  const onDialogClose = () => {
    reset(defaultValues())
    setDialogOpen(false)
  }

  const onDialogSave = () => {
    trigger().then((success) => {
      if (success) {
        const formValues = getValues()
        const body = {
          configuration: {
            fakturownia_api_token: formValues.fakturownia_api_token,
            fakturownia_subdomain: formValues.fakturownia_subdomain,
          },
        }

        userContext
          .fetch("/integrations/fakturownia", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
          })
          .then((data) => {
            setDialogOpen(false)
            if (onSuccess) {
              onSuccess(data)
            }
          })
          .catch((error) => {
            error.response.json().then((errorData: any) => {
              console.warn(errorData)
              if (errorData.body.includes("cannot_connect_to_fakturownia")) {
                AppToaster.showError({
                  message:
                    "We could not connect to Fakturownia, please make sure your API token and Subdomain are correct",
                })
              } else {
                AppToaster.showError({
                  message: "We could not save configuration",
                })
              }
            })
          })
      }
    })
  }

  const onCardClick = () => {
    setDialogOpen(true)
  }

  const tokenField = register("fakturownia_api_token", {
    required: "Fakturownia API token cannot be empty",
  })
  const subdomainField = register("fakturownia_subdomain", {
    required: "Subdomain cannot be empty",
  })

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={onDialogClose}
        title="Fakturownia"
        canOutsideClickClose={Config.canOutsideClickCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>Provide API token and subdomain</p>
          <Label>
            API token
            <InputGroup
              name={tokenField.name}
              autoFocus
              placeholder="API token"
              intent={fieldIntent(errors, tokenField.name)}
              large
              fill
              inputRef={tokenField.ref}
              onChange={tokenField.onChange}
              onBlur={tokenField.onBlur}
            />
            {validationErrorMsg(errors, tokenField.name)}
          </Label>
          <Label>
            Subdomain
            <InputGroup
              name={subdomainField.name}
              placeholder="Subdomain"
              intent={fieldIntent(errors, subdomainField.name)}
              large
              fill
              inputRef={subdomainField.ref}
              onChange={subdomainField.onChange}
              onBlur={subdomainField.onBlur}
            />
            {validationErrorMsg(errors, subdomainField.name)}
          </Label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close"
              onClick={onDialogClose}
              intent={Intent.NONE}
              outlined
              large
            />
            <Button
              type="submit"
              text="Save"
              onClick={onDialogSave}
              intent={Intent.PRIMARY}
              large
              disabled={!formState.isValid}
            />
          </div>
        </div>
      </Dialog>
      <DataSource
        integration={integration}
        containerClassName="fakturownia"
        onClick={onCardClick}
      />
    </>
  )
}

export default withHookFormHelpter(FakturowniaDataSource)

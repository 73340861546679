import React, { useState, useEffect, useContext } from "react"
import {
  Card,
  H4,
  Icon,
  Dialog,
  Spinner,
  Button,
  Intent,
  Classes,
} from "@blueprintjs/core"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import { Section } from "../Content"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import { capitalize } from "../textHelpers"
import IntercomDataSource from "./IntercomDataSource"
import HubspotDataSource from "./HubspotDataSource"
import StripeDataSource from "./StripeDataSource"
import ChargebeeDataSource from "./ChargebeeDataSource"
import FakturowniaDataSource from "./FakturowniaDataSource"
import WfirmaDataSource from "./WfirmaDataSource"
import { writeToClipboard } from "../utils"
import Config from "../config"
import UsersSettings from "./UsersSettings"
import SettingsSegmenters from "./SettingsSegmenters"

import "./Settings.css"
import AccountSettings from "./AccountSettings"
import CustomAttributes from "./CustomAttributes"

const withFrontendParams = (connectUrl) =>
  `${connectUrl}?frontend_request=true&redirect_url=${encodeURIComponent(
    window.location.href
  )}`

export const DataSource = ({ integration, containerClassName, onClick }) => {
  const onCardClick = () => {
    if (integration.enabled && !integration.configurable) return

    if (onClick) {
      onClick(integration)
    } else {
      window.location.href = withFrontendParams(integration.connect_url)
    }
  }

  const interactive = () => !integration.enabled || integration.configurable

  const progressMessage = (integration) => {
    if (integration.initial_progress) {
      const percentage = (integration.initial_progress * 100).toFixed()
      return `Sync in progress (${percentage}%)`
    } else {
      return "Sync in progress"
    }
  }

  const integrationStatusElement = () => {
    if (!integration.enabled) return

    if (integration.configurable && !integration.configured) {
      return (
        <div className="integrationStatusUnconfigured">
          <Icon icon="dot" />
          Configuration needed
        </div>
      )
    }

    if (!integration.initially_synced) {
      return (
        <div className="integrationStatusSuccess">
          <Spinner size={Spinner.SIZE_SMALL} intent={Intent.SUCCESS} />
          &nbsp;{progressMessage(integration)}
        </div>
      )
    }

    return (
      <div className="integrationStatusSuccess">
        <Icon icon="dot" />
        Connected
      </div>
    )
  }

  return (
    <Card
      className="dataSourceCard"
      interactive={interactive()}
      onClick={onCardClick}
    >
      <div className={classnames(containerClassName, "dataSourceLogo")}></div>
      <div className="integrationStatusContainer">
        {integrationStatusElement()}
      </div>
    </Card>
  )
}

const CustomDataSource = ({ onClick, text, icon }) => {
  return (
    <Card className="dataSourceCard" interactive onClick={onClick}>
      <Icon icon={icon} iconSize={Icon.SIZE_LARGE} />
      <H4 className="cardText">{text}</H4>
    </Card>
  )
}

const CsvImportDataSource = () => {
  const history = useHistory()
  // const [csvImportOpen, setCsvImportOpen] = useState(false)

  // const openCsvImport = () => {
  //   setCsvImportOpen(true)
  // }

  // const onCsvImportClose = () => {
  //   setCsvImportOpen(false)
  // }
  const startCsvImport = () => {
    history.push("/import-subscriptions")
  }

  return (
    <>
      {/* <DataImportDialog
        isOpen={csvImportOpen}
        onClose={onCsvImportClose}
        openImportDialog={openCsvImport}
      /> */}
      <CustomDataSource
        icon="import"
        text="Import CSV"
        onClick={startCsvImport}
      />
    </>
  )
}

const ContactSupport = ({ isOpen, onClose, integrationName }) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Integration in closed beta"
      canOutsideClickClose={Config.canOutsideClickCloseDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        The {integrationName} integration is in closed beta.{" "}
        <a href="mailto:support@getprobe.io">Get in touch</a> to enable it.
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Close"
            onClick={onClose}
            intent={Intent.NONE}
            outlined
            large
          />
        </div>
      </div>
    </Dialog>
  )
}

const ApiDataSource = () => {
  const userContext = useContext(UserContext)
  const [apiToken, setApiToken] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const onDialogClose = () => {
    setDialogOpen(false)
  }
  const onClick = () => {
    setDialogOpen(true)
  }

  useEffect(() => {
    if (!userContext.hasUserLevelAccess()) return undefined

    userContext
      .fetch("/users/me")
      .then((data) => setApiToken(data.api_token))
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({ message: "We could not get your API token" })
      })
  }, [userContext])

  const copyApiToken = () => {
    writeToClipboard(apiToken, "API Token copied to clipboard")
  }

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={onDialogClose}
        title="Probe API"
        canOutsideClickClose={Config.canOutsideClickCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            API Token: <pre className="apiTokenDisplay">{apiToken}</pre>
            <Button
              intent={Intent.PRIMARY}
              minimal
              icon="clipboard"
              align="right"
              onClick={copyApiToken}
            />
          </p>
          <p>
            Visit our{" "}
            <a href={`${process.env.REACT_APP_PAGE_URL}/docs/api/`}>API docs</a>{" "}
            to learn more.
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close"
              onClick={onDialogClose}
              intent={Intent.NONE}
              outlined
              large
            />
          </div>
        </div>
      </Dialog>
      <CustomDataSource icon="code" text="Probe API" onClick={onClick} />
    </>
  )
}

export const DataSources = ({ integrations, setIntegration }) => {
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const [integrationName, setIntegrationName] = useState(null)

  const onClickOpenSupport = (integration) => {
    setIntegrationName(capitalize(integration.name))
    setSupportDialogOpen(true)
  }

  const supportDialogOnClose = () => {
    setSupportDialogOpen(false)
  }

  return (
    <div className="dataSources">
      <ContactSupport
        isOpen={supportDialogOpen}
        onClose={supportDialogOnClose}
        integrationName={integrationName}
      />
      <CsvImportDataSource />
      {integrations.stripe && (
        <StripeDataSource integration={integrations.stripe} />
      )}
      {integrations.chargebee && (
        <ChargebeeDataSource integration={integrations.chargebee} />
      )}
      {integrations.intercom && (
        <IntercomDataSource
          integration={integrations.intercom}
          onSuccess={setIntegration}
        />
      )}
      {integrations.zendesk && (
        <DataSource
          integration={integrations.zendesk}
          containerClassName="zendesk"
          onClick={onClickOpenSupport}
        />
      )}
      {integrations.hubspot && (
        <HubspotDataSource
          integration={integrations.hubspot}
          onSuccess={setIntegration}
        />
      )}
      {integrations.fakturownia && (
        <FakturowniaDataSource integration={integrations.fakturownia} />
      )}
      {integrations.wfirma && (
        <WfirmaDataSource integration={integrations.wfirma} />
      )}
      <ApiDataSource />
    </div>
  )
}

const Settings = ({ integrations, setIntegration }) => {
  return (
    <div className="settingsContainer">
      <AccountSettings />
      <UsersSettings />
      <Section
        title="Data sources"
        subtitle="Connect Probe with other tools to advance your analytics"
      >
        <DataSources
          integrations={integrations}
          setIntegration={setIntegration}
        />
      </Section>
      <CustomAttributes />
      <SettingsSegmenters />
    </div>
  )
}

export default Settings

import React, {
  useContext,
  useCallback,
  SyntheticEvent,
  Dispatch,
  useState,
  useEffect,
  useMemo,
} from "react"
import classnames from "classnames"
import { EditableText, Intent, Classes } from "@blueprintjs/core"
import PresentMode from "./PresentMode"
import BusinessReportTextSection from "./BusinessReportTextSection"
import LineChartSection from "./LineChartSection"
import StackedBarChartSection from "./StackedBarChartSection"
import BarChartSection from "./BarChartSection"
import CohortSection from "./CohortSection"
import { UserContext } from "./UserContext"
import { EmptyBusinessReportState } from "./NonIdealState"
import { AppToaster } from "./AppToaster"
import {
  SectionInState as Section,
  State as Report,
  Action,
} from "./businessReportReducer"

import "./BusinessReport.css"
import KeyNumbersSection from "./KeyNumbersSection"
import useCableSubscription from "./api/useCableSubscription"
import Config from "./config"

type BusinessReportProps = {
  planId: string | undefined
  report: Report
  presentMode: boolean
  togglePresentMode: () => void
  dispatch: Dispatch<Action>
  onAddChart: (event: SyntheticEvent<HTMLElement>) => void
  hasEditRights: boolean
  canAccessDetails: boolean
  filter?: Types.Api.BusinessReportSegmentValue
  sectionsDefinition: Types.Api.SectionDefinition[]
}

type ExportResultChannel = {
  url: string
}

function componentFromDataType(
  dataType: Types.Api.SectionDataType
):
  | typeof LineChartSection
  | typeof BarChartSection
  | typeof StackedBarChartSection
  | typeof CohortSection {
  if (dataType === "line_chart") {
    return LineChartSection
  } else if (dataType === "bar_chart") {
    return BarChartSection
  } else if (dataType === "stacked_bar_chart") {
    return StackedBarChartSection
  } else if (dataType === "cohort") {
    return CohortSection
  } else {
    throw new Error("Unknown type")
  }
}

const BusinessReport = React.memo(
  ({
    planId,
    report,
    presentMode,
    togglePresentMode,
    dispatch,
    onAddChart,
    hasEditRights,
    canAccessDetails,
    filter,
    sectionsDefinition,
  }: BusinessReportProps) => {
    const [plan, setPlan] = useState<Types.Api.Plan | undefined>()
    const userContext = useContext(UserContext)

    const fetchPlan = () => {
      if (planId && userContext.hasUserLevelAccess()) {
        userContext
          .fetch(`/plans/${planId}`)
          .then((plan: Types.Api.Plan) => {
            setPlan(plan)
          })
          .catch((error) => {
            console.warn(error)
          })
      }
    }

    useEffect(fetchPlan, [userContext, planId])

    const channelConnectAttrs = useMemo(
      () => ({
        channel: "BusinessReportChannel",
        id: userContext.isLoggedIn ? report.id : report.secret,
        version: Config.apiVersion,
      }),
      [report.id, report.secret, userContext]
    )

    const updateWholeReport = useCallback(
      (data) => {
        if (data.object === "business_report_section") {
          dispatch({
            type: "updateSection",
            sectionId: data.payload.id,
            attributes: data.payload,
          })
        } else if (data.object === "business_report") {
          dispatch({ type: "updateReport", attributes: data.payload })
        } else {
          console.warn("Unknown webHook object type", data)
        }
      },
      [dispatch]
    )

    useCableSubscription(report ? channelConnectAttrs : null, updateWholeReport)

    const onExportChannelDataReceived = useCallback(
      (exportResult: ExportResultChannel) => {
        AppToaster.show({
          icon: "export",
          timeout: 0,
          intent: Intent.SUCCESS,
          message: "Your export is completed",
          action: {
            onClick: () => window.open(exportResult.url),
            text: "Download",
          },
        })
      },
      []
    )

    const exportChannelConnectAttrs = useMemo(() => {
      return { channel: "SectionDetailsExportChannel" }
    }, [])
    useCableSubscription(exportChannelConnectAttrs, onExportChannelDataReceived)

    const headSection = (report: Types.Api.Report) => {
      return (
        <BusinessReportTextSection
          key="section-head"
          title={report.title}
          reportId={report.id}
          summary={report.summary}
          disabled={!hasEditRights}
        />
      )
    }

    const slideFromSection = (section: Section) => {
      const ContentComponent = componentFromDataType(section.data_type)

      return {
        title: section.title,
        content: (
          <ContentComponent
            section={section}
            currency={report.currency}
            useDecimal={section.decimal}
            slideMode
            canAccessDetails={canAccessDetails}
            key={section.id}
            target={findTargets(section)}
            isLastSection={true}
            isFirstSection={true}
            filter={filter}
            resolution={section.resolution}
            definition={findSectionDefinition(section)}
            keyNumbers={
              report.keyNumbers
                ? report.keyNumbers.filter(
                    (number) => number.section_id === section.id
                  )
                : []
            }
          />
        ),
      }
    }

    const buildSlides = () => {
      const entrySlide = {
        title: report.title,
        annotation: null,
        content: (
          <div className={classnames(Classes.TEXT_LARGE, Classes.RUNNING_TEXT)}>
            <EditableText defaultValue={report.summary} multiline disabled />
          </div>
        ),
      }
      const sectionSlides = report.sections.map(slideFromSection)
      sectionSlides.unshift(entrySlide)
      return sectionSlides
    }

    const renderSection = (
      section: Section,
      isFirstSection: boolean,
      isLastSection: boolean
    ) => {
      const SectionComponent = componentFromDataType(section.data_type)
      return (
        <SectionComponent
          key={section.id}
          target={findTargets(section)}
          section={section}
          useDecimal={section.decimal}
          currency={report.currency}
          isLastSection={isLastSection}
          isFirstSection={isFirstSection}
          canAccessDetails={canAccessDetails}
          filter={filter}
          resolution={section.resolution}
          definition={findSectionDefinition(section)}
          keyNumbers={
            report.keyNumbers
              ? report.keyNumbers.filter(
                  (number) => number.section_id === section.id
                )
              : []
          }
        />
      )
    }

    const findSectionDefinition = (section: Types.Api.Section) => {
      return sectionsDefinition.find(
        (definition) => definition.id === section.definition_id
      )
    }

    const findTargets = (section: Types.Api.Section) => {
      if (!plan) return undefined

      return plan.target_types.find((target: Types.Api.PlanTarget) =>
        target.definition_ids.includes(section.definition_id)
      )
    }

    const renderPresentMode = () => {
      return (
        <PresentMode
          slides={buildSlides()}
          isOpen={presentMode}
          togglePresentMode={togglePresentMode}
          currentSlide={0}
        />
      )
    }

    const buildSections = () => {
      const sections = report.sections.map((section, index) =>
        renderSection(
          section,
          index === 0,
          index === report.sections.length - 1
        )
      )

      return [
        headSection(report),
        <KeyNumbersSection
          currency={report.currency}
          key="key_numbers"
          report_secret={report.secret}
          dispatch={dispatch}
          numbers={report.keyNumbers || []}
        />,
      ].concat(sections)
    }

    const renderSections = () =>
      presentMode ? renderPresentMode() : buildSections()

    return (
      <>
        {renderSections()}
        {report.sections.length === 0 && (
          <EmptyBusinessReportState
            hasEditRights={hasEditRights}
            onAddChart={onAddChart}
          />
        )}
      </>
    )
  }
)

export default BusinessReport

import { Button, H5 } from "@blueprintjs/core"
import React from "react"
import { UseFormReturn, useFieldArray } from "react-hook-form"
import { FilterField } from "./FilterFieldSelect"
import GroupDefinitionForm from "./GroupDefinitionForm"
import { FilterNodeArgument, UserSection } from "./types"

import css from "./newSection.module.css"
import FilterArgumentForm from "./FilterArgumentForm"

type GroupsListFormProps = {
  useFormMethods: UseFormReturn<UserSection>
  filterFields: FilterField[]
  serieIndex: number
}

const GroupsListForm = ({
  useFormMethods,
  filterFields,
  serieIndex,
}: GroupsListFormProps) => {
  const { control, watch, setValue } = useFormMethods
  const { fields, append, remove } = useFieldArray({
    control,
    name: `series.${serieIndex}.groups`,
  })

  const serieValue = watch(`series.${serieIndex}.value`)

  const addNewGroup = (_index: number, filterNode: FilterNodeArgument) => {
    const groupName = `Serie${serieIndex + 1}.Group${fields.length + 1}`
    append({
      name: groupName,
      definition: [
        {
          operator: "and",
          arguments: [filterNode],
        },
      ],
    })

    setValue(`series.${serieIndex}.definition`, {
      type: "group",
      group_name: groupName,
    })
  }

  const removeGroup = (index: number) => {
    remove(index)
    setValue(`series.${serieIndex}.definition`, undefined)
  }

  const filtersTitle = () =>
    serieValue.object === "cost"
      ? "Filter costs"
      : "Filter customers & payments"

  const groupsActionTarget = () => (
    <Button minimal icon="filter" text={filtersTitle()} intent="primary" />
  )

  return (
    <div className={css.groupsContainer}>
      {fields.length > 0 && (
        <div className={css.groups}>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <H5 className={css.title}>
                  {filtersTitle()}
                  <Button
                    small
                    minimal
                    icon="trash"
                    onClick={() => removeGroup(index)}
                  />
                </H5>
                <GroupDefinitionForm
                  useFormMethods={useFormMethods}
                  items={filterFields}
                  serieIndex={serieIndex}
                  groupIndex={index}
                  removeGroup={removeGroup}
                />
              </div>
            )
          })}
        </div>
      )}
      {fields.length === 0 &&
        serieValue.object !== "custom" &&
        serieValue.attribute !== "formula" && (
          <div className={css.groupActions}>
            <FilterArgumentForm
              index={-1}
              items={filterFields}
              onSave={addNewGroup}
              target={groupsActionTarget()}
            />
          </div>
        )}
    </div>
  )
}

export default GroupsListForm

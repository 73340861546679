import React, { useState } from "react"
import { Tab, Tabs } from "@blueprintjs/core"
import classNames from "classnames"
import { MultiLineChart } from "./charts/LineChart"
import {
  sortKeysIfNeeded,
  buildSegmentsFromSection,
  ChartSegment,
  extractDataPointsRange,
} from "./chartHelpers"
import BusinessReportChartSection, {
  BusinessReportChartSectionWithDetailsProps,
} from "./BusinessReportChartSection"
import withCategorySwitcher from "./withCategorySwitcher"

import css from "./BusinessReportChartSection.module.css"

const LineChartSection = ({
  section,
  useDecimal,
  onShowDetails,
  canAccessDetails,
  category,
  renderCategorySwitcher,
  filter,
  resolution,
  slideMode,
  withDetails,
  buildExportItems,
  getFilterByKeys,
  renderSectionSettings,
}: BusinessReportChartSectionWithDetailsProps) => {
  // If first segment is detailed - all of them are detailed and we need to show switcher from
  // monetary to counts
  const [tabIndex, setTabIndex] = useState(0)
  const segments = buildSegmentsFromSection(section) as ChartSegment[]

  const renderInTabs = () => {
    const tabs = segments.map((segment, index) => {
      const key = `section.key_${index}`
      return (
        <Tab
          key={key}
          id={index}
          title={segment.title}
          panelClassName="tabPanel"
          panel={segmentToChart(segment)}
        />
      )
    })

    // setState is async function, and we need value immidiately
    // useEffect can not be use because it called after render
    let activeTabIndex = tabIndex
    if ((section.filtered && tabIndex !== 0) || tabIndex >= segments.length) {
      setTabIndex(0)
      activeTabIndex = 0
    }

    const exportItem = buildExportItems({
      segmentId: segments[activeTabIndex].segment_id,
      category: category,
    })

    return (
      <Tabs
        key={`${section.id}-${section.filtered}`}
        id={section.id}
        animate
        className="tabs"
        onChange={(index) => setTabIndex(index as number)}
      >
        {tabs}
        <Tabs.Expander />
        <div className={classNames(css.alignCenter, css.sectionActions)}>
          {renderCategorySwitcher(exportItem)}
          {renderSectionSettings(segments[activeTabIndex].segment_id)}
        </div>
      </Tabs>
    )
  }

  const segmentToChart = (segment: ChartSegment) => {
    const { startDate, endDate } = extractDataPointsRange(segment.data) as {
      startDate: string
      endDate: string
    }

    const onClick = (
      date: string,
      showOnlyKeys: string[],
      dontShowKeys: string[]
    ) => {
      const dataAtDate = segment.data.find((v) => v.time === date)
      const filterByKeys = getFilterByKeys(
        showOnlyKeys,
        dontShowKeys,
        segment.grouped_segment_names,
        Object.keys(dataAtDate ? dataAtDate.values : {})
      )

      return onShowDetails({
        date,
        start_date: startDate,
        end_date: endDate,
        section_id: section.id,
        legacy_series: section.legacy_series,
        segment_id: segment.segment_id,
        segment_value: filter ? filter.value : segment.segment_value,
        filter_by: filterByKeys,
        filter_details: true,
        section_definition_id: section.definition_id,
      })
    }
    const segmentAttrs = segment.data[0]
      ? sortKeysIfNeeded(section.definition_id, segment.id, segment.data)
      : []

    return (
      <MultiLineChart
        data={segment.data}
        category={category}
        onClick={onClick}
        segmentAttrs={segmentAttrs}
        groupedSegmentNames={segment.grouped_segment_names}
        yReferenceLine={segment.y_reference_line}
        useDecimal={useDecimal}
        detailed={withDetails && canAccessDetails}
        showLegend={!segment.is_total || segmentAttrs.length > 1}
        resolution={resolution}
        withPercent={section.supports_segment_percentage_display}
        slideMode={slideMode}
      />
    )
  }

  return renderInTabs()
}

export default BusinessReportChartSection(
  withCategorySwitcher(LineChartSection)
)

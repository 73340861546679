import React from "react"
import {
  Button,
  InputGroup,
  ControlGroup,
  Label,
  Intent,
  H3,
} from "@blueprintjs/core"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "./withHookFormHelpers"

import css from "./NumberRangeSelectorForm.module.css"
import formCss from "./forms.module.css"
import classNames from "classnames"
import { Definition } from "./api/SegmenterDefinition"

type NumberRangeSelectorProps = InjectedHookHelpersProps<Definition> & {
  useFormMethods: UseFormReturn<Definition>
}

const NumberRangeSelector = ({
  useFormMethods,
  validationErrorMsg,
  fieldIntent,
}: NumberRangeSelectorProps) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setError,
    setValue,
  } = useFormMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "internalBuckets",
  })

  const watchRanges = watch("internalBuckets")
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchRanges[index],
    }
  })

  const emptyPoint = {
    from: "",
    to: "",
    name: "",
  }

  const addPoint = () => {
    if (controlledFields.length === 0) {
      append({ ...emptyPoint, from: "0" })
    } else {
      const from =
        parseInt(controlledFields[controlledFields.length - 1].to) + 1 || ""
      append({ ...emptyPoint, from: from.toString() })
    }
  }

  const removePoint = (index: number) => {
    remove(index)
  }

  const updateNextRange = (index: number, value: string) => {
    if (index === controlledFields.length - 1) return

    const newValue = `${parseInt(value) + 1}`
    setValue(`internalBuckets.${index + 1}.from`, newValue)
  }

  const validateRangeRules = (value: string, index: number) => {
    if (parseInt(value) <= parseInt(controlledFields[index].from)) {
      return "Max value can not be smaller than its min value"
    }

    return true
  }

  const validateAllRanges = () => {
    controlledFields.forEach((field, index) => {
      const validation = validateRangeRules(field.to, index)
      if (typeof validation === "string") {
        setError(`internalBuckets.${index}.to`, {
          type: "custom",
          message: validation,
        })
      }
    })
  }

  const renderPoint = (value: string, index: number, field: any) => {
    const nameField = register(`internalBuckets.${index}.name`)
    const fromField = register(`internalBuckets.${index}.from`)
    const toField = register(`internalBuckets.${index}.to`, {
      required: "Required",
      pattern: {
        value: /^\d*$/,
        message: "Value should be a number ex. 1200",
      },
      validate: (v) => validateRangeRules(v, index),
    })
    return (
      <>
        <div className={classNames(formCss.fieldsRow, css.cancelBottomPadding)}>
          <div className={formCss.fieldsRowActionButton}>
            <Button
              icon="trash"
              minimal
              onClick={() => removePoint(index)}
              intent={Intent.PRIMARY}
              disabled={index === 0 && controlledFields.length !== 1}
            />
          </div>
          <ControlGroup fill>
            <InputGroup
              id={field.id}
              className={formCss.displayNone}
              name={`internalBuckets.${index}.name`}
              fill
              disabled
              defaultValue={field.defaultValue?.name}
              value={controlledFields[index].name}
              inputRef={nameField.ref}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
              type="hidden"
            />
            <Label>
              {index === 0 && "Min value"}
              <InputGroup
                id={field.id}
                className={css.textRight}
                name={`internalBuckets.${index}.from`}
                fill
                disabled
                defaultValue={field.defaultValue?.from}
                value={controlledFields[index].from}
                inputRef={fromField.ref}
                onChange={fromField.onChange}
                onBlur={fromField.onBlur}
              />
            </Label>
            <Label>
              {index === 0 && "Max value"}
              <InputGroup
                id={field.id}
                name={`internalBuckets.${index}.to`}
                className={css.textRight}
                intent={fieldIntent(errors, `internalBuckets.${index}.to`)}
                fill
                defaultValue={field.to}
                onBlur={(e) => {
                  validateAllRanges()
                  toField.onBlur(e)
                }}
                autoComplete="off"
                inputRef={toField.ref}
                onChange={(e) => {
                  toField.onChange(e)
                  updateNextRange(index, e.target.value)
                }}
              />
            </Label>
          </ControlGroup>
        </div>
        <div className={formCss.validationMsg}>
          {validationErrorMsg(errors, `internalBuckets.${index}.to`)}
        </div>
      </>
    )
  }

  const renderBreakPoints = () =>
    controlledFields.map((field, index) => renderPoint("", index, field))

  const infinityValue = () => {
    return `> ${controlledFields[controlledFields.length - 1].to}`
  }

  return (
    <>
      <H3 className={formCss.title}>Ranges</H3>
      <p>
        For numeric values you can segment based on the range a given value
        falls into. Each range you define will be visualized as a separate
        segment value. Ex. 0 - 100, 101-500, {`>`} 500 etc.
      </p>
      <div className={formCss.body}>
        {controlledFields.length > 0 && (
          <>
            {renderBreakPoints()}
            <div className={formCss.fieldsRow}>
              <ControlGroup fill className={css.infinityValue}>
                <InputGroup
                  className={classNames(css.textRight)}
                  fill
                  value={infinityValue()}
                  disabled
                />
              </ControlGroup>
            </div>
          </>
        )}
        <div className={classNames(formCss.fieldsRow, formCss.justifyFlexEnd)}>
          <div className={formCss.fieldsRowActionButton}>
            <Button
              icon="add"
              text="Add range"
              minimal
              onClick={addPoint}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default withHookFormHelpers(NumberRangeSelector)

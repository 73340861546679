import React, { useContext } from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { UserContext } from "./UserContext"

type ProtectedRouteProps = {
  children: React.ReactChildren
  allowForLoggedIn: boolean
}

const ProtectedRoute = ({
  children,
  allowForLoggedIn,
  ...rest
}: ProtectedRouteProps & RouteProps) => {
  const userContext = useContext(UserContext)
  return (
    <Route
      {...rest}
      render={() => {
        if (allowForLoggedIn || userContext.hasUserLevelAccess()) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: "/sign_in",
              }}
            />
          )
        }
      }}
    />
  )
}

export default ProtectedRoute

import React, { useContext, useState } from "react"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import { ClickedChartDetails } from "../BusinessReportChartSection"
import {
  Button,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core"
import queryString from "query-string"
import { Subtract } from "utility-types"
import { formatMonthYearISO } from "../formatters"

const buildQueryString = (o: object) =>
  queryString.stringify(o, { skipNull: true })

interface ClickedChartDetailsContext {
  context: ClickedChartDetails
  variant: "money" | "sub" | "units_sold"
}

interface withExportDetailsProps {
  exporting: boolean
  onExportClick: (exportFullReport: boolean) => void
}

const withExportDetails =
  <T extends ClickedChartDetailsContext & withExportDetailsProps>(
    WrappedComponent: React.ComponentType<T>
  ) =>
  (props: Subtract<T, withExportDetailsProps>) => {
    const userContext = useContext(UserContext)
    const [exporting, setExporting] = useState(false)
    const {
      date,
      section_id,
      segment_id,
      segment_value,
      start_date,
      end_date,
      cohort_date,
    } = props.context

    const onExportClick = (exportFullReport: boolean) => {
      let url
      if (exportFullReport) {
        url = `/business_report/sections/${section_id}/export_details?${buildQueryString(
          {
            start_date: cohort_date || start_date,
            end_date: end_date,
            segment_id: segment_id,
            segment_value: segment_value,
            cohort_date: cohort_date,
            variant: props.variant,
          }
        )}`
      } else {
        url = `/business_report/sections/${section_id}/export_details?${buildQueryString(
          {
            date,
            segment_id: segment_id,
            segment_value: segment_value,
            cohort_date: cohort_date,
            variant: props.variant,
          }
        )}`
      }

      setExporting(true)
      userContext
        .fetch(url, {
          method: "POST",
        })
        .then(() => {
          AppToaster.show(
            {
              icon: "export",
              timeout: 5000,
              intent: Intent.SUCCESS,
              message:
                "Your export is in progress. We will notify you once completed",
            },
            "export"
          )
        })
        .catch(() =>
          AppToaster.show(
            {
              icon: "export",
              timeout: 30000,
              intent: Intent.DANGER,
              message: "We could not start your export, please try again later",
            },
            "export"
          )
        )
        .finally(() => setExporting(false))
    }

    return (
      <WrappedComponent
        {...(props as T)}
        exporting={exporting}
        onExportClick={onExportClick}
      />
    )
  }

type ExportSectionDetailsProps = ClickedChartDetailsContext &
  withExportDetailsProps & {
    exportFullReport: boolean
    text: string
  }

type ExportDetailsButtonProps = {
  context: ClickedChartDetailsContext["context"]
  withPaymentsExport?: boolean
}

export const ExportDetailsButton = ({
  context,
  withPaymentsExport = false,
}: ExportDetailsButtonProps) => {
  const contextFullRangeCaption = () => {
    return `${formatMonthYearISO(
      context.cohort_date || context.start_date
    )} - ${formatMonthYearISO(context.end_date)}`
  }

  const popoverContent = () => (
    <Menu>
      <MenuDivider title="Customers" />
      <ExportSectionDetailsMenuItem
        context={context}
        text={`Export range ${contextFullRangeCaption()}`}
        variant="money"
        exportFullReport={true}
      />
      <ExportSectionDetailsMenuItem
        context={context}
        text={`Export ${formatMonthYearISO(context.date, context.resolution)}`}
        variant="money"
        exportFullReport={false}
      />
      {withPaymentsExport && (
        <>
          <MenuDivider title="Payments" />
          <ExportSectionDetailsMenuItem
            context={context}
            text={`Export range ${contextFullRangeCaption()}`}
            variant="sub"
            exportFullReport={true}
          />
          <ExportSectionDetailsMenuItem
            context={context}
            text={`Export ${formatMonthYearISO(
              context.date,
              context.resolution
            )}`}
            variant="sub"
            exportFullReport={false}
          />
        </>
      )}
      <MenuDivider title="Units sold" />
      <ExportSectionDetailsMenuItem
        context={context}
        text={`Export range ${contextFullRangeCaption()}`}
        variant="units_sold"
        exportFullReport={true}
      />
      <ExportSectionDetailsMenuItem
        context={context}
        text={`Export ${formatMonthYearISO(context.date, context.resolution)}`}
        variant="units_sold"
        exportFullReport={false}
      />
    </Menu>
  )

  return (
    <Popover content={popoverContent()} position={Position.BOTTOM}>
      <Button text="Export" intent="primary" minimal outlined icon="export" />
    </Popover>
  )
}

export const ExportSectionDetailsMenuItem = withExportDetails(
  ({ onExportClick, text, exportFullReport }: ExportSectionDetailsProps) => {
    return (
      <MenuItem text={text} onClick={() => onExportClick(exportFullReport)} />
    )
  }
)

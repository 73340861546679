export type SectionInState = Types.Api.Section & {
  filtered: boolean
  original?: Types.Api.Section
  scroll: boolean
}

export type State = Omit<Types.Api.Report, "sections"> & {
  sections: SectionInState[]
  filtered: boolean
  delayedUpdate?: State
  keyNumbers: Types.Api.KeyNumber[]
  tags: string[]
}

export type Action =
  | { type: "updateSection"; sectionId: string; attributes: object }
  | {
      type: "updateSectionSegment"
      sectionId: string
      segmentId: string
      attributes: object
    }
  | { type: "updateReport"; attributes: State }
  | { type: "applyFilter"; sections: SectionInState[] }
  | { type: "clearFilter" }
  | { type: "removeSection"; sectionId: string }
  | { type: "rearrangeSection"; sectionId: string; newPosition: number }
  | { type: "resetScroll" }
  | { type: "addComment"; sectionId: string; value: Types.Api.Comment }
  | { type: "updateComment"; sectionId: string; value: Types.Api.Comment }
  | { type: "scrollToSection"; sectionId: string }
  | { type: "updateReportCurrency"; currency: string }
  | { type: "updateKeyNumbers"; keyNumbers: Types.Api.KeyNumber[] }
  | { type: "addKeyNumber"; keyNumber: Types.Api.KeyNumber }
  | {
      type: "deleteKeyNumber"
      keyNumber: { sectionId: string; definitionId: string }
    }

const updateSectionSegment = (
  sections: SectionInState[],
  sectionId: string,
  segmentId: string,
  attributes: object
) => {
  return sections.map((section) => {
    if (section.id === sectionId) {
      const segments = section.segments.map((segment) => {
        if (segment.id === segmentId) {
          return { ...segment, ...attributes }
        } else {
          return segment
        }
      })

      return { ...section, segments: segments }
    } else {
      return section
    }
  })
}

const mergeIntoSection = (
  sections: SectionInState[],
  sectionId: string,
  merge: object
) => {
  return sections.map((section) => {
    if (section.id === sectionId) {
      return Object.assign({}, section, merge)
    } else {
      return section
    }
  })
}

const removeSection = (sections: SectionInState[], sectionId: string) => {
  return sections.filter((section) => section.id !== sectionId)
}

const rearrangeSection = (
  sections: SectionInState[],
  sectionId: string,
  newPosition: number
) => {
  const index = sections.findIndex((section) => section.id === sectionId)
  const newSections = sections.filter((section) => section.id !== sectionId)

  newSections.splice(newPosition, 0, sections[index])

  const sectionsWithPosition = newSections.map((section, position) =>
    Object.assign({}, section, {
      position: position,
      scroll: position === newPosition,
    })
  )

  return sectionsWithPosition
}

const resetScroll = (sections: SectionInState[]) =>
  sections.map((section) => Object.assign({}, section, { scroll: null }))

const addNewComment = (
  sections: SectionInState[],
  sectionId: string,
  comment: Types.Api.Comment
) => {
  return sections.map((section) => {
    if (section.id === sectionId) {
      section.comments = section.comments.concat([comment])
    }

    return section
  })
}

const updateComment = (
  sections: SectionInState[],
  sectionId: string,
  updatedComment: Types.Api.Comment
) => {
  return sections.map((section) => {
    if (section.id === sectionId) {
      section.comments.map((comment) =>
        comment.id === updatedComment.id ? updatedComment : comment
      )
    }

    return section
  })
}

const markScrollToSection = (sections: SectionInState[], sectionId: string) =>
  sections.map((section) => ({ ...section, scroll: section.id === sectionId }))

const applyFilter = (
  sections: SectionInState[],
  filteredSections: SectionInState[]
) =>
  filteredSections.map((section) => {
    const originalSection = sections.find((sec) => sec.id === section.id)
    return {
      ...section,
      filtered: true,
      original: originalSection!.original
        ? originalSection!.original
        : originalSection,
    }
  })

const clearFilter = (state: State): State => {
  if (state.filtered && state.delayedUpdate) {
    return { ...state.delayedUpdate }
  }

  if (state.filtered) {
    const originalSection = state.sections.map(
      (section) => ({ ...section.original, filtered: false } as SectionInState)
    )

    return { ...state, filtered: false, sections: originalSection }
  } else {
    return { ...state }
  }
}

// It is possible that UpdateReport action comes via webSoket
// when user has filter applied - in this case store newest state
// under separate key, it will be applied when filter is cleared
const updateReport = (state: State, newState: State) => {
  if (state && state.filtered) {
    return { ...state, delayedUpdate: newState }
  } else {
    return { ...state, ...newState }
  }
}

export const businessReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "updateSectionSegment":
      return {
        ...state,
        sections: updateSectionSegment(
          state.sections,
          action.sectionId,
          action.segmentId,
          action.attributes
        ),
      }
    case "updateSection":
      return {
        ...state,
        sections: mergeIntoSection(
          state.sections,
          action.sectionId,
          action.attributes
        ),
      }
    case "updateReport":
      return updateReport(state, action.attributes)
    case "applyFilter":
      return {
        ...state,
        filtered: true,
        sections: applyFilter(state.sections, action.sections),
      }
    case "clearFilter":
      return clearFilter(state)
    case "removeSection":
      return {
        ...state,
        sections: removeSection(state.sections, action.sectionId),
      }
    case "rearrangeSection":
      return {
        ...state,
        sections: rearrangeSection(
          state.sections,
          action.sectionId,
          action.newPosition
        ),
      }
    case "resetScroll":
      return { ...state, sections: resetScroll(state.sections) }
    case "addComment":
      return {
        ...state,
        sections: addNewComment(state.sections, action.sectionId, action.value),
      }
    case "updateComment":
      return {
        ...state,
        sections: updateComment(state.sections, action.sectionId, action.value),
      }
    case "scrollToSection":
      return {
        ...state,
        sections: markScrollToSection(state.sections, action.sectionId),
      }
    case "updateReportCurrency":
      return {
        ...state,
        currency: action.currency,
      }
    case "updateKeyNumbers":
      return {
        ...state,
        keyNumbers: action.keyNumbers,
      }
    case "addKeyNumber":
      return {
        ...state,
        keyNumbers: state.keyNumbers.concat([action.keyNumber]),
      }
    case "deleteKeyNumber":
      return {
        ...state,
        keyNumbers: state.keyNumbers.filter((number) => {
          return (
            number.section_id !== action.keyNumber.sectionId ||
            number.definition_id !== action.keyNumber.definitionId
          )
        }),
      }
  }
}

export default businessReportReducer

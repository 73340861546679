import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import { UserContext } from "./UserContext"
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Legend,
} from "recharts"
import { getTooltipLabelFormatter, yTickFormatter } from "./chartHelpers"
import { Section } from "./Content"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import ExploreSettingsDrawer, {
  ExploreSettingsFormData,
} from "./ExploreSettingsDrawer"
import { Button, Intent } from "@blueprintjs/core"

import css from "./CustomersExplore.module.css"
import { formatCurrency } from "./formatters"
import { LoadingState } from "./NonIdealState"
import colorScheme from "./colorScheme"
import withInteractiveLegend from "./charts/withInteractiveLegend"

interface MatchParams {
  sectionId: string
  segmentId: string
  date: string
}

interface LocationState {
  title: string
}

interface CustomerExplorerProps extends RouteComponentProps<MatchParams> {}

type GroupedCustomerMetrics = {
  [key: string]: Types.Api.CustomerMetric[]
}

type ScatteredChartProps = {
  data: Types.Api.CustomerMetric[] | GroupedCustomerMetrics
  yAxisDimention: ExploreSettingsFormData["yAxisDimention"]
  xReferenceLines: ExploreSettingsFormData["xReferenceLines"]
  yReferenceLines: ExploreSettingsFormData["yReferenceLines"]
  isHidden: (key: string) => boolean
  onLegendClick: (legentItem: any, index: number, event: any) => void
}

const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    const dataPoint = props.payload[0].payload
    return (
      <div className={css.tooltip}>
        <p>
          <b>{dataPoint.customer.name}</b>
        </p>
        <p>
          Customer for (months):{" "}
          <span className={css.number}>
            {parseInt(dataPoint.metrics.months_being_customer)}
          </span>
        </p>
        <p>
          Starting MRR:{" "}
          <span className={css.number}>
            {formatCurrency(
              parseFloat(dataPoint.metrics.new_mrr_when_occurred)
            )}
          </span>
        </p>
        <p>
          Current MRR:{" "}
          <span className={css.number}>
            {formatCurrency(parseFloat(dataPoint.metrics.mrr))}
          </span>
        </p>
        <p>
          Total recurring:{" "}
          <span className={css.number}>
            {formatCurrency(parseFloat(dataPoint.metrics.accrued_total))}
          </span>
        </p>
      </div>
    )
  }

  return null
}

const ScatteredChart = withInteractiveLegend(
  ({
    data,
    yAxisDimention,
    xReferenceLines,
    yReferenceLines,
    isHidden,
    onLegendClick,
  }: ScatteredChartProps) => {
    const scheme = colorScheme.main()
    const xDataKey = (data: Types.Api.CustomerMetric) => {
      return parseInt(data.metrics.months_being_customer)
    }

    const calculateGrowthPercent = (data: Types.Api.CustomerMetric) => {
      if (
        parseFloat(data.metrics.new_mrr_when_occurred) === 0 ||
        !data.metrics.mrr
      )
        return 0
      const res = Math.round(
        100 *
          (parseFloat(data.metrics.mrr) /
            parseFloat(data.metrics.new_mrr_when_occurred) -
            1)
      )
      return res
    }

    const yDataKey = (data: Types.Api.CustomerMetric) => {
      if (yAxisDimention === "growth_since_start")
        return calculateGrowthPercent(data)

      return parseFloat(data.metrics[yAxisDimention])
    }

    const yAxisLabel = () => {
      if (yAxisDimention === "growth_since_start") {
        return {
          value: "Growth since starting MRR, %",
          angle: -90,
          position: "insideLeft",
        }
      } else if (yAxisDimention === "accrued_total") {
        return {
          value: "Total acquired MRR, money",
          angle: -90,
          position: "insideLeft",
        }
      } else if (yAxisDimention === "mrr") {
        return {
          value: "Current MRR, money",
          angle: -90,
          position: "insideLeft",
        }
      } else {
        return {
          value: "Starting MRR, money",
          angle: -90,
          position: "insideLeft",
        }
      }
    }

    const renderXReferenceLines = () =>
      xReferenceLines.map((line, index) => (
        <ReferenceLine
          key={index}
          x={line.value}
          stroke="black"
          label={line.label}
          strokeDasharray="3 3"
        />
      ))

    const renderYReferenceLines = () =>
      yReferenceLines.map((line, index) => (
        <ReferenceLine
          key={index}
          y={line.value}
          stroke="black"
          label={line.label}
          strokeDasharray="3 3"
        />
      ))

    const segmented = useMemo(() => !Array.isArray(data), [data])

    const renderScatter = (
      name: string,
      data: Types.Api.CustomerMetric[],
      key: number
    ) => {
      return (
        <Scatter
          name={name}
          data={data}
          fill={scheme.next()}
          key={key}
          hide={isHidden(name)}
        />
      )
    }

    const renderScatters = () => {
      if (segmented && !Array.isArray(data)) {
        return Object.keys(data).map((key: string, index: number) => {
          const scatterData = isHidden(key) ? [] : data[key]
          return renderScatter(key, scatterData, index)
        })
      } else {
        return renderScatter("mrr", data as Types.Api.CustomerMetric[], 0)
      }
    }

    return (
      <div style={{ userSelect: "none", cursor: "crosshair" }}>
        <ResponsiveContainer
          width="100%"
          aspect={2.1}
          className={css.crossHair}
        >
          <ScatterChart
            margin={{
              top: 20,
              right: 5,
              bottom: 5,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey={xDataKey}
              type="number"
              label={{
                value: "Customer lifetime, months",
                position: "insideBottomRight",
                offset: 0,
              }}
            />
            {renderXReferenceLines()}
            {renderYReferenceLines()}
            <YAxis
              label={yAxisLabel()}
              axisLine={false}
              tickFormatter={yTickFormatter}
              dataKey={yDataKey}
              tickLine={false}
            />
            {renderScatters()}
            {segmented && <Legend onClick={onLegendClick} />}
            <Tooltip
              labelFormatter={getTooltipLabelFormatter("month")}
              content={<CustomTooltip />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    )
  }
)

const CustomersExplore = ({
  match: {
    params: { sectionId, segmentId, date },
  },
}: CustomerExplorerProps) => {
  const [formData, setFormData] = useState<ExploreSettingsFormData>({
    yAxisDimention: "new_mrr_when_occurred",
    xReferenceLines: [
      { value: 12, label: "1 year" },
      { value: 24, label: "2 years" },
      { value: 36, label: "3 years" },
    ],
    yReferenceLines: [],
    segmentName: "",
  })
  const userContext = useContext(UserContext)
  const [formOpen, setFormOpen] = useState(false)
  const [data, setData] = useState<Types.Api.CustomerMetric[]>([])
  const [loading, setLoading] = useState(false)
  const [customerSegmentsNames, setCustomerSegmentsNames] = useState<string[]>(
    []
  )
  const location = useLocation<LocationState>()

  useEffect(() => {
    setLoading(true)
    userContext
      .fetch(
        `/customer_metrics?section_id=${sectionId}&segment_id=${segmentId}&date=${date}`
      )
      .then((response) => setData(response.items))
      .finally(() => setLoading(false))
  }, [date, sectionId, segmentId, userContext])

  const onSettings = () => setFormOpen(true)
  const closeForm = () => setFormOpen(false)

  const onFormSubmit = (newFormData: ExploreSettingsFormData) => {
    setFormData(newFormData)
  }

  const fetchSegmenterDefinitions = () => {
    userContext
      .fetch("/customer_segments", { method: "GET" })
      .then((data) =>
        setCustomerSegmentsNames(
          data.items.map((segment: Types.Api.SegmentDefintion) => segment.name)
        )
      )
  }

  useEffect(fetchSegmenterDefinitions, [userContext])

  const groupBySegment = useCallback(
    (data: Types.Api.CustomerMetric[], segmentName: string) => {
      return data.reduce(
        (grouped: GroupedCustomerMetrics, item: Types.Api.CustomerMetric) => {
          const segment = item.customer.segments.find(
            (seg: Types.Api.CustomerSegment) => seg.segment.name === segmentName
          )
          const key = segment && segment.value ? segment.value : "Undefined"

          if (!grouped[key]) grouped[key] = []
          grouped[key].push(item)
          return grouped
        },
        {}
      )
    },
    []
  )

  const calculateGrowthPercent = useCallback(
    (item: Types.Api.CustomerMetric) => {
      if (
        parseFloat(item.metrics.new_mrr_when_occurred) === 0 ||
        !item.metrics.mrr
      ) {
        return NaN
      }
      const res = Math.round(
        100 *
          (parseFloat(item.metrics.mrr) /
            parseFloat(item.metrics.new_mrr_when_occurred) -
            1)
      )

      return res
    },
    []
  )

  const groupByGrowth = useCallback(
    (data: Types.Api.CustomerMetric[]) => {
      const group = data.reduce(
        (grouped: GroupedCustomerMetrics, item: Types.Api.CustomerMetric) => {
          const growth = calculateGrowthPercent(item)
          let key = ""
          if (growth === 0) {
            key = "0 %"
          } else if (growth > 0 && growth <= 100) {
            key = "1% - 100%"
          } else if (growth > 100) {
            key = "> 100%"
          } else if (growth < 0) {
            key = "Negative growth"
          } else {
            if (!item.metrics.mrr) {
              key = "Churned customers"
            } else {
              key = "Starting MRR is 0"
            }
          }

          if (!grouped[key]) grouped[key] = []
          grouped[key].push(item)

          return grouped
        },
        {}
      )
      const final: GroupedCustomerMetrics = {}
      Object.keys(group).forEach((key) => {
        const percent = Math.round((100 * group[key].length) / data.length)
        const stat = `(${group[key].length} of ${data.length} or ${percent}%)`
        final[`${key} ${stat}`] = group[key]
      })
      return final
    },
    [calculateGrowthPercent]
  )

  const groupedData = useMemo(() => {
    if (data.length === 0) return undefined
    if (formData.yAxisDimention === "growth_since_start") {
      return groupByGrowth(data)
    }

    if (formData.segmentName !== "") {
      return groupBySegment(data, formData.segmentName)
    }

    return undefined
  }, [
    data,
    formData.segmentName,
    formData.yAxisDimention,
    groupByGrowth,
    groupBySegment,
  ])

  return (
    <Section
      title={`Explore customers ${
        location.state ? `from ${location.state.title}` : ""
      }`}
      helpText="Use this explore view to get a deep understanding of selected customer group. Every dot represents a customer. X axis shows total number of months customer paid for, Y axis shows their starting MRR and it can be changed to the total mrr received in the chart settings. Open chart settings to segment this group, edit reference lines or change Y axis dimention."
      actionButton={
        <Button
          large
          outlined
          intent={Intent.PRIMARY}
          onClick={onSettings}
          text="Chart settings"
          disabled={loading}
        />
      }
    >
      {loading && <LoadingState />}
      {!loading && (
        <>
          <ExploreSettingsDrawer
            isOpen={formOpen}
            onSubmit={onFormSubmit}
            onClose={closeForm}
            segmentNames={customerSegmentsNames}
            formData={formData}
          />
          <ScatteredChart
            data={groupedData ? groupedData : data}
            {...formData}
          />
        </>
      )}
    </Section>
  )
}

export default withRouter(CustomersExplore)

import React from "react"

import { IItemRendererProps, MultiSelect } from "@blueprintjs/select"

import { Button, Intent, MenuItem } from "@blueprintjs/core"

type FilterValueMultiSelectProps = {
  items: string[]
  selectedItems: string[]
  onItemSelect: (value: string) => void
  onRemove: (value: string, index: number) => void
  onClear: () => void
  intent?: Intent
}

const FilterValueMultiSelect = ({
  items,
  selectedItems,
  onItemSelect,
  onRemove,
  onClear,
  intent = Intent.NONE,
}: FilterValueMultiSelectProps) => {
  const itemPredicate = (query: string, item: string) => {
    if (query.length === 0) return true

    const lowercaseQuery = query.toLowerCase()
    return item.toLowerCase().includes(lowercaseQuery)
  }

  const itemRenderer = (
    item: string,
    { handleClick, index, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) return null

    return (
      <MenuItem
        key={index}
        text={item}
        onClick={handleClick}
        active={modifiers.active}
        icon={selectedItems.includes(item) ? "tick" : undefined}
        shouldDismissPopover={false}
      />
    )
  }

  const tagRenderer = (item: string) => item

  const clearButton = () => {
    if (selectedItems.length == 0) return <></>
    return <Button icon="cross" minimal={true} onClick={onClear} />
  }

  return (
    <MultiSelect<string>
      itemRenderer={itemRenderer}
      items={items}
      tagRenderer={tagRenderer}
      itemPredicate={itemPredicate}
      onItemSelect={onItemSelect}
      onRemove={onRemove}
      resetOnSelect={true}
      selectedItems={selectedItems}
      popoverProps={{
        minimal: true,
        boundary: "viewport",
      }}
      noResults={<MenuItem disabled={true} text="No results" />}
      fill={true}
      tagInputProps={{
        rightElement: clearButton(),
        tagProps: { minimal: true },
        intent: intent,
      }}
      placeholder="Select values ..."
    />
  )
}

export default FilterValueMultiSelect

import {
  Drawer,
  Classes,
  Button,
  Intent,
  Label,
  InputGroup,
  HTMLSelect,
  ControlGroup,
} from "@blueprintjs/core"
import React, { useEffect } from "react"
import { useForm, UseFormReturn } from "react-hook-form"

import formsCss from "../forms.module.css"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"

type ObjectAttributeFormProps =
  InjectedHookHelpersProps<Types.Api.ObjectAttribute> & {
    useFormMethods: UseFormReturn<Types.Api.ObjectAttribute>
  }

const ObjectAttributeForm = withHookFormHelpers(
  ({
    useFormMethods,
    fieldIntent,
    validationErrorMsg,
  }: ObjectAttributeFormProps) => {
    const {
      register,
      formState: { errors },
      watch,
    } = useFormMethods

    const attrId = watch("id")
    const nameField = register("name", { required: "Name is required" })
    const objectField = register("object")

    return (
      <>
        <div className={formsCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Name
              <InputGroup
                placeholder="Attribute name"
                intent={fieldIntent(errors, nameField.name)}
                name={nameField.name}
                onChange={nameField.onChange}
                onBlur={nameField.onBlur}
                inputRef={nameField.ref}
                autoFocus={true}
                autoComplete="off"
                large
              />
              {validationErrorMsg(errors, nameField.name)}
            </Label>
          </ControlGroup>
        </div>
        <div className={formsCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Object
              <HTMLSelect
                disabled={!!attrId}
                fill
                elementRef={objectField.ref}
                onBlur={objectField.onBlur}
                onChange={objectField.onChange}
                name={objectField.name}
                options={[
                  { label: "Customer", value: "customer" },
                  { label: "Payment", value: "subscription_history" },
                  { label: "Cost", value: "cost" },
                ]}
                large
              />
            </Label>
          </ControlGroup>
        </div>
      </>
    )
  }
)

type ObjectAttributeDrawerFormProps = {
  defaultValues?: Types.Api.ObjectAttribute
  isOpen: boolean
  onClose: () => void
  onSubmit: (attr: Types.Api.ObjectAttribute) => void
}

const ObjectAttributeDrawerForm = ({
  defaultValues,
  isOpen,
  onClose,
  onSubmit,
}: ObjectAttributeDrawerFormProps) => {
  const useFormMethods = useForm<Types.Api.ObjectAttribute>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  })

  const { trigger, getValues, reset, formState } = useFormMethods

  useEffect(() => {
    reset(defaultValues || { name: "" })
  }, [defaultValues, reset])

  const onFormSave = () => {
    trigger().then((success) => {
      if (success) {
        onSubmit(getValues())
        onClose()
      }
    })
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="40%"
      onClose={onClose}
      title="New attribute"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={formsCss.insideDrawer}>
          <ObjectAttributeForm useFormMethods={useFormMethods} />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formsCss.actions}>
          <Button
            disabled={!formState.isValid}
            text={defaultValues?.id ? "Save" : "Create"}
            outlined
            onClick={onFormSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default ObjectAttributeDrawerForm

import React, { Dispatch } from "react"
import { FailureState, LoadingState } from "./NonIdealState"
import { Action } from "./businessReportReducer"
import { UserContext } from "./UserContext"
import { fetchReport } from "./BusinessReportHelper"

import "./BusinessReport.css"

type BusinessReportProviderState = {
  inProgress: boolean
  failure: boolean
}

type BusinessReportProviderProps = {
  secret: string
  currency: string | null
  shared: boolean
  demo: boolean
  dispatch: Dispatch<Action>
}

class BusinessReportProvider extends React.Component<
  BusinessReportProviderProps,
  BusinessReportProviderState
> {
  static contextType = UserContext

  constructor(props: BusinessReportProviderProps) {
    super(props)

    this.state = {
      inProgress: false,
      failure: false,
    }
  }

  componentDidMount() {
    this.setState({ inProgress: true, failure: false })
    fetchReport(
      this.props.secret,
      this.props.currency,
      this.props.shared,
      this.props.demo,
      this.context
    )
      .then((report) => {
        this.setState({ inProgress: false })
        this.props.dispatch({ type: "updateReport", attributes: report })
      })
      .catch(() => {
        this.setState({ inProgress: false, failure: true })
      })
  }

  render() {
    const { inProgress, failure } = this.state
    return (
      <>
        {inProgress && <LoadingState />}
        {failure && <FailureState title="Uh :( we failed to fetch report" />}
      </>
    )
  }
}

export default BusinessReportProvider

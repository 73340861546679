import React, { useCallback, useEffect, useState } from "react"
import {
  Label,
  InputGroup,
  Drawer,
  Intent,
  H3,
  Classes,
  Button,
  ControlGroup,
  HTMLSelect,
} from "@blueprintjs/core"
import { useForm, UseFormReturn, useFieldArray } from "react-hook-form"
import classnames from "classnames"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "./withHookFormHelpers"

import formCss from "./forms.module.css"
import MonthYearSelector from "./MonthYearSelector"
import { indexToLetter } from "./textHelpers"

type ColumnVariable = {
  name: string
  fetcher: "BookedTotalContractValue" | "Mrr"
  start_year: string
  start_month: string
  end_year: string
  end_month: string
}

export type ColumnFormType = {
  id?: string
  recalculation_status?: "in_progress" | "finished"
  name: string
  formula: string
  variables: ColumnVariable[]
}

type AddCustomersTableColumnFormProps =
  InjectedHookHelpersProps<ColumnFormType> & {
    useFormMethods: UseFormReturn<ColumnFormType>
  }

type ColumnVariableFormProps = InjectedHookHelpersProps<ColumnFormType> & {
  variable: ColumnVariable
  index: number
  onRemove: (index: number) => void
  useFormMethods: UseFormReturn<ColumnFormType>
  disableDelete?: boolean
}

const ColumnVariableForm = ({
  index,
  variable,
  onRemove,
  useFormMethods,
  validationErrorMsg,
  disableDelete,
}: ColumnVariableFormProps) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormMethods
  const startMonth = register(`variables.${index}.start_month`, {
    required: "Starting month must be set",
  })
  const endMonth = register(`variables.${index}.end_month`, {
    required: "Ending month must be set",
  })
  const startYear = register(`variables.${index}.start_year`, {
    required: "Starting year must be set",
  })
  const endYear = register(`variables.${index}.end_year`, {
    required: "Ending year must be set",
  })
  const fetcher = register(`variables.${index}.fetcher`, {
    required: "Calculation is required",
  })

  const fetcherValue = watch(fetcher.name)
  const endDates = watch([startYear.name, startMonth.name])

  useEffect(() => {
    if (fetcherValue === "Mrr") {
      const [year, month] = endDates

      setValue(`variables.${index}.end_year`, year)
      setValue(`variables.${index}.end_month`, month)
    }
  }, [endDates, index, fetcherValue, setValue])

  return (
    <>
      <div className={formCss.fieldsRow}></div>
      <span>
        Variable: <b>{variable.name}</b>
      </span>
      <div className={formCss.fieldsRow}>
        <ControlGroup fill>
          <Label>
            Calculation
            <HTMLSelect
              fill
              elementRef={fetcher.ref}
              onBlur={fetcher.onBlur}
              onChange={fetcher.onChange}
              name={fetcher.name}
              options={[
                {
                  label: "Booked total contract value",
                  value: "BookedTotalContractValue",
                },
                { label: "Monthly recurring revenue", value: "Mrr" },
              ]}
            />
          </Label>
        </ControlGroup>
      </div>
      <div className={formCss.fieldsRow}>
        <ControlGroup fill>
          <MonthYearSelector
            label="From"
            monthField={startMonth}
            yearField={startYear}
          />
          {fetcherValue !== "Mrr" && (
            <>
              <Label></Label>
              <MonthYearSelector
                label="To"
                monthField={endMonth}
                yearField={endYear}
              />
            </>
          )}
        </ControlGroup>
        <div className={formCss.fieldsRowActionButton}>
          <Button
            icon="trash"
            disabled={disableDelete}
            minimal
            onClick={() => onRemove(index)}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
      <div>
        {validationErrorMsg(errors, startMonth.name)}
        {validationErrorMsg(errors, startYear.name)}
        {validationErrorMsg(errors, endMonth.name)}
        {validationErrorMsg(errors, endYear.name)}
        {validationErrorMsg(errors, `variables.${index}`)}
      </div>
    </>
  )
}

const AddCustomersTableColumnForm = withHookFormHelpers(
  ({
    useFormMethods,
    validationErrorMsg,
    fieldIntent,
  }: AddCustomersTableColumnFormProps) => {
    const {
      control,
      register,
      formState: { errors },
    } = useFormMethods
    const { fields, append, remove } = useFieldArray({
      control,
      name: "variables",
    })

    const renderVariables = () =>
      fields.map((field, index) => (
        <ColumnVariableForm
          key={field.id}
          index={index}
          variable={field}
          fieldIntent={fieldIntent}
          validationErrorMsg={validationErrorMsg}
          onRemove={remove}
          useFormMethods={useFormMethods}
          disableDelete={fields.length === 1}
        />
      ))

    const addVariable = () => {
      append({
        fetcher: "BookedTotalContractValue",
        name: indexToLetter(fields.length),
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      })
    }

    const nameField = register("name", {
      required: "Column name is required",
    })
    const formulaField = register("formula")
    return (
      <>
        <Label>
          Column name
          <InputGroup
            large
            intent={fieldIntent(errors, nameField.name)}
            name={nameField.name}
            placeholder="Revenue Q1 `22"
            autoFocus
            autoComplete="off"
            inputRef={nameField.ref}
            onChange={nameField.onChange}
            onBlur={nameField.onBlur}
          />
          {validationErrorMsg(errors, nameField.name)}
        </Label>
        {renderVariables()}
        <div className={classnames(formCss.fieldsRow)}>
          <div className={formCss.fieldsRowActionButton}>
            <Button
              text="Add variable"
              minimal
              outlined
              onClick={addVariable}
              intent={Intent.PRIMARY}
            />
          </div>
        </div>
        {fields.length > 1 && (
          <Label>
            Formula
            <InputGroup
              large
              intent={fieldIntent(errors, formulaField.name)}
              name={formulaField.name}
              placeholder="ex. a - b"
              autoComplete="off"
              inputRef={formulaField.ref}
              onChange={formulaField.onChange}
              onBlur={formulaField.onBlur}
            />
            {validationErrorMsg(errors, formulaField.name)}
          </Label>
        )}
      </>
    )
  }
)

type AddCustomersTableColumnDrawerProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (formData: ColumnFormType) => Promise<any>
  column: ColumnFormType
}

const AddCustomersTableColumnDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  column,
}: AddCustomersTableColumnDrawerProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const defaultValues = useCallback((column: ColumnFormType) => {
    if (column.id) return column

    return {
      ...column,
      name: "",
      formula: "",
      variables: [
        {
          fetcher: "BookedTotalContractValue",
          name: "a",
          start_month: "",
          start_year: "",
          end_month: "",
          end_year: "",
        } as ColumnVariable,
      ],
    }
  }, [])

  const useFormMethods = useForm<ColumnFormType>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues(column),
  })

  const { getValues, reset, trigger, setError } = useFormMethods

  useEffect(() => {
    reset(defaultValues(column))
  }, [column, reset, defaultValues])

  const isValidVariable = (variable: ColumnVariable, index: number) => {
    const startDate = new Date(
      parseInt(variable.start_year),
      parseInt(variable.start_month) - 1,
      1
    )
    const endDate = new Date(
      parseInt(variable.end_year),
      parseInt(variable.end_month) - 1,
      1
    )
    if (startDate > endDate) {
      setError(`variables.${index}`, {
        type: "custom",
        message: "From date can not be ahead of the To date",
      })

      return false
    }

    return true
  }

  const onSave = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()

        const variableValidation = values.variables.map((variable, index) =>
          isValidVariable(variable, index)
        )

        if (variableValidation.includes(false)) return

        if (values.variables.length === 1) {
          values.formula = values.variables[0].name
        }

        if (values.variables.length > 1 && values.formula === "") {
          setError("formula", {
            type: "custom",
            message: "Formula is required when using more than one variable",
          })
          return
        }

        setSubmitting(true)
        onSubmit(values)
          .then(() => onClose())
          .catch((errors: any) => {
            if (
              errors.response.status === 400 ||
              errors.response.status === 422
            ) {
              errors.response.json().then((errorData: any) => {
                setValidationErrors(
                  Object.values<string[]>(errorData.errors).flat()
                )
              })
            } else {
              setValidationErrors([
                "Something went wrong and we could not create this column. Please try again or contact us at support@getprobe.io",
              ])
            }
          })
          .finally(() => setSubmitting(false))
      }
    })
  }

  const onCancel = () => {
    setValidationErrors([])
    reset(defaultValues(column))
    onClose()
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={onCancel}
      title="Create column"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={formCss.insideDrawer}>
          <H3 className={formCss.title}>Column definition</H3>
          <p>Create calculated column on customers table</p>
          <div className={formCss.body}>
            <AddCustomersTableColumnForm useFormMethods={useFormMethods} />
            {validationErrors.map((error, index) => (
              <p key={index} className={formCss.errorLabel}>
                {error}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            text={
              column.id
                ? "Save changes and recalculate"
                : "Create column and calculate"
            }
            outlined
            onClick={onSave}
            loading={submitting}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onCancel}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default AddCustomersTableColumnDrawer

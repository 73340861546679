import React from "react"
import { H1, H2, Button, Intent } from "@blueprintjs/core"
import BusinessReportsContainer from "./BusinessReportsContainer"
import { DataSources } from "./settings/Settings"
import classNames from "classnames"
import YouTube from "react-youtube"
import { useHistory } from "react-router-dom"

import css from "./GettingStarted.module.css"
type GettingStartedProps = {
  integrations: any
  setIntegrations: () => void
}

type YoutubeEmbedProp = {
  videoId: string
}

const YoutubeEmbed = ({ videoId }: YoutubeEmbedProp) => {
  const opts = {
    height: "360",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  }

  return (
    <div className={css.youtubeEmbed}>
      <YouTube videoId={videoId} opts={opts} />
    </div>
  )
}

const GettingStarted = ({
  integrations,
  setIntegrations,
}: GettingStartedProps) => {
  const history = useHistory()

  const filterIntegrations = (integrations: any) => {
    if (Object.keys(integrations).length === 0) return integrations

    return ["stripe", "chargebee", "hubspot"].reduce(
      (obj: any, key: string) => {
        if (Object.keys(integrations).includes(key)) {
          obj[key] = integrations[key]
          return obj
        }

        return obj
      },
      {}
    )
  }

  const onCreateNewReport = () => {
    history.push("/reports/library")
  }

  return (
    <div className={classNames(css.gettingStartedContainer)}>
      <div className={css.stepSection}>
        <H1>&#127881;&nbsp;Getting Started</H1>
        <p>
          Welcome to Probe. Here is a couple of steps to get you up and running.
          Whatch video bellow to learn how to setup your Probe account.
        </p>
        <YoutubeEmbed videoId="vVyWEtoX0xA" />
      </div>
      <div className={css.stepSection}>
        <H2>Step 1: View demo report</H2>
        <p>
          If you just wanna see how reports in probe look like - here is a demo
          one for you. It missing some of the features - as access to underlying
          data. But overall is a good place to start if you want just to look
          around
        </p>
        <BusinessReportsContainer />
      </div>
      <div className={css.stepSection}>
        <H2>Step 2: Connect you data</H2>
        <p>There are many ways to connect you data - here is some</p>
        <DataSources
          integrations={filterIntegrations(integrations)}
          setIntegration={setIntegrations}
        />
      </div>
      <div className={css.stepSection}>
        <H2>Step 3: Create your first report</H2>
        <p>
          You can pick one of the predefined templates or create an empty report
          and add any analytical section you like.
        </p>
        <p>
          <Button
            large
            outlined
            intent={Intent.PRIMARY}
            text="Create new report"
            icon="plus"
            onClick={onCreateNewReport}
          />
        </p>
      </div>
      <div className={css.stepSection}>
        <H2>Step 4: Setup segmentation to dig deeper</H2>
        <p>
          Segmenting your data by different attributes let you extract maximum
          from what you got. Here is how you can do it.
        </p>
        <YoutubeEmbed videoId="ABnk62VSB9M" />
      </div>
      <div className={css.stepSection}>
        <H2>Did you get lost? Let us help!</H2>
        <p>
          We are here to get you up and running with Probe - if you have any
          questions just send as an email.
        </p>
      </div>
    </div>
  )
}

export default GettingStarted

import React, { useContext, useState, useEffect } from "react"
import {
  Button,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  Classes,
  HTMLSelect,
  ControlGroup,
  FormGroup,
} from "@blueprintjs/core"
import differenceInMonths from "date-fns/differenceInMonths"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

import popoverCss from "./popoverForm.module.css"
import css from "./EditReportRangePopover.module.css"

const EditReportRangePopover = ({
  target,
  period,
  onUpdateSuccess,
  reportId,
  className,
  disabled,
}) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const userContext = useContext(UserContext)

  const [startYear, setStartYear] = useState(period.start_year)
  const [startMonth, setStartMonth] = useState(period.start_month)
  const [endYear, setEndYear] = useState(period.end_year)
  const [endMonth, setEndMonth] = useState(period.end_month)
  const [validationError, setValidationError] = useState(null)

  const revertStateToOriginal = () => {
    setStartYear(period.start_year)
    setStartMonth(period.start_month)
    setEndYear(period.end_year)
    setEndMonth(period.end_month)
    setValidationError(null)
  }

  const validateRange = () => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1

    // you can not move report to the future
    if (
      endYear > currentYear ||
      (endMonth > currentMonth && endYear === currentYear)
    ) {
      setValidationError(`Can not extend range beyound current date.`)
      return
    }

    // validate for started At being > then ended at
    const startDate = new Date(startYear, startMonth - 1, 1)
    const endDate = new Date(endYear, endMonth - 1, 1)
    if (startDate > endDate) {
      setValidationError("From date can not be ahead of the To date")
      return
    }

    // validate for min 3 month
    const diffInMonth = differenceInMonths(endDate, startDate) + 1

    // difference is in a full month and we take first day to creare date
    // ex. 1st Nov 2020 - 1st Jan 2021 - gives 2 full month but it is still OK for us
    // cause we show full months on backend
    if (diffInMonth < 3) {
      setValidationError("Total report range should be at least 3 months")
      return
    }

    if (diffInMonth > 72) {
      setValidationError("Maximum range report can show is 6 years")
      return
    }

    // validate for max 72 month
    setValidationError(null)
  }

  useEffect(validateRange, [startYear, startMonth, endYear, endMonth])

  const renderSelect = (values, selectedValue, onChange) => (
    <HTMLSelect onChange={onChange} defaultValue={selectedValue}>
      {values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </HTMLSelect>
  )

  const onStartYearChange = (event) => {
    setStartYear(parseInt(event.currentTarget.value))
  }

  const onStartMonthChange = (event) => {
    setStartMonth(
      MONTHS.findIndex((month) => month === event.currentTarget.value) + 1
    )
  }

  const onEndYearChange = (event) => {
    setEndYear(parseInt(event.currentTarget.value))
  }

  const onEndMonthChange = (event) => {
    setEndMonth(
      MONTHS.findIndex((month) => month === event.currentTarget.value) + 1
    )
  }

  const selectedPeriod = () => ({
    period: {
      start_year: startYear,
      start_month: startMonth,
      end_year: endYear,
      end_month: endMonth,
    },
  })

  const renderEndYear = () => {
    const currentYear = new Date().getFullYear()
    let years = [period.end_year].concat(
      [...Array(7).keys()].map((key) => period.end_year - (key + 1))
    )
    years = years.concat(
      [...Array(currentYear - period.end_year).keys()].map(
        (key) => period.end_year + (key + 1)
      )
    )
    return renderSelect(years.sort(), period.end_year, onEndYearChange)
  }

  const renderStartYear = () => {
    const currentYear = new Date().getFullYear()
    let years = [period.end_year].concat(
      [...Array(7).keys()].map((key) => period.end_year - (key + 1))
    )
    years = years.concat(
      [...Array(currentYear - period.end_year).keys()].map(
        (key) => period.end_year + (key + 1)
      )
    )
    return renderSelect(years.sort(), period.start_year, onStartYearChange)
  }

  const onReportRangeUpdate = () => {
    return userContext
      .fetch(`/business_reports/${reportId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedPeriod()),
      })
      .then((data) => onUpdateSuccess(data))
      .catch(() =>
        AppToaster.showError({
          message: "Could not update your report's range",
        })
      )
  }

  const renderEditForm = () => {
    return (
      <div className={popoverCss.popover}>
        <div className={popoverCss.popoverBody}>
          <div className={css.reportRange}>
            <FormGroup label="From" labelInfo="(required)">
              <ControlGroup fill>
                {renderSelect(
                  MONTHS,
                  MONTHS[startMonth - 1],
                  onStartMonthChange
                )}
                {renderStartYear()}
              </ControlGroup>
            </FormGroup>
            <FormGroup label="To" labelInfo="(required)">
              <ControlGroup fill>
                {renderSelect(MONTHS, MONTHS[endMonth - 1], onEndMonthChange)}
                {renderEndYear()}
              </ControlGroup>
            </FormGroup>
            {validationError && (
              <p className={css.validationMsg}>{validationError}</p>
            )}
          </div>
        </div>
        <div className={popoverCss.actions}>
          <Button
            outlined
            disabled={!!validationError}
            intent={Intent.PRIMARY}
            text="Update and recalculate"
            className={Classes.POPOVER_DISMISS}
            onClick={onReportRangeUpdate}
          />
          <Button
            className={Classes.POPOVER_DISMISS}
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={revertStateToOriginal}
          />
        </div>
      </div>
    )
  }

  return (
    <Popover
      className={className}
      target={target}
      content={renderEditForm()}
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
      boundary="window"
      position={Position.BOTTOM}
      disabled={disabled}
    />
  )
}

export default EditReportRangePopover

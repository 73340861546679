import { Button, H5, Tag } from "@blueprintjs/core"
import React from "react"
import { FilterValueArgument, Serie, UserSection } from "./types"

import css from "./newSection.module.css"
import { UseFormReturn } from "react-hook-form"
import { FilterField, FilterValue } from "./FilterFieldSelect"
import GroupsListForm from "./GroupsListForm"
import SerieValueForm from "./SerieValueForm"
import ValidationError from "./ValidationError"

type SerieFormProps = {
  index: number
  serie: Serie
  removeSerie: (index: number) => void
  updateSerie: (index: number, serie: Serie) => void
  useFormMethods: UseFormReturn<UserSection>
  filterFields: FilterField[]
  values: FilterValue[]
}

const SerieForm = ({
  index,
  serie,
  updateSerie,
  removeSerie,
  useFormMethods,
  filterFields,
  values,
}: SerieFormProps) => {
  const selectedValueLabel = () => {
    return values.find(
      (value) =>
        value.object === serie.value?.object &&
        value.attribute === serie.value?.attribute
    )?.label
  }

  const renderValue = () => {
    return (
      <Tag large minimal>
        <b>{selectedValueLabel()}</b>
      </Tag>
    )
  }

  const renderReference = () => {
    return (
      <Tag large minimal>
        Formula reference: {serie.reference}
      </Tag>
    )
  }

  const findSelectedArgumentValue = (arg: FilterValueArgument) => {
    if (arg.type === "list") {
      const selectedOption = arg.options?.find((opt) => opt.value === arg.value)
      return selectedOption?.label
    }

    return arg.value
  }

  const renderArguments = () => {
    return serie.value.arguments.map((arg, index) => {
      return (
        <Tag key={index} minimal large>{`${
          arg.label
        }: ${findSelectedArgumentValue(arg)}`}</Tag>
      )
    })
  }

  const onRemoveSerie = () => {
    removeSerie(index)
  }

  const allowedFilterInput = () => {
    return filterFields.filter((filter) =>
      filter.value_objects.includes(serie.value.object)
    )
  }

  return (
    <div>
      <H5 className={css.title}>
        {serie.name}{" "}
        <Button small minimal icon="trash" onClick={onRemoveSerie} />
      </H5>
      <ValidationError
        errors={useFormMethods.formState.errors}
        name={`series.${index}`}
      />
      <div className={css.displayRow}>
        <SerieValueForm
          onSave={(serie: Serie) => updateSerie(index, serie)}
          defaultValues={serie}
          values={values}
          target={<Button minimal icon="edit" intent="primary" />}
        />
        {renderValue()}
        {renderArguments()}
        {renderReference()}
        {serie.hidden && (
          <Tag minimal large>
            Hidden
          </Tag>
        )}
      </div>
      <GroupsListForm
        useFormMethods={useFormMethods}
        serieIndex={index}
        filterFields={allowedFilterInput()}
      />
    </div>
  )
}

export default SerieForm

import React, { useContext } from "react"
import { MenuItem } from "@blueprintjs/core"
import queryString from "query-string"
import { UserContext } from "./UserContext"

type BusinessReportSectionExportProps = {
  sectionId: string
  segmentId: string
  variant: "money" | "customer_count" | "units_sold"
  segmentValue?: string | string[]
}

type QueryProps = {
  withSegmentValue: boolean
}

const BusinessReportSectionExport = ({
  sectionId,
  segmentId,
  variant,
  segmentValue,
}: BusinessReportSectionExportProps) => {
  const userContext = useContext(UserContext)
  const segmentValueNormalized = segmentValue
    ? Array.isArray(segmentValue)
      ? "_grouped"
      : segmentValue
    : null

  const query = ({ withSegmentValue }: QueryProps) =>
    queryString.stringify(
      {
        segment_id: segmentId,
        variant: variant,
        segment_value: withSegmentValue ? segmentValueNormalized : undefined,
      },
      { skipNull: true }
    )

  const onExport = () => {
    const url = `/business_report/sections/${sectionId}/export_data?${query({
      withSegmentValue: true,
    })}`
    userContext.performDownloadWithAuthorization(url)
  }

  const onExportAll = () => {
    const url = `/business_report/sections/${sectionId}/export_data?${query({
      withSegmentValue: false,
    })}`
    userContext.performDownloadWithAuthorization(url)
  }

  return (
    <>
      <MenuItem text="Export numbers" onClick={onExport} />
      {segmentValue && (
        <MenuItem
          text="Export numbers of all segment values"
          onClick={onExportAll}
        />
      )}
    </>
  )
}

export default BusinessReportSectionExport

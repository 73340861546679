import React, { useContext } from "react"
import { DataSource } from "./Settings"
import { UserContext } from "../UserContext"
import { apiUrl } from "../apiHelpers"

const StripeDataSource = ({ integration }) => {
  const userContext = useContext(UserContext)

  const onCardClick = () => {
    if (integration.enabled) {
      return
    }

    const authorizationParam = encodeURIComponent(userContext.user.api_token)
    const url = apiUrl(
      `/users/auth/stripe_connect?authorization=${authorizationParam}`
    )
    window.location.href = url
  }

  return (
    <DataSource
      integration={integration}
      containerClassName="stripe"
      onClick={onCardClick}
    />
  )
}

export default StripeDataSource

import React, { useCallback, useMemo } from "react"
import { Intent, Button, Tag } from "@blueprintjs/core"
import DataTable, {
  formatDate,
  formatText,
  DataType,
  EMPTY_VALUE,
} from "./DataTable"
import { formatCurrency } from "./formatters"

import css from "./CustomersTable.module.css"
import tableCss from "./DataTable.module.css"

export const CustomersTable = ({
  customers,
  onSort,
  onNextPage,
  onPrevPage,
  range,
  onDetails,
  customerColumns,
  fullWidth,
}) => {
  const customerMetricAccessor = (metricId) => {
    return (row) => {
      const mrrMetric = row.metrics.find(
        (metric) =>
          metric.kind === metricId || metric.definition_id === metricId
      )
      if (!mrrMetric || !mrrMetric.value) {
        return EMPTY_VALUE
      }
      return formatCurrency(parseFloat(mrrMetric.value))
    }
  }

  const customerSegmentAccessor = (segmentId) => {
    return (row) => {
      const segment = row.segments.find(
        (segmentDefinition) => segmentDefinition.segment.id === segmentId
      )

      if (!segment || !segment.value) {
        return EMPTY_VALUE
      }

      return <Tag minimal>{segment.value}</Tag>
    }
  }

  const columnDefinitionToTableColumn = useCallback((columnDefinition) => {
    return {
      id: `metrics.user_defined.${columnDefinition.id}`,
      Header: columnDefinition.name,
      className: css.mrr,
      dataType: DataType.CURRENCY,
      accessor: customerMetricAccessor(columnDefinition.id),
    }
  }, [])

  const customerSegmentToTableColumn = useCallback((columnDefinition) => {
    return {
      id: `customer_segment.${columnDefinition.id}`,
      Header: columnDefinition.name,
      dataType: DataType.STRING,
      accessor: customerSegmentAccessor(columnDefinition.id),
      disableSortBy: !columnDefinition.sortable,
    }
  }, [])

  const columnNameSpecificProps = useCallback(
    (column) => {
      switch (column.id) {
        case "name":
          return {
            Cell: (props) => (
              <span
                className={tableCss.clickableText}
                onClick={() => onDetails({ id: props.row.original.id })}
              >
                {formatText(props.cell.value)}
              </span>
            ),
          }
        case "mrr":
          return {
            id: "metrics.mrr",
            accessor: customerMetricAccessor("mrr"),
          }
        case "lifetime_value":
          return {
            id: "metrics.lifetime_value",
            accessor: customerMetricAccessor("lifetime_value"),
          }
        case "added_at":
          return {
            accessor: (row) => formatDate(row.added_at),
          }
        default:
          return {}
      }
    },
    [onDetails]
  )

  const sortType = useCallback((column) => {
    if (column.data_type === "date") {
      return "datetime"
    } else if (column.data_type === "string") {
      return "basic"
    } else {
      return ""
    }
  }, [])

  const standardCustomerColumnToTableColumn = useCallback(
    (column) => {
      return {
        id: column.id,
        accessor: column.id,
        Header: column.name,
        disableSortBy: !column.sortable,
        sortType: sortType(column),
        dataType: column.data_type,
        ...columnNameSpecificProps(column),
        className: css[column.id],
      }
    },
    [columnNameSpecificProps, sortType]
  )

  const columns = useMemo(() => {
    return customerColumns.map((column) => {
      switch (column.source) {
        case "customer_segment":
          return customerSegmentToTableColumn(column)
        case "definition":
          return columnDefinitionToTableColumn(column.definition)
        case "static":
          return standardCustomerColumnToTableColumn(column)
        default:
          console.warn("Unknown column type")
      }
    })
  }, [
    customerColumns,
    columnDefinitionToTableColumn,
    standardCustomerColumnToTableColumn,
    customerSegmentToTableColumn,
  ])

  return (
    <>
      <DataTable
        columns={columns}
        data={customers}
        onSort={onSort}
        fullWidth={fullWidth}
        capitalize
      />
      <div className={css.navigation}>
        <Button
          onClick={onPrevPage}
          disabled={onPrevPage ? false : true}
          text="Previous page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
        {range && <div>{`${range[0]} - ${range[1]}`}</div>}
        <Button
          onClick={onNextPage}
          disabled={onNextPage ? false : true}
          text="Next page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
      </div>
    </>
  )
}

export default CustomersTable

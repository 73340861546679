import React from "react"
import { rollbar } from "./rollbar"
import { FailureState } from "./NonIdealState"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    rollbar.error(error)
  }

  render() {
    if (this.state.hasError) {
      return <FailureState title={this.props.errorMsg} inDialog />
    }

    return this.props.children
  }
}

import Rollbar from "rollbar"

export const rollbar = new Rollbar({
  enabled: process.env.NODE_ENV === "production",
  accessToken: "bc023a24c3c74866b3c0f5ec91a508a1",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ENV_NAME,
    client: {
      javascript: {
        code_version: process.env.REACT_APP_COMMIT_HASH,
      },
    },
  },
})

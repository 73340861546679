import React from "react"
import { Button } from "@blueprintjs/core"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import FilterArgumentForm from "./FilterArgumentForm"
import { FilterField } from "./FilterFieldSelect"
import { FilterNodeArgument, UserSection } from "./types"

import css from "./newSection.module.css"

type FilterNodeFormProps = {
  useFormMethods: UseFormReturn<UserSection>
  items: FilterField[]
  serieIndex: number
  groupIndex: number
  nodeIndex: number
  onRemove: (index: number) => void
}

const FilterNodeForm = ({
  useFormMethods,
  items,
  serieIndex,
  groupIndex,
  nodeIndex,
  onRemove,
}: FilterNodeFormProps) => {
  const { control } = useFormMethods
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `series.${serieIndex}.groups.${groupIndex}.definition.${nodeIndex}.arguments`,
  })

  const onUpdateArgument = (index: number, value: FilterNodeArgument) => {
    update(index, value)
  }

  const onAddArgument = (_index: number, value: FilterNodeArgument) => {
    append(value)
  }

  const onArgumentRemove = (index: number) => {
    remove(index)
  }

  return (
    <div className={css.displayRow}>
      <Button
        title="Remove filters"
        minimal
        icon="filter-remove"
        onClick={() => onRemove(nodeIndex)}
      />
      {fields.map((field, index) => {
        return (
          <div key={index} className={css.filterArgument}>
            <FilterArgumentForm
              key={field.id}
              index={index}
              items={items}
              onRemove={onArgumentRemove}
              onSave={onUpdateArgument}
              defaultValues={field}
            />
            {index < fields.length - 1 && (
              <span style={{ color: "gray", alignSelf: "center" }}>and</span>
            )}
          </div>
        )
      })}
      <FilterArgumentForm
        key={-1}
        index={-1}
        items={items}
        onRemove={onArgumentRemove}
        onSave={onAddArgument}
        target={
          <Button icon="plus" minimal intent="primary" title="Add criteria" />
        }
      />
    </div>
  )
}

export default FilterNodeForm

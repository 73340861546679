import React from "react"
import {
  Menu,
  MenuDivider,
  MenuItem,
  Intent,
  Popover,
  Button,
  Position,
  IconName,
} from "@blueprintjs/core"
import { SectionSettingsType } from "./BusinessReportChartSection"

type SectionSettingsMenuProps = {
  onRemoveSectionSegment: (segmentId: string) => void
  onRemoveSection: () => void
  onEditCalculation: () => void
  onMoveUp: () => void
  onMoveDown: () => void
  updateSectionSettings: (settings: SectionSettingsType) => void
  sectionSettings: SectionSettingsType
  definitionId: string
  groupingPeriods?: Types.Api.SectionDefinition["supported_grouping_periods"]
  isFirstSection: boolean
  isLastSection: boolean
  activeSegmentId?: string
  activeSegmentName?: string
}

const SectionSettingsMenu = ({
  onRemoveSectionSegment,
  onRemoveSection,
  onEditCalculation,
  onMoveUp,
  onMoveDown,
  isFirstSection,
  isLastSection,
  updateSectionSettings,
  sectionSettings,
  definitionId,
  groupingPeriods,
  activeSegmentId,
  activeSegmentName,
}: SectionSettingsMenuProps) => {
  const iconForChartDataType = (type: Types.Api.SectionDataType): IconName => {
    if (type === "line_chart") return "timeline-line-chart"
    if (type === "bar_chart") return "timeline-bar-chart"
    if (type === "stacked_bar_chart") return "stacked-chart"

    return "timeline-events"
  }

  const onVisualizationClick = (event: any) => {
    updateSectionSettings({
      ...sectionSettings,
      dataType: event.currentTarget.id,
    })
  }

  const onResolutionClick = (event: any) => {
    updateSectionSettings({
      ...sectionSettings,
      resolution: event.currentTarget.id,
    })
  }

  const supportedChartDataTypes = {
    line_chart: "Line chart",
    bar_chart: "Bar chart",
    stacked_bar_chart: "Stacked bar",
  }

  const supportedResolutionTypes = {
    week: "Week",
    month: "Month",
    quarter: "Quarter",
    year: "Year",
  }

  const renderResolutionItems = () => {
    if (!groupingPeriods) return <></>
    return (
      <>
        <MenuDivider title="Resolution" />
        {groupingPeriods.map((resolutionType) => {
          return (
            <MenuItem
              id={resolutionType}
              key={`${resolutionType}-${supportedResolutionTypes[resolutionType]}`}
              text={supportedResolutionTypes[resolutionType]}
              active={sectionSettings.resolution === resolutionType}
              onClick={onResolutionClick}
            />
          )
        })}
      </>
    )
  }

  const renderVisualizationItems = () => {
    const isMrrMovementSection = definitionId === "existing_mrr_movement"
    const chartDataTypeName = Object.keys(supportedChartDataTypes).includes(
      sectionSettings.dataType
    )

    if (!chartDataTypeName || isMrrMovementSection) {
      return null
    }

    return (
      <>
        <MenuDivider title="Visualization" />
        {Object.entries(supportedChartDataTypes).map(([itemType, itemName]) => {
          return (
            <MenuItem
              id={itemType}
              key={`${itemType}-${itemName}`}
              icon={iconForChartDataType(itemType as Types.Api.SectionDataType)}
              text={itemName}
              active={sectionSettings.dataType === itemType}
              onClick={onVisualizationClick}
            />
          )
        })}
      </>
    )
  }

  const onParentBehaviorChangeClick = (event: any) => {
    updateSectionSettings({
      ...sectionSettings,
      useHierarchy: event.currentTarget.id === "group" ? true : false,
    })
  }

  const renderParentsBehaviorItems = () => {
    return (
      <>
        <MenuDivider title="Customers hierarchy" />
        <MenuItem
          id="group"
          text="Active"
          icon="layout-hierarchy"
          onClick={onParentBehaviorChangeClick}
          active={sectionSettings.useHierarchy}
        />
        <MenuItem
          id="separate"
          text="Disabled"
          onClick={onParentBehaviorChangeClick}
          active={!sectionSettings.useHierarchy}
        />
      </>
    )
  }

  const renderSettingsContent = () => (
    <Menu>
      <MenuDivider title="Position" />
      <MenuItem
        text="Move up"
        disabled={isFirstSection}
        icon="arrow-up"
        onClick={onMoveUp}
      />
      <MenuItem
        text="Move down"
        disabled={isLastSection}
        icon="arrow-down"
        onClick={onMoveDown}
      />
      {renderVisualizationItems()}
      {renderResolutionItems()}
      {renderParentsBehaviorItems()}
      <MenuDivider />
      {definitionId === "user_filtered" && (
        <MenuItem
          text="Edit calculation"
          icon="edit"
          onClick={onEditCalculation}
        />
      )}
      {activeSegmentId && activeSegmentName && (
        <MenuItem
          text={`Delete segmentation: ${activeSegmentName}`}
          onClick={() => onRemoveSectionSegment(activeSegmentId)}
          icon="trash"
          intent={Intent.DANGER}
        />
      )}
      <MenuItem
        text="Delete section"
        icon="trash"
        onClick={onRemoveSection}
        intent={Intent.DANGER}
      />
    </Menu>
  )

  return (
    <Popover
      content={renderSettingsContent()}
      position={Position.BOTTOM}
      placement="bottom"
    >
      <Button icon="cog" minimal intent={Intent.NONE} />
    </Popover>
  )
}

export default SectionSettingsMenu

import React from "react"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import SerieForm from "./SerieForm"
import { Serie, UserSection } from "./types"
import css from "./newSection.module.css"
import { FilterField, FilterValue } from "./FilterFieldSelect"
import SerieValueForm from "./SerieValueForm"
import { indexToLetter } from "../textHelpers"
import ValidationError from "./ValidationError"

type SeriesListFormProps = {
  useFormMethods: UseFormReturn<UserSection>
  values: FilterValue[]
  inputFilters: FilterField[]
}

const SeriesListForm = ({
  useFormMethods,
  values,
  inputFilters,
}: SeriesListFormProps) => {
  const { control, clearErrors, formState } = useFormMethods
  const { error } = useFormMethods.getFieldState(
    `series`,
    useFormMethods.formState
  )

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "series",
    rules: { minLength: 1 },
  })

  const defaultSerie = (): Serie => {
    return {
      name: `Serie${fields.length + 1}`,
      value: values[0],
      groups: [],
      reference: indexToLetter(fields.length),
      hidden: false,
    }
  }

  const renderAddNewSerie = () => {
    if (values.length == 0) return <></>

    return (
      <SerieValueForm
        values={values}
        onSave={onAddSerie}
        defaultValues={defaultSerie()}
      />
    )
  }

  const removeSerie = (index: number) => {
    remove(index)
  }

  const updateSerie = (index: number, serie: Serie) => {
    if (
      serie.value.object === "custom" &&
      serie.value.attribute === "formula" &&
      serie.definition
    ) {
      delete serie["groups"]
      delete serie["definition"]
    }
    update(index, serie)
  }

  const onAddSerie = (serie: Serie) => {
    if (error && error.type === "custom") {
      clearErrors("series")
    }
    append(serie)
  }

  return (
    <>
      <ValidationError errors={formState.errors} name="series" />
      {fields.length > 0 && (
        <div className={css.series}>
          {fields.map((field, index) => {
            return (
              <SerieForm
                key={field.id}
                index={index}
                serie={field}
                removeSerie={removeSerie}
                filterFields={inputFilters}
                useFormMethods={useFormMethods}
                values={values}
                updateSerie={updateSerie}
              />
            )
          })}
        </div>
      )}
      <div className={css.groupActions}>{renderAddNewSerie()}</div>
    </>
  )
}

export default SeriesListForm

import React from "react"
import { H1 } from "@blueprintjs/core"
import { formatCurrency } from "./formatters"

type CustomerDetailsSummaryProps = {
  metrics: {
    mrr: string
    accrued_total: string
    one_time_accrued_total: string
  }
  currency: string
}

import css from "./CustomerDetailsSummary.module.css"

type KeyNumberProps = {
  title: string
  number: string
  kindLabel: string
}

export const KeyNumber = ({ title, number, kindLabel }: KeyNumberProps) => (
  <div className={css.numberCard}>
    <span>{title}</span>
    <div className={css.number}>
      <span>{kindLabel}</span>
      <H1>{number}</H1>
    </div>
  </div>
)

const CustomerDetailsSummary = ({
  metrics,
  currency,
}: CustomerDetailsSummaryProps) => {
  return (
    <div className={css.summaryNumbers}>
      <KeyNumber
        title="MRR"
        kindLabel={currency}
        number={formatCurrency(parseFloat(metrics.mrr) || 0)}
      />
      <KeyNumber
        title="Total acquired MRR"
        kindLabel={currency}
        number={formatCurrency(parseFloat(metrics.accrued_total) || 0)}
      />
      <KeyNumber
        title="Total acquired one-time"
        kindLabel={currency}
        number={formatCurrency(parseFloat(metrics.one_time_accrued_total) || 0)}
      />
    </div>
  )
}

export default CustomerDetailsSummary

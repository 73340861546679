import React, { useEffect } from "react"
import {
  Drawer,
  Classes,
  Button,
  Intent,
  H3,
  Label,
  HTMLSelect,
  ControlGroup,
  InputGroup,
} from "@blueprintjs/core"
import { useForm, UseFormReturn, useFieldArray } from "react-hook-form"
import classnames from "classnames"
import formCss from "./forms.module.css"

export type ExploreSettingsFormData = {
  yAxisDimention:
    | "new_mrr_when_occurred"
    | "accrued_total"
    | "growth_since_start"
    | "mrr"
  xReferenceLines: { value: number; label: string }[]
  yReferenceLines: { value: number; label: string }[]
  segmentName: string
}

const yAxisDimentionOptions = [
  { label: "Starting MRR of the customer", value: "new_mrr_when_occurred" },
  {
    label: "Total acquired MRR",
    value: "accrued_total",
  },
  {
    label: "Growth % since starting MRR",
    value: "growth_since_start",
  },
  {
    label: "Current MRR",
    value: "mrr",
  },
]

type ExploreSettingsFormProps = {
  useFormMethods: UseFormReturn<ExploreSettingsFormData>
  segmentNames: string[]
}

type ReferenceLinesFormProps = {
  useFormMethods: UseFormReturn<ExploreSettingsFormData>
  fieldsName: "xReferenceLines" | "yReferenceLines"
}

const ReferenceLinesForm = ({
  useFormMethods,
  fieldsName,
}: ReferenceLinesFormProps) => {
  const { register, control } = useFormMethods
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldsName,
  })

  const renderReferenceLinesInputs = () => {
    return fields.map((field, index) => {
      const xValueField = register(`${fieldsName}.${index}.value`, {
        valueAsNumber: true,
      })
      const labelField = register(`${fieldsName}.${index}.label`)

      return (
        <>
          <div className={formCss.fieldsRow} key={field.id}>
            <div className={formCss.fieldsRowActionButton}>
              <Button
                icon="trash"
                minimal
                onClick={() => remove(index)}
                intent={Intent.PRIMARY}
              />
            </div>
            <ControlGroup fill>
              <Label>
                {index === 0 && "Reference value"}
                <InputGroup
                  id={field.id}
                  name={xValueField.name}
                  onChange={xValueField.onChange}
                  onBlur={xValueField.onBlur}
                  inputRef={xValueField.ref}
                  defaultValue={field.value ? field.value.toString() : ""}
                />
              </Label>
              <Label>
                {index === 0 && "Label"}
                <InputGroup
                  id={field.id}
                  name={labelField.name}
                  onChange={labelField.onChange}
                  onBlur={labelField.onBlur}
                  inputRef={labelField.ref}
                  defaultValue={field.label}
                />
              </Label>
            </ControlGroup>
          </div>
        </>
      )
    })
  }

  const appendReferenceLine = () => {
    append({ value: 0, label: "" })
  }

  return (
    <>
      {renderReferenceLinesInputs()}
      <div className={classnames(formCss.fieldsRow, formCss.justifyFlexEnd)}>
        <div className={formCss.fieldsRowActionButton}>
          <Button
            icon="add"
            text="Add reference line"
            minimal
            onClick={appendReferenceLine}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
    </>
  )
}

const ExploreSettingsForm = ({
  useFormMethods,
  segmentNames,
}: ExploreSettingsFormProps) => {
  const { register } = useFormMethods
  const yAxisDimentionField = register("yAxisDimention")
  const segmentNameField = register("segmentName")

  return (
    <div className={formCss.insideDrawer}>
      <div className={formCss.body}>
        <H3 className={formCss.title}>Explore dimentions</H3>
        <p>
          You can play with different dimentions to get a better understanding
          of the selected customer group
        </p>
        <Label>
          Y axis dimention
          <HTMLSelect
            large
            options={yAxisDimentionOptions}
            elementRef={yAxisDimentionField.ref}
            onChange={yAxisDimentionField.onChange}
            onBlur={yAxisDimentionField.onBlur}
            name={yAxisDimentionField.name}
          />
        </Label>
        <Label>
          Display with customer segment
          <HTMLSelect
            large
            options={[{ label: "Select segment ...", value: "" }].concat(
              segmentNames.map((name) => ({ label: name, value: name }))
            )}
            elementRef={segmentNameField.ref}
            onChange={segmentNameField.onChange}
            onBlur={segmentNameField.onBlur}
            name={segmentNameField.name}
          />
        </Label>
        <br />
        <H3 className={formCss.title}>Reference lines</H3>
        <p>X axis reference lines</p>
        <ReferenceLinesForm
          useFormMethods={useFormMethods}
          fieldsName="xReferenceLines"
        />
        <p>Y axis reference lines</p>
        <ReferenceLinesForm
          useFormMethods={useFormMethods}
          fieldsName="yReferenceLines"
        />
      </div>
    </div>
  )
}

type ExploreSettingsDrawerProps = {
  onSubmit: (formData: ExploreSettingsFormData) => void
  segmentNames: string[]
  formData: ExploreSettingsFormData
  isOpen: boolean
  onClose: () => void
}

const ExploreSettingsDrawer = ({
  onSubmit,
  isOpen,
  onClose,
  formData,
  segmentNames,
}: ExploreSettingsDrawerProps) => {
  const useFormMethods = useForm<ExploreSettingsFormData>({
    mode: "all",
    defaultValues: {
      ...formData,
    },
  })

  const { getValues, reset, formState, trigger } = useFormMethods

  useEffect(() => reset(formData), [reset, formData])

  const closeDrawer = () => {
    reset(formData)
    onClose()
  }

  const onFormSave = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()
        onSubmit(values)
        onClose()
      }
    })
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={closeDrawer}
      title="Explore different dimentions"
    >
      <div className={Classes.DRAWER_BODY}>
        <ExploreSettingsForm
          useFormMethods={useFormMethods}
          segmentNames={segmentNames}
        />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            disabled={!formState.isValid}
            text="Save"
            outlined
            onClick={onFormSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default ExploreSettingsDrawer

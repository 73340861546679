import React, { useState } from "react"
import { Icon, H3, Button, Intent, Tag } from "@blueprintjs/core"
import EditCustomerPopover from "./EditCustomerPopover"
import TimesensitiveSegmentValuesDialog from "./TimesensitiveSegmentValuesDialog"
import { IconName } from "@blueprintjs/icons"

import css from "./CustomerInfo.module.css"

type CustomerInfoProps = {
  customer: Types.Api.Customer
  onCustomerUpdated?: (customer: Types.Api.Customer) => void
}

const CustomerInfo = ({ customer, onCustomerUpdated }: CustomerInfoProps) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const [customerSegment, setCustomerSegment] =
    useState<Types.Api.CustomerSegment>()

  const editCustomerFormPopoverTarget = () => (
    <span>
      <Button
        data-testid="edit-customer-popover-btn"
        icon="edit"
        minimal
        intent={Intent.PRIMARY}
        text={!customer.name || customer.name.length === 0 ? "Edit" : ""}
      />
    </span>
  )

  const onDialogClose = () => {
    setDialogOpen(false)
    setCustomerSegment(undefined)
  }

  const onTimesensetiveSegmentClick = (
    customerSegment: Types.Api.CustomerSegment
  ) => {
    setCustomerSegment(customerSegment)
    setDialogOpen(true)
  }

  const timesensitiveSegmentValueProps = (
    customerSegmentItem: Types.Api.CustomerSegment
  ) => {
    if (!customerSegmentItem.segment.time_sensitive || !onCustomerUpdated)
      return {}

    return {
      icon: "layout-linear" as IconName,
      interactive: true,
      onClick: () => onTimesensetiveSegmentClick(customerSegmentItem),
      intent: Intent.PRIMARY,
    }
  }

  const updatedSegmentValue = (
    newCustomerSegment: Types.Api.CustomerSegment
  ) => {
    let newSegments: Types.Api.CustomerSegment[] = []
    if (newCustomerSegment.value) {
      newSegments = customer.segments.map((customerSegmentItem) =>
        customerSegmentItem.segment.id === newCustomerSegment.segment.id
          ? newCustomerSegment
          : customerSegmentItem
      )
    } else {
      newSegments = customer.segments.filter(
        (customerSegmentItem) =>
          customerSegmentItem.segment.id !== newCustomerSegment.segment.id
      )
    }

    if (onCustomerUpdated) {
      onCustomerUpdated({ ...customer, segments: newSegments })
    }
  }

  const renderCustomerSegments = () => {
    if (!customer.segments) return

    return customer.segments.map((customerSegmentItem) => (
      <Tag
        key={customerSegmentItem.segment.id}
        large
        minimal
        round
        {...timesensitiveSegmentValueProps(customerSegmentItem)}
      >
        {customerSegmentItem.value}
      </Tag>
    ))
  }

  const renderEditCustomerPopover = () => {
    if (!onCustomerUpdated) return

    return (
      <EditCustomerPopover
        customerFormData={customer}
        popoverTarget={editCustomerFormPopoverTarget()}
        customer={customer}
        onCustomerUpdated={onCustomerUpdated}
      />
    )
  }

  const renderExternalLinks = () => {
    if (!customer.links) return <></>

    return (
      <span className={css.externalLinks}>
        {customer.links.map((link, index) => (
          <Tag
            key={index}
            interactive
            minimal
            intent="primary"
            icon={<Icon icon="share" iconSize={12} />}
            onClick={() => window.open(link.url, "_blank", "noreferrer")}
          >
            {link.external_system}
          </Tag>
        ))}
      </span>
    )
  }

  return (
    <>
      {customerSegment && onCustomerUpdated && (
        <TimesensitiveSegmentValuesDialog
          isDialogOpen={isDialogOpen}
          onDialogClose={onDialogClose}
          customerId={customer.id}
          customerSegment={customerSegment}
          updatedSegmentValue={updatedSegmentValue}
        />
      )}
      <div className={css.customerHeader}>
        <div className={css.customerAvatar}>
          <Icon icon="office" iconSize={20} />
        </div>
        <div className={css.customerInfo}>
          <H3>
            {customer.name}&nbsp;{renderEditCustomerPopover()}
            {renderExternalLinks()}
          </H3>
          {customer.id && <span>{customer.id}</span>}
          <div className={css.segments}>{renderCustomerSegments()}</div>
        </div>
      </div>
    </>
  )
}

export default CustomerInfo

import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react"
import {
  Drawer,
  Button,
  Intent,
  Icon,
  Popover,
  Menu,
  MenuItem,
  Position,
  MenuDivider,
  H5,
  Callout,
} from "@blueprintjs/core"
import classnames from "classnames"
import { UserContext } from "./UserContext"
import DataTable, { DataType, formatText } from "./DataTable"
import { formatCurrency } from "./formatters"
import { LoadingOverlay } from "./NonIdealState"
import ConfirmDialog from "./ConfirmDialog"
import { AppToaster } from "./AppToaster"
import CustomerInfo from "./CustomerInfo"
import CustomerMergeDrawer from "./CustomerMergeDrawer"
import CustomerAssignParentDrawer from "./CustomerAssignParentDrawer"
import pluralize from "pluralize"

import CustomerSubscriptionHistoriesTable from "./CustomerSubscriptionHistoriesTable"

import formCss from "./forms.module.css"
import css from "./CustomerDetailsDrawer.module.css"
import CustomerDetailsSummary from "./CustomerDetailsSummary"
import TinyLineChart from "./charts/TinyLineChart"

const DRAWER_THEME = {
  key: "drawer_theme",
  drawerSize: "60%",
  cssClass: css.drawerTheme,
}

const FULL_SCREEN_THEME = {
  key: "full_screen_theme",
  drawerSize: "100%",
  cssClass: css.fullScreenTheme,
}

const ChildrenCustomersTable = ({
  customers,
  changeDetailedCustomer,
  userCurrency,
}) => {
  const findMetric = useCallback((metrics, kind) => {
    return metrics.find((metric) => metric.kind === kind)
  }, [])

  const columns = useMemo(() => {
    return [
      {
        id: "name",
        Header: "Name",
        className: css.col,
        accessor: (row) => (
          <span
            className={css.clickable}
            onClick={() => changeDetailedCustomer(row)}
          >
            {formatText(row.name)}
          </span>
        ),
        disableSortBy: true,
        Footer: "Total",
      },
      {
        id: "latest_mrr",
        Header: `Latest MRR (${userCurrency})`,
        accessor: (row) => {
          const mrr = findMetric(row.metrics, "mrr")
          return mrr ? formatCurrency(parseFloat(mrr.value)) : "--"
        },
        dataType: DataType.NUMBER,
        className: css.col,
        disableSortBy: true,
      },
      {
        id: "lifetime_value",
        Header: `Lifetime value (${userCurrency})`,
        accessor: (row) => {
          const total = findMetric(row.metrics, "total")
          return total ? formatCurrency(parseFloat(total.value)) : "--"
        },
        dataType: DataType.NUMBER,
        className: css.col,
        disableSortBy: true,
        Footer: (info) =>
          React.useMemo(() => {
            const sum = info.rows.reduce((sum, row) => {
              const total = findMetric(row.original.metrics, "total")
              return total ? sum + parseFloat(total.value) : sum
            }, 0)
            return formatCurrency(sum)
          }, [info.rows]),
      },
    ]
  }, [changeDetailedCustomer, userCurrency, findMetric])

  return (
    <DataTable
      wrapperClassName={css.timelineTable}
      columns={columns}
      data={customers}
      withFooter
    />
  )
}

const CustomerDetailsDrawer = ({
  customer,
  isOpen,
  onClose,
  onCustomerRemove,
  onSubscriptionRemove,
  onCustomerUpdated,
  changeDetailedCustomer,
}) => {
  const [parentCustomer, setParentCustomer] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingChildren, setLoadingChildren] = useState(false)
  const [childrendCustomers, setChildrenCustomers] = useState([])
  const [theme, setTheme] = useState(DRAWER_THEME)
  const [mergeDrawerOpen, setMergeDrawerOpen] = useState(false)
  const [assignParentDrawerOpen, setAssignParentDrawerOpen] = useState(false)
  const [customerMetrics, setCustomerMetrics] = useState(null)
  const [latestMonthMetrics, setLatestMonthMetrics] = useState(null)
  const [timelineLimit, setTimelineLimit] = useState(100)

  const userContext = useContext(UserContext)

  const fetchCustomerMetrics = () => {
    if (!customer.id) return

    setLatestMonthMetrics(null)
    setCustomerMetrics(null)

    userContext
      .fetch(`/customer_metrics/${customer.id}`, { method: "GET" })
      .then((data) => {
        setCustomerMetrics(
          data.timeline.map((point) => ({
            date: point.date,
            value: parseFloat(point.metrics.revenue || 0),
          }))
        )

        setLatestMonthMetrics(data.timeline.at(-1)?.metrics)
      })
  }
  useEffect(fetchCustomerMetrics, [userContext, customer.id])

  const fetchParentCustomer = () => {
    if (!customer.parent_id) return

    userContext
      .fetch(`/customers/${customer.parent_id}`, { method: "GET" })
      .then((data) => setParentCustomer(data))
      .catch(() => {
        AppToaster.showError({ message: "We could not fetch parent customer" })
      })
  }
  useEffect(fetchParentCustomer, [userContext, customer.parent_id])

  const fetchChildrenBatch = useCallback(
    (customerIds) => {
      return userContext.fetch("/customer/show_batch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids: customerIds }),
      })
    },
    [userContext]
  )

  const hasChildren = useMemo(
    () => customer.children_ids && customer.children_ids.length > 0,
    [customer]
  )

  const fetchChildrenCustomers = () => {
    if (!hasChildren) {
      return
    }

    const batches = []
    const batchSize = 100
    for (let i = 0; i < customer.children_ids.length; i += batchSize) {
      batches.push(customer.children_ids.slice(i, i + batchSize))
    }

    setLoadingChildren(true)
    Promise.all(batches.map((batch) => fetchChildrenBatch(batch)))
      .then((responses) => {
        const customers = responses
          .flatMap((data) => data.items)
          .sort((a, b) => parseFloat(b.metrics.mrr) - parseFloat(a.metrics.mrr))
        setChildrenCustomers(customers)
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "We could not fetch children customers.",
        })
      })
      .finally(() => setLoadingChildren(false))
  }

  useEffect(fetchChildrenCustomers, [
    userContext,
    customer,
    fetchChildrenBatch,
    hasChildren,
  ])

  const fetchSubscriptions = useCallback(() => {
    if (!customer.id) return

    const startedAtSort =
      localStorage.getItem("customer_details_payments_sort") || "desc"
    setLoading(true)
    const params = new URLSearchParams()
    params.set("currency", userContext.user.currency)
    if (timelineLimit) {
      params.set("limit", timelineLimit)
    }
    params.set("sort_by", `started_at:${startedAtSort}`)
    userContext
      .fetch(`/customers/${customer.id}/timeline?${params.toString()}`, {
        method: "GET",
      })
      .then((data) => {
        setLoading(false)
        setSubscriptions(data.items)
      })
      .catch(() => {
        setSubscriptions([])
        setLoading(false)
      })
  }, [userContext, customer.id, timelineLimit])

  const onSort = useCallback(
    (sortBy) => {
      if (sortBy.length > 0) {
        const { desc } = sortBy[0]
        localStorage.setItem(
          "customer_details_payments_sort",
          desc ? "desc" : "asc"
        )
        fetchSubscriptions()
      }
    },
    [fetchSubscriptions]
  )

  const onRemoveSuccess = useCallback(() => {
    fetchSubscriptions()
    if (onSubscriptionRemove) {
      onSubscriptionRemove()
    }
  }, [fetchSubscriptions, onSubscriptionRemove])

  const onUpdateSuccess = useCallback(
    () => fetchSubscriptions(),
    [fetchSubscriptions]
  )

  const [confirmOpen, setConfirmOpen] = useState(false)

  const cancelCustomerRemove = () => {
    setConfirmOpen(false)
  }

  const showCustomerRemoveDialog = () => {
    setConfirmOpen(true)
  }

  const toggleTheme = () => {
    setTheme(theme.key === DRAWER_THEME.key ? FULL_SCREEN_THEME : DRAWER_THEME)
  }

  const confirmCustomerRemove = useCallback(() => {
    setConfirmOpen(false)
    userContext
      .fetch(`/customers/${customer.id}`, {
        method: "DELETE",
      })
      .then(() => {
        onCustomerRemove(customer.id)
        onClose()
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not remove customer",
        })
      })
  }, [customer.id, onClose, onCustomerRemove, setConfirmOpen, userContext])

  useEffect(fetchSubscriptions, [customer.id, fetchSubscriptions])

  const openCustomerMergeDrawer = () => {
    setMergeDrawerOpen(true)
  }

  const toggleInclusionInMetrics = () => {
    userContext
      .fetch(`/customers/${customer.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          include_in_calculations_by_default:
            !customer.include_in_calculations_by_default,
        }),
      })
      .then((customer) => onCustomerUpdated(customer))
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Sorry, could not update the setting. Please try again.",
        })
      })
  }

  const renderActionsMenu = () => (
    <Menu>
      <MenuItem text="Merge customer" onClick={openCustomerMergeDrawer} />
      {customer.children_ids?.length === 0 && (
        <MenuItem text="Assign parent" onClick={openAssignParentDrawer} />
      )}
      <MenuItem
        text="Exclude from metrics calculation"
        icon={customer.include_in_calculations_by_default ? undefined : "tick"}
        onClick={toggleInclusionInMetrics}
        active={!customer.include_in_calculations_by_default}
      />
      <MenuDivider />
      <MenuItem
        icon="trash"
        text="Delete customer"
        onClick={showCustomerRemoveDialog}
        intent={Intent.DANGER}
      />
    </Menu>
  )

  const onMergeSuccess = () => {
    setMergeDrawerOpen(false)
    fetchSubscriptions()
  }

  const onAssignParentSuccess = (parentCustomer) => {
    setAssignParentDrawerOpen(false)
    setParentCustomer(parentCustomer)
  }

  const openAssignParentDrawer = () => {
    setAssignParentDrawerOpen(true)
  }

  const renderParentInfo = () => {
    if (!parentCustomer) return

    return (
      <div className={css.parentCustomerInfo}>
        <Icon icon="office"></Icon>
        <span>Parent</span>
        <span
          className={css.clickable}
          onClick={() => onChangeDetailedCustomer(parentCustomer)}
        >
          {parentCustomer.name}
        </span>
      </div>
    )
  }

  const clearState = () => {
    setParentCustomer(null)
    setChildrenCustomers([])
  }

  const onCloseDrawer = () => {
    clearState()
    onClose()
  }

  const onChangeDetailedCustomer = (customer) => {
    clearState()
    changeDetailedCustomer(customer)
  }

  return (
    <Drawer
      hasBackdrop={true}
      isOpen={isOpen}
      size={theme.drawerSize}
      onClose={onCloseDrawer}
      title={`Details: ${customer.name || ""}`}
    >
      <div className={classnames(css.customerPreview, theme.cssClass)}>
        <CustomerMergeDrawer
          targetCustomer={customer}
          isOpen={mergeDrawerOpen}
          onSuccess={onMergeSuccess}
          onCancel={() => setMergeDrawerOpen(false)}
        />
        <CustomerAssignParentDrawer
          targetCustomer={customer}
          isOpen={assignParentDrawerOpen}
          onSuccess={onAssignParentSuccess}
          onCancel={() => setAssignParentDrawerOpen(false)}
        />
        <ConfirmDialog
          title="Delete customer"
          isOpen={confirmOpen}
          onCancel={cancelCustomerRemove}
          onConfirm={confirmCustomerRemove}
        >
          This operation is not reversable. All customer data and subscriptions
          will be lost. Do you want to proceed?
        </ConfirmDialog>
        <div className={css.customerInfo}>
          {customer.include_in_calculations_by_default === false && (
            <Callout intent={Intent.WARNING}>
              Payments of this customer will not be taken into account when
              calculating your metrics. Use actions menu to change this
              behavior.
            </Callout>
          )}
          <div className={formCss.headerActions} showonhover="true">
            {renderParentInfo()}
            <Popover
              className={css.customerActions}
              content={renderActionsMenu()}
              position={Position.DOWN}
            >
              <Button
                icon="chevron-down"
                text="Actions"
                minimal
                intent={Intent.PRIMARY}
              />
            </Popover>
          </div>
          <CustomerInfo
            customer={customer}
            onCustomerUpdated={onCustomerUpdated}
          />
        </div>
        {childrendCustomers.length > 0 && (
          <div
            className={classnames(css.timelineContainer, css.childrenCustomers)}
          >
            {loadingChildren && <LoadingOverlay />}
            <div className={css.dataSectionHeader}>
              <H5>
                {childrendCustomers.length}&nbsp;
                {pluralize("children", childrendCustomers.length)}
              </H5>
              <span className={css.grow}></span>
            </div>
            <ChildrenCustomersTable
              customers={childrendCustomers}
              changeDetailedCustomer={onChangeDetailedCustomer}
              userCurrency={userContext.user.currency}
            />
          </div>
        )}
        <div className={css.summaryContainer}>
          {!hasChildren &&
            customerMetrics &&
            customerMetrics.length > 0 &&
            latestMonthMetrics && (
              <>
                <TinyLineChart data={customerMetrics} />
                <CustomerDetailsSummary
                  metrics={latestMonthMetrics}
                  currency={userContext.user.currency}
                />
              </>
            )}
        </div>
        <div className={css.timelineContainer}>
          {loading && <LoadingOverlay />}
          <div className={css.dataSectionHeader}>
            <H5>Payments</H5>
            <span className={css.grow}></span>
            <Button
              icon={
                <Icon
                  icon={
                    theme.key === "full_screen_theme"
                      ? "minimize"
                      : "fullscreen"
                  }
                  iconSize={12}
                />
              }
              minimal
              small
              onClick={toggleTheme}
            />
          </div>
          {isOpen && (
            <CustomerSubscriptionHistoriesTable
              histories={subscriptions}
              onUpdateSuccess={onUpdateSuccess}
              onRemoveSuccess={onRemoveSuccess}
              currency={userContext.user.currency}
              onSort={onSort}
              startedAtDefaultSort={
                localStorage.getItem("customer_details_payments_sort") || "desc"
              }
            />
          )}
          {subscriptions.length > 0 &&
            subscriptions.length === timelineLimit && (
              <Button
                text="Show all payments"
                minimal
                outlined
                intent="primary"
                onClick={() => setTimelineLimit(null)}
              />
            )}
        </div>
      </div>
    </Drawer>
  )
}

export default CustomerDetailsDrawer

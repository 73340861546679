import React, { useState, useContext, useCallback } from "react"
import { withRouter } from "react-router-dom"
import BusinessReportsProvider from "./BusinessReportsProvider"
import DataImportDialog from "./DataImportDialog"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import BusinessReports from "./BusinessReports"
import ConfirmDialog from "./ConfirmDialog"
import { SharedReportsOnlyEmptyState } from "./NonIdealState"

const BusinessReportsContainer = () => {
  const [csvImportOpen, setCsvImportOpen] = useState(false)
  const userContext = useContext(UserContext)
  const [reports, setReports] = useState<Types.Api.Report[] | null>(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [reportIdToRemove, setReportIdToRemove] = useState(null)

  const openCsvImport = () => {
    setCsvImportOpen(true)
  }

  const onCsvImportClose = () => {
    setCsvImportOpen(false)
  }

  const onReportsFetchSuccess = useCallback((reports) => {
    setReports(reports)
  }, [])

  const cancelReportRemove = () => {
    setConfirmOpen(false)
    setReportIdToRemove(null)
  }

  const removeReportConfirm = () => {
    setConfirmOpen(false)
    userContext
      .fetch(`/business_reports/${reportIdToRemove}`, {
        method: "DELETE",
      })
      .then(() => {
        const existingReports = reports as Types.Api.Report[]
        setReports(
          existingReports.filter((report) => report.id !== reportIdToRemove)
        )
        setReportIdToRemove(null)
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not remove report",
        })
      })
  }

  const onUpdateReport = (report: Types.Api.Report) => {
    return userContext
      .fetch(`/business_reports/${report.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tags: report.tags }),
      })
      .then((newReport: Types.Api.Report) => {
        setReports(
          reports
            ? reports.map((report) =>
                report.id === newReport.id ? newReport : report
              )
            : null
        )
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not update report",
        })
      })
  }

  const onRemoveReportClick = useCallback((reportId) => {
    setReportIdToRemove(reportId)
    setConfirmOpen(true)
  }, [])

  if (userContext.user?.permission_level === "shared_report_only")
    return <SharedReportsOnlyEmptyState />

  return (
    <>
      <DataImportDialog
        isOpen={csvImportOpen}
        onClose={onCsvImportClose}
        openImportDialog={openCsvImport}
      />
      {!reports && (
        <BusinessReportsProvider onFetchSuccess={onReportsFetchSuccess} />
      )}
      {reports && (
        <>
          <ConfirmDialog
            isOpen={confirmOpen}
            onCancel={cancelReportRemove}
            onConfirm={removeReportConfirm}
            title="Delete report"
          >
            This operation is not reversable. All of the comments and data
            description will be lost. Do you want to proceed?
          </ConfirmDialog>
          <BusinessReports
            reports={reports}
            onRemoveReportClick={onRemoveReportClick}
            onUpdateReport={onUpdateReport}
          />
        </>
      )}
    </>
  )
}

export default withRouter<any, any>(BusinessReportsContainer)

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react"
import pluralize from "pluralize"
import { UserContext } from "./UserContext"
import { Section } from "./Content"
import CustomersTable from "./CustomersTable"
import SearchInput, { SearchApiDestination } from "./SearchInput"

import css from "./TableContainer.module.css"
import { withRouter } from "react-router-dom"
import withDetailedCustomer from "./withDetailedCustomer"
import CustomerDetailsDrawer from "./CustomerDetailsDrawer"
import AddCustomersTableColumnDrawer from "./AddCustomersTableColumnDrawer"
import { Button, Intent } from "@blueprintjs/core"
import CustomerColumnsDrawer from "./CustomerColumnsDrawer"
import { LoadingOverlay } from "./NonIdealState"

const CustomersContainer = (props) => {
  const {
    detailedCustomer,
    setDetailedCustomer,
    history,
    onDetailedCustomerUpdated,
  } = props
  const [customers, setCustomers] = useState([])
  const [customerColumns, setCustomerColumns] = useState([])
  const [currentPageInfo, setCurrentPageInfo] = useState({ total_count: 0 })
  const [currentCursor, setCurrentCursor] = useState(null)
  const userContext = useContext(UserContext)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [columnDrawerOpen, setColumnDrawerOpen] = useState(false)
  const [editColumnsDrawerOpen, setEditColumnsDrawerOpen] = useState(false)
  const [editColumn, setEditColumn] = useState(null)
  const [loading, setLoading] = useState(false)

  const fullWidthTable = useMemo(
    () => customerColumns.length <= 1,
    [customerColumns]
  )

  const fetchColumnDefinitions = () => {
    userContext
      .fetch("/users/me", {
        method: "GET",
      })
      .then((user) => setCustomerColumns(user.customer_columns))
  }

  useEffect(fetchColumnDefinitions, [userContext])

  const updateAllColumns = (columns) => {
    userContext
      .fetch("/users/me", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer_columns: columns }),
      })
      .then((data) => setCustomerColumns(data.customer_columns))
  }

  useEffect(() => {
    const customerIdDetailed = props.match.params.customerId
    if (!customerIdDetailed || customerIdDetailed === detailedCustomer.id)
      return

    setDetailedCustomer({ id: customerIdDetailed, segments: null })
    setIsDetailsOpen(true)
  }, [props.match.params.customerId, setDetailedCustomer, detailedCustomer])

  const updateCurrentPageInfo = (response) => {
    const { total_count, current_range, next, prev } = response
    setCurrentPageInfo({ total_count, current_range, next, prev })
  }

  const createColumnDefinition = (definition) => {
    return new Promise((resolve, reject) => {
      userContext
        .fetch("/customer_related_metric_definitions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(definition),
        })
        .then(() => {
          fetchColumnDefinitions()
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const editColumnDefinition = (definition) => {
    return new Promise((resolve, reject) => {
      userContext
        .fetch(`/customer_related_metric_definitions/${definition.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(definition),
        })
        .then((data) => {
          setCustomerColumns(
            customerColumns.map((metric) =>
              metric.id === definition.id ? data : metric
            )
          )
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createOrEditColumnDefinition = (definition) => {
    if (definition.id) {
      return editColumnDefinition(definition)
    } else {
      return createColumnDefinition(definition)
    }
  }

  const fetchCustomers = useCallback(
    (cursor, sortBy) => {
      setLoading(true)
      let searchParams = {}
      if (cursor) searchParams.next = cursor
      if (sortBy) searchParams.sort_by = sortBy

      userContext
        .fetch(`/customers?${new URLSearchParams(searchParams).toString()}`, {
          method: "GET",
        })
        .then((data) => {
          setCustomers(data.items)
          setCurrentCursor(cursor)
          updateCurrentPageInfo(data)
        })
        .catch(() => setCustomers([]))
        .finally(() => setLoading(false))
    },
    [userContext]
  )

  const onSort = useCallback(
    (sortBy) => {
      if (sortBy.length > 0) {
        const { id, desc } = sortBy[0]
        fetchCustomers(null, `${id}:${desc ? "desc" : "asc"}`)
      }
    },
    [fetchCustomers]
  )

  useEffect(fetchCustomers, [fetchCustomers])

  const onNextPage = useCallback(() => {
    fetchCustomers(currentPageInfo.next)
  }, [fetchCustomers, currentPageInfo])

  const onPrevPage = useCallback(() => {
    fetchCustomers(currentPageInfo.prev)
  }, [fetchCustomers, currentPageInfo])

  const onSearchSuccess = useCallback((customers) => {
    setCustomers(customers)
    updateCurrentPageInfo({ total_count: customers.length })
  }, [])

  const onSearchQueryChange = useCallback(
    (query) => {
      if (query.length === 0) {
        fetchCustomers()
      }
    },
    [fetchCustomers]
  )

  const onCustomerRemove = useCallback(() => {
    fetchCustomers(currentCursor)
  }, [fetchCustomers, currentCursor])

  const changeDetailedCustomer = useCallback(
    (customer) => {
      history.push(`/customers/${customer.id}`)
    },
    [history]
  )

  const onClose = () => {
    history.push("/customers")
    setDetailedCustomer({})
    setIsDetailsOpen(false)
  }

  const onAddNewColumnDefinition = () => {
    setEditColumn(null)
    setColumnDrawerOpen(true)
  }

  const onEditColumns = () => {
    fetchColumnDefinitions()
    setEditColumnsDrawerOpen(true)
  }

  const actions = () => {
    return (
      <div className={css.right}>
        <Button
          onClick={onEditColumns}
          text="Edit columns"
          intent={Intent.PRIMARY}
          outlined
        />
      </div>
    )
  }

  const onEditColumn = (column) => {
    setEditColumn(column)
    setColumnDrawerOpen(true)
  }

  const onDeleteColumn = (column) => {
    return new Promise((resolve, reject) => {
      userContext
        .fetch(`/customer_related_metric_definitions/${column.id}`, {
          headers: { "Content-Type": "application/json" },
          method: "DELETE",
        })
        .then(() => {
          setCustomerColumns(
            customerColumns.filter((metric) => metric.id !== column.id)
          )
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const onCloseColumnsDrawer = () => {
    setEditColumnsDrawerOpen(false)
    updateAllColumns(customerColumns)
  }

  return (
    <Section
      title="Customers"
      subtitle={`${currentPageInfo.total_count} ${pluralize(
        "customer",
        currentPageInfo.total_count
      )}`}
    >
      <div className={css.tableActions}>
        <div className={css.left}>
          <SearchInput
            apiDestination={SearchApiDestination.CUSTOMER}
            className={css.searchInput}
            onSuccess={onSearchSuccess}
            onQueryChange={onSearchQueryChange}
            small
          />
        </div>
        {actions()}
      </div>
      <CustomerDetailsDrawer
        isOpen={isDetailsOpen}
        customer={detailedCustomer}
        onClose={onClose}
        onCustomerRemove={onCustomerRemove}
        onCustomerUpdated={onDetailedCustomerUpdated}
        changeDetailedCustomer={changeDetailedCustomer}
      />
      <div className={css.tableContainer}>
        {loading && <LoadingOverlay />}
        <CustomersTable
          customers={customers}
          onSort={onSort}
          onNextPage={currentPageInfo.next && onNextPage}
          onPrevPage={currentPageInfo.prev && onPrevPage}
          range={currentPageInfo.current_range}
          onDetails={changeDetailedCustomer}
          customerColumns={customerColumns.filter((column) => column.visible)}
          fullWidth={fullWidthTable}
        />
      </div>
      <AddCustomersTableColumnDrawer
        isOpen={columnDrawerOpen}
        onClose={() => setColumnDrawerOpen(false)}
        onSubmit={createOrEditColumnDefinition}
        column={editColumn || {}}
      />
      <CustomerColumnsDrawer
        columns={customerColumns}
        setColumns={setCustomerColumns}
        onDelete={onDeleteColumn}
        onEdit={onEditColumn}
        isOpen={editColumnsDrawerOpen}
        onClose={onCloseColumnsDrawer}
        onAdd={onAddNewColumnDefinition}
      />
    </Section>
  )
}

export default withRouter(withDetailedCustomer(CustomersContainer))

const buildScheme = (colors) => {
  let currentIndex = 0

  return {
    next: () => {
      const color = colors[currentIndex]
      currentIndex += 1
      if (currentIndex >= colors.length) {
        currentIndex = 0
      }
      return color
    },
  }
}

// the order of colors is important, don't reorder
// The tool we used to generate Main Colort
// https://vis4.net/palettes/#/13|s|8884d8,82ca9d,923a9d,003f5c,ffa600|ffffe0,ff005e,93003a|0|0
const MAIN_COLORS = [
  "#8884d8",
  "#82ca9d",
  "#923a9d",
  "#003f5c",
  "#ffa600",
  "#869bc4",
  "#879a9d",
  "#613c87",
  "#55613d",
  "#84b3b1",
  "#8d6a9d",
  "#313d72",
  "#aa841f",
]
const GREEN_COLORS = ["#60998e", "#71b297", "#82ca9d", "#bbe1b2", "#e3efd3"]
const MRR_MOVEMENT_COLORS = ["#ff6361", "#ffa600", "#003f5c", "#58508d"]

export default {
  main: () => buildScheme(MAIN_COLORS),
  green: () => buildScheme(GREEN_COLORS),
  mrrMovement: () => buildScheme(MRR_MOVEMENT_COLORS),
}

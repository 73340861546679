import React, { useContext, useEffect } from "react"
import {
  Button,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  Classes,
} from "@blueprintjs/core"
import SubscriptionForm, {
  convertInputToSubscriptionAttrs,
} from "./SubscriptionForm"
import { withSubscriptionFormValidation } from "./withSubscriptionFormValidation"
import { UserContext } from "./UserContext"
import { formatCurrencyForEdit } from "./formatters"

import popoverCss from "./popoverForm.module.css"

export const editInOriginalCurrency = (subscription) => {
  return {
    ...subscription,
    currency: subscription.original_currency,
    total_value: formatCurrencyForEdit(subscription.original_total_value),
  }
}

const EditSubscriptionPopover = ({
  popoverTarget,
  subscription,
  subscriptionFormData,
  setSubscriptionFormData,
  onSubscriptionFormFieldChange,
  canSubmitSubscription,
  onUpdateSuccess,
}) => {
  const userContext = useContext(UserContext)

  const onSubscriptionUpdate = () => {
    return userContext
      .fetch(`/subscriptions/history/${subscriptionFormData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          convertInputToSubscriptionAttrs(
            subscriptionFormData.customer_id,
            subscriptionFormData
          )
        ),
      })
      .then((data) => onUpdateSuccess(data))
  }

  useEffect(
    () => setSubscriptionFormData(editInOriginalCurrency(subscription)),
    [subscription, setSubscriptionFormData]
  )

  const onPopoverClose = () => {
    setSubscriptionFormData(editInOriginalCurrency(subscription))
  }

  const renderEditForm = () => {
    return (
      <div className={popoverCss.popover}>
        <div className={popoverCss.popoverBody}>
          <SubscriptionForm
            onFieldChange={onSubscriptionFormFieldChange}
            defaults={subscriptionFormData}
            autoFocus
            compact
          />
        </div>
        <div className={popoverCss.actions}>
          <Button
            disabled={!canSubmitSubscription}
            outlined
            intent={Intent.PRIMARY}
            text="Update"
            className={Classes.POPOVER_DISMISS}
            onClick={onSubscriptionUpdate}
          />
          <Button
            className={Classes.POPOVER_DISMISS}
            minimal
            intent={Intent.DANGER}
            text="Cancel"
          />
        </div>
      </div>
    )
  }

  return (
    <Popover
      onClose={onPopoverClose}
      target={popoverTarget}
      content={renderEditForm()}
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
      boundary="viewport"
      position={Position.BOTTOM}
    />
  )
}

export default withSubscriptionFormValidation(EditSubscriptionPopover)

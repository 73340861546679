import React, { useState, useContext } from "react"
import { Button, Intent, Tooltip } from "@blueprintjs/core"
import EditSubscriptionPopover, {
  editInOriginalCurrency,
} from "./EditSubscriptionPopover"
import ConfirmDialog from "./ConfirmDialog"
import { AppToaster } from "./AppToaster"
import { UserContext } from "./UserContext"
import { SubscriptionItemTypes } from "./SubscriptionItemTypeSwitcher"
import css from "./SubscriptionCellWithActions.module.css"

export const SubscriptionItemTypeIcon = ({ type }) => {
  const getItemType = (itemTypeKey) => {
    switch (itemTypeKey) {
      case SubscriptionItemTypes.Recurring.KEY:
        return SubscriptionItemTypes.Recurring
      case SubscriptionItemTypes.Metered.KEY:
        return SubscriptionItemTypes.Metered
      case SubscriptionItemTypes.OneTime.KEY:
        return SubscriptionItemTypes.OneTime
      case SubscriptionItemTypes.Freemium.KEY:
        return SubscriptionItemTypes.Freemium
      default:
        return null
    }
  }

  const itemType = getItemType(type)

  return (
    <Tooltip content={itemType.TOOLTIP} position="top">
      <div className={css.itemTypeIcon}>{itemType.SHORTHAND}</div>
    </Tooltip>
  )
}

const SubscriptionCellWithActions = ({
  formattedValue,
  subscription,
  onUpdateSuccess,
  onRemoveSuccess,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const userContext = useContext(UserContext)

  const cancelClicked = () => {
    setConfirmOpen(false)
  }

  const deleteClicked = () => {
    setConfirmOpen(true)
  }

  const removeSubscription = () => {
    setConfirmOpen(false)
    userContext
      .fetch(`/subscriptions/history/${subscription.id}`, {
        method: "DELETE",
      })
      .then(() => {
        onRemoveSuccess(subscription.id)
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not remove subscription",
        })
      })
  }

  const editFormPopoverTarget = () => (
    <span showonhover="true">
      <Button icon="edit" minimal intent={Intent.PRIMARY} />
    </span>
  )

  return (
    <div className={css.startedAt}>
      <SubscriptionItemTypeIcon type={subscription.items[0].type} />
      <span>{formattedValue}</span>
      <EditSubscriptionPopover
        subscription={subscription}
        popoverTarget={editFormPopoverTarget()}
        subscriptionFormData={editInOriginalCurrency(subscription)}
        onUpdateSuccess={onUpdateSuccess}
      />
      <ConfirmDialog
        title="Delete subscription"
        isOpen={confirmOpen}
        onCancel={cancelClicked}
        onConfirm={removeSubscription}
      >
        This operation is not reversable. Subscription data will be lost. Do you
        want to proceed?
      </ConfirmDialog>
      <span showonhover="true">
        <Button
          icon="trash"
          minimal
          intent={Intent.PRIMARY}
          onClick={deleteClicked}
          showonhover="true"
        />
      </span>
    </div>
  )
}

export default SubscriptionCellWithActions

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react"
import { Drawer, Button, H3, Classes, Intent } from "@blueprintjs/core"
import {
  useFieldArray,
  UseFormReturn,
  useForm,
  Controller,
} from "react-hook-form"
import SelectSegmentValue, {
  SegmentItem,
} from "./components/SelectSegmentValue"
import { UserContext } from "./UserContext"
import classnames from "classnames"

import formCss from "./forms.module.css"

export type SegmentValueNode = {
  segment: {
    name: string
    value: string
    negated?: boolean
    type: "segment" | "deal"
  }
}

export type ValueNode = {
  type: "segment" | "deal"
  name: string
  value: string
}

export type NegatedValueNode = {
  operator: "not"
  arguments: ValueNode
}

type SegmentsValue = {
  label: string
  value: string
  name: string
  type: string
  id: string
}

export type ViewNode = ValueNode | NegatedValueNode

export type View = {
  operator: "and" | "or"
  arguments: ViewNode[]
}

type ViewFormProps = {
  useFormMethods: UseFormReturn<View>
  items: SegmentsValue[]
}

type ViewNodeFormProps = {
  useFormMethods: UseFormReturn<View>
  index: number
  node: ViewNode
  items: SegmentsValue[]
  onRemove: (index: number) => void
}

const ViewNodeForm = ({
  useFormMethods,
  index,
  items,
  node,
  onRemove,
}: ViewNodeFormProps) => {
  const { control } = useFormMethods
  const targetRef = useRef<any>(null)

  const name = useCallback(() => {
    if ("operator" in node) {
      return node.arguments.name
    } else {
      return node.name
    }
  }, [node])

  const label = useCallback(() => {
    if (name() === "deal_owner") {
      return "Deal owner"
    } else if (name() === "product_key") {
      return "Product"
    } else {
      return name()
    }
  }, [name])

  const nodeToSegmentItem = useCallback(() => {
    if ("operator" in node) {
      return {
        ...node.arguments,
        label: label(),
        negater: true,
      }
    } else {
      return {
        ...node,
        label: label(),
      }
    }
  }, [node, label])

  useEffect(() => {
    if (name() === "" && targetRef.current) {
      targetRef.current.click()
    }
  }, [targetRef, name])

  const createNegatedItem = (item: SegmentItem) => {
    return {
      operator: "not",
      arguments: {
        ...item,
      },
    }
  }

  const renderSelectSegmentValue = ({ field }: any) => {
    return (
      <SelectSegmentValue
        items={items}
        onItemSelect={(item) =>
          field.onChange({
            value: item.value,
            name: item.name,
            type: item.type,
          })
        }
        onItemNegateChange={(item) => field.onChange(createNegatedItem(item))}
        activeItem={nodeToSegmentItem()}
        targetRef={targetRef}
      />
    )
  }

  return (
    <div className={formCss.fieldsRow}>
      <Controller
        control={control}
        name={`arguments.${index}`}
        render={renderSelectSegmentValue}
      />
      <div
        className={classnames(
          formCss.fieldsRowActionButton,
          formCss.alignFlexCenter
        )}
      >
        <Button
          icon="trash"
          minimal
          onClick={() => onRemove(index)}
          intent={Intent.PRIMARY}
        />
      </div>
    </div>
  )
}

const ViewForm = ({ useFormMethods, items }: ViewFormProps) => {
  const { control, watch } = useFormMethods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "arguments",
  })

  const operator = watch("operator")

  const appendNode = () => {
    append({
      name: "",
      value: "",
      type: "segment",
    })
  }

  const renderSelectedOperator = () => (
    <Button
      onClick={appendNode}
      text={operator.toUpperCase()}
      outlined
      intent="primary"
      minimal
    />
  )

  const renderOperatorChoice = ({ field }: any) => {
    if (fields.length > 1) {
      return renderSelectedOperator()
    }

    return (
      <>
        <Button
          onClick={() => {
            appendNode()
            field.onChange("and")
          }}
          text="AND"
          outlined
          intent="primary"
          minimal
        />
        <Button
          onClick={() => {
            appendNode()
            field.onChange("or")
          }}
          text="OR"
          outlined
          intent="primary"
          minimal
        />
      </>
    )
  }

  return (
    <>
      {fields.map((field, index) => {
        return (
          <>
            <ViewNodeForm
              key={field.id}
              node={field}
              index={index}
              useFormMethods={useFormMethods}
              items={items}
              onRemove={remove}
            />
            {index !== fields.length - 1 && (
              <span
                className={classnames(formCss.spanLabel, formCss.disabledLabel)}
              >
                {operator}
              </span>
            )}
          </>
        )
      })}
      <div className={classnames(formCss.fieldsRow)}>
        <Controller
          control={control}
          name="operator"
          render={renderOperatorChoice}
        />
      </div>
    </>
  )
}

type ReportViewDrawerProps = {
  isOpen: boolean
  onClose: () => void
  filter: View
  onSubmit: (view: View) => void
}

const ReportViewDrawer = ({
  isOpen,
  onClose,
  filter,
  onSubmit,
}: ReportViewDrawerProps) => {
  const useFormMethods = useForm<View>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...filter,
    },
  })

  const { getValues, reset } = useFormMethods

  const userContext = useContext(UserContext)
  const [segmentsValues, setSegmentsValues] = useState<SegmentsValue[]>([])
  const [dealOwneValues, setDealOwnerValues] = useState<SegmentsValue[]>([])
  const [productValues, setProductValues] = useState<SegmentsValue[]>([])

  const fetchValues = useCallback(
    (definition: Types.Api.SegmentDefintion) => {
      return userContext
        .fetch(
          `/customer_segments/${definition.id}/search_values?query=&limit=100`,
          {
            method: "GET",
          }
        )
        .then(
          (response) =>
            new Promise((resolve) => resolve({ ...response, definition }))
        )
    },
    [userContext]
  )

  const mapSegmentValue = useCallback(
    (
      definition: Types.Api.SegmentDefintion,
      item: Types.Api.CustomerSegment
    ) => ({
      value: item.value,
      name: definition.name,
      label: definition.name,
      id: definition.id,
      type: "segment",
    }),
    []
  )

  const mapDealOwnerValue = useCallback(
    (item: any) => ({
      value: item.name,
      label: "Deal owner",
      name: "owner_name",
      type: "deal",
    }),
    []
  )

  const mapProductValue = useCallback((item: any) => {
    return {
      value: item.key,
      label: "Product",
      name: "product_key",
      type: "subscription",
    }
  }, [])

  const fetchDealOwners = () => {
    userContext
      .fetch("/deal_owners", { method: "GET" })
      .then((data) =>
        setDealOwnerValues(
          data.items.map((item: any) => mapDealOwnerValue(item))
        )
      )
  }

  const fetchProducts = () => {
    userContext
      .fetch(`/product/search?query=&limit=100`, { method: "GET" })
      .then((data) =>
        setProductValues(data.items.map((item: any) => mapProductValue(item)))
      )
  }

  const fetchSegmentsValues = useCallback(
    (items: Types.Api.SegmentDefintion[]) => {
      Promise.all(
        items.map((item: Types.Api.SegmentDefintion) => fetchValues(item))
      ).then((responses) => {
        setSegmentsValues(
          responses.flatMap((data: any) =>
            data.items.map((item: any) =>
              mapSegmentValue(data.definition, item)
            )
          )
        )
      })
    },
    [fetchValues, mapSegmentValue]
  )

  const fetchSegmenterDefinitions = () => {
    userContext
      .fetch("/customer_segments", { method: "GET" })
      .then((data) => fetchSegmentsValues(data.items))
  }

  useEffect(fetchSegmenterDefinitions, [fetchSegmentsValues, userContext])
  useEffect(fetchDealOwners, [mapDealOwnerValue, userContext])
  useEffect(fetchProducts, [mapProductValue, userContext])

  const onSave = () => {
    const values = getValues()
    onSubmit(values)
    onClose()
  }

  const onCancel = () => {
    reset(filter)
    onClose()
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={onCancel}
      title="Define report view"
      enforceFocus={false}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={formCss.insideDrawer}>
          <H3 className={formCss.title}>Report view</H3>
          <p>
            Only customers that match criterias defined bellow will be taken
            into account when calculating metrics in this report
          </p>
          <div className={formCss.body}>
            <ViewForm
              useFormMethods={useFormMethods}
              items={segmentsValues.concat(dealOwneValues, productValues)}
            />
          </div>
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            disabled={!useFormMethods.formState.isValid}
            text="Save view and recalculate"
            outlined
            onClick={onSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onCancel}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default ReportViewDrawer

import React from "react"
import { HTMLTable } from "@blueprintjs/core"

import css from "./SubscriptionBased.module.css"

type CalculationDetailsTableProps = {
  calculationDetails: Types.Api.GenericSegmentDetail["calculation_details"]
}

const CalculationDetailsTable = ({
  calculationDetails,
}: CalculationDetailsTableProps) => {
  return (
    <div className={css.segmentDetails}>
      <div className={css.detailsTable}>
        <HTMLTable>
          <tbody>
            {calculationDetails.map((detail, index) => {
              return (
                <tr key={index}>
                  <td>
                    <b>{detail.name}</b>
                  </td>
                  <td>{detail.value}</td>
                </tr>
              )
            })}
          </tbody>
        </HTMLTable>
      </div>
    </div>
  )
}

export default CalculationDetailsTable

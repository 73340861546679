import { Button, Tag } from "@blueprintjs/core"
import React from "react"
import { useFieldArray, UseFormReturn } from "react-hook-form"
import { FilterField } from "./FilterFieldSelect"
import FilterNodeForm from "./FilterNodeForm"
import { UserSection } from "./types"

import css from "./newSection.module.css"

type GroupDefinitionFormProps = {
  removeGroup: (index: number) => void
  useFormMethods: UseFormReturn<UserSection>
  items: FilterField[]
  serieIndex: number
  groupIndex: number
}

const GroupDefinitionForm = ({
  removeGroup,
  useFormMethods,
  items,
  groupIndex,
  serieIndex,
}: GroupDefinitionFormProps) => {
  const { control } = useFormMethods
  const { fields, remove } = useFieldArray({
    control,
    name: `series.${serieIndex}.groups.${groupIndex}.definition`,
  })

  const onRemove = (index: number) => {
    if (fields.length == 1) {
      removeGroup(groupIndex)
    } else {
      remove(index)
    }
  }

  const renderResetSubscription = (index: number) => {
    return (
      <div className={css.displayRow}>
        <Button
          title="Remove filters"
          minimal
          icon="filter-remove"
          onClick={() => onRemove(index)}
        />
        <Tag minimal large>
          Search in all payments
        </Tag>
      </div>
    )
  }

  return (
    <div className={css.groupConditions}>
      {fields.map((field, nodeIndex) => {
        return (
          <div key={field.id}>
            {field.operator === "reset_subscriptions" &&
              renderResetSubscription(nodeIndex)}
            {field.operator !== "reset_subscriptions" && (
              <FilterNodeForm
                items={items}
                useFormMethods={useFormMethods}
                groupIndex={groupIndex}
                nodeIndex={nodeIndex}
                serieIndex={serieIndex}
                onRemove={onRemove}
              />
            )}
            {nodeIndex < fields.length - 1 && (
              <span style={{ color: "gray" }}>and</span>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default GroupDefinitionForm

import React, { useState } from "react"
import {
  Classes,
  FileInput,
  Button,
  Spinner,
  Intent,
  Callout,
} from "@blueprintjs/core"

import "./DataImportDialog.css"
import css from "./ImportFileSelector.module.css"

type ImportActionsType = {
  onImport: (file: File | any) => void
  selectedFile: File | null
  importInProgress: boolean
}

const ImportActions = ({
  onImport,
  selectedFile,
  importInProgress,
}: ImportActionsType) => (
  <div className={css.importActions}>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <a href="mailto:support@getprobe.io" className="secondaryAction">
        Let us handle it
      </a>
      {onImport && !importInProgress && (
        <Button
          disabled={!selectedFile}
          text="Import"
          onClick={() => onImport(selectedFile)}
          intent={Intent.PRIMARY}
          outlined
          large
        />
      )}
      {importInProgress && <Spinner size={35} intent={Intent.PRIMARY} />}
    </div>
  </div>
)

type CsvFileInputType = {
  selectedFile: File | null
  onSelectFile: (file: File | null) => void
  helpText?: string
}

const CsvFileInput = ({
  selectedFile,
  onSelectFile,
  helpText,
}: CsvFileInputType) => {
  const onFileSelect = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      onSelectFile(event.target.files[0])
    }
  }

  const fileInputText = () => {
    return selectedFile ? selectedFile.name : "Choose file ..."
  }

  return (
    <>
      <div>
        <p>Please select CSV file containing segments</p>
        {helpText && (
          <Callout intent={Intent.PRIMARY} className={css.importCallout}>
            {helpText}
          </Callout>
        )}

        <FileInput
          large
          fill
          onInputChange={onFileSelect}
          text={fileInputText()}
          inputProps={{ accept: ".csv" }}
        />
      </div>
    </>
  )
}

type ImportSegmentsFileSelectorType = {
  onImport: (file: File | any) => void
  importInProgress: boolean
  helpText?: string
}

const ImportSegmentsFileSelector = ({
  onImport,
  importInProgress,
  helpText,
}: ImportSegmentsFileSelectorType) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  return (
    <>
      <CsvFileInput
        selectedFile={selectedFile}
        onSelectFile={(file) => setSelectedFile(file)}
        helpText={helpText}
      />
      <ImportActions
        onImport={onImport}
        selectedFile={selectedFile}
        importInProgress={importInProgress}
      />
    </>
  )
}

export default ImportSegmentsFileSelector

import React, { useContext } from "react"
import {
  Button,
  Popover,
  Menu,
  MenuItem,
  Position,
  MenuDivider,
} from "@blueprintjs/core"
import Logo from "./Logo"
import { withRouter } from "react-router-dom"
import { UserContext } from "./UserContext"
import { REDIRECT_AFTER_SIGN_IN_KEY } from "./SignIn"
import Avatar from "./Avatar"

import "./ActionBar.css"

const AuthSection = ({ history, userName }) => {
  const userContext = useContext(UserContext)

  const onSignoutClick = () => {
    userContext.performSignOut()
  }

  const onSettingsClick = () => {
    history.push("/settings")
  }

  const onGettingsStartedClick = () => {
    history.push("/getting-started")
  }

  const menu = () => (
    <Menu>
      {userContext.hasUserLevelAccess() && (
        <>
          <MenuItem
            icon="help"
            text="Getting started"
            onClick={onGettingsStartedClick}
          />
          <MenuItem icon="cog" text="Settings" onClick={onSettingsClick} />
          <MenuDivider />
        </>
      )}
      <MenuItem text="Sign out" onClick={onSignoutClick} />
    </Menu>
  )

  return (
    <Popover content={menu()} position={Position.BOTTOM_RIGHT}>
      <Avatar name={userName} clickable />
    </Popover>
  )
}

export const REPORTS_BUTTON = {
  key: "reports",
  text: "Reports",
  onClick: (history) => history.push("/"),
}

export const BUTTONS_WITHOUT_REPORT_CONTEXT = [REPORTS_BUTTON]

export const ActionBar = withRouter(
  ({ history, buttons, hasRejectedInvoices }) => {
    const userContext = useContext(UserContext)

    const onSignIn = () => {
      localStorage.setItem(
        REDIRECT_AFTER_SIGN_IN_KEY,
        history.location.pathname
      )
      history.push("/sign_in")
    }

    const defaultButtons = () => {
      let buttons = BUTTONS_WITHOUT_REPORT_CONTEXT.concat([
        {
          key: "customers",
          text: "Customers",
          onClick: (history) => history.push("/customers"),
        },
        {
          key: "Payments",
          text: "Payments",
          onClick: (history) => history.push("/payments"),
        },
        {
          key: "costs",
          text: "Costs",
          onClick: (history) => history.push("/costs"),
        },
      ])

      if (hasRejectedInvoices) {
        buttons.push({
          key: "invoices",
          text: "Import rejected invoices",
          onClick: (history) => history.push("/invoices"),
          outlined: true,
        })
      }

      return buttons
    }

    const signInButton = (
      <Button
        icon="log-in"
        text="Sign in"
        onClick={onSignIn}
        intent="primary"
        minimal
        outlined
        className="whiteTextButton"
      />
    )
    const allButtons =
      buttons && buttons.length > 0 ? buttons : defaultButtons()

    const toButton = (props) => {
      const onClick = () => props.onClick(history)
      return (
        <Button
          {...props}
          className="whiteTextButton"
          onClick={onClick}
          intent="primary"
          minimal
        />
      )
    }

    return (
      <div className="ActionBar">
        <div className="actionBarContainer">
          <Logo whiteLogo />
          <div className="centerActionBar">
            <div className="left">
              {userContext.hasUserLevelAccess() &&
                allButtons
                  .filter((button) => button.align !== "right")
                  .map((button) => toButton(button))}
            </div>
            <div className="right">
              {userContext.hasUserLevelAccess() &&
                allButtons
                  .filter((button) => button.align === "right")
                  .map((button) => toButton(button))}
            </div>
          </div>
          <div className="authSection">
            {!userContext.isLoggedIn && signInButton}
            {userContext.hasUserLevelAccess() && (
              <Button
                icon="plus"
                intent="primary"
                className="whiteTextButton"
                outlined
                key="create_report"
                text="Create new report"
                onClick={() => history.push("/reports/library")}
              />
            )}
            {userContext.isLoggedIn && (
              <AuthSection history={history} userName={userContext.user.name} />
            )}
          </div>
        </div>
      </div>
    )
  }
)

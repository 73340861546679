import React, { useState } from "react"
import { Button, ButtonGroup } from "@blueprintjs/core"

export const ButtonIndex = {
  LEFT: 0,
  RIGHT: 1,
}

const ButtonSwitcher = ({
  leftText,
  rightText,
  onSwitch,
  selectedIndex,
  fill,
}) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(selectedIndex || 0)

  const onLeftButtonClick = () => {
    if (activeButtonIndex !== ButtonIndex.LEFT) {
      setActiveButtonIndex(ButtonIndex.LEFT)
      onSwitch(ButtonIndex.LEFT)
    }
  }

  const onRightButtonClick = () => {
    if (activeButtonIndex !== ButtonIndex.RIGHT) {
      setActiveButtonIndex(ButtonIndex.RIGHT)
      onSwitch(ButtonIndex.RIGHT)
    }
  }

  return (
    <ButtonGroup fill={fill}>
      <Button
        active={activeButtonIndex === ButtonIndex.LEFT}
        onClick={onLeftButtonClick}
        outlined
        text={leftText}
        small
      />
      <Button
        active={activeButtonIndex === ButtonIndex.RIGHT}
        onClick={onRightButtonClick}
        outlined
        text={rightText}
        small
      />
    </ButtonGroup>
  )
}

export default ButtonSwitcher

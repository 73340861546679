import React from "react"

import { LineChart, Line, BarChart, Bar, XAxis, YAxis } from "recharts"
import schemes from "./colorScheme"

const data = [
  { name: "Page A", uv: 4000, pv: 1400, amt: -2400, av: -1400 },
  { name: "Page B", uv: 3000, pv: 2398, amt: -2210, av: -400 },
  { name: "Page C", uv: 2000, pv: 4800, amt: -2290, av: -1100 },
  { name: "Page D", uv: 2780, pv: 3908, amt: -2000, av: -3400 },
  { name: "Page E", uv: 1890, pv: 4800, amt: -2181, av: -2000 },
]

type SizeProp = {
  small?: boolean | undefined
  large?: boolean | undefined
}

const sizeFromProp = (small: SizeProp["small"], large: SizeProp["large"]) => {
  if (small) {
    return 40
  } else if (large) {
    return 120
  } else {
    return 80
  }
}

export const LineThumbnail = ({ small, large }: SizeProp) => {
  const scheme = schemes.main()

  return (
    <LineChart
      width={sizeFromProp(small, large)}
      height={sizeFromProp(small, large)}
      data={data}
    >
      <Line
        type="linear"
        dataKey="pv"
        stroke={scheme.next()}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
    </LineChart>
  )
}

export const MultiLineThumbnail = ({ small, large }: SizeProp) => {
  const scheme = schemes.main()

  return (
    <LineChart
      width={sizeFromProp(small, large)}
      height={sizeFromProp(small, large)}
      data={data}
    >
      <Line
        type="linear"
        dataKey="pv"
        stroke={scheme.next()}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
      <Line
        type="linear"
        dataKey="uv"
        stroke={scheme.next()}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
    </LineChart>
  )
}

export const BarThumbnail = ({ small, large }: SizeProp) => {
  const scheme = schemes.main()

  return (
    <BarChart
      width={sizeFromProp(small, large)}
      height={sizeFromProp(small, large)}
      data={data}
    >
      <Bar dataKey="uv" fill={scheme.next()} isAnimationActive={false} />
    </BarChart>
  )
}

export const MultiBarThumbnail = ({ small, large }: SizeProp) => {
  const scheme = schemes.main()

  return (
    <BarChart
      width={sizeFromProp(small, large)}
      height={sizeFromProp(small, large)}
      data={data}
      barGap={1}
    >
      <Bar dataKey="pv" fill={scheme.next()} isAnimationActive={false} />
      <Bar dataKey="uv" fill={scheme.next()} isAnimationActive={false} />
    </BarChart>
  )
}

export const StackBarThumbnail = ({ small, large }: SizeProp) => {
  const scheme = schemes.mrrMovement()

  return (
    <BarChart
      width={sizeFromProp(small, large)}
      height={sizeFromProp(small, large)}
      data={data}
    >
      <Bar
        dataKey="pv"
        fill={scheme.next()}
        stackId="stack"
        isAnimationActive={false}
      />
      <Bar
        dataKey="uv"
        fill={scheme.next()}
        stackId="stack"
        isAnimationActive={false}
      />
      <Bar
        dataKey="av"
        fill={scheme.next()}
        stackId="stack"
        isAnimationActive={false}
      />
      <Bar
        dataKey="amt"
        fill={scheme.next()}
        stackId="stack"
        isAnimationActive={false}
      />
    </BarChart>
  )
}

export const CohortThumbnail = ({ small, large }: SizeProp) => {
  const cohortData = [
    { name: "Page A", a: 100, b: 100, c: 100, d: 100, e: 100 },
    { name: "Page B", a: 100, b: 100, c: 100, d: 100, e: 0 },
    { name: "Page C", a: 100, b: 100, c: 100, d: 0, e: 0 },
    { name: "Page D", a: 100, b: 100, c: 0, d: 0, e: 0 },
    { name: "Page E", a: 100, b: 0, c: 0, d: 0, e: 0 },
  ]
  const scheme = schemes.green()

  return (
    <BarChart
      width={sizeFromProp(small, large)}
      height={(sizeFromProp(small, large) * 3) / 4}
      data={cohortData}
      layout="vertical"
      barCategoryGap={1}
    >
      <XAxis type="number" hide />
      <YAxis dataKey="name" type="category" hide />
      <Bar
        dataKey="a"
        stackId="a"
        fill={scheme.next()}
        isAnimationActive={false}
      />
      <Bar
        dataKey="b"
        stackId="a"
        fill={scheme.next()}
        isAnimationActive={false}
      />
      <Bar
        dataKey="c"
        stackId="a"
        fill={scheme.next()}
        isAnimationActive={false}
      />
      <Bar
        dataKey="d"
        stackId="a"
        fill={scheme.next()}
        isAnimationActive={false}
      />
      <Bar
        dataKey="e"
        stackId="a"
        fill={scheme.next()}
        isAnimationActive={false}
      />
    </BarChart>
  )
}

import React, { useContext, useState, useEffect, useCallback } from "react"
import { Intent, Callout } from "@blueprintjs/core"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import { SelectedType } from "./DataMappingImportStep"
import DataImportWizard from "./DataImportWizard"

const ImportSegmentsContainer = () => {
  const helpText =
    "You will be asked to select two columns from the csv file to import attribute values. One containing customer ids and another one containing attribute values."

  const userContext = useContext(UserContext)

  const [customerSegments, setCustomerSegments] = useState<
    Array<Types.Api.SegmentDefintion>
  >([])

  const fetchCustomerSegments = useCallback(() => {
    const requestOptions = {
      method: "GET",
    }

    userContext
      .fetch("/customer_segments", requestOptions)
      .then((data) => {
        setCustomerSegments(data.items)
      })
      .catch(() =>
        AppToaster.showError({
          message:
            "Something went wrong, we could not fetch your segments, please try to refresh the page",
        })
      )
  }, [userContext])

  useEffect(() => {
    fetchCustomerSegments()
  }, [userContext, fetchCustomerSegments])

  const customerSegmentsToMappingValues = (
    segments: Types.Api.SegmentDefintion[]
  ): SelectedType[] => {
    const mappingValues: SelectedType[] = [
      {
        id: "customerId",
        type: "customer_id",
        name: "Customer ID",
        columnIndex: -1,
        required: true,
      },
    ]

    return mappingValues.concat(
      segments.map((segment, index) => ({
        id: segment.id,
        type: "customer_segment",
        name: segment.name,
        columnIndex: index,
        required: true,
      }))
    )
  }

  const uploadCsv = (file: File) => {
    const formData = new FormData()
    formData.append("csv", file)

    const requestOptions = {
      method: "POST",
      body: formData,
    }

    return userContext.fetch("/segments_importer/draft", requestOptions)
  }

  const findCustomerIdMapping = (selectedMappings: SelectedType[]) =>
    selectedMappings.find((item) => item.type === "customer_id")
  const findSegmentValueMapping = (selectedMappings: SelectedType[]) =>
    selectedMappings.find((item) => item.type === "customer_segment")

  const isMappingComplete = (selectedMappings: SelectedType[]) =>
    !!findCustomerIdMapping(selectedMappings) &&
    !!findSegmentValueMapping(selectedMappings)

  const finalizeImport = (
    importId: number,
    skipHeaders: boolean,
    mappings: SelectedType[]
  ) => {
    const customerIdMapping = findCustomerIdMapping(mappings)
    const segmentMapping = findSegmentValueMapping(mappings)

    if (!customerIdMapping || !segmentMapping) {
      AppToaster.showError({
        message:
          "Looks like import mapping is incomplete - make sure you map your columns correctly",
      })
      return new Promise((_resolve, reject) => reject())
    }

    const formData = new FormData()
    formData.append("import_id", importId.toString())
    formData.append(
      "public_id_column_index",
      customerIdMapping.columnIndex.toString()
    )
    formData.append(
      "segment_column_index",
      segmentMapping.columnIndex.toString()
    )
    formData.append("skip_headers", skipHeaders.toString())
    formData.append("customer_segment_id", segmentMapping.id)

    const requestOptions = {
      method: "POST",
      body: formData,
    }

    return userContext.fetch("/segments_importer/finalize", requestOptions)
  }

  const renderValidationCallout = (selectedMappings: SelectedType[]) => {
    if (
      selectedMappings.length > 1 &&
      !findCustomerIdMapping(selectedMappings)
    ) {
      return (
        <Callout intent={Intent.DANGER}>
          One of the columns must be mapped to Customer ID
        </Callout>
      )
    } else {
      return (
        <Callout intent={Intent.PRIMARY}>
          Only one value column can be imported at the time
        </Callout>
      )
    }
  }

  return (
    <DataImportWizard
      importChannelName="SegmentsImportChannel"
      mappingValues={customerSegmentsToMappingValues(customerSegments)}
      uploadCsv={uploadCsv}
      isMappingComplete={isMappingComplete}
      finalizeImport={finalizeImport}
      renderValidationCallout={renderValidationCallout}
      title="Import attribute values"
      selectFileHelpText={helpText}
      fullWidthMapping={false}
    />
  )
}

export default ImportSegmentsContainer

import React from "react"

type ReportContext = {
  dispatch: (payload: any) => any
  demo: boolean
  hasEditRights: boolean
  accountId?: string
  settings: {
    repeatable: boolean
    display_quarters: boolean
  }
  secret: string
}

const BusinessReportContext = React.createContext<ReportContext>({
  dispatch: (payload: any) => {}, // eslint-disable-line
  demo: false,
  hasEditRights: false,
  settings: {
    repeatable: false,
    display_quarters: false,
  },
  secret: "",
})

export default BusinessReportContext

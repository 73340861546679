import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  SyntheticEvent,
} from "react"
import { Collapse, TextArea, Button, Intent } from "@blueprintjs/core"
import { UserContext } from "../UserContext"

import "./AddComment.css"

type AddCommentProps = {
  commentedId: string
  isOpen: boolean
  onCommentAdded: (comment: Types.Api.Comment) => void
  onCancel: (event: SyntheticEvent) => void
}

const AddComment = ({
  commentedId,
  isOpen,
  onCommentAdded,
  onCancel,
}: AddCommentProps) => {
  const [commentText, setCommentText] = useState("")
  const userContext = useContext(UserContext)
  const addCommentRef = useRef<HTMLDivElement>(null)

  const onAddComment = () => {
    userContext
      .fetch("/comments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          commented_id: commentedId,
          content: commentText,
        }),
      })
      .then((data) => {
        setCommentText("")
        onCommentAdded(data)
      })
  }

  const onCommentTextChange = (event: React.FormEvent<HTMLElement>) => {
    setCommentText((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    if (isOpen) {
      const timeout = window.setTimeout(
        () =>
          addCommentRef!.current!.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          }),
        200
      )

      return () => window.clearTimeout(timeout)
    }
  }, [isOpen])

  return (
    <Collapse isOpen={isOpen} keepChildrenMounted>
      <div className="addComment" ref={addCommentRef}>
        <TextArea
          large
          fill
          value={commentText}
          onChange={onCommentTextChange}
          className="commentText"
        />
        <div className="buttons">
          <Button
            text="Comment"
            onClick={onAddComment}
            minimal
            intent={Intent.PRIMARY}
            className="addCommentButton"
            disabled={commentText.length === 0}
          />
          <Button
            text="Cancel"
            onClick={onCancel}
            minimal
            intent={Intent.DANGER}
            className="addCommentButton"
          />
        </div>
      </div>
    </Collapse>
  )
}

export default AddComment

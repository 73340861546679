import React from "react"
import classnames from "classnames"

import css from "./Avatar.module.css"

type AvatarProps = {
  name: string
  clickable?: boolean
  dark?: boolean
}

const Avatar = ({ name, clickable, dark }: AvatarProps) => {
  const initials = () => {
    return name.charAt(0).toUpperCase() + name.charAt(1).toLowerCase()
  }

  return (
    <div
      className={classnames(css.stubAvatar, {
        [css.clickable]: clickable,
        [css.dark]: dark,
      })}
      title={name}
    >
      {initials()}
    </div>
  )
}

export default Avatar

import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useCableSubscription = (
  connectAttrs: any,
  onDataReceived: (data: any) => void
) => {
  const WEBSOCKET_TOAST_KEY = "WEBSOCKET_TOAST"
  const userContext = useContext(UserContext)
  const [websocketConnectionLost, setWebsocketConnectionLost] = useState(false)

  const onChannelConnected = useCallback(() => {
    if (websocketConnectionLost) {
      AppToaster.show(
        {
          timeout: 2000,
          intent: "success",
          message: "Connection re-established",
        },
        WEBSOCKET_TOAST_KEY
      )
      setWebsocketConnectionLost(false)
    }
  }, [websocketConnectionLost])

  const onChannelDisconnected = useCallback(() => {
    setWebsocketConnectionLost(true)
    AppToaster.show(
      {
        timeout: -1,
        intent: "danger",
        message: "Connection lost, trying to reconnect...",
      },
      WEBSOCKET_TOAST_KEY
    )
  }, [])

  const createCable = useCallback(() => {
    if (connectAttrs) {
      return userContext.cable.subscriptions.create(connectAttrs, {
        received: onDataReceived,
        connected: onChannelConnected,
        disconnected: onChannelDisconnected,
      })
    }
  }, [
    userContext,
    connectAttrs,
    onDataReceived,
    onChannelDisconnected,
    onChannelConnected,
  ])

  useEffect(() => {
    const subscription = createCable()

    return () => {
      subscription?.unsubscribe()
    }
  }, [createCable])
}

export default useCableSubscription

import React, { useState, useContext } from "react"
import {
  Dialog,
  Button,
  Intent,
  Classes,
  InputGroup,
  Label,
} from "@blueprintjs/core"
import { useForm } from "react-hook-form"
import { DataSource } from "./Settings"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import withHookFormHelpter, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import Config from "../config"

type WfirmaDataSourceFormType = {
  wfirma_email: string
  wfirma_password: string
}

type WfirmaDataSourceProps =
  InjectedHookHelpersProps<WfirmaDataSourceFormType> & {
    integration: Types.Api.Integration
    onSuccess: (data: any) => void
  }

const WfirmaDataSource = ({
  integration,
  onSuccess,
  validationErrorMsg,
  fieldIntent,
}: WfirmaDataSourceProps) => {
  const userContext = useContext(UserContext)
  const [dialogOpen, setDialogOpen] = useState(false)

  const defaultValues = () => ({
    wfirma_email: integration.configuration?.wfirma_email,
    wfirma_password: integration.configuration?.wfirma_password,
  })

  const {
    formState: { errors },
    getValues,
    register,
    trigger,
    formState,
    reset,
  } = useForm<WfirmaDataSourceFormType>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues(),
  })

  const onDialogClose = () => {
    reset(defaultValues())
    setDialogOpen(false)
  }

  const onDialogSave = () => {
    trigger().then((success) => {
      if (success) {
        const formValues = getValues()
        const body = { configuration: formValues }

        userContext
          .fetch("/integrations/wfirma", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
          })
          .then((data) => {
            setDialogOpen(false)
            if (onSuccess) {
              onSuccess(data)
            }
          })
          .catch((error) => {
            error.response.json().then((errorData: any) => {
              console.warn(errorData)
              if (errorData.body.includes("cannot_connect")) {
                AppToaster.showError({
                  message:
                    "We could not connect to wFirma, please make sure your email and password are correct",
                })
              } else {
                AppToaster.showError({
                  message: "We could not save configuration",
                })
              }
            })
          })
      }
    })
  }

  const onCardClick = () => {
    setDialogOpen(true)
  }

  const emailField = register("wfirma_email", {
    required: "wFirma email cannot be empty",
  })
  const passwordField = register("wfirma_password", {
    required: "wFirma password cannot be empty",
  })

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={onDialogClose}
        title="wFirma"
        canOutsideClickClose={Config.canOutsideClickCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>Provide email and password</p>
          <Label>
            Email
            <InputGroup
              name={emailField.name}
              autoFocus
              placeholder="Email"
              intent={fieldIntent(errors, emailField.name)}
              large
              fill
              inputRef={emailField.ref}
              onChange={emailField.onChange}
              onBlur={emailField.onBlur}
            />
            {validationErrorMsg(errors, emailField.name)}
          </Label>
          <Label>
            Password
            <InputGroup
              name={passwordField.name}
              placeholder="Password"
              intent={fieldIntent(errors, passwordField.name)}
              large
              fill
              inputRef={passwordField.ref}
              onChange={passwordField.onChange}
              onBlur={passwordField.onBlur}
            />
            {validationErrorMsg(errors, passwordField.name)}
          </Label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close"
              onClick={onDialogClose}
              intent={Intent.NONE}
              outlined
              large
            />
            <Button
              type="submit"
              text="Save"
              onClick={onDialogSave}
              intent={Intent.PRIMARY}
              large
              disabled={!formState.isValid}
            />
          </div>
        </div>
      </Dialog>
      <DataSource
        integration={integration}
        containerClassName="wfirma"
        onClick={onCardClick}
      />
    </>
  )
}

export default withHookFormHelpter(WfirmaDataSource)

import React, { useState, useContext } from "react"
import { Dialog, Button, Intent, Classes, InputGroup } from "@blueprintjs/core"
import { useForm, Controller } from "react-hook-form"
import { DataSource } from "./Settings"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import Config from "../config"
import { apiUrl } from "../apiHelpers"

import formCss from "../forms.module.css"

const HubspotDataSource = ({ integration, onSuccess }) => {
  const userContext = useContext(UserContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const config = integration.configuration
  const [attributeName, setAttributeName] = useState(
    config && config.public_id_attribute_name
      ? config.public_id_attribute_name
      : ""
  )

  const {
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  })

  const onDialogClose = () => {
    setAttributeName(config.public_id_attribute_name || "")
    setDialogOpen(false)
  }

  const onDialogSave = () => {
    const body = { configuration: { public_id_attribute_name: attributeName } }

    userContext
      .fetch("/integrations/hubspot", {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      })
      .then((data) => {
        setDialogOpen(false)
        onSuccess(data)
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({ message: "We could not save configuration" })
      })
  }

  const onCardClick = () => {
    if (integration.enabled) {
      setDialogOpen(true)
    } else {
      const authorizationParam = encodeURIComponent(userContext.user.api_token)
      const url = apiUrl(
        `/users/auth/hubspot?authorization=${authorizationParam}`
      )
      window.location.href = url
    }
  }

  const fieldIntent = (name) => (errors[name] ? Intent.DANGER : Intent.NONE)

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={onDialogClose}
        title="HubSpot"
        canOutsideClickClose={Config.canOutsideClickCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Provide the attribute name where the <em>Customer ID</em> is stored
            in your HubSpot app
          </p>
          <Controller
            control={control}
            name="attribute_name"
            defaultValue={attributeName}
            render={({ field }) => (
              <InputGroup
                autoFocus
                placeholder="Attribute name"
                value={field.value}
                onChange={(event) => {
                  setAttributeName(event.currentTarget.value)
                  field.onChange(event.currentTarget.value)
                }}
                intent={fieldIntent("attribute_name")}
                large
                fill
              />
            )}
            rules={{ required: "Attribute name cannot be empty" }}
          />
          {errors["attribute_name"] && (
            <p className={formCss.errorLabel}>
              {errors["attribute_name"].message}
            </p>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close"
              onClick={onDialogClose}
              intent={Intent.NONE}
              outlined
              large
            />
            <Button
              type="submit"
              text="Save"
              onClick={onDialogSave}
              intent={Intent.PRIMARY}
              large
              disabled={errors["attribute_name"] || attributeName.length === 0}
            />
          </div>
        </div>
      </Dialog>
      <DataSource
        integration={integration}
        containerClassName="hubspot"
        onClick={onCardClick}
      />
    </>
  )
}

export default HubspotDataSource

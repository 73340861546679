import React, { useEffect, useContext, useState, SyntheticEvent } from "react"
import { Card, H4, Intent, Button, MenuItem, IconName } from "@blueprintjs/core"
import { UserContext } from "./UserContext"
import { State as Report } from "./businessReportReducer"
import { MultiSelect } from "@blueprintjs/select"

import css from "./BusinessReportPreview.module.css"
import { periodToRangeLabels } from "./ReportInfoTags"

type ReportTagsInputProps = {
  tags: string[]
  selected: string[]
  onTagsUpdate: (tags: string[]) => void
  placeholder?: string
  large?: boolean
  icon?: IconName
  allowCreateItem?: boolean
}

export const ReportTagsInput = ({
  tags,
  selected,
  onTagsUpdate,
  placeholder = "Add tag...",
  large = false,
  icon = "tag",
  allowCreateItem = true,
}: ReportTagsInputProps) => {
  const removeTag = (tag: string) => {
    onTagsUpdate(selected.filter((value) => value !== tag))
  }

  const onItemSelect = (
    tag: string,
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    event?.stopPropagation()
    if (selected.indexOf(tag) == -1) {
      onTagsUpdate(selected.concat([tag]))
    } else {
      removeTag(tag)
    }
  }

  const renderCreateNewTag = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
  ) => {
    return (
      <MenuItem
        active={active}
        icon="add"
        text={`Create ${query}`}
        shouldDismissPopover={false}
        onClick={handleClick}
      />
    )
  }

  const onItemRemove = (tag: string) => {
    removeTag(tag)
  }

  const onItemCreate = (tag: string) => {
    return tag
  }

  const renderTag = (tag: string, props: any) => (
    <MenuItem
      key={tag}
      text={tag}
      shouldDismissPopover={false}
      onClick={props.handleClick}
      icon={selected.indexOf(tag) != -1 ? "tick" : undefined}
    />
  )

  const itemPredicate = (query: string, tag: string) => {
    return tag.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  }

  const onTagClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
  }

  return (
    <MultiSelect
      fill
      tagInputProps={{
        inputProps: {
          onClick: (event) => event.stopPropagation(),
        },
        large: large,
        leftIcon: icon,
        className: css.noTagInputBorder,
        placeholder: placeholder,
        tagProps: {
          minimal: true,
          onClick: onTagClick,
          large: large,
        },
      }}
      popoverProps={{ minimal: true }}
      items={tags}
      itemRenderer={renderTag}
      onItemSelect={onItemSelect}
      onRemove={onItemRemove}
      itemPredicate={itemPredicate}
      tagRenderer={(tag) => tag}
      selectedItems={selected}
      createNewItemFromQuery={allowCreateItem ? onItemCreate : undefined}
      createNewItemRenderer={allowCreateItem ? renderCreateNewTag : undefined}
      createNewItemPosition="first"
      resetOnSelect={true}
      noResults={<MenuItem disabled={true} text="No results" />}
    />
  )
}

type BusinessReportPreviewProps = {
  report: Report
  onClick: (secret: string) => void
  onRemoveReportClick: (reportId: string) => void
  onUpdateReport: (report: Report) => void
  tags: string[]
}

const BusinessReportPreview = ({
  report,
  onClick,
  onRemoveReportClick,
  onUpdateReport,
  tags,
}: BusinessReportPreviewProps) => {
  const userContext = useContext(UserContext)
  const [hasEditRights, setHasEditRights] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  useEffect(() => setSelectedTags(report.tags), [report.tags])

  useEffect(() => {
    userContext
      .fetch(`/business_reports/${report.id}/can_edit`)
      .then((parsed) => setHasEditRights(parsed.allowed))
  }, [report, userContext])

  const removeReportClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    onRemoveReportClick(report.id)
  }

  const onCardClick = () => {
    onClick(report.secret)
  }

  const onTagsUpdate = (tags: string[]) => {
    setSelectedTags(tags)
    onUpdateReport({ ...report, tags })
  }

  const ownerName = () => {
    const name = report.owner?.name

    return name ? `By ${name}` : ""
  }

  const rangeLabel = periodToRangeLabels(report.period)

  return (
    <Card interactive={true} onClick={onCardClick} className={css.reportCard}>
      <div className={css.header}>
        <H4 className={css.title}>{report.title}</H4>
        {hasEditRights && (
          <Button
            className={css.deleteAction}
            intent={Intent.NONE}
            minimal
            icon="trash"
            alignText="right"
            onClick={removeReportClick}
          />
        )}
      </div>
      <div className={css.info}>
        <div className={css.range}>
          {rangeLabel.startLabel} - {rangeLabel.endLabel}
        </div>
        <div>{ownerName()}</div>
      </div>
      <div className={css.fade}>{report.summary}</div>
      <div className={css.tagsInput}>
        <ReportTagsInput
          tags={tags}
          selected={selectedTags}
          onTagsUpdate={onTagsUpdate}
        />
      </div>
    </Card>
  )
}

export default React.memo(BusinessReportPreview)

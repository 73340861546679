import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  useCallback,
  Dispatch,
} from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import BusinessReportStatusSection from "./BusinessReportStatusSection"
import DataImportDialog from "./DataImportDialog"
import BusinessReport from "./BusinessReport"
import businessReportReducer, {
  Action as MainAction,
} from "./businessReportReducer"
import BusinessReportProvider from "./BusinessReportProvider"
import { AppToaster } from "./AppToaster"
import { UserContext } from "./UserContext"
import AddSectionDialog from "./AddSectionDialog"
import BusinessReportContext from "./BusinessReportContext"
import ReportFilter from "./ReportFilter"
import { RangeTag, SettingsTag } from "./ReportInfoTags"
import BusinessReportShareDialog from "./BusinessReportShareDialog"
import BusinessReportActionBar, {
  ActionBarButton,
} from "./BusinessReportActionBar"
import { rollbar } from "./rollbar"
import { BusinessReportCurrencySwitcher } from "./BusinessReportCurrencySwitcher"
import ReportContentTable from "./ReportContentTable"

import "./BusinessReportContainer.css"
import { Icon } from "@blueprintjs/core"
import ReportViewDrawer, { View } from "./ReportViewDrawer"
import ReportViewTagsSection from "./ReportViewTags"
import useSectionDefinitions from "./api/useSectionDefinitions"

interface MatchParams {
  secret: string
}

interface BusinessReportContainerProps
  extends RouteComponentProps<MatchParams> {
  demo: boolean
  shared: boolean
}

interface BusinessReportContainerWithStateProps
  extends BusinessReportContainerProps {
  dispatch: Dispatch<MainAction>
  state: any
}

const BusinessReportContainer = (props: BusinessReportContainerProps) => {
  const [state, dispatch] = useReducer<any>(businessReportReducer, undefined)
  const [csvImportOpen, setCsvImportOpen] = useState(false)
  const currency = new URLSearchParams(props.location.search).get("currency")

  const openCsvImport = () => {
    setCsvImportOpen(true)
  }

  const onCsvImportClose = () => {
    setCsvImportOpen(false)
  }

  return (
    <div className="reportContainer">
      <DataImportDialog
        isOpen={csvImportOpen}
        onClose={onCsvImportClose}
        openImportDialog={openCsvImport}
      />
      {!state && (
        <BusinessReportProvider
          secret={props.match.params.secret}
          demo={props.demo}
          dispatch={dispatch}
          shared={props.shared}
          currency={currency}
        />
      )}
      {state && (
        <BusinessReportContainerWithState
          {...props}
          dispatch={dispatch}
          state={state}
        />
      )}
    </div>
  )
}

const BusinessReportContainerWithState = (
  props: BusinessReportContainerWithStateProps
) => {
  const { dispatch, state } = props
  const [filterItems, setFilterItems] = useState<
    Types.Api.BusinessReportSegmentValue[]
  >([])
  const [presentMode, setPresentMode] = useState(false)
  const [addSectionOpen, setAddSectionOpen] = useState(false)
  const userContext = useContext(UserContext)
  const [hasEditRights, setHasEditRights] = useState(false)
  const [promptToDataImport, setPromptToDataImport] = useState(false)
  const [repeatable, setRepeatable] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(false)
  const [displayQuarters, setDisplayQuarters] = useState(false)
  const [subtractForeverDiscount, setSubtractForeverDiscount] = useState(false)
  const [subtractFiniteDiscount, setSubtractFinateDiscount] = useState(false)
  const [planId, setPlanId] = useState()
  const [selectedFilter, setSelectedFilter] = useState<
    Types.Api.BusinessReportSegmentValue | undefined
  >(undefined)
  const noNeedToCheckRights =
    props.demo ||
    !userContext.hasUserLevelAccess() ||
    userContext.user!.account_id !== state.account_id ||
    props.shared
  const noNeedToCheckDataExistence =
    props.demo || !userContext.hasUserLevelAccess()
  const canShare =
    userContext.hasUserLevelAccess() &&
    userContext.user!.account_id === state.account_id &&
    !props.shared
  const canAccessDetails = canShare
  const reportId = state.id
  const [dialogOpen, setDialogOpen] = useState(false)
  const [viewDrawerOpen, setViewDrawerOpen] = useState(false)
  const sectionsDefinition = useSectionDefinitions()

  const onDialogClose = () => setDialogOpen(false)
  const openDialog = () => setDialogOpen(true)

  const contextValue = () => ({
    dispatch,
    demo: props.demo,
    hasEditRights,
    accountId: state.account_id,
    settings: {
      repeatable,
      auto_refresh: autoRefresh,
      display_quarters: displayQuarters,
      subtract_forever_discount_from_mrr: subtractForeverDiscount,
      subtract_finite_discount_from_mrr: subtractFiniteDiscount,
    },
    secret: state.secret,
  })

  const onFilterSelect = (filter: Types.Api.BusinessReportSegmentValue) => {
    setSelectedFilter(filter)
    const params = new URLSearchParams()
    params.set("secret", state!.secret)
    params.set("filter_segmenter_id", filter.segment.id)
    params.set("filter_segment_value", filter.value)
    params.set("currency", state.currency)

    const fetchPath = `/business_reports/by_secret_filtered?${params.toString()}`

    userContext
      .fetch(fetchPath)
      .then((data) =>
        dispatch({ type: "applyFilter", sections: data.sections })
      )
  }

  const onFilterClear = useCallback(() => {
    setSelectedFilter(undefined)
    dispatch({ type: "clearFilter" })
  }, [dispatch])

  useEffect(() => {
    if (!userContext.hasUserLevelAccess()) return

    userContext
      .fetch("/plans")
      .then((plans) => {
        if (plans.items.length > 0) {
          setPlanId(plans.items[0].id)
        }
      })
      .catch((error) => {
        console.warn(error)
        rollbar.error(error)
      })
  }, [userContext])

  useEffect(() => {
    if (noNeedToCheckRights) return undefined

    userContext
      .fetch(`/business_reports/${reportId}/can_edit`)
      .then((parsed) => setHasEditRights(parsed.allowed))
  }, [noNeedToCheckRights, reportId, userContext])

  const fetchFullSettings = useCallback(() => {
    userContext
      .fetch(`/business_reports/${reportId}/settings`)
      .then((settings) => {
        setRepeatable(settings.repeatable)
        setAutoRefresh(settings.auto_refresh)
        setDisplayQuarters(settings.display_quarters)
        setSubtractFinateDiscount(settings.subtract_finite_discount_from_mrr)
        setSubtractForeverDiscount(settings.subtract_forever_discount_from_mrr)
      })
  }, [reportId, userContext])

  const fetchSettingsBySecret = useCallback(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }

    const path = `/business_reports/settings_by_secret?secret=${state.secret}`

    return userContext.fetch(path, requestOptions).then((settings) => {
      setDisplayQuarters(settings.display_quarters)
    })
  }, [state.secret, userContext])

  useEffect(() => {
    if (hasEditRights) {
      fetchFullSettings()
    } else {
      fetchSettingsBySecret()
    }
  }, [hasEditRights, fetchFullSettings, fetchSettingsBySecret])

  const updateCurrency = (currency: string) => {
    return userContext.fetch(`/business_reports/${reportId}/settings`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ currency: currency.toLowerCase() }),
    })
  }

  const updateReportView = (view: View) => {
    userContext
      .fetch(`/business_reports/${reportId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: view.arguments.length > 0 ? view : undefined,
        }),
      })
      .then(() => {
        onRefreshClick()
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "We could not save this view, please try again or contact us",
        })
      })
  }

  const onSettingsChange = useCallback(
    (value) => {
      if (
        value.repeatable !== repeatable ||
        value.auto_refresh !== autoRefresh ||
        value.display_quarters !== displayQuarters ||
        value.subtract_finite_discount_from_mrr !== subtractFiniteDiscount ||
        value.subtract_forever_discount_from_mrr !== subtractForeverDiscount
      ) {
        userContext
          .fetch(`/business_reports/${reportId}/settings`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(value),
          })
          .then((settings) => {
            setRepeatable(settings.repeatable)
            setAutoRefresh(settings.auto_refresh)
            setSubtractFinateDiscount(
              settings.subtract_finite_discount_from_mrr
            )
            setSubtractForeverDiscount(
              settings.subtract_forever_discount_from_mrr
            )
            // when switching from quarter to monthly resolution - clear filter if any applied
            if (
              value.display_quarters === false &&
              displayQuarters === true &&
              selectedFilter
            ) {
              onFilterClear()
            }
            setDisplayQuarters(settings.display_quarters)
          })
          .catch((error) => {
            console.warn(error)
            AppToaster.showError({
              message: "We could not save your changes. Please try again.",
            })
          })
      }
    },
    [
      repeatable,
      autoRefresh,
      subtractFiniteDiscount,
      subtractForeverDiscount,
      userContext,
      reportId,
      displayQuarters,
      selectedFilter,
      onFilterClear,
    ]
  )

  useEffect(() => {
    if (noNeedToCheckDataExistence) return undefined

    userContext
      .fetch("/data_existence")
      .then((parsed) => setPromptToDataImport(!parsed.finance))
  }, [noNeedToCheckDataExistence, userContext])

  const togglePresentMode = useCallback(() => {
    setPresentMode(!presentMode)
  }, [presentMode])

  const presentModeClick = () => {
    AppToaster.show({
      icon: "presentation",
      timeout: 3500,
      message: <>Hot keys: arrow &#8594;, space, &#8592; arrow, Esc</>,
    })
    togglePresentMode()
  }

  const addSection = useCallback(() => {
    onFilterClear()
    setAddSectionOpen(true)
  }, [onFilterClear, setAddSectionOpen])

  const onRefreshClick = useCallback(() => {
    onFilterClear()

    userContext
      .fetch(`/business_reports/${reportId}/refresh`, { method: "POST" })
      .then((data) => dispatch({ type: "updateReport", attributes: data }))
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not refresh the report",
        })
      })
  }, [userContext, reportId, onFilterClear, dispatch])

  const onCreateView = () => {
    setViewDrawerOpen(true)
  }

  const onAddSectionClose = () => {
    setAddSectionOpen(false)
  }

  const actionBarButtons = () => {
    const buttons = [] as Array<ActionBarButton>

    if (hasEditRights) {
      buttons.push({
        key: "add_section",
        text: "Add chart",
        icon: <Icon icon="add" iconSize={16} color="#106ba3" />,
        onClick: addSection,
      })
    }

    buttons.push({
      key: "report_present",
      text: "Present",
      icon: "presentation",
      onClick: presentModeClick,
    })

    if (hasEditRights) {
      buttons.push({
        key: "report_refresh",
        text: "Recalculate",
        icon: "refresh",
        disabled: state!.recalculation_status === "recalculating",
        onClick: onRefreshClick,
      })

      buttons.push({
        key: "create_view",
        text: state!.filter ? "Edit view" : "Create view",
        icon: "th-filtered",
        disabled: state!.recalculation_status === "recalculating",
        onClick: onCreateView,
      })
    }

    if (canShare) {
      buttons.push({
        key: "report_share",
        text: "Share",
        icon: "link",
        onClick: openDialog,
      })
    }

    return buttons
  }

  const onAddChartClick = useCallback(() => {
    addSection()
  }, [addSection])

  const fetchSegmentValues = () => {
    if (props.shared) return

    performFetchSegmentValues(state.secret)
      .then((data) => setFilterItems(data.items))
      .catch(() => {
        AppToaster.showError({ message: "We could not fetch possible filters" })
      })
  }

  const performFetchSegmentValues = useCallback(
    (secret: string): Promise<Types.Api.BusinessReportSegmentValueList> => {
      return userContext.fetch(
        `/business_report_segment_values/by_secret?secret=${secret}&query=`
      )
    },
    [userContext]
  )

  useEffect(fetchSegmentValues, [
    performFetchSegmentValues,
    props.shared,
    state.secret,
  ])
  useEffect(() => {
    if (
      (state.recalculation_status === "recalculating" || props.shared) &&
      selectedFilter
    ) {
      onFilterClear()
    }
  }, [state, props.shared, selectedFilter, onFilterClear])

  const reportFilter = () => (
    <ReportFilter
      items={filterItems}
      selectedFilter={selectedFilter}
      onFilterSelect={onFilterSelect}
      onFilterClear={onFilterClear}
      disabled={state.recalculation_status === "recalculating" || props.shared}
      predefinedSegmenterName={state.filtered_segmenter_name}
      predefinedSegmentValue={state.filtered_segment_value}
    />
  )

  const onCurrencySwitch = (currency: string) => {
    updateCurrency(currency)
      .then((settings) => {
        props.dispatch({
          type: "updateReportCurrency",
          currency: settings.currency,
        })
        onRefreshClick()
      })
      .catch(() => {
        AppToaster.showError({
          message: "We could not update your report currency, please try again",
        })
      })
  }

  const prepareFilter = () => {
    if (!state.filter) {
      return {
        operator: "and",
        arguments: [
          {
            type: "segment",
            name: "",
            value: "",
          },
        ],
      }
    }

    return state.filter
  }

  return (
    <BusinessReportContext.Provider value={contextValue()}>
      <BusinessReportActionBar
        buttons={actionBarButtons()}
        refreshedAt={state.refreshed_at}
        settingsTag={
          hasEditRights && <SettingsTag onSettingsChange={onSettingsChange} />
        }
        rangeTag={
          <RangeTag
            period={state.period}
            reportId={state.id}
            dispatch={dispatch}
            disabledRangePicker={
              state.recalculation_status === "recalculating" ||
              !hasEditRights ||
              props.shared
            }
          />
        }
        filterTag={reportFilter()}
        currencySwitcher={
          <BusinessReportCurrencySwitcher
            onSwitch={onCurrencySwitch}
            reportCurrency={state.currency}
            disabledSwitch={
              state.recalculation_status === "recalculating" ||
              !hasEditRights ||
              props.shared
            }
          />
        }
      />
      {hasEditRights && (
        <ReportViewDrawer
          isOpen={viewDrawerOpen}
          onClose={() => setViewDrawerOpen(false)}
          filter={prepareFilter()}
          onSubmit={updateReportView}
        />
      )}
      <AddSectionDialog
        history={props.history}
        sections={sectionsDefinition}
        dispatch={dispatch}
        businessReportId={state.id}
        isOpen={addSectionOpen}
        onClose={onAddSectionClose}
      />
      <BusinessReportStatusSection
        recalculationStatus={state.recalculation_status}
        freshnessStatus={state.freshness_status}
        onRefreshClick={onRefreshClick}
        hasEditRights={hasEditRights}
        promptToDataImport={promptToDataImport}
      />
      {state.filter && <ReportViewTagsSection filter={state.filter} />}
      <ReportContentTable report={state} />
      <BusinessReportShareDialog
        isDialogOpen={dialogOpen}
        onDialogClose={onDialogClose}
        selectedFilter={selectedFilter}
        reportId={reportId}
        currency={state.currency}
      />
      <BusinessReport
        planId={planId}
        dispatch={dispatch}
        report={state}
        presentMode={presentMode}
        togglePresentMode={togglePresentMode}
        hasEditRights={hasEditRights}
        onAddChart={onAddChartClick}
        canAccessDetails={canAccessDetails}
        filter={selectedFilter}
        sectionsDefinition={sectionsDefinition}
      />
    </BusinessReportContext.Provider>
  )
}

export default withRouter(BusinessReportContainer)

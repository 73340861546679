import { Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core"
import {
  IItemRendererProps,
  ItemListRenderer,
  Select,
} from "@blueprintjs/select"
import React from "react"

import css from "./SelectValue.module.css"

export type ValueObject = {
  [index: string]: string | any
  id?: string
}

type GroupedItems = {
  [key: string]: React.ReactElement[]
}

const SelectValueList = Select.ofType<ValueObject>()

export type SelectValueProps = {
  activeItem?: ValueObject
  items: ValueObject[]
  onItemSelect: (value: ValueObject) => void
  labelKey: string
  searchBy: string[]
  groupBy?: string
  targetRef?: any
  placeholder: string
  onRemove?: (value: ValueObject) => void
}

const SelectValue = ({
  activeItem,
  onItemSelect,
  items,
  labelKey,
  searchBy,
  groupBy,
  targetRef,
  placeholder,
  onRemove,
}: SelectValueProps) => {
  const itemPredicate = (query: string, item: ValueObject) => {
    if (query.length === 0) return true

    const lowercaseQuery = query.toLowerCase()

    return searchBy
      .map((key) => item[key].toLowerCase().includes(lowercaseQuery))
      .includes(true)
  }

  const itemRenderer = (
    item: ValueObject,
    { handleClick, index, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        key={`${item.id ? item.id : ""}_${item[labelKey].replaceAll(
          " ",
          "_"
        )}_${index}`}
        text={item[labelKey]}
        onClick={handleClick}
        active={modifiers.active}
      />
    )
  }

  const renderMenu: ItemListRenderer<ValueObject> = ({
    filteredItems,
    itemsParentRef,
    renderItem,
  }) => {
    if (filteredItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          <MenuItem disabled text="No results" />
        </Menu>
      )
    }

    const renderedItems = groupBy
      ? renderGrouped({ renderItem, filteredItems, groupByKey: groupBy })
      : renderFlat({ renderItem, filteredItems })

    return <Menu ulRef={itemsParentRef}>{renderedItems}</Menu>
  }

  const renderFlat = ({ renderItem, filteredItems }: any) => {
    return filteredItems.map(renderItem)
  }

  const renderGrouped = ({ renderItem, filteredItems, groupByKey }: any) => {
    const groupedItems = filteredItems.reduce(
      (res: GroupedItems, item: ValueObject, index: number) => {
        const rendered = renderItem(item, index)
        if (rendered === null) return res

        res[item[groupByKey]] = res[item[groupByKey]] || []
        res[item[groupByKey]].push(rendered)
        return res
      },
      {}
    )

    return Object.keys(groupedItems).flatMap((key, index) => {
      return [
        <MenuDivider key={index} title={key} className={css.noBorder} />,
        groupedItems[key],
      ].flat()
    })
  }

  return (
    <SelectValueList
      activeItem={activeItem}
      items={items}
      itemListRenderer={renderMenu}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      fill
      popoverProps={{ minimal: true }}
      inputProps={{ large: true }}
      filterable
      matchTargetWidth
    >
      <Tag
        className={css.selectValueTag}
        elementRef={targetRef}
        icon="double-caret-vertical"
        large
        interactive
        fill
        minimal
        {...(onRemove && activeItem
          ? { onRemove: () => onRemove(activeItem) }
          : {})}
      >
        {activeItem ? activeItem[labelKey] : placeholder}
      </Tag>
    </SelectValueList>
  )
}

export default SelectValue

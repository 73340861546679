import React, { useCallback, useMemo, useState } from "react"
import {
  Button,
  Spinner,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  Tag,
  Icon,
  Tooltip,
} from "@blueprintjs/core"
import { useHistory } from "react-router-dom"
import pluralize from "pluralize"
import { AppToaster } from "./AppToaster"
import { Section } from "./Content"
import ImportFileSelector from "./ImportFileSelector"
import ImportSegmentsErrorsSummary from "./ImportSegmentsErrorsSummary"
import { writeToClipboard } from "./utils"
import DataMappingStep, { SelectedType } from "./DataMappingImportStep"
import { SuccessState } from "./NonIdealState"

import css from "./DataImportWizard.module.css"
import { DataImportStatusPolling } from "./DataImportDialog"
import useCableSubscription from "./api/useCableSubscription"

type SkipHeadersPickerType = {
  skipHeaders: boolean
  setSkipHeaders: (_: boolean) => void
}

const SkipHeadersPicker = ({
  skipHeaders,
  setSkipHeaders,
}: SkipHeadersPickerType) => {
  const menu = () => {
    return (
      <Menu>
        <MenuItem
          key="Yes"
          text="Yes"
          onClick={() => setSkipHeaders(true)}
          active={skipHeaders}
        />
        <MenuItem
          key="No"
          text="No"
          onClick={() => setSkipHeaders(false)}
          active={!skipHeaders}
        />
      </Menu>
    )
  }

  return (
    <Popover content={menu()} position={Position.BOTTOM} placement="bottom">
      <Tag
        icon={<Icon icon="chevron-down" iconSize={10} />}
        minimal
        intent={Intent.PRIMARY}
        interactive={true}
        large
      >
        {skipHeaders ? "Yes" : "No"}
      </Tag>
    </Popover>
  )
}

type DataImportWizardProps = {
  importChannelName: string
  mappingValues: SelectedType[]
  uploadCsv: (file: File) => Promise<Types.Api.CsvImportResponseDefinition>
  isMappingComplete: (mappings: SelectedType[]) => boolean
  finalizeImport: (
    importId: number,
    skipHeaders: boolean,
    mappings: SelectedType[]
  ) => Promise<any>
  renderValidationCallout: (mappings: SelectedType[]) => React.ReactNode
  fullWidthMapping?: boolean
  title: string
  selectFileHelpText?: string
  statusCheckMode?: "channel" | "polling"
}

const DataImportWizard = ({
  importChannelName,
  mappingValues,
  uploadCsv,
  isMappingComplete,
  finalizeImport,
  renderValidationCallout,
  fullWidthMapping = true,
  title,
  selectFileHelpText,
  statusCheckMode = "channel",
}: DataImportWizardProps) => {
  const IMPORT_STATUS = {
    NOT_STARTED: "not_started",
    UPLOADING: "uploading",
    PREVIEW: "preview",
    FINALIZING: "finalizing",
    FINALIZED: "finalized",
  }

  const history = useHistory()
  const [previewData, setPreviewData] =
    useState<Types.Api.CsvPreviewData | null>(null)

  const [skipHeaders, setSkipHeaders] = useState(false)
  const [importId, setImportId] = useState<number | null>(null)
  const [importStatus, setImportStatus] = useState(IMPORT_STATUS.NOT_STARTED)
  const [summaryData, setSummaryData] =
    useState<Types.Api.CsvImportResultDefinition>()
  const [selectedMappings, setSelectedMappings] = useState<SelectedType[]>([])

  const onSuccess = (data: Types.Api.CsvImportResponseDefinition) => {
    setPreviewData(data.preview_rows)
    setImportId(data.import_id)
    setImportStatus(IMPORT_STATUS.PREVIEW)
  }

  const onImport = (selectedFile: File | null) => {
    if (!selectedFile) {
      return
    }

    setImportStatus(IMPORT_STATUS.UPLOADING)
    uploadCsv(selectedFile)
      .then((data) => onSuccess(data))
      .catch(() =>
        AppToaster.showError({
          message: "Something went wrong with uploading csv file",
        })
      )
  }

  const onFinalizing = () => {
    setImportStatus(IMPORT_STATUS.FINALIZING)
  }

  type ResultDataType = {
    result: Types.Api.CsvImportResultDefinition
  }

  const onImportStatusReceived = useCallback(
    (data: ResultDataType) => {
      setSummaryData(data.result)
      setImportStatus(IMPORT_STATUS.FINALIZED)
    },
    [IMPORT_STATUS.FINALIZED]
  )

  const validForSubmit = () => {
    return (
      importStatus !== IMPORT_STATUS.FINALIZING &&
      importStatus !== IMPORT_STATUS.FINALIZED &&
      isMappingComplete(selectedMappings)
    )
  }

  const importConfiguration = () => (
    <div className={css.importConfig}>
      <span>
        <Tooltip
          position={Position.TOP}
          openOnTargetFocus={false}
          popoverClassName="titleTooltip"
          content="Indicate whether first row of your csv should be skipped during import because it is a header."
        >
          <Icon icon="info-sign" iconSize={14} className="titleTooltipSign" />
        </Tooltip>
        Skip headers
      </span>
      <SkipHeadersPicker
        skipHeaders={skipHeaders}
        setSkipHeaders={setSkipHeaders}
      />
    </div>
  )

  const setSelectedMapping = (selectedType: SelectedType) => {
    setSelectedMappings(
      selectedMappings
        .filter(
          (item) =>
            item.columnIndex !== selectedType.columnIndex &&
            item.id !== selectedType.id &&
            item.type !== selectedType.type
        )
        .concat([selectedType])
    )
  }

  const cleanSelectedMapping = (selectedType: SelectedType) => {
    setSelectedMappings(
      selectedMappings.filter(
        (item) =>
          item.columnIndex !== selectedType.columnIndex &&
          item.id !== selectedType.id &&
          item.type !== selectedType.type
      )
    )
  }

  const onFinalizeImport = () => {
    finalizeImport(importId!, skipHeaders, selectedMappings).then(() =>
      onFinalizing()
    )
  }

  const renderDataMappingStep = () => (
    <>
      <div className={css.importPreview}>
        {previewData && (
          <DataMappingStep
            data={previewData}
            values={mappingValues}
            selectedTypes={selectedMappings}
            setMapping={setSelectedMapping}
            cleanMapping={cleanSelectedMapping}
            fullWidth={fullWidthMapping}
          />
        )}
      </div>
      <div className={css.importFooter}>
        {importConfiguration()}
        <div className={css.importButtons}>
          {importStatus !== IMPORT_STATUS.FINALIZING && (
            <Button
              disabled={!validForSubmit()}
              text={title}
              onClick={onFinalizeImport}
              intent={Intent.PRIMARY}
              outlined
              large
            />
          )}
          {importStatus === IMPORT_STATUS.FINALIZING && (
            <div className={css.importing}>
              Importing, please dont close this page
              <Spinner size={35} intent={Intent.PRIMARY} />
            </div>
          )}
        </div>
      </div>
    </>
  )

  const renderImportSummaryStep = () => {
    if (summaryData && importCompletedSuccessfully()) {
      return (
        <SuccessState
          title="It is a success!"
          description={`All ${summaryData.imported_count} ${pluralize(
            "record",
            summaryData.imported_count
          )} imported successfully.`}
          onAction={endImport}
        />
      )
    }

    if (summaryData && !importCompletedSuccessfully()) {
      return (
        <>
          <ImportSegmentsErrorsSummary summaryData={summaryData} />
          <div className={css.importFooter}>
            <Button
              text="Copy not imported lines"
              onClick={copyErrors}
              intent={Intent.PRIMARY}
              large
              outlined
            />
            <div className={css.importButtons}>
              <Button
                text="Finish"
                onClick={endImport}
                intent={Intent.PRIMARY}
                large
                outlined
              />
            </div>
          </div>
        </>
      )
    }
  }

  const isFileUploadStep = () =>
    importStatus === IMPORT_STATUS.NOT_STARTED ||
    importStatus === IMPORT_STATUS.UPLOADING

  const isDataMappingStep = () =>
    importStatus === IMPORT_STATUS.PREVIEW ||
    importStatus === IMPORT_STATUS.FINALIZING

  const isImportSummaryStep = () => importStatus === IMPORT_STATUS.FINALIZED

  const importCompletedSuccessfully = () =>
    summaryData && summaryData.errors.length === 0

  const endImport = () => {
    history.push("/")
  }

  const copyErrors = () => {
    if (summaryData) {
      const failedRows = summaryData.errors.map((line) => line.row)
      writeToClipboard(
        failedRows.join("\r\n"),
        "Not imported rows are copied to clipboard"
      )
    }
  }

  const importChannel = useMemo(() => {
    if (statusCheckMode === "channel") {
      return { channel: importChannelName }
    }

    return null
  }, [statusCheckMode, importChannelName])

  useCableSubscription(importChannel, onImportStatusReceived)

  const mountImportStatusCheck = () => {
    if (importId && importStatus === IMPORT_STATUS.FINALIZING) {
      return (
        <DataImportStatusPolling
          importId={importId}
          onCompleted={onImportStatusReceived}
          onError={() =>
            alert("something went wrong with import status checking")
          }
        />
      )
    }

    return <></>
  }

  return (
    <Section title={title} small={isFileUploadStep()}>
      {mountImportStatusCheck()}
      {isFileUploadStep() && (
        <ImportFileSelector
          onImport={onImport}
          importInProgress={importStatus === IMPORT_STATUS.UPLOADING}
          helpText={selectFileHelpText}
        />
      )}
      {isDataMappingStep() && (
        <>
          {renderValidationCallout(selectedMappings)}
          {renderDataMappingStep()}
        </>
      )}
      {isImportSummaryStep() && renderImportSummaryStep()}
    </Section>
  )
}

export default DataImportWizard

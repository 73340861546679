import React, { useState, useCallback, useContext, useEffect } from "react"
import { UserContext } from "./UserContext"

const withDetailedCustomer = (WrappedComponent) => (props) => {
  const userContext = useContext(UserContext)
  const [detailedCustomer, setDetailedCustomer] = useState({})

  const setDetailedCustomerAndKeepSegments = useCallback(
    (customer) => {
      if (customer.id === detailedCustomer.id) {
        setDetailedCustomer({ ...customer, ...detailedCustomer })
      } else {
        setDetailedCustomer(customer)
      }
    },
    [detailedCustomer]
  )

  useEffect(() => {
    if (detailedCustomer.id) {
      userContext
        .fetch(`/customers/${detailedCustomer.id}`)
        .then((customerData) => setDetailedCustomer(customerData))
    }
  }, [detailedCustomer.id, userContext])

  const onDetailedCustomerUpdated = useCallback((customer) => {
    setDetailedCustomer(customer)
  }, [])

  return (
    <WrappedComponent
      {...props}
      detailedCustomer={detailedCustomer}
      setDetailedCustomer={setDetailedCustomerAndKeepSegments}
      onDetailedCustomerUpdated={onDetailedCustomerUpdated}
    />
  )
}

export default withDetailedCustomer

import React, { useMemo, useEffect } from "react"
import {
  Drawer,
  Intent,
  Classes,
  Button,
  Popover,
  Position,
  Menu,
  MenuItem,
} from "@blueprintjs/core"
import { useForm } from "react-hook-form"
import CostForm from "./CostForm"

import formCss from "../forms.module.css"
import css from "./CostDrawer.module.css"
import { parseISO } from "date-fns"
import { formatTime, jsDateToISOString } from "../formatters"

type CostDrawer = {
  isOpen: boolean
  onClose: () => void
  defaultValues: Types.Api.Cost
  onSubmit: (cost: Types.Api.Cost) => void
  onDelete: (cost: Types.Api.Cost) => void
}

const CostDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  onDelete,
  defaultValues,
}: CostDrawer) => {
  const formDefaultValues = useMemo(
    () => ({
      ...defaultValues,
      date: parseISO(defaultValues.date as string),
      due_date: defaultValues.due_date
        ? parseISO(defaultValues.due_date as string)
        : null,
    }),
    [defaultValues]
  )

  const useFormMethods = useForm<Types.Api.Cost>({
    mode: "all",
    defaultValues: formDefaultValues,
  })

  const { trigger, formState, getValues, reset } = useFormMethods

  useEffect(() => reset(formDefaultValues), [formDefaultValues, reset])

  const editMode = useMemo(() => {
    return defaultValues?.id && defaultValues.id.length > 0
  }, [defaultValues])

  const onFormSave = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()

        onSubmit({
          ...values,
          date: formatTime(
            jsDateToISOString(values.date as Date),
            "yyyy-MM-dd"
          ),
          due_date: values.due_date
            ? formatTime(
                jsDateToISOString(values.due_date as Date),
                "yyy-MM-dd"
              )
            : null,
        })
        closeDrawer()
      }
    })
  }

  const closeDrawer = () => {
    reset(defaultValues)
    onClose()
  }

  const renderActionsMenu = () => (
    <Menu>
      <MenuItem
        icon="trash"
        text="Delete cost"
        onClick={() => onDelete(defaultValues)}
        intent={Intent.DANGER}
      />
    </Menu>
  )

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={closeDrawer}
      title={editMode ? "Edit cost" : "Create cost"}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={formCss.insideDrawer}>
          {defaultValues.id && (
            <div className={formCss.headerActions}>
              <Popover
                content={renderActionsMenu()}
                position={Position.BOTTOM}
                className={css.costActions}
              >
                <Button
                  icon="chevron-down"
                  text="Actions"
                  minimal
                  intent={Intent.PRIMARY}
                />
              </Popover>
            </div>
          )}
          <CostForm useFormMethods={useFormMethods} />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            disabled={!formState.isValid}
            text={editMode ? "Save changes" : "Add costs"}
            outlined
            onClick={onFormSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default CostDrawer

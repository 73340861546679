import React, { useState, useEffect, useContext, useCallback } from "react"
import {
  H3,
  Label,
  FormGroup,
  InputGroup,
  Icon,
  Classes,
  HTMLSelect,
} from "@blueprintjs/core"
import { useForm, Controller } from "react-hook-form"
import parseISO from "date-fns/parseISO"
import classnames from "classnames"
import addMonths from "date-fns/addMonths"
import { jsDateToISOString } from "./formatters"
import ProductInput from "./ProductInput"
import withHookFormHelpers from "./withHookFormHelpers"
import DateInputWithValidation from "./DateInputWithValidation"
import { UserContext } from "./UserContext"
import SubscriptionItemTypeSwitcher from "./SubscriptionItemTypeSwitcher"

import formCss from "./forms.module.css"

export const convertInputToSubscriptionAttrs = (customerId, subscription) => {
  const started_at = `${subscription.started_at}T00:00:00Z`
  const ended_at_date = jsDateToISOString(
    addMonths(
      parseISO(subscription.started_at),
      subscription.duration_in_months
    )
  )
  const ended_at = `${ended_at_date}T00:00:00Z`

  return {
    started_at: started_at,
    ended_at: ended_at,
    monthly_amount: `${
      subscription.total_value / subscription.duration_in_months
    }`,
    customer_id: customerId,
    currency: subscription.currency,
    items: subscription.items,
  }
}

const SubscriptionForm = ({
  onFieldChange,
  defaults,
  autoFocus,
  compact,
  fieldIntent,
  validationErrorMsg,
}) => {
  const userContext = useContext(UserContext)
  const [currencies, setCurrencies] = useState([defaults.currency])

  const fetchAllowedCurrencies = () => {
    userContext
      .fetch("/currencies", {
        method: "GET",
      })
      .then((data) => {
        setCurrencies(data.items)
      })
      .catch((error) => {
        console.warn(error)
        setCurrencies([defaults.currency])
      })
  }

  useEffect(fetchAllowedCurrencies, [userContext, defaults.currency])

  const formDefaultValues = useCallback(
    (defaultValues) => ({
      ...defaultValues,
      started_at: parseISO(defaultValues.started_at),
      product_key: defaultValues.items[0].product_key,
      item_type: defaultValues.items[0].type,
      quantity: defaultValues.items[0].quantity,
    }),
    []
  )

  const {
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: formDefaultValues(defaults),
    reValidateMode: "onChange",
  })

  useEffect(
    () => reset(formDefaultValues(defaults)),
    [defaults, formDefaultValues, reset]
  )

  const handleProductInputChange = (value, props) => {
    if (value && value.length > 0) {
      props.onChange(value)
      onFieldChange(value, "product_key")
    } else {
      props.onChange(null)
      onFieldChange(null, "product_key")
    }
  }

  const productInputRender = ({ field }) => {
    return (
      <ProductInput
        onItemSelect={(item) => handleProductInputChange(item.value, field)}
        defaultValue={field.value}
        intent={fieldIntent(errors, "product_key")}
      />
    )
  }

  const onItemTypeChange = (itemType) => {
    onFieldChange(itemType, "item_type")
  }

  const renderItemType = ({ field }) => (
    <SubscriptionItemTypeSwitcher
      itemKey={field.value}
      onSwitch={onItemTypeChange}
      fill={compact}
    />
  )

  return (
    <div className={formCss.body}>
      {!compact && <H3 className={formCss.title}>Payment</H3>}
      <FormGroup>
        <Controller
          control={control}
          name="item_type"
          render={renderItemType}
        />
      </FormGroup>
      <div className={formCss.columns}>
        <Label className={formCss.totalValue}>
          Total value
          <InputGroup
            name="total_value"
            autoFocus={autoFocus}
            fill
            large
            leftElement={<Icon icon="bank-account" />}
            placeholder="Total value of the subscription"
            onChange={(event) =>
              onFieldChange(
                errors.total_value ? null : event.target.value,
                "total_value"
              )
            }
            inputRef={
              register("total_value", {
                required: "Total value is required",
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: "Value should be a number ex. 1200.20",
                },
              }).ref
            }
            intent={fieldIntent(errors, "total_value")}
            autoComplete="off"
          />
          {validationErrorMsg(errors, "total_value")}
        </Label>
        <Label className={formCss.currency}>
          Currency
          <HTMLSelect
            large
            value={defaults.currency}
            onChange={(event) => onFieldChange(event.target.value, "currency")}
            options={currencies}
          />
        </Label>
      </div>
      <div className={formCss.columns}>
        <div className={classnames(formCss.startAt, Classes.LABEL)}>
          <div className={formCss.label}>Start date</div>
          <DateInputWithValidation
            control={control}
            fieldName="started_at"
            intent={fieldIntent(errors, "started_at")}
            rules={{ required: "Start date is required" }}
            onFieldChange={onFieldChange}
          />
          {validationErrorMsg(errors, "started_at")}
        </div>
        <Label className={formCss.length}>
          Months
          <InputGroup
            name="duration_in_months"
            type="number"
            fill
            large
            placeholder="Months"
            min={1}
            onChange={(event) =>
              onFieldChange(
                errors.duration_in_months ? null : event.target.value,
                "duration_in_months"
              )
            }
            inputRef={
              register("duration_in_months", {
                required: "Required",
                min: {
                  value: 1,
                  message: "Minimal value is 1",
                },
              }).ref
            }
            intent={fieldIntent(errors, "duration_in_months")}
            autoComplete="off"
          />
          {validationErrorMsg(errors, "duration_in_months")}
        </Label>
      </div>
      <Label>
        Product name
        <Controller
          control={control}
          name="product_key"
          render={productInputRender}
          rules={{ required: "Product name is required" }}
        />
        {validationErrorMsg(errors, "product_key")}
      </Label>
      <Label>
        Units sold
        <InputGroup
          name="quantity"
          type="number"
          fill
          large
          placeholder="Units sold"
          min={1}
          onChange={(event) =>
            onFieldChange(
              errors.quantity ? null : event.target.value,
              "quantity"
            )
          }
          inputRef={
            register("quantity", {
              required: "Required",
              min: {
                value: 1,
                message: "Minimal value is 1",
              },
              valueAsNumber: true,
            }).ref
          }
          intent={fieldIntent(errors, "quantity")}
          autoComplete="off"
        />
        {validationErrorMsg(errors, "quantity")}
      </Label>
    </div>
  )
}

export default withHookFormHelpers(SubscriptionForm)

import React, { useState, useEffect, useContext } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Label, InputGroup, H2 } from "@blueprintjs/core"
import { apiUrl, apiErrorsToMessages, prefixWithVersion } from "./apiHelpers"
import { UserContext, HTTPError } from "./UserContext"
import WiggleButton from "./WiggleButton"
import ApiErrorCallout from "./ApiErrorCallout"
import Logo from "./Logo"

import css from "./signSection.module.css"

const FinishInvitation = () => {
  const userContext = useContext(UserContext)
  const { token } = useParams()
  const history = useHistory()

  const [invitation, setInvitation] = useState(null)
  const [failedResponse, setFailedResponse] = useState(null)
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")

  const onPasswordChange = (event) => {
    setPassword(event.target.value)
  }
  const onPasswordConfirmationChange = (event) => {
    setPasswordConfirmation(event.target.value)
  }
  const onSubmit = () => {
    const params = {
      user: {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: token,
      },
    }
    return fetch(apiUrl("/users/password"), {
      method: "PUT",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          return userContext.performSignIn(invitation.invitee.email, password)
        } else {
          return response.json().then((body) => {
            setFailedResponse(body)
            throw new HTTPError(response)
          })
        }
      })
      .then(() => history.push("/"))
  }

  useEffect(() => {
    fetch(apiUrl(prefixWithVersion(`/invitations/${token}`))).then(
      (response) => {
        if (response.ok) {
          return response.json().then((body) => setInvitation(body))
        }
      }
    )
  }, [token])

  return (
    <form className={css.main}>
      <center>
        <Logo />
      </center>
      <center>
        <H2>Complete invitation</H2>
      </center>
      {failedResponse && (
        <ApiErrorCallout messages={apiErrorsToMessages(failedResponse)} />
      )}
      <div>
        {invitation && (
          <Label>
            Email
            <InputGroup
              large
              type="text"
              value={invitation.invitee.email}
              disabled
            />
          </Label>
        )}
        <Label>
          Password
          <InputGroup
            large
            type="password"
            value={password}
            onChange={onPasswordChange}
          />
        </Label>
        <Label>
          Repeat password
          <InputGroup
            large
            type="password"
            value={passwordConfirmation}
            onChange={onPasswordConfirmationChange}
          />
        </Label>
        <WiggleButton
          type="submit"
          rightIcon="arrow-right"
          text="Finish signing up"
          onClick={onSubmit}
        />
      </div>
    </form>
  )
}

export default FinishInvitation

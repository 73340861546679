import React, { useState } from "react"
import { Tab, Tabs } from "@blueprintjs/core"
import classNames from "classnames"
import StackedBarChart from "./charts/StackedBarChart"
import {
  buildSegmentsFromSection,
  isTotalSegmentOnly,
  extractDataPointsRange,
  sortKeysIfNeeded,
  isTotalSegment,
  ChartSegment,
} from "./chartHelpers"
import schemes from "./colorScheme"
import withCategorySwitcher from "./withCategorySwitcher"
import BusinessReportChartSection, {
  BusinessReportChartSectionWithDetailsProps,
} from "./BusinessReportChartSection"

import css from "./BusinessReportChartSection.module.css"

const StackedBarChartSection = ({
  section,
  useDecimal,
  onShowDetails,
  category,
  renderCategorySwitcher,
  filter,
  resolution,
  slideMode,
  withDetails,
  buildExportItems,
  getFilterByKeys,
  renderSectionSettings,
  setExpandedDataPoint,
}: BusinessReportChartSectionWithDetailsProps) => {
  const [tabIndex, setTabIndex] = useState(0)
  const segments = buildSegmentsFromSection(section) as ChartSegment[]
  const isMrrMovementSection = section.definition_id === "existing_mrr_movement"
  let activeTabIndex = tabIndex

  if ((section.filtered && tabIndex !== 0) || tabIndex >= segments.length) {
    setTabIndex(0)
    activeTabIndex = 0
  }

  const currentSegment = segments[activeTabIndex]

  const createOnClickDetailsHandler = (segment: ChartSegment, filter: any) => {
    const { startDate, endDate } = extractDataPointsRange(segment.data) as {
      startDate: string
      endDate: string
    }

    return (date: string, showOnlyKeys: string[], dontShowKeys: string[]) => {
      const filterDetails = !!(showOnlyKeys || !isMrrMovementSection)
      const dataAtDate = segment.data.find((v) => v.time === date)
      const filterByKeys = getFilterByKeys(
        showOnlyKeys,
        dontShowKeys,
        segment.grouped_segment_names,
        Object.keys(dataAtDate ? dataAtDate.values : {})
      )

      onShowDetails({
        date,
        section_id: segment.section_id,
        segment_id: segment.segment_id,
        legacy_series: section.legacy_series,
        filter_details: filterDetails,
        segment_value: filter ? filter.value : segment.segment_value,
        filter_by: filterByKeys,
        start_date: startDate,
        end_date: endDate,
        noSummary: isMrrMovementSection,
        section_definition_id: section.definition_id,
      })
    }
  }

  const segmentToChart = (segment: ChartSegment) => {
    const mrrMovementAttrs = ["churn", "contraction", "expansion", "renewal"]
    const dataAttrs = segment.data[0]
      ? sortKeysIfNeeded(section.definition_id, segment.id, segment.data)
      : []
    const segmentAttrs = isMrrMovementSection ? mrrMovementAttrs : dataAttrs
    const scheme = isMrrMovementSection ? schemes.mrrMovement() : null

    return (
      <>
        <StackedBarChart
          groupedSegmentNames={segment.grouped_segment_names}
          category={category}
          data={segment.data}
          segmentAttrs={segmentAttrs}
          useDecimal={useDecimal}
          onClick={createOnClickDetailsHandler(segment, filter)}
          detailed={withDetails}
          scheme={scheme}
          showLegend={!isTotalSegment(segment) || segmentAttrs.length > 1}
          resolution={resolution}
          withPercent={section.supports_segment_percentage_display}
          slideMode={slideMode}
          setExpandedDataPoint={setExpandedDataPoint}
        />
      </>
    )
  }

  const renderTab = (segment: ChartSegment, index: number) => {
    const tabTitle = isTotalSegmentOnly(section) ? "" : segment.title

    return (
      <Tab
        key={`${segment.id}-${section.filtered}`}
        id={index}
        panelClassName="tabPanel"
        title={tabTitle}
        panel={segmentToChart(segment)}
      />
    )
  }

  const renderTabs = () => segments.map(renderTab)

  if (segments.length === 0) return null

  const onTabChange = (newTabId: string | number) =>
    setTabIndex(parseInt(newTabId as string))

  const segmentValue =
    currentSegment.segment_value !== "total"
      ? currentSegment.segment_value
      : undefined

  const exportItem = buildExportItems({
    segmentId: currentSegment.segment_id,
    category: category,
    segmentValue: segmentValue,
  })

  return (
    <Tabs
      key={`${section.id}-${section.filtered}`}
      id={section.id}
      className="tabs"
      onChange={onTabChange}
      selectedTabId={activeTabIndex}
    >
      {renderTabs()}
      <Tabs.Expander />
      <div className={classNames(css.alignCenter, css.sectionActions)}>
        {renderCategorySwitcher(exportItem)}
        {renderSectionSettings(currentSegment.segment_id)}
      </div>
    </Tabs>
  )
}

export default BusinessReportChartSection(
  withCategorySwitcher(StackedBarChartSection)
)

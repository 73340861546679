import React, { useCallback, useEffect, useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Section } from "./Content"
import BusinessReportPreview, { ReportTagsInput } from "./BusinessReportPreview"
import { State as Report } from "./businessReportReducer"

import "./BusinessReports.css"

interface BusinessReportsProps extends RouteComponentProps<any> {
  reports: Report[]
  onRemoveReportClick: (reportId: string) => void
  onUpdateReport: (report: Report) => void
}

const BusinessReports = ({
  reports,
  history,
  onRemoveReportClick,
  onUpdateReport,
}: BusinessReportsProps) => {
  const [filterTags, setFilterTags] = useState<string[]>([])

  const onReportClick = useCallback(
    (reportId) => {
      history.push(`/reports/${reportId}`)
    },
    [history]
  )

  useEffect(() => {
    const tags = JSON.parse(localStorage.getItem("filterTags") || "[]")
    setFilterTags(tags)
  }, [])

  useEffect(() => {
    localStorage.setItem("filterTags", JSON.stringify(filterTags))
  }, [filterTags])

  const distinctTags = () => {
    const values = new Set<string>()
    reports
      .flatMap((report) => report.tags)
      .sort()
      .forEach((tag) => values.add(tag))

    return Array.from(values)
  }

  const onFilterTagsUpdate = (tags: string[]) => {
    setFilterTags(tags)
  }

  const filteredReports = () => {
    if (filterTags.length === 0) return reports

    return reports.filter((report) => {
      return filterTags.every((tag) => report.tags.includes(tag))
    })
  }

  return (
    <>
      {reports.length > 0 && (
        <Section title="Reports">
          <ReportTagsInput
            tags={distinctTags()}
            selected={filterTags}
            onTagsUpdate={onFilterTagsUpdate}
            icon="filter"
            large={true}
            placeholder="Filter reports by tag..."
            allowCreateItem={false}
          />
          <div className="businessReportsList">
            {filteredReports().map((report) => (
              <BusinessReportPreview
                key={report.id}
                report={report}
                onClick={onReportClick}
                onRemoveReportClick={onRemoveReportClick}
                onUpdateReport={onUpdateReport}
                tags={distinctTags()}
              />
            ))}
          </div>
        </Section>
      )}
    </>
  )
}

export default withRouter<any, any>(BusinessReports)

import React, { useContext, useState } from "react"
import { Card, H5, Classes, Button, Intent } from "@blueprintjs/core"
import useObjectAttributes from "../api/useObjectAttributes"
import { Section } from "../Content"
import css from "./SettingsSegmenters.module.css"
import { capitalize } from "../textHelpers"
import { useHistory } from "react-router-dom"
import ObjectAttributeDrawerForm from "./ObjectAttributeDrawerForm"
import ObjectAttribute from "../api/ObjectAttribute"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"

const CustomAttributes = () => {
  const history = useHistory()
  const [attributes, fetchAttributes] = useObjectAttributes()
  const [isFormOpen, setFormOpen] = useState(false)
  const userContext = useContext(UserContext)
  const [editAttribute, setEditAttribute] =
    useState<Types.Api.ObjectAttribute | null>(null)

  const attributeObjectToUserfriendly = (
    object: Types.Api.ObjectAttribute["object"]
  ) => {
    if (object === "subscription_history") return "Payment"

    return capitalize(object)
  }

  const deleteAttribute = (
    event: React.SyntheticEvent,
    attr: Types.Api.ObjectAttribute
  ) => {
    event.stopPropagation()
    ObjectAttribute.delete(userContext, attr.id)
      .then(fetchAttributes)
      .catch((error) => {
        if (error.response.status === 422) {
          error.response.json().then((errorData: any) => {
            AppToaster.showError({ message: errorData.errors[0]?.detail })
          })
        } else {
          AppToaster.showError({
            message: "Cound not remove attribute. Please, try again.",
          })
        }
      })
  }

  const renderCustomAttribute = (attr: Types.Api.ObjectAttribute) => {
    return (
      <Card
        className={css.card}
        key={attr.id}
        interactive={true}
        onClick={() => onEditAttribute(attr)}
      >
        <div className={css.body}>
          <H5>{attr.name}</H5>
          <div className={Classes.TEXT_MUTED}>
            {attributeObjectToUserfriendly(attr.object)}
          </div>
        </div>
        <div className={css.actions}>
          <span>
            <Button
              icon="trash"
              minimal
              intent="danger"
              onClick={(e) => deleteAttribute(e, attr)}
            />
          </span>
        </div>
      </Card>
    )
  }

  const onImportSegmentsClick = () => {
    history.push("/import-segments")
  }

  const actionButton = () => (
    <div className={css.actionButtons}>
      <Button
        large
        onClick={openForm}
        intent={Intent.PRIMARY}
        outlined
        icon="plus"
        text="Add attribute"
      />
      <Button
        large
        onClick={onImportSegmentsClick}
        intent={Intent.PRIMARY}
        outlined
        icon="import"
        text="Import attribute values"
      />
    </div>
  )

  const openForm = () => setFormOpen(true)
  const closeForm = () => {
    setFormOpen(false)
    setEditAttribute(null)
  }

  const onSubmit = (attr: Types.Api.ObjectAttribute) => {
    if (attr.id) {
      ObjectAttribute.update(userContext, attr).then(fetchAttributes)
    } else {
      ObjectAttribute.create(userContext, attr).then(fetchAttributes)
    }
  }

  const onEditAttribute = (attr: Types.Api.ObjectAttribute) => {
    setEditAttribute(attr)
    openForm()
  }

  return (
    <Section
      title="Custom attributes"
      subtitle="Add customer attributes to collect additional data"
      actionButton={actionButton()}
    >
      <ObjectAttributeDrawerForm
        defaultValues={editAttribute || undefined}
        isOpen={isFormOpen}
        onClose={closeForm}
        onSubmit={onSubmit}
      />
      <div className={css.definitionsContainer}>
        {attributes.map(renderCustomAttribute)}
      </div>
    </Section>
  )
}

export default CustomAttributes

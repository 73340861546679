import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import { Icon } from "@blueprintjs/core"
import { Section } from "./Content"
import ReportTemplatePreview from "./ReportTemplatePreview"
import { LineThumbnail, CohortThumbnail } from "./ChartThumbnails"
import { formatTime } from "./formatters"
import { AppToaster } from "./AppToaster"
import { UserContext } from "./UserContext"

import "./ReportsLibrary.css"

const ReportsLibrary = ({ templates, history }) => {
  const userContext = useContext(UserContext)

  const buildReportTitle = (templateId) => {
    const reportCreatedAt = formatTime(new Date().toISOString(), "d MMM yyyy")

    if (templateId === "blank_report") {
      return `${reportCreatedAt} - business report`
    } else if (templateId === "customer_success") {
      return `${reportCreatedAt} - customer success overview`
    } else {
      return `${reportCreatedAt} - business overview`
    }
  }

  const onTemplateClick = (templateId) => {
    const title = buildReportTitle(templateId)

    let sections = []

    if (templateId === "blank_report") {
      sections = []
    } else {
      sections = templates
        .find((template) => template.id === templateId)
        .definitions.map((definition) => ({ definition_id: definition.id }))
    }

    createReport(title, sections)
  }

  const createReport = (reportTitle, sections) => {
    userContext
      .fetch("/business_reports", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title: reportTitle, sections: sections }),
      })
      .then((data) => {
        history.push({
          pathname: `/reports/${data.secret}`,
        })
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not add new report",
        })
      })
  }

  const thumbnail = (templateId) => {
    if (templateId === "customer_success") {
      return <CohortThumbnail large />
    } else {
      return <LineThumbnail large />
    }
  }

  return (
    <Section title="Reports library">
      <div className="reportsLibrary">
        <ReportTemplatePreview
          id="blank_report"
          thumbnail={<Icon icon="plus" iconSize={60} />}
          name="Blank report"
          description="Create blank report and add any section you need."
          onClick={() => onTemplateClick("blank_report")}
          definitions={[]}
        />

        {templates.map((template) => (
          <ReportTemplatePreview
            key={template.id}
            id={template.id}
            thumbnail={thumbnail(template.id)}
            name={template.name}
            description={template.description}
            definitions={template.definitions}
            onClick={() => onTemplateClick(template.id)}
          />
        ))}
      </div>
    </Section>
  )
}

export default withRouter(ReportsLibrary)

import React, { useContext } from "react"
import classnames from "classnames"
import { Classes, H1, EditableText } from "@blueprintjs/core"
import BusinessReportContext from "./BusinessReportContext"
import { UserContext } from "./UserContext"

import sectionStyles from "./businessReportSection.module.css"
import "./BusinessReportTextSection.css"

type BusinessReportTextSectionLogicProps = {
  title: string
  summary: string
  reportId: string
  disabled: boolean
}

const BusinessReportTextSectionLogic = ({
  title,
  summary,
  reportId,
  disabled,
}: BusinessReportTextSectionLogicProps) => {
  const { dispatch, demo } = useContext(BusinessReportContext)
  const userContext = useContext(UserContext)

  const onConfirmTitle = (value: string) => {
    const dispatcher = (title: string) =>
      dispatch({ type: "updateReport", attributes: { title } })

    if (demo) {
      dispatcher(value)
      return
    }

    userContext
      .fetch(`/business_reports/${reportId}`, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify({ title: value }),
      })
      .then((body) => {
        dispatcher(body.title)
      })
  }

  const onConfirmSummary = (value: string) => {
    const dispatcher = (summary: string) =>
      dispatch({ type: "updateReport", attributes: { summary } })

    if (demo) {
      dispatcher(value)
      return
    }
    userContext
      .fetch(`/business_reports/${reportId}`, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify({ summary: value }),
      })
      .then((body) => {
        dispatcher(body.summary)
      })
  }

  const enableEditMode = (sectionName: string) => {
    if (sectionName === "title") {
      return
    }

    if (sectionName === "summary") {
      return
    }
  }

  return (
    <div className={sectionStyles.content}>
      <div className="Title">
        <H1>
          <EditableText
            defaultValue={title}
            key={title}
            placeholder="Title ..."
            onConfirm={onConfirmTitle}
            multiline
            onEdit={() => enableEditMode("title")}
            disabled={disabled}
          />
        </H1>
      </div>
      <div className={summary ? sectionStyles.Visible : sectionStyles.Hidden}>
        <div className={classnames(Classes.TEXT_LARGE, Classes.RUNNING_TEXT)}>
          <EditableText
            defaultValue={summary}
            key={summary}
            placeholder={"Executive summary ..."}
            multiline
            disabled={disabled}
            onEdit={() => enableEditMode("summary")}
            onConfirm={onConfirmSummary}
          />
        </div>
      </div>
    </div>
  )
}

export default BusinessReportTextSectionLogic

import React, { useMemo } from "react"
import pluralize from "pluralize"
import DataTable from "./DataTable"

type ImportSegmentsErrorsSummaryDefinition = {
  summaryData: Types.Api.CsvImportResultDefinition
}

const ImportSegmentsErrorsSummary = ({
  summaryData,
}: ImportSegmentsErrorsSummaryDefinition) => {
  const columns = useMemo(
    () => [
      {
        id: "row_index",
        Header: "Row number",
        accessor: "row_index",
        disableSortBy: true,
      },
      {
        id: "customer_id",
        Header: "Customer Id",
        accessor: "customer_id",
        disableSortBy: true,
      },
      {
        id: "reason",
        Header: "Reason",
        accessor: "reason",
        disableSortBy: true,
      },
      {
        id: "row",
        Header: "Row",
        accessor: (record: Types.Api.CsvImportResultErrorDefinition) =>
          record.row.join(","),
        disableSortBy: true,
      },
    ],
    []
  )

  return (
    <>
      We failed to import <b>{summaryData.errors.length}</b>{" "}
      {pluralize("record", summaryData.errors.length)}. See details bellow.
      <DataTable columns={columns} data={summaryData.errors} fullWidth />
    </>
  )
}

export default ImportSegmentsErrorsSummary

import React, { useEffect, useState, useContext } from "react"
import { UserContext } from "./UserContext"
import { FailureState, LoadingState } from "./NonIdealState"

type BusinessReportsProviderProps = {
  onFetchSuccess: (reports: Types.Api.Report[]) => void
}

const BusinessReportsProvider = ({
  onFetchSuccess,
}: BusinessReportsProviderProps) => {
  const [inProgress, setInProgress] = useState(false)
  const [failure, setFailure] = useState(false)
  const userContext = useContext(UserContext)

  const fetchReports = () => {
    setFailure(false)
    setInProgress(true)

    userContext
      .fetch("/business_reports/latest", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        setInProgress(false)
        onFetchSuccess(data.items)
      })
      .catch(() => {
        setFailure(true)
        setInProgress(false)
      })
  }

  useEffect(fetchReports, [userContext, onFetchSuccess])

  return (
    <>
      {failure && (
        <FailureState title="Uh :( we failed to fetch your reports" />
      )}
      {!failure && inProgress && <LoadingState />}
    </>
  )
}

export default BusinessReportsProvider

import React, { useMemo } from "react"
import DataTable, {
  DataType,
  formatText,
  ColumnValuesFilter,
  formatDate,
} from "../DataTable"
import { formatCurrency } from "../formatters"

import css from "./CostsTable.module.css"
import tableCss from "../DataTable.module.css"
import { capitalize } from "../textHelpers"
import { Tag } from "@blueprintjs/core"

type CostsTableProps = {
  costs: Types.Api.Cost[]
  withFooter: boolean
  onCostEdit: (cost: Types.Api.Cost) => void
  costAttrs: Types.Api.ObjectAttribute[]
}

const CostsTable = ({
  costs,
  withFooter,
  onCostEdit,
  costAttrs,
}: CostsTableProps) => {
  const costAttrsColumns = useMemo((): any[] => {
    return costAttrs.map((attr) => ({
      id: attr.id,
      Header: capitalize(attr.name),
      accessor: (row: any) => {
        const segment = row.segments.find((seg: any) => seg.name === attr.name)

        if (!segment) return

        return <Tag minimal>{segment.value}</Tag>
      },
      disableSortBy: true,
    }))
  }, [costAttrs])

  const columns = useMemo(() => {
    return [
      {
        id: "invoice_number",
        Header: "Invoice number",
        accessor: "invoice_number",
        Cell: (props: any) => (
          <span
            className={tableCss.clickableText}
            onClick={() => onCostEdit(props.row.original)}
          >
            {formatText(props.cell.value)}
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: "value",
        Header: "Value",
        accessor: (row: any) => formatCurrency(parseFloat(row.value)),
        disableSortBy: true,
        dataType: DataType.CURRENCY,
      },
      {
        id: "currency",
        Header: "Currency",
        accessor: "currency",
        disableSortBy: true,
        Filter: ColumnValuesFilter,
        filter: "equals",
      },
      {
        id: "date",
        Header: "Date",
        accessor: (row: any) => formatDate(row.date),
        disableSortBy: true,
      },
      {
        id: "due_date",
        Header: "Due date",
        accessor: (row: any) => formatDate(row.due_date),
        disableSortBy: true,
      },
    ].concat(costAttrsColumns)
  }, [onCostEdit, costAttrsColumns])

  return (
    <DataTable
      fullWidth
      columns={columns}
      data={costs}
      withFooter={withFooter}
      wrapperClassName={css.tableWrapper}
    />
  )
}

export default CostsTable

import React, { useState, useContext, useEffect, useMemo } from "react"
import { Dialog, Classes, Intent, InputGroup, Button } from "@blueprintjs/core"
import classnames from "classnames"
import pluralize from "pluralize"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import { SectionPreview } from "./SectionPreview"
import Config from "./config"
import { NotFoundState } from "./NonIdealState"

import "./AddSectionDialog.css"
import css from "./commonCss/scrollbox.module.css"
import BusinessReportContext from "./BusinessReportContext"

const firstSectionFromModifiedSections = (report, modifiedSections) => {
  const modifiedIds = modifiedSections.map((section) => section.id)
  return report.sections.find((section) => modifiedIds.includes(section.id))
}

const SectionsDefinitionList = ({ sections, selectedSections, onClick }) => {
  const [filterValue, setFilterValue] = useState("")

  const onFilterChange = (event) => {
    setFilterValue(event.target.value)
  }

  const filteredSections = useMemo(() => {
    const searchString = filterValue.toLowerCase()

    if (searchString.length > 2) {
      return sections.filter((section) =>
        section.name.toLowerCase().includes(searchString)
      )
    } else {
      return sections
    }
  }, [filterValue, sections])

  const isSectionSelected = (sectionId) => {
    return (
      selectedSections[sectionId] &&
      selectedSections[sectionId].some((segment) => segment.is_total)
    )
  }

  return (
    <>
      <div className="searchInput">
        <InputGroup
          autoFocus
          leftIcon="search"
          placeholder="Start typing metric name"
          round
          onChange={onFilterChange}
          value={filterValue}
        />
      </div>
      <div className={classnames("sectionsList", css.scrollbox)}>
        {filteredSections.length === 0 ? (
          <NotFoundState description="We did not find any matching sections" />
        ) : (
          filteredSections.map((section) => (
            <SectionPreview
              key={section.id}
              selected={isSectionSelected(section.id)}
              sectionDefinition={section}
              onClick={onClick}
              selectedSegments={selectedSections[section.id]}
            />
          ))
        )}
      </div>
    </>
  )
}

const AddSectionDialog = ({
  history,
  sections,
  dispatch,
  businessReportId,
  isOpen,
  onClose,
}) => {
  const userContext = useContext(UserContext)
  const businessReportContext = useContext(BusinessReportContext)
  const [selectedSections, setSelectedSections] = useState({})

  useEffect(() => {
    if (!isOpen) {
      setSelectedSections({})
    }
  }, [isOpen])

  const addNewSection = (businessReportId, definitionId, segmentIds) => {
    return userContext.fetch(`/business_report/${businessReportId}/sections`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        definition_id: definitionId,
        segment_ids: segmentIds,
      }),
    })
  }

  const updateExistingSection = (section, segmentIds) => {
    const existingSegmentIds = section.segments.map((segment) => segment.id)
    const newSegmentIds = [...new Set(segmentIds.concat(existingSegmentIds))]
    return userContext.fetch(`/business_report/sections/${section.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ segment_ids: newSegmentIds }),
    })
  }

  const isSectionSelected = (definitionId, segmenter) => {
    return (
      definitionId in selectedSections &&
      selectedSections[definitionId].includes(segmenter)
    )
  }

  const addToSelection = (definitionId, segmenter) => {
    let newSelection = {}
    newSelection[definitionId] = [segmenter].concat(
      selectedSections[definitionId] || []
    )
    setSelectedSections({ ...selectedSections, ...newSelection })
  }

  const removeFromSelection = (definitionId, segmenter) => {
    const newSegmentSelection = selectedSections[definitionId].filter(
      (segmentSelecionId) => segmentSelecionId !== segmenter
    )

    if (newSegmentSelection.length > 0) {
      let newSelection = {}
      newSelection[definitionId] = newSegmentSelection
      setSelectedSections({ ...selectedSections, ...newSelection })
    } else {
      let newSelection = Object.assign({}, selectedSections)
      delete newSelection[definitionId]
      setSelectedSections(newSelection)
    }
  }

  const onSectionClick = (definitionId, segmenter) => {
    if (isSectionSelected(definitionId, segmenter)) {
      removeFromSelection(definitionId, segmenter)
    } else {
      addToSelection(definitionId, segmenter)
    }
  }

  const addSelectedSectionsClick = () => {
    onClose()
    Promise.all(
      selectedSectionKeys().map((key) =>
        addSection(
          key,
          selectedSections[key].map((segmenter) => segmenter.id)
        )
      )
    )
      .then((apiResponses) => {
        return userContext
          .fetch(`/business_reports/${businessReportId}`, {
            headers: { "Content-Type": "application/json" },
          })
          .then((businessReport) => {
            dispatch({ type: "updateReport", attributes: businessReport })
            dispatch({
              type: "scrollToSection",
              sectionId: firstSectionFromModifiedSections(
                businessReport,
                apiResponses
              ).id,
            })
          })
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Something went wrong and we could not add some of the sections to your report : (",
        })
      })
  }

  const createCustomSectionClick = () => {
    history.push(`/reports/${businessReportContext.secret}/new-section`)
  }

  const addSection = (definitionId, segmentIds) => {
    return userContext
      .fetch(`/business_reports/${businessReportId}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((businessReport) => {
        const existingSection = businessReport.sections.find(
          (section) => section.definition_id === definitionId
        )
        if (existingSection) {
          return updateExistingSection(existingSection, segmentIds)
        } else {
          return addNewSection(businessReportId, definitionId, segmentIds)
        }
      })
  }

  const selectedSectionKeys = () => Object.keys(selectedSections)

  const addSectionsButtonText = () => {
    const sectionsCount = selectedSectionKeys().length

    if (sectionsCount === 0) {
      return "Add sections"
    }

    let segmentsCount = 0
    selectedSectionKeys().forEach(
      (key) =>
        (segmentsCount =
          segmentsCount +
          selectedSections[key].filter((segmenter) => !segmenter.is_total)
            .length)
    )

    let text = `Add ${sectionsCount} ${pluralize("section", sectionsCount)}`
    if (segmentsCount > 0) {
      text = `${text} and ${segmentsCount} ${pluralize(
        "segment",
        segmentsCount
      )}`
    }

    return text
  }

  return (
    <Dialog
      icon="plus"
      title="Add chart"
      isOpen={isOpen}
      onClose={onClose}
      className="addSectionDialog"
      canOutsideClickClose={Config.canOutsideClickCloseDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        {sections && (
          <SectionsDefinitionList
            sections={sections}
            selectedSections={selectedSections}
            onClick={onSectionClick}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Cancel"
            onClick={onClose}
            intent={Intent.DANGER}
            minimal
            large
          />
          <Button
            intent={Intent.PRIMARY}
            outlined
            large
            text="Create custom section"
            disabled={selectedSectionKeys().length != 0}
            onClick={createCustomSectionClick}
          />
          <Button
            disabled={selectedSectionKeys().length === 0}
            text={addSectionsButtonText()}
            onClick={addSelectedSectionsClick}
            intent={Intent.PRIMARY}
            large
            outlined
          />
        </div>
      </div>
    </Dialog>
  )
}

export default AddSectionDialog

import React, { useContext } from "react"
import { MenuItem } from "@blueprintjs/core"
import { UserContext } from "./UserContext"

type BusinessReportSectionKeyNumberProps = {
  sectionId: string
  secret: string
  segmentId: string
  active: boolean
  dispatch: (attr: any) => void
}

const BusinessReportSectionKeyNumber = ({
  secret,
  sectionId,
  segmentId,
  active,
  dispatch,
}: BusinessReportSectionKeyNumberProps) => {
  const userContext = useContext(UserContext)

  const createKeyNumber = () => {
    const params = {
      secret,
      section_id: sectionId,
      definition_id: segmentId,
    }
    userContext
      .fetch("/business_reports/by_secret/key_numbers", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      })
      .then((number: Types.Api.KeyNumber) =>
        dispatch({ type: "addKeyNumber", keyNumber: number })
      )
  }

  const deleteKeyNumber = () => {
    const params = {
      secret,
      section_id: sectionId,
      definition_id: segmentId,
    }
    userContext
      .fetch("/business_reports/by_secret/key_numbers", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      })
      .then(() =>
        dispatch({
          type: "deleteKeyNumber",
          keyNumber: { sectionId, definitionId: segmentId },
        })
      )
  }

  const toggleKeyNumber = () => {
    if (active) {
      deleteKeyNumber()
    } else {
      createKeyNumber()
    }
  }

  return (
    <MenuItem
      text="Show in key numbers"
      onClick={toggleKeyNumber}
      active={active}
      icon={active ? "tick" : undefined}
    />
  )
}

export default BusinessReportSectionKeyNumber

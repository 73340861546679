import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"
import SegmenterDefinitionApi, { Definition } from "./SegmenterDefinition"

const useSegmenterDefinitions = (): [Definition[], boolean, () => void] => {
  const [definitions, setDefinitions] = useState<Definition[]>([])
  const [loading, setLoading] = useState(false)

  const userContext = useContext(UserContext)

  const fetchDefinitions = useCallback(() => {
    setLoading(true)
    userContext
      .fetch("/segmenter_definitions")
      .then((data) =>
        setDefinitions(
          data.items.map(SegmenterDefinitionApi.convertApiToInternal)
        )
      )
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({ message: "Could not get segmenters" })
      })
      .finally(() => setLoading(false))
  }, [userContext])

  useEffect(() => {
    fetchDefinitions()
  }, [userContext, fetchDefinitions])

  return [definitions, loading, fetchDefinitions]
}

export default useSegmenterDefinitions

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react"
import { FocusStyleManager } from "@blueprintjs/core"
import { HashRouter, Route, Switch } from "react-router-dom"
import { UserContextProvider, UserContext } from "./UserContext"
import SignIn from "./SignIn"
import SignInViaToken from "./SignInViaToken"
import SignUp from "./SignUp"
import FinishInvitation from "./FinishInvitation"
import ResetPassword from "./ResetPassword"
import ResetPasswordStart from "./ResetPasswordStart"
import BusinessReportsContainer from "./BusinessReportsContainer"
import BusinessReportContainer from "./BusinessReportContainer"
import Settings from "./settings/Settings"
import { ActionBar } from "./ActionBar"
import { Content } from "./Content"
import { AppToaster } from "./AppToaster"
import ProtectedRoute from "./ProtectedRoute"
import ReportsLibrary from "./ReportsLibrary"
import CustomersContainer from "./CustomersContainer"
import SubscriptionsContainer from "./SubscriptionsContainer"
import InvoiceItemsContainer from "./InvoiceItemsContainer"
import CostsContainer from "./costs/CostsContainer"
import BusinessReportGroupContainer from "./BusinessReportGroupContainer"
import ImportSegmentsContainer from "./ImportSegmentsContainer"
import GettingStarted from "./GettingStarted"
import ImportSubscriptionsContainer from "./ImportSubscriptionsContainer"
import CustomersExplore from "./CustomersExplore"

import "./App.css"
import NewSectionContainer from "./NewSectionContainer"
import useCableSubscription from "./api/useCableSubscription"

FocusStyleManager.onlyShowFocusOnTabs()

const ProbeApp = () => {
  const [integrations, setIntegrations] = useState({})
  const [reportTemplates, setReportTemplates] = useState([])
  const [hasRejectedInvoices, setHasRejectedInvoices] = useState(false)
  const userContext = useContext(UserContext)

  const onIntegrationStatusEventsChannelReceived = useCallback(
    ({ id, name, type }) => {
      if (type === "initially_synced") {
        AppToaster.show(
          {
            timeout: 10000,
            intent: "success",
            message: `We have completed importing your ${name} data`,
          },
          `integration-${id}`
        )
      }
    },
    []
  )

  const onIntegrationsChannelReceived = useCallback((integration) => {
    setIntegrations((integrations) => {
      const newIntegration = {
        ...integrations[integration.name],
        ...integration,
      }
      let toMerge = {}
      toMerge[integration.name] = newIntegration
      return Object.assign({}, integrations, toMerge)
    })
  }, [])

  const fetchIntegrations = () => {
    if (!userContext.hasUserLevelAccess()) return

    userContext
      .fetch("/integrations/action_urls", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) =>
        setIntegrations((integrations) =>
          Object.assign({}, integrations, response.data)
        )
      )
  }

  const checkForRejectedInvoices = useCallback(() => {
    if (!userContext.hasUserLevelAccess()) return

    userContext
      .fetch(`/invoice_items/status`, {
        method: "GET",
      })
      .then((data) => {
        setHasRejectedInvoices(data.has_rejected_invoice_items)
      })
      .catch((error) => {
        console.warn(error)
      })
  }, [setHasRejectedInvoices, userContext])

  const setIntegration = (integration) => {
    const keyedIntegration = {}
    keyedIntegration[integration.name] = integration
    setIntegrations((integrations) =>
      Object.assign({}, integrations, keyedIntegration)
    )
  }

  const fetchReportTemplates = () => {
    if (!userContext.hasUserLevelAccess()) return

    userContext
      .fetch("/business_report/templates", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReportTemplates(response.items)
      })
  }

  useEffect(fetchIntegrations, [userContext])
  useEffect(fetchReportTemplates, [userContext])
  useEffect(checkForRejectedInvoices, [userContext, checkForRejectedInvoices])

  const integrationStatusChannel = useMemo(
    () =>
      userContext.hasUserLevelAccess()
        ? { channel: "IntegrationStatusEventsChannel" }
        : null,
    [userContext]
  )

  useCableSubscription(
    integrationStatusChannel,
    onIntegrationStatusEventsChannelReceived
  )

  const integrationsChannel = useMemo(
    () =>
      userContext.hasUserLevelAccess()
        ? { channel: "IntegrationsChannel" }
        : null,
    [userContext]
  )

  useCableSubscription(integrationsChannel, onIntegrationsChannelReceived)

  return (
    <>
      <ActionBar hasRejectedInvoices={hasRejectedInvoices} />
      <HashRouter>
        <Switch>
          <Route path="/demo/:secret">
            <Content>
              <BusinessReportContainer demo />
            </Content>
          </Route>
          <ProtectedRoute path="/getting-started">
            <Content withSideMargins>
              <GettingStarted
                integrations={integrations}
                setIntegration={setIntegration}
              />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/reports/library">
            <Content withSideMargins>
              <ReportsLibrary templates={reportTemplates} />
            </Content>
          </ProtectedRoute>
          <Route path="/reports/shared/:secret">
            <Content>
              <BusinessReportContainer shared />
            </Content>
          </Route>
          <Route path="/reports/group/:secret">
            <Content>
              <BusinessReportGroupContainer />
            </Content>
          </Route>
          <ProtectedRoute path="/reports/:secret/new-section/:sectionId">
            <Content withSideMargins withBottomMargin={false}>
              <NewSectionContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/reports/:secret/new-section">
            <Content withSideMargins withBottomMargin={false}>
              <NewSectionContainer />
            </Content>
          </ProtectedRoute>
          <Route path="/reports/:secret">
            <Content doubleActionBarTopMargin>
              <BusinessReportContainer />
            </Content>
          </Route>
          <ProtectedRoute path="/settings">
            <Content withSideMargins>
              <Settings
                integrations={integrations}
                setIntegration={setIntegration}
              />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/customers/:customerId">
            <Content withSideMargins withBottomMargin={false}>
              <CustomersContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/customers">
            <Content withSideMargins withBottomMargin={false}>
              <CustomersContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/explore/:sectionId/:segmentId/:date">
            <Content withSideMargins withBottomMargin={false}>
              <CustomersExplore />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/subscriptions">
            <Content withSideMargins withBottomMargin={false}>
              <SubscriptionsContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/payments">
            <Content withSideMargins withBottomMargin={false}>
              <SubscriptionsContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/invoices">
            <Content withSideMargins withBottomMargin={false}>
              <InvoiceItemsContainer
                onAllDistillersAdded={() => setHasRejectedInvoices(false)}
              />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/import-segments">
            <Content withSideMargins withBottomMargin={false}>
              <ImportSegmentsContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/import-subscriptions">
            <Content withSideMargins withBottomMargin={false}>
              <ImportSubscriptionsContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/costs">
            <Content withSideMargins withBottomMargin={false}>
              <CostsContainer />
            </Content>
          </ProtectedRoute>
          <ProtectedRoute path="/" allowForLoggedIn>
            <Content withSideMargins>
              <BusinessReportsContainer />
            </Content>
          </ProtectedRoute>
        </Switch>
      </HashRouter>
    </>
  )
}

function App() {
  return (
    <HashRouter>
      <div className="body">
        <Switch>
          <Route path="/sign_in_via_token/:jwtToken">
            <SignInViaToken />
          </Route>
          <Route path="/sign_in">
            <SignIn />
          </Route>
          <Route path="/sign_up">
            <SignUp />
          </Route>
          <Route path="/invitation/:token">
            <FinishInvitation />
          </Route>
          <Route path="/reset_password/:token">
            <ResetPassword />
          </Route>
          <Route path="/reset_password">
            <ResetPasswordStart />
          </Route>
          <Route path="/">
            <ProbeApp />
          </Route>
        </Switch>
      </div>
    </HashRouter>
  )
}

export default UserContextProvider(App)

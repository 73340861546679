import React from "react"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts"
import { ExpandedDataPoint } from "../BusinessReportChartSection"

import schemes from "../colorScheme"
import { formatCurrency } from "../formatters"

const MoneyLabel = (props: any) => {
  const { x, y, stroke, value, height } = props

  if (!value) return <></>

  return (
    <text
      x={x}
      dx={10}
      y={y + height / 2 + 6}
      fill={stroke}
      fontSize={14}
      textAnchor="left"
    >
      {formatCurrency(parseFloat(value))}
    </text>
  )
}

type HorizontalBarChartProps = {
  dataPoint: ExpandedDataPoint
}

const HorizontalBarChart = ({ dataPoint }: HorizontalBarChartProps) => {
  const scheme = schemes.main()
  return (
    <ResponsiveContainer width="100%" aspect={1.9}>
      <BarChart
        data={dataPoint.data
          .filter((point) => parseInt(point.value) !== 0)
          .sort((a, b) => parseInt(b.value) - parseInt(a.value))}
        layout="vertical"
      >
        <YAxis orientation="left" type="category" dataKey="name" width={100} />
        <XAxis type="number" dataKey="value" hide />
        <Bar dataKey="value" fill={scheme.next()} label={<MoneyLabel />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default HorizontalBarChart

export const capitalize = (str: string) => {
  const lowercase = str.toLowerCase()

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
}

export const indexToLetter = (index: number) => {
  // ASCII value of first character
  const a = "a".charCodeAt(0)
  const numberToCharacter = (number: number) => String.fromCharCode(a + number)

  return numberToCharacter(index)
}

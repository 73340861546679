import React, { useCallback } from "react"
import {
  Button,
  InputGroup,
  ControlGroup,
  Label,
  Intent,
} from "@blueprintjs/core"
import { useFieldArray, UseFormReturn, Controller } from "react-hook-form"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "./../withHookFormHelpers"
import classNames from "classnames"
import { AccountSettingsFormType } from "./AccountSettings"
import DateInputWithValidation from "../DateInputWithValidation"

import formCss from "./../forms.module.css"

type CurrencyRatesFProps = InjectedHookHelpersProps<AccountSettingsFormType> & {
  useFormMethods: UseFormReturn<AccountSettingsFormType>
  usedCurrencies: string[]
  accountCurrency: string
}

const Rates = ({ control, index, accountCurrency, fieldIntent }: any) => {
  const { fields } = useFieldArray({
    control,
    name: `account_exchange_rates.${index}.rates`,
  })

  const renderInput = useCallback(
    ({ field, formState }: any, iso: string, index) => (
      <Label className={formCss.textRight}>
        {index === 0 && (
          <span className={formCss.spanLabel}>{iso.toUpperCase()}</span>
        )}
        <InputGroup
          className={formCss.textRight}
          placeholder="ex. 4.98"
          fill
          defaultValue={field.value}
          autoComplete="off"
          inputRef={field.ref}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          intent={fieldIntent(formState.errors, field.name)}
        />
      </Label>
    ),
    [fieldIntent]
  )

  return (
    <>
      {fields
        .filter((field: any) => field.iso !== accountCurrency)
        .map((field: any, rateIndex: number) => {
          return (
            <Controller
              key={field.id}
              control={control}
              name={`account_exchange_rates.${index}.rates.${rateIndex}.rate`}
              render={(props) => renderInput(props, field.iso, index)}
              rules={{
                required: "Required",
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: "Should be a number ex. 4.24",
                },
              }}
            />
          )
        })}
    </>
  )
}

const RatesInput = ({
  control,
  index,
  removePoint,
  register,
  accountCurrency,
  fieldIntent,
}: any) => {
  const renderRateRow = useCallback(() => {
    return (
      <div className={formCss.fieldsRow}>
        <div className={formCss.fieldsRowActionButton}>
          <Button
            icon="trash"
            minimal
            onClick={() => removePoint(index)}
            intent={Intent.PRIMARY}
          />
        </div>
        <ControlGroup fill>
          <Label>
            {index === 0 && "Since"}
            <DateInputWithValidation
              control={control}
              fieldName={`account_exchange_rates.${index}.since`}
              intent="none"
              rules={{ required: "Required" }}
              onFieldChange={() => {}}
              large={false}
            />
          </Label>
          <Rates
            {...{ control, register, index, accountCurrency, fieldIntent }}
          />
        </ControlGroup>
      </div>
    )
  }, [control, register, index, accountCurrency, fieldIntent, removePoint])

  return (
    <Controller
      control={control}
      name={`account_exchange_rates.${index}.since`}
      render={renderRateRow}
    />
  )
}

const CurrencyRatesForm = ({
  useFormMethods,
  fieldIntent,
  usedCurrencies,
  accountCurrency,
  validationErrorMsg,
}: CurrencyRatesFProps) => {
  const {
    register,
    formState: { errors },
    control,
    clearErrors,
  } = useFormMethods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "account_exchange_rates",
  })

  const removePoint = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )

  const addPoint = () => {
    clearErrors("account_exchange_rates")
    if (fields.length === 0) {
      append({
        since: new Date(),
        rates: usedCurrencies
          .filter((iso) => iso !== accountCurrency)
          .map((iso) => ({ iso, rate: "" })),
      })
    } else {
      const field = fields.at(-1)
      if (!field) return
      append({
        since: new Date(),
        rates: field.rates.map((rate: any) => ({ ...rate, rate: "" })),
      })
    }
  }

  return (
    <div>
      <p>
        For subscriptions that started before the provided exchange rates, the
        earliest exchange rates will be used.
      </p>
      {validationErrorMsg(errors, "account_exchange_rates")}
      {fields.map((field, index) => (
        <RatesInput
          key={field.id}
          {...{
            control,
            index,
            removePoint,
            register,
            accountCurrency,
            fieldIntent,
          }}
        />
      ))}
      <div className={classNames(formCss.fieldsRow, formCss.justifyFlexEnd)}>
        <div className={formCss.fieldsRowActionButton}>
          <Button
            icon="add"
            text="Add rates"
            minimal
            onClick={addPoint}
            intent={Intent.PRIMARY}
          />
        </div>
      </div>
    </div>
  )
}

export default withHookFormHelpers(CurrencyRatesForm)

import React, { useEffect, useContext, useState } from "react"
import { Drawer, Classes, Button, Intent, Spinner } from "@blueprintjs/core"
import classnames from "classnames"
import { withDistillerFormValidation } from "./withDistillerFormValidation"
import DistillerForm, {
  DistillerFormDataType,
  DistillerFormOnFieldChangeFunctionType,
  IssueDateTreatmentType,
} from "./DistillerForm"
import { SubscriptionItemTypes } from "./SubscriptionItemTypeSwitcher"
import { UserContext } from "./UserContext"

import formCss from "./forms.module.css"
import css from "./DistillerFormContainer.module.css"

const defaultDistiller = (line_name: string) => ({
  line_name: line_name,
  item_type: SubscriptionItemTypes.Recurring.KEY,
  issue_date_treatment: IssueDateTreatmentType.StartedAt,
  matcher: "equal",
})

type DistillerFormContainerOnSubmitType = (data: DistillerFormDataType) => void
type DistillerFormContainerSetFormDataType = (
  data: DistillerFormDataType
) => void

type DistillerFormContainerType = {
  lineItemName: string
  isOpen: boolean
  onClose: () => void
  onSubmit: DistillerFormContainerOnSubmitType
  onFormFieldChange: DistillerFormOnFieldChangeFunctionType
  formData: DistillerFormDataType
  setFormData: DistillerFormContainerSetFormDataType
  canSubmit: boolean
}

const DistillerFormContainer = ({
  lineItemName,
  isOpen,
  onClose,
  onSubmit,
  onFormFieldChange,
  formData,
  setFormData,
  canSubmit,
}: DistillerFormContainerType) => {
  const userContext = useContext(UserContext)
  const [affectedInvoiceCount, setAffectedInvoiceCount] = useState(null)
  const [failedAffectedInvoiceCountCall, setFailedAffectedInvoiceCountCall] =
    useState(false)

  useEffect(() => {
    setFormData(defaultDistiller(lineItemName))
    setAffectedInvoiceCount(null)
    setFailedAffectedInvoiceCountCall(false)

    if (!lineItemName) {
      return
    }

    userContext
      .fetch(
        `/distillers/exact_match_affected_invoices?line_name=${lineItemName}`,
        {
          method: "GET",
        }
      )
      .then((response) => {
        setAffectedInvoiceCount(response.count)
      })
      .catch((error) => {
        setFailedAffectedInvoiceCountCall(true)
        console.log(error)
      })
  }, [lineItemName, setFormData, isOpen, userContext])

  const onCreate = (formData: DistillerFormDataType) => {
    onSubmit(formData)
    onClose()
  }

  const submitButtonText = () => {
    if (!affectedInvoiceCount) {
      return "Create rule and process"
    }
    if (affectedInvoiceCount === 1) {
      return "Create rule and process 1 invoice"
    }
    return `Create rule and process ${affectedInvoiceCount} invoices`
  }

  return (
    <Drawer
      hasBackdrop={true}
      isOpen={isOpen}
      size="35%"
      onClose={onClose}
      title={`Add rule for: ${lineItemName}`}
    >
      <div className={classnames(css.distillerAddForm, Classes.DRAWER_BODY)}>
        <DistillerForm onFieldChange={onFormFieldChange} defaults={formData} />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            outlined
            text={submitButtonText()}
            onClick={() => onCreate(formData)}
            large
            intent={Intent.PRIMARY}
            disabled={!canSubmit}
          />
          {!affectedInvoiceCount && !failedAffectedInvoiceCountCall && (
            <Spinner size={Spinner.SIZE_SMALL} intent={Intent.SUCCESS} />
          )}
          <Button
            minimal
            text="Cancel"
            onClick={onClose}
            large
            intent={Intent.DANGER}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default withDistillerFormValidation(DistillerFormContainer)

import React, { useContext } from "react"
import { Classes, EditableText } from "@blueprintjs/core"
import classnames from "classnames"
import BusinessReportContext from "./BusinessReportContext"
import { UserContext } from "./UserContext"

type SectionSummaryProps = {
  id: string
  value: string
}

type SectionEditResponse = {
  summary: string
}

const SectionSummary = ({ id, value }: SectionSummaryProps) => {
  const { dispatch, demo } = useContext(BusinessReportContext)
  const userContext = useContext(UserContext)

  const onConfirm = (value: string) => {
    const dispatcher = (summary: string) =>
      dispatch({
        type: "updateSection",
        sectionId: id,
        attributes: { summary },
      })

    if (demo) {
      dispatcher(value)
      return
    }

    userContext
      .fetch(`/business_report/sections/${id}`, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify({ summary: value }),
      })
      .then((body: SectionEditResponse) => {
        dispatcher(body.summary)
      })
  }

  return (
    <div className={classnames(Classes.RUNNING_TEXT)}>
      <EditableText
        defaultValue={value}
        onConfirm={onConfirm}
        placeholder="Section description..."
        multiline
        disabled={!userContext.hasUserLevelAccess()}
      />
    </div>
  )
}

export default SectionSummary

import React, { useMemo } from "react"
import { Button, H4, HTMLTable, Tag } from "@blueprintjs/core"
import { ClickedChartDetails } from "../BusinessReportChartSection"
import { capitalize } from "../textHelpers"
import { formatCurrency } from "../formatters"
import pluralize from "pluralize"
import { formatText } from "../DataTable"
import tableCss from "../DataTable.module.css"
import css from "./SubscriptionBased.module.css"
import detailsCss from "../SectionDetails.module.css"
import CalculationDetailsTable from "./CalculationDetailsTable"
import { ExportDetailsButton } from "./ExportSectionDetails"
import { useHistory } from "react-router-dom"
import classNames from "classnames"

type SegmentDetailsTableProp = {
  details: Types.Api.SegmentDetailValue
  segmentName: string
  cohortReferenceValue?: string
  cohortDate?: string
  showCustomerDetails: (customer: Types.Api.SegmentDetailTopCustomer) => void
  withHeader?: boolean
  definition_id: Types.Api.Section["definition_id"]
}

const SegmentDetailsTable = ({
  details,
  segmentName,
  cohortReferenceValue,
  cohortDate,
  withHeader,
  showCustomerDetails,
  definition_id,
}: SegmentDetailsTableProp) => {
  const additionalDetailsColumnNames = useMemo(() => {
    const names = new Set<string>()
    details.top_customers?.forEach((customer) => {
      customer.additional_values?.forEach((item) => names.add(item.name))
    })

    return Array.from(names)
  }, [details.top_customers])
  const headerFromSegmentName = (name: string) =>
    name === "_grouped" ? "Other" : capitalize(name)

  const fullDataShown = (segmentData: Types.Api.SegmentDetailValue) =>
    segmentData.rest.count === 0

  const currencyDisplay = (value: string) => formatCurrency(parseFloat(value))

  const renderCustomerNameCell = (
    customer: Types.Api.SegmentDetailTopCustomer
  ) => {
    const customerName = formatText(customer.name)

    if (customer.deleted) return customerName

    return (
      <span
        className={tableCss.clickableText}
        onClick={() => showCustomerDetails(customer)}
      >
        {customerName}
      </span>
    )
  }

  const renderCohortSegmentDescription = (
    segmentData: Types.Api.SegmentDetailValue
  ) => {
    if (cohortReferenceValue) {
      return (
        <p>
          Total value is <b>{currencyDisplay(segmentData.total_value)}</b> (
          <b>{segmentData.total_count}</b>{" "}
          {pluralize("customer", segmentData.total_count)}) out of{" "}
          <b>{currencyDisplay(cohortReferenceValue)}</b>
        </p>
      )
    } else {
      return (
        <p>
          Total value is <b>{currencyDisplay(segmentData.total_value)}</b> (
          <b>{segmentData.total_count}</b>{" "}
          {pluralize("customer", segmentData.total_count)}) out of initial{" "}
          <b>{currencyDisplay(segmentData.initial_cohort_value)}</b> (
          <b>{segmentData.initial_cohort_count}</b>{" "}
          {pluralize("customer", segmentData.initial_cohort_count)})
        </p>
      )
    }
  }

  const lostMoneyProducts = (
    customer: Types.Api.SegmentDetailTopCustomer
  ): Types.Api.SubscriptionDetail[] => {
    if (customer.movement_from) {
      return customer.movement_from.filter((fromSub) => {
        const toSub = customer.movement_to?.find((sub) => fromSub.id === sub.id)

        return (
          !toSub ||
          parseInt(toSub.monthly_amount) > parseInt(fromSub.monthly_amount)
        )
      })
    }

    return []
  }

  const gainMoneyProducts = (
    customer: Types.Api.SegmentDetailTopCustomer
  ): Types.Api.SubscriptionDetail[] => {
    if (customer.movement_to) {
      const res = customer.movement_to.filter((toSub) => {
        const fromSub = customer.movement_from?.find(
          (sub) => toSub.id === sub.id
        )

        return (
          !fromSub ||
          parseInt(toSub.monthly_amount) > parseInt(fromSub.monthly_amount)
        )
      })

      return res
    }

    return []
  }

  const renderAdditionalDetailsColHeaders = () => {
    return additionalDetailsColumnNames.map((colName, index) => (
      <th key={index} className={classNames(css.numberCol, css.extraCol)}>
        {colName}
      </th>
    ))
  }

  const renderAdditionalDetailsColValues = (
    customer: Types.Api.SegmentDetailTopCustomer
  ) => {
    return additionalDetailsColumnNames.map((colName, index) => {
      let value = customer.additional_values?.find(
        (item) => item.name === colName
      )?.value

      if (value && parseFloat(value)) {
        value = formatCurrency(parseFloat(value))
      }

      return (
        <td key={index} className={classNames(css.numberCol)}>
          {value}
        </td>
      )
    })
  }

  const renderProductColHeades = (
    customer: Types.Api.SegmentDetailTopCustomer
  ) => {
    if (customer.movement_from || customer.movement_to) {
      if (segmentName === "expansion" || definition_id === "expansion") {
        return <th>Gained money on</th>
      } else {
        return <th>Lost money on</th>
      }
    }

    if (customer.subscriptions) {
      return <th>Products</th>
    } else {
      return <></>
    }
  }

  const renderProductTags = (subscriptions: Types.Api.SubscriptionDetail[]) => {
    const uniqueProductKey = subscriptions
      .map((sub) => sub.product_key)
      .filter((sub, i, arr) => arr.indexOf(sub) === i)

    return uniqueProductKey.map((product_key, index) => {
      const count = subscriptions.filter(
        (subscription) => subscription.product_key === product_key
      ).length
      return (
        <Tag key={index} minimal>
          {count > 1 ? `${count} x ` : ""}
          {product_key}
        </Tag>
      )
    })
  }

  const renderProductCells = (customer: Types.Api.SegmentDetailTopCustomer) => {
    if (customer.movement_from || customer.movement_to) {
      let products = []
      if (segmentName === "contraction" || definition_id === "contraction") {
        products = lostMoneyProducts(customer)
      } else {
        products = gainMoneyProducts(customer)
      }

      return (
        <>
          <td>
            <div className={css.productTagsCol}>
              {products.length > 0 ? renderProductTags(products) : "--"}
            </div>
          </td>
        </>
      )
    }

    if (customer.subscriptions) {
      return (
        <td>
          <div className={css.productTagsCol}>
            {renderProductTags(customer.subscriptions)}
          </div>
        </td>
      )
    } else {
      return <></>
    }
  }

  const renderSegmentDescription = (
    segmentData: Types.Api.SegmentDetailValue
  ) => {
    if (segmentData.total_count === 0) {
      return <></>
    } else if (cohortDate) {
      return (
        <>
          {renderCohortSegmentDescription(segmentData)}
          {!fullDataShown(segmentData) && (
            <p>
              <b>
                Top {segmentData.top_customers.length}{" "}
                {pluralize("customer", segmentData.top_customers.length)}
              </b>
            </p>
          )}
        </>
      )
    } else {
      return (
        <>
          <p>
            <b>{segmentData.total_count}</b>{" "}
            {pluralize("customer", segmentData.total_count)} with total value of{" "}
            <b>{formatCurrency(parseFloat(segmentData.total_value))}</b>
          </p>
          {!fullDataShown(segmentData) && (
            <p>
              <b>
                Top {segmentData.top_customers.length}{" "}
                {pluralize("customer", segmentData.top_customers.length)}
              </b>
            </p>
          )}
        </>
      )
    }
  }

  const renderCustomerRow = (customer: Types.Api.SegmentDetailTopCustomer) => (
    <tr key={customer.id}>
      <td className={css.customerIdCol}>
        {!customer.deleted ? customer.id : "Deleted"}
      </td>
      <td className={css.customerNameCol}>
        {renderCustomerNameCell(customer)}
      </td>
      {renderProductCells(customer)}
      <td className={css.numberCol}>
        {formatCurrency(parseFloat(customer.value))}
      </td>
      {renderAdditionalDetailsColValues(customer)}
    </tr>
  )

  const renderHeaderRow = ({
    customer,
    withAdditionalDetails,
  }: {
    customer: Types.Api.SegmentDetailTopCustomer
    withAdditionalDetails: boolean
  }) => {
    return (
      <tr>
        <th>Customer ID</th>
        <th>Name</th>
        {renderProductColHeades(customer)}
        <th className={css.numberCol}>Value</th>
        {withAdditionalDetails && renderAdditionalDetailsColHeaders()}
      </tr>
    )
  }

  const renderAdditionalLists = () => {
    return details.additional_lists?.map(
      (list: Types.Api.AdditionalList, index: number) => (
        <div key={index} className={css.additionalList}>
          <p>{list.name}</p>
          <div className={css.detailsTable}>
            <HTMLTable>
              <thead>
                {renderHeaderRow({
                  customer: list.objects[0],
                  withAdditionalDetails: false,
                })}
              </thead>
              <tbody>{list.objects.map(renderCustomerRow)}</tbody>
            </HTMLTable>
          </div>
        </div>
      )
    )
  }

  return (
    <div className={css.segmentDetails}>
      {withHeader &&
        (details.total_count > 0 ||
          details.calculation_details?.length > 0) && (
          <H4>{headerFromSegmentName(segmentName)}</H4>
        )}
      {details.calculation_details &&
        details.calculation_details.length > 0 && (
          <CalculationDetailsTable
            calculationDetails={details.calculation_details}
          />
        )}
      {renderSegmentDescription(details)}
      {details.total_count > 0 && (
        <div className={css.detailsTable}>
          <HTMLTable>
            <thead>
              {renderHeaderRow({
                customer: details.top_customers[0],
                withAdditionalDetails: true,
              })}
            </thead>
            <tbody>
              {details.top_customers.map(renderCustomerRow)}
              {!fullDataShown(details) && (
                <tr>
                  <td>
                    {details.rest.count} more{" "}
                    {pluralize("customer", details.rest.count)}
                  </td>
                  <td colSpan={4} className={css.numberCol}>
                    {formatCurrency(parseFloat(details.rest.value))}
                  </td>
                </tr>
              )}
            </tbody>
          </HTMLTable>
        </div>
      )}
      {renderAdditionalLists()}
    </div>
  )
}

type DetailsSummaryTableProps = {
  details: Types.Api.ActualDetail
  segmentNames: string[]
}

const DetailsSummaryTable = ({
  details,
  segmentNames,
}: DetailsSummaryTableProps) => {
  const totalValue = useMemo(
    () =>
      segmentNames.reduce(
        (res, key) => res + parseFloat(details[key].total_value),
        0
      ),
    [details, segmentNames]
  )

  const totalCustomersNum = useMemo(
    () => segmentNames.reduce((res, key) => res + details[key].total_count, 0),
    [details, segmentNames]
  )

  const totalQuantity = useMemo(
    () =>
      segmentNames.reduce((res, key) => {
        const quantity = details[key].total_quantity
        if (quantity !== undefined) {
          return res + parseInt(quantity)
        } else {
          return res
        }
      }, 0),
    [details, segmentNames]
  )

  const displayQuantityValue = (quantity: string | undefined) => {
    if (quantity) {
      return parseInt(quantity)
    } else {
      return ""
    }
  }

  return (
    <div className={css.segmentDetails}>
      <H4>Summary</H4>
      <div className={css.detailsTable}>
        <HTMLTable>
          <thead>
            <tr>
              <th className={css.segmentNameCol}>Segment Name</th>
              <th className={css.numberCol}>Total Value</th>
              <th className={css.numberCol}>Units #</th>
              <th className={css.numberCol}>Customers #</th>
              <th className={css.numberCol}>% of total</th>
            </tr>
          </thead>
          <tbody>
            {segmentNames.map((key) => (
              <tr key={key}>
                <td className={css.segmentNameCol}>{key}</td>
                <td className={css.numberCol}>
                  {formatCurrency(parseFloat(details[key].total_value))}
                </td>
                <td className={css.numberCol}>
                  {displayQuantityValue(details[key].total_quantity)}
                </td>
                <td className={css.numberCol}>{details[key].total_count}</td>
                <td className={css.numberCol}>
                  {Math.round(
                    (100 * parseFloat(details[key].total_value)) / totalValue
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Total Value</td>
              <td className={css.numberCol}>{formatCurrency(totalValue)}</td>
              <td className={css.numberCol}>{totalQuantity}</td>
              <td className={css.numberCol}>{totalCustomersNum}</td>
              <td></td>
            </tr>
          </tfoot>
        </HTMLTable>
      </div>
    </div>
  )
}

type SubscriptionBasedDetailsProps = {
  detailedData: Types.Api.ActualDetail
  context: ClickedChartDetails
  keysToDisplay: string[]
  showCustomerDetails: (customer: Types.Api.SegmentDetailTopCustomer) => void
  withPaymentsExport: boolean
  withExplore: boolean
  exploreTitle: string
}

const SubscriptionBasedDetails = ({
  context,
  detailedData,
  keysToDisplay,
  showCustomerDetails,
  withExplore,
  exploreTitle,
  withPaymentsExport,
}: SubscriptionBasedDetailsProps) => {
  const history = useHistory()
  const hasDetailedData = useMemo(() => {
    return (
      keysToDisplay
        .map((key) => detailedData[key].total_count)
        .reduce((sum, e) => sum + e, 0) > 0
    )
  }, [detailedData, keysToDisplay])

  const renderDetailedData = () => {
    if (!detailedData) return

    const manySegments = keysToDisplay.length > 1
    return (
      <>
        {hasDetailedData && manySegments && !context.noSummary && (
          <DetailsSummaryTable
            details={detailedData}
            segmentNames={keysToDisplay}
          />
        )}
        {keysToDisplay.map((key) => (
          <SegmentDetailsTable
            key={`${context.segment_id}_${key}`}
            details={detailedData[key]}
            segmentName={key}
            cohortReferenceValue={context.cohortReferenceValue}
            cohortDate={context.cohort_date}
            withHeader={Object.keys(detailedData).length > 1}
            showCustomerDetails={showCustomerDetails}
            definition_id={context.section_definition_id}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {hasDetailedData && (
        <div className={detailsCss.actions}>
          <ExportDetailsButton
            context={context}
            withPaymentsExport={withPaymentsExport}
          />
          {withExplore && (
            <Button
              intent="primary"
              icon="send-to-graph"
              outlined
              text="Explore this group of customers (trial)"
              onClick={() =>
                history.push({
                  pathname: `/explore/${context.section_id}/${context.segment_id}/${context.date}`,
                  state: { title: exploreTitle },
                })
              }
            />
          )}
        </div>
      )}
      {renderDetailedData()}
    </>
  )
}

export default SubscriptionBasedDetails

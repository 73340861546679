import React, { useState, useContext, useEffect } from "react"
import {
  Popover,
  Intent,
  Position,
  Icon,
  HTMLSelect,
  Label,
  Button,
  Classes,
} from "@blueprintjs/core"
import classnames from "classnames"
import { UserContext } from "./UserContext"
import popoverCss from "./popoverForm.module.css"

type onSwitchType = (currency: string) => void

interface BusinessReportCurrencySwitcherProps {
  onSwitch: onSwitchType
  reportCurrency: string
  disabledSwitch: boolean
}

export const BusinessReportCurrencySwitcher = ({
  onSwitch,
  reportCurrency,
  disabledSwitch,
}: BusinessReportCurrencySwitcherProps) => {
  const userContext = useContext(UserContext)
  const [selectedCurrency, setSelectedCurrency] = useState(
    reportCurrency.toUpperCase()
  )
  const [currencies, setCurrencies] = useState([reportCurrency])

  const fetchAllowedCurrencies = () => {
    if (!userContext.isLoggedIn) {
      return
    }
    userContext
      .fetch("/currencies", {
        method: "GET",
      })
      .then((data) => {
        setCurrencies(data.items)
      })
      .catch((error) => {
        console.warn(error)
        setCurrencies([reportCurrency])
      })
  }

  useEffect(fetchAllowedCurrencies, [userContext, reportCurrency])

  const saveAndRecalculate = () => onSwitch(selectedCurrency)

  const handleCurrencyChange = (currency: string) => {
    setSelectedCurrency(currency)
  }

  const onCancel = () => setSelectedCurrency(reportCurrency)

  const renderCurrencyChangeContent = () => (
    <div className={classnames(popoverCss.popover, popoverCss.widthAuto)}>
      <div className={popoverCss.popoverBody}>
        <Label>
          Report currency
          <HTMLSelect
            name="business_report_currency"
            large
            value={selectedCurrency}
            onChange={(event) => handleCurrencyChange(event.target.value)}
            options={currencies.map((currency) => ({
              value: currency.toUpperCase(),
            }))}
          />
        </Label>
      </div>
      <div className={popoverCss.actions}>
        <Button
          outlined
          intent={Intent.PRIMARY}
          text="Update and recalculate"
          className={Classes.POPOVER_DISMISS}
          onClick={saveAndRecalculate}
        />
        <Button
          className={Classes.POPOVER_DISMISS}
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={onCancel}
        />
      </div>
    </div>
  )

  return (
    <Popover
      content={renderCurrencyChangeContent()}
      position={Position.BOTTOM}
      placement="bottom"
    >
      <Button
        icon={!disabledSwitch && <Icon icon="edit" iconSize={10} />}
        minimal
        intent={Intent.NONE}
        disabled={disabledSwitch}
      >
        {reportCurrency.toUpperCase()}
      </Button>
    </Popover>
  )
}

export default BusinessReportCurrencySwitcher

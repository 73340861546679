import React, { PureComponent } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts"
import CHART_SETTINGS from "./chartSettings"
import {
  tooltipFormatterFactory,
  getTooltipLabelFormatter,
  legendFormatter,
  getXTickFormatter,
  yTickFormatter,
  xTickValueToDate,
  getCategoryAttributeName,
} from "../chartHelpers"
import { ChartProps, ChartState } from "./types"
import withInteractiveLegend from "./withInteractiveLegend"
import schemes from "../colorScheme"

const DetailedCursor = (props: any) => {
  const { x, y, width, height, fill, stroke } = props

  return (
    <rect
      fill={fill}
      stroke={stroke}
      x={x}
      y={y}
      width={width}
      height={height}
      onClick={() =>
        props.onCursorClick(xTickValueToDate(props.payload[0].payload.name))
      }
      style={{ cursor: "pointer" }}
    />
  )
}

class MultipleBarChartImpl extends PureComponent<ChartProps, ChartState> {
  formatter = () =>
    tooltipFormatterFactory({
      useDecimal: this.props.useDecimal,
      withPercent: this.props.withPercent,
      category: this.props.category,
    })

  onCursorClick = (date: string) => {
    this.props.onClick(date, this.props.showOnlyKeys, this.props.dontShowKeys)
  }

  onBarClick = (payload: { name: number }) => {
    this.props.onClick(
      xTickValueToDate(payload.name),
      this.props.showOnlyKeys,
      this.props.dontShowKeys
    )
  }

  detailedTooltipProps = () => {
    if (this.props.detailed) {
      return {
        cursor: <DetailedCursor onCursorClick={this.onCursorClick} />,
      }
    } else {
      return {}
    }
  }

  detailedBarProps = () => {
    if (this.props.detailed) {
      return {
        style: { cursor: "pointer" },
        onClick: this.onBarClick,
      }
    } else {
      return {}
    }
  }

  yAxisPadding = (onlyPositives: boolean, onlyNegatives: boolean) => {
    return {
      top: onlyNegatives ? 0 : CHART_SETTINGS.yAxisBarPadding.top,
      bottom: onlyPositives ? 0 : CHART_SETTINGS.yAxisBarPadding.bottom,
    }
  }

  itemSorter = (item: any) => {
    const category = getCategoryAttributeName(this.props.category)

    return -item.payload[category][item.name]
  }

  render() {
    const { yReferenceLine } = this.props
    const scheme = schemes.main()
    const categoryAttributeName = getCategoryAttributeName(this.props.category)

    const dataPoints = this.props.data
      .map((dataPoint) =>
        this.props.segmentAttrs.map(
          (segmentAttr) => dataPoint[categoryAttributeName][segmentAttr]
        )
      )
      .flat()

    const onlyPositives = dataPoints.every((point) => point >= 0)
    const onlyNegatives = dataPoints.every((point) => point <= 0)

    return (
      <>
        <ResponsiveContainer width="100%" aspect={1.9}>
          <BarChart data={this.props.data} margin={CHART_SETTINGS.margin}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              tickFormatter={getXTickFormatter(
                this.props.data.length,
                this.props.resolution
              )}
              orientation={onlyNegatives ? "top" : "bottom"}
            />
            <YAxis
              axisLine={false}
              padding={this.yAxisPadding(onlyPositives, onlyNegatives)}
              domain={[
                (dataMin: number) => (dataMin > 0 ? 0 : dataMin),
                (dataMax: number) => (dataMax < 0 ? 0 : dataMax),
              ]}
              tickFormatter={yTickFormatter}
              orientation="right"
              tickLine={false}
            />
            {yReferenceLine && (
              <ReferenceLine
                y={yReferenceLine.value}
                label={yReferenceLine.label}
                stroke="red"
                strokeDasharray="3 3"
              />
            )}
            <Tooltip
              {...this.detailedTooltipProps()}
              formatter={this.formatter()}
              itemSorter={this.itemSorter}
              labelFormatter={getTooltipLabelFormatter(this.props.resolution)}
            />
            {this.props.showLegend && (
              <Legend
                formatter={legendFormatter(this.props.groupedSegmentNames)}
                onClick={this.props.onLegendClick}
              />
            )}
            {!onlyPositives && <ReferenceLine y={0} stroke="#000" />}
            {this.props.segmentAttrs.map((attr) => (
              <Bar
                {...this.detailedBarProps()}
                animationDuration={CHART_SETTINGS.animationDuration}
                key={attr}
                name={attr}
                hide={this.props.isHidden(attr)}
                dataKey={(data) =>
                  data[getCategoryAttributeName(this.props.category)][attr]
                }
                fill={scheme.next()}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </>
    )
  }
}

const MultipleBarChart = withInteractiveLegend(MultipleBarChartImpl)
export default MultipleBarChart

import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react"
import { H3, EditableText, Drawer, MenuItem } from "@blueprintjs/core"
import BusinessReportContext from "./BusinessReportContext"
import { UserContext } from "./UserContext"
import { LoadingOverlay, NoDataOverlay } from "./NonIdealState"
import SectionDetails from "./SectionDetails"
import { formatMonthYearISO } from "./formatters"
import ErrorBoundary from "./ErrorBoundary"
import parseISO from "date-fns/parseISO"
import classnames from "classnames"
import { AppToaster } from "./AppToaster"
import { SectionInState as Section } from "./businessReportReducer"
import {
  ChartSegment,
  extractDetailed,
  sectionSegmentSupportedInKeyNumbers,
} from "./chartHelpers"
import BusinessReportSectionExport from "./BusinessReportSectionExport"
import SectionSettingsMenu from "./SectionSettingsMenu"
import SectionSummary from "./SectionSummary"

import sectionStyles from "./businessReportSection.module.css"
import css from "./BusinessReportChartSection.module.css"
import CommentsContainer from "./comments/CommentsContainer"
import HorizontalBarChart from "./charts/HorizontalBarChart"
import BusinessReportSectionKeyNumber from "./BusinessReportSectionKeyNumber"
import { useHistory } from "react-router-dom"

interface BusinessReportChartSectionProps {
  target?: Types.Api.PlanTarget | undefined
  section: Section
  isFirstSection?: boolean
  isLastSection?: boolean
  slideMode?: boolean
  currency: string
  useDecimal: boolean
  canAccessDetails: boolean
  filter?: Types.Api.BusinessReportSegmentValue
  resolution: Types.Api.Section["resolution"]
  keyNumbers: Types.Api.KeyNumber[]
  definition?: Types.Api.SectionDefinition
}

export type ClickedChartDetails = {
  date: string
  cohort_date?: string
  section_id: string
  legacy_series: boolean
  segment_id: string
  segment_value?: ChartSegment["segment_value"]
  filter_details?: boolean
  start_date: string
  end_date: string
  filter_by?: string[]
  cohortReferenceValue?: string
  noSummary?: boolean
  section_definition_id: Types.Api.Section["definition_id"]
  resolution?: Types.Api.Section["resolution"]
}

type ExportItemsProps = {
  segmentId: string
  category: Types.Api.DataCategory["category"]
  segmentValue?: string | string[] | undefined
}

export type ExpandedDataPoint = {
  time: string
  data: {
    name: string
    value: string
  }[]
}

export type SectionSettingsType = {
  dataType: Types.Api.SectionDataType
  useHierarchy: boolean
  resolution: Types.Api.Section["resolution"]
}

export interface BusinessReportChartSectionWithDetailsProps
  extends BusinessReportChartSectionProps {
  onShowDetails: (context: ClickedChartDetails) => void
  getFilterByKeys: (
    showOnlyKeys: string[],
    dontShowKeys: string[],
    grouped_names: string[],
    allKeys: string[]
  ) => string[] | undefined
  category: Types.Api.DataCategory["category"]
  renderCategorySwitcher: (
    additionalItems?: React.ReactElement
  ) => React.ReactElement
  renderSectionSettings: (activeSegmentId?: string) => React.ReactElement
  withDetails: boolean
  buildExportItems: ({
    segmentId,
    category,
    segmentValue,
  }: ExportItemsProps) => React.ReactElement | undefined
  setExpandedDataPoint: (point: ExpandedDataPoint) => void
}

const BusinessReportChartSectionImplementation = (
  WrappedComponent: any,
  props: BusinessReportChartSectionProps
) => {
  const { section, isFirstSection, isLastSection } = props
  const { dispatch, demo, secret } = useContext(BusinessReportContext)
  const userContext = useContext(UserContext)
  const [detailsContext, setDetailsContext] = useState<
    ClickedChartDetails | undefined
  >(undefined)
  const [expandedDataPoint, setExpandedDataPoint] =
    useState<ExpandedDataPoint>()
  const sectionRef = useRef<HTMLDivElement>(null)
  const detailsOpen = !!detailsContext
  const isSlideMode = props.slideMode ? true : false
  const displayQuarters = props.resolution === "quarter"
  const history = useHistory()

  useEffect(() => {
    if (section.scroll) {
      window.scroll({
        top: sectionRef!.current!.offsetTop - 80,
        behavior: "smooth",
      })
      dispatch({ type: "resetScroll" })
    }
  }, [section.scroll, dispatch])

  const onConfirm = (value: string) => {
    const dispatcher = (title: string) =>
      dispatch({
        type: "updateSection",
        sectionId: section.id,
        attributes: { title },
      })
    if (demo) {
      dispatcher(value)
      return
    }

    userContext
      .fetch(`/business_report/sections/${section.id}`, {
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify({ title: value }),
      })
      .then((body) => {
        dispatcher(body.title)
      })
  }

  const onEditCalculation = () => {
    history.push(`/reports/${secret}/new-section/${section.id}`)
  }

  const onRemoveSection = () => {
    const dispatcher = () => {
      dispatch({ type: "removeSection", sectionId: section.id })
    }

    if (demo) {
      dispatcher()
      return
    }

    userContext
      .fetch(`/business_report/sections/${section.id}`, {
        headers: { "Content-Type": "application/json" },
        method: "DELETE",
      })
      .then(() => dispatcher())
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message: "Something went wrong and we could not delete section",
        })
      })
  }

  const onRemoveSectionSegment = (segmentId: string) => {
    if (demo) {
      return
    }

    userContext
      .fetch(`/business_report/sections/${section.id}/segments/${segmentId}`, {
        headers: { "Content-Type": "application/json" },
        method: "DELETE",
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Something went wrong and we could not delete section segment",
        })
      })
  }

  const onSectionRearrange = (newPosition: number) => {
    const dispatcher = (newPosition: number) => {
      dispatch({
        type: "rearrangeSection",
        sectionId: section.id,
        newPosition: newPosition,
      })
    }

    if (demo) {
      dispatcher(newPosition)
      return
    }

    userContext
      .fetch(`/business_report/sections/${section.id}`, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ position: newPosition }),
        method: "PUT",
      })
      .then((data) => dispatcher(data.position))
  }

  const onSectionUp = () => onSectionRearrange(section.position - 1)
  const onSectionDown = () => onSectionRearrange(section.position + 1)
  const onSectionExpandOther = useCallback(
    (segmentId: string) => {
      userContext
        .fetch(
          `/business_report/sections/${section.id}/segments/${segmentId}`,
          {
            method: "GET",
          }
        )
        .then((data) => {
          dispatch({
            type: "updateSectionSegment",
            sectionId: section.id,
            segmentId: segmentId,
            attributes: {
              ...data,
              grouped_segment_names: [],
              series: data.series.map((s: any) => ({
                ...s,
                data_points: s.serie_limited_data_points,
              })),
            },
          })
        })
    },
    [userContext, section, dispatch]
  )

  const getFilterByKeys = (
    showOnlyKeys: string[],
    dontShowKeys: string[],
    grouped_names: string[],
    allKeys: string[]
  ) => {
    const showOnlyNormalized = showOnlyKeys.flatMap((v) =>
      v === "_grouped" ? grouped_names : v
    )
    const dontShowNormalized = dontShowKeys.flatMap((v) =>
      v === "_grouped" ? grouped_names : v
    )
    const allKeysNormalized = allKeys.flatMap((v) =>
      v === "_grouped" ? grouped_names : v
    )

    if (showOnlyKeys.length > 0) {
      return showOnlyNormalized
    } else if (dontShowKeys.length > 0) {
      return allKeysNormalized.filter((v) => !dontShowNormalized.includes(v))
    } else if (props.filter) {
      return [props.filter.value]
    } else {
      return undefined
    }
  }

  const onShowDetails = (context: ClickedChartDetails) => {
    setDetailsContext({ ...context, resolution: section.resolution })
  }

  const closeDetails = () => {
    setDetailsContext(undefined)
  }

  const cohortDetailsDrawerTitle = () => {
    if (!detailsContext || !detailsContext.cohort_date) return ""

    const date = parseISO(detailsContext.date)
    const cohortDate = parseISO(detailsContext.cohort_date)
    const lifetimeNumber =
      12 * date.getFullYear() +
      date.getMonth() -
      12 * cohortDate.getFullYear() -
      cohortDate.getMonth()

    let numberLabel = lifetimeNumber.toString()

    if (section.resolution === "quarter" || section.resolution === "year") {
      if (lifetimeNumber === 0) {
        numberLabel = "initial"
      } else {
        numberLabel = (lifetimeNumber - 1).toString()
      }
    }

    return (
      `${section.title} - ` +
      formatMonthYearISO(detailsContext.cohort_date, section.resolution) +
      ` Cohort - ${section.resolution} ` +
      numberLabel
    )
  }

  const drawerTitle = () => {
    if (!detailsContext) return ""

    if (section.data_type === "cohort") {
      return cohortDetailsDrawerTitle()
    }

    return (
      `${section.title} - ` +
      formatMonthYearISO(detailsContext.date, section.resolution)
    )
  }

  const overlayText = () => {
    if (displayQuarters && !section.filtered) {
      return <>This chart is not supported in quarterly resolution.</>
    }
    return (
      <>
        This chart is missing selected segment.
        <br />
        Edit report to add missing segmentation.
      </>
    )
  }

  const updateSectionSettings = (sectionSettings: SectionSettingsType) => {
    userContext
      .fetch(`/business_report/sections/${section.id}`, {
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data_type: sectionSettings.dataType,
          use_hierarchy: sectionSettings.useHierarchy,
          resolution: sectionSettings.resolution,
        }),
        method: "PUT",
      })
      .then((data) => {
        dispatch({
          type: "updateSection",
          sectionId: section.id,
          attributes: {
            data_type: data.data_type,
            use_hierarchy: data.use_hierarchy,
            resolution: data.resolution,
          },
        })
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Something went wrong and we could not update section settings",
        })
      })
  }

  const withDetails = useMemo(() => {
    if (section.segments.length === 0) return false

    return extractDetailed(section.segments[0])
  }, [section])

  const activeSegmentAttrs = (activeSegmentId: string) => {
    const activeSegment = section.segments.find(
      (item) => item.id === activeSegmentId
    )

    if (!activeSegment || section.segments.length === 1) return {}

    return {
      activeSegmentId: activeSegment.id,
      activeSegmentName: activeSegment.name,
    }
  }

  const renderSectionSettingsMenu = (activeSegmentId: string) => {
    if (!userContext.hasUserLevelAccess()) return <></>

    return (
      <SectionSettingsMenu
        onRemoveSectionSegment={onRemoveSectionSegment}
        onEditCalculation={onEditCalculation}
        onRemoveSection={onRemoveSection}
        onMoveUp={onSectionUp}
        onMoveDown={onSectionDown}
        isFirstSection={isFirstSection || false}
        isLastSection={isLastSection || false}
        updateSectionSettings={updateSectionSettings}
        sectionSettings={{
          dataType: section.data_type,
          useHierarchy: section.use_hierarchy,
          resolution: section.resolution,
        }}
        definitionId={section.definition_id}
        groupingPeriods={props.definition?.supported_grouping_periods}
        {...activeSegmentAttrs(activeSegmentId)}
      />
    )
  }

  const categoryToExportVariant = useCallback(
    (category: Types.Api.DataCategory["category"]) => {
      if (category === "units") {
        return "units_sold"
      } else if (category === "counts") {
        return "customer_count"
      } else {
        return "money"
      }
    },
    []
  )

  const buildExportItems = useCallback(
    ({ segmentId, category, segmentValue }: ExportItemsProps) => {
      const segment = section.segments.find((s) => s.id === segmentId)
      const groupedSegmentsPresent =
        segment && segment.grouped_segment_names?.length > 0

      if (props.canAccessDetails && !isSlideMode) {
        return (
          <>
            {sectionSegmentSupportedInKeyNumbers(section, segmentId) && (
              <BusinessReportSectionKeyNumber
                sectionId={section.id}
                segmentId={segmentId}
                secret={secret}
                active={
                  props.keyNumbers.findIndex(
                    (number) =>
                      number.section_id === section.id &&
                      number.definition_id === segmentId
                  ) !== -1
                }
                dispatch={dispatch}
              />
            )}
            {groupedSegmentsPresent && (
              <MenuItem
                text="Expand grouped segments"
                onClick={() => onSectionExpandOther(segmentId)}
              />
            )}
            <BusinessReportSectionExport
              sectionId={section.id}
              segmentId={segmentId}
              variant={categoryToExportVariant(category)}
              segmentValue={segmentValue}
            />
          </>
        )
      } else {
        return undefined
      }
    },
    [
      section,
      isSlideMode,
      props.canAccessDetails,
      categoryToExportVariant,
      secret,
      props.keyNumbers,
      dispatch,
      onSectionExpandOther,
    ]
  )

  return (
    <>
      <Drawer
        hasBackdrop={true}
        isOpen={detailsOpen}
        size="1200px"
        onClose={closeDetails}
        title={drawerTitle()}
        icon="list-detail-view"
      >
        {detailsContext && (
          <SectionDetails
            underlyingData={section.underlying_data}
            context={detailsContext}
            withExplore={
              section.data_type !== "cohort" &&
              section.definition_id !== "existing_mrr_movement"
            }
            exploreTitle={drawerTitle()}
            withPaymentsExport={section.supports_payments_export}
          />
        )}
      </Drawer>
      {!isSlideMode && (
        <div className={sectionStyles.contentHeader}>
          <H3>
            <EditableText
              defaultValue={section.title}
              onConfirm={onConfirm}
              placeholder="Chart title ..."
              disabled={!userContext.hasUserLevelAccess()}
            />
          </H3>
          <SectionSummary id={section.id} value={section.summary} />
        </div>
      )}
      <div
        className={classnames(
          sectionStyles.content,
          section.data_type === "cohort" ? sectionStyles.cohort : ""
        )}
        ref={sectionRef}
        id={section.definition_id}
      >
        <div className={css.chartContainer}>
          <ErrorBoundary errorMsg="Something went wrong and we could not render this section">
            {section.recalculation_status === "recalculating" && (
              <LoadingOverlay />
            )}
            {section.segments.length === 0 ? (
              <>
                <NoDataOverlay text={overlayText()} />
                {section.filtered && !displayQuarters && (
                  <WrappedComponent
                    {...props}
                    buildExportItems={buildExportItems}
                    getFilterByKeys={getFilterByKeys}
                    section={props.section.original}
                    onShowDetails={onShowDetails}
                    withDetails={withDetails && props.canAccessDetails}
                    renderSectionSettings={renderSectionSettingsMenu}
                  />
                )}
              </>
            ) : (
              <>
                <WrappedComponent
                  {...props}
                  getFilterByKeys={getFilterByKeys}
                  buildExportItems={buildExportItems}
                  onShowDetails={onShowDetails}
                  withDetails={withDetails && props.canAccessDetails}
                  renderSectionSettings={renderSectionSettingsMenu}
                  setExpandedDataPoint={setExpandedDataPoint}
                />
              </>
            )}
            {expandedDataPoint && (
              <div className={css.expandedDataPoint}>
                <H3>{formatMonthYearISO(expandedDataPoint.time)}</H3>
                <HorizontalBarChart dataPoint={expandedDataPoint} />
              </div>
            )}
          </ErrorBoundary>
        </div>
      </div>
      {!isSlideMode && (
        <div
          className={classnames(
            sectionStyles.comments,
            section.data_type === "cohort" ? sectionStyles.commentsCohort : ""
          )}
        >
          <CommentsContainer
            commentedId={section.id}
            comments={section.comments}
            dispatch={dispatch}
            demo={demo}
          />
        </div>
      )}
    </>
  )
}

const BusinessReportChartSection = (WrappedComponent: any) => {
  return (props: BusinessReportChartSectionProps) =>
    BusinessReportChartSectionImplementation(WrappedComponent, props)
}

export default BusinessReportChartSection

import React, { useEffect, useContext } from "react"
import { Intent, Button, Drawer, Classes } from "@blueprintjs/core"
import classnames from "classnames"
import { UserContext } from "./UserContext"
import CustomerSelect from "./CustomerSelect"
import CustomerForm from "./CustomerForm"
import SubscriptionForm from "./SubscriptionForm"
import { withSubscriptionFormValidation } from "./withSubscriptionFormValidation"
import {
  withCustomerFormValidation,
  withCustomerSegments,
} from "./withCustomerFormValidation"
import { jsDateToISOString } from "./formatters"
import CustomerInfo from "./CustomerInfo"
import generatePublicId from "./PublicId"

import css from "./CreateSubscriptionDrawer.module.css"
import formCss from "./forms.module.css"

export const defaultSubscription = (currency) => ({
  id: generatePublicId("subh"),
  duration_in_months: 1,
  started_at: jsDateToISOString(new Date()),
  segments: [],
  items: [{ product_key: "", type: "recurring", quantity: 1 }],
  currency: currency,
})

const CreateSubscriptionDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  onSubscriptionFormFieldChange,
  onCustomerFormFieldChange,
  onCustomerSegmentChange,
  customerFormData,
  subscriptionFormData,
  setCustomerFormData,
  setSubscriptionFormData,
  canSubmitSubscription,
  canSubmitCustomer,
  customerSegments,
}) => {
  const userContext = useContext(UserContext)

  const onCustomerUpdated = (customer) => {
    setCustomerFormData(customer)
  }

  const clearFormData = () => {
    setCustomerFormData(null)
    setSubscriptionFormData(defaultSubscription(userContext.user.currency))
  }

  useEffect(clearFormData, [
    isOpen,
    setCustomerFormData,
    setSubscriptionFormData,
    userContext.user?.currency,
  ])

  const onCustomerSelected = (selectedCustomer) => {
    setCustomerFormData(selectedCustomer)
  }

  const onNewCustomerAdded = (name) => {
    setCustomerFormData({ name, id: null, segments: [] })
  }

  const backToSearch = () => {
    clearFormData()
  }

  const onCreate = ({ isAddingAnother }) => {
    onSubmit(customerFormData, subscriptionFormData)
    if (isAddingAnother) {
      clearFormData()
    } else {
      onClose()
    }
  }

  const onCreateAndAddAnother = () => {
    onCreate({ isAddingAnother: true })
  }

  const canSubmit = canSubmitCustomer && canSubmitSubscription
  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={onClose}
      title="New payment"
      enforceFocus={false}
    >
      <div className={classnames(css.newSubscriptionForm, Classes.DRAWER_BODY)}>
        {customerFormData ? (
          <>
            <div className={formCss.headerActions}>
              <Button
                minimal
                intent={Intent.NONE}
                icon="arrow-left"
                onClick={backToSearch}
              />
            </div>
            {customerFormData.id ? (
              <CustomerInfo
                customer={customerFormData}
                onCustomerUpdated={onCustomerUpdated}
              />
            ) : (
              <CustomerForm
                customer={customerFormData}
                customerSegments={customerSegments}
                onFieldChange={onCustomerFormFieldChange}
                onSegmentChange={onCustomerSegmentChange}
              />
            )}
            <SubscriptionForm
              onFieldChange={onSubscriptionFormFieldChange}
              defaults={subscriptionFormData}
              autoFocus={customerFormData.id ? true : false}
            />
          </>
        ) : (
          <CustomerSelect
            onCustomerSelected={onCustomerSelected}
            onNewCustomerAdded={onNewCustomerAdded}
            withCreate
          />
        )}
      </div>
      {customerFormData && (
        <div className={Classes.DRAWER_FOOTER}>
          <div className={formCss.actions}>
            <Button
              outlined
              text="Create"
              onClick={onCreate}
              large
              intent={Intent.PRIMARY}
              disabled={!canSubmit}
            />
            <Button
              outlined
              text="Create and add another"
              onClick={onCreateAndAddAnother}
              large
              intent={Intent.PRIMARY}
              disabled={!canSubmit}
            />
            <Button
              minimal
              text="Cancel"
              onClick={onClose}
              large
              intent={Intent.DANGER}
            />
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default withSubscriptionFormValidation(
  withCustomerFormValidation(withCustomerSegments(CreateSubscriptionDrawer))
)

import React from "react"
import { Collapse } from "@blueprintjs/core"
import { CSSTransition } from "react-transition-group"
import Comment from "./Comment"

import "./CommentsList.css"

type CommentsListProps = {
  comments: Types.Api.Comment[]
  allowPin: boolean
  demo: boolean
  isOpen: boolean
  toggleCommentPin: (id: string, unpinned: boolean) => void
}

type CommentsListState = {
  pinnedIds: string[]
}

class CommentsList extends React.Component<
  CommentsListProps,
  CommentsListState
> {
  constructor(props: CommentsListProps) {
    super(props)
    this.state = {
      pinnedIds: this.filterPinnedIds(),
    }
  }

  filterPinnedIds = () => {
    const { comments } = this.props

    if (!comments) {
      return []
    } else {
      return comments
        .filter((comment) => comment.pinned_at)
        .map((comment) => comment.id)
    }
  }

  renderComment = (comment: Types.Api.Comment) => {
    // TODO: This is hot fix, for some reason the props of the component are being updated but state is the same
    // seems like one component copy is being used, weird imo
    if (!comment) {
      return
    }

    return (
      <Comment
        key={comment.id}
        id={comment.id}
        demo={this.props.demo}
        pinned={this.state.pinnedIds.includes(comment.id)}
        user={comment.user}
        content={comment.content}
        date={comment.added_at}
        togglePin={this.togglePin}
        allowPin={this.props.allowPin}
      />
    )
  }

  showPinned = () => {
    const comments = this.state.pinnedIds
      .map((id) => this.props.comments.find((comment) => comment.id === id))
      .filter((comment) => comment) as Types.Api.Comment[]

    return comments.map((comment) => this.renderComment(comment))
  }

  togglePin = (id: string) => {
    const pinnedIds = this.state.pinnedIds
    const index = pinnedIds.indexOf(id)

    index === -1 ? pinnedIds.push(id) : pinnedIds.splice(index, 1)

    if (!this.props.demo) {
      this.props.toggleCommentPin(id, index === -1)
    }

    this.setState({ pinnedIds: pinnedIds })
  }

  render() {
    return (
      <div className="comments">
        {!this.props.isOpen && this.showPinned()}
        <Collapse isOpen={this.props.isOpen} keepChildrenMounted>
          <CSSTransition
            classNames="comment"
            timeout={{ enter: 500, exit: 300 }}
          >
            <div>
              {this.props.comments.map((comment) =>
                this.renderComment(comment)
              )}
            </div>
          </CSSTransition>
        </Collapse>
      </div>
    )
  }
}

export default CommentsList

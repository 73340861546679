import React from "react"
import { LineChart, Line, XAxis, YAxis, Tooltip, ReferenceLine } from "recharts"

import schemes from "../colorScheme"
import { formatCurrency, formatMonthYearDate } from "../formatters"

type TinyLineChartDataPoint = {
  date: string
  value: string
}

type TinyLineChartProps = {
  data: TinyLineChartDataPoint[]
}

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload, index, lastIndex } = props
  if (index !== 0 && index !== lastIndex) return <></>

  const textAnchor = () => {
    if (lastIndex === 0) return "middle"

    return index === 0 ? "start" : "end"
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor={textAnchor()} fill="#666">
        {formatMonthYearDate(payload.value)}
      </text>
    </g>
  )
}

const TinyLineChart = ({ data }: TinyLineChartProps) => {
  if (!data || data.length === 0) return <></>
  const scheme = schemes.main()

  const tooltipFormatter = (value: number) => {
    return [formatCurrency(value), "Revenue"]
  }

  const labelFormatter = (label: string) => formatMonthYearDate(label)

  return (
    <LineChart data={data} width={250} height={110} margin={{ top: 20 }}>
      <XAxis
        dataKey="date"
        type="category"
        interval={0}
        axisLine={false}
        tick={<CustomizedAxisTick lastIndex={data.length - 1} />}
        tickSize={0}
      />
      <YAxis hide />
      <Line
        dot={data.length > 1 ? false : { strokeWidth: 10 }}
        dataKey="value"
        type="linear"
        strokeWidth="2"
        stroke={scheme.next()}
      />
      <ReferenceLine y={0} />
      <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
    </LineChart>
  )
}

export default TinyLineChart

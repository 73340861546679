import React, { useEffect, useContext } from "react"
import { KeyNumber } from "./CustomerDetailsSummary"
import { formatCurrency } from "./formatters"
import sectionStyles from "./businessReportSection.module.css"
import css from "./KeyNumbersSection.module.css"
import { UserContext } from "./UserContext"

type KeyNumbersSectionProps = {
  currency: string
  numbers: Types.Api.KeyNumber[]
  dispatch: (obj: any) => void
  report_secret: string
}

const KeyNumbersSection = ({
  currency,
  numbers,
  dispatch,
  report_secret,
}: KeyNumbersSectionProps) => {
  const userContext = useContext(UserContext)

  const fetchKeyNumbers = () => {
    const params = new URLSearchParams()
    params.set("secret", report_secret)
    userContext
      .fetch(`/business_reports/by_secret/key_numbers?${params.toString()}`)
      .then((data) =>
        dispatch({ type: "updateKeyNumbers", keyNumbers: data.items })
      )
  }

  useEffect(fetchKeyNumbers, [report_secret, userContext, dispatch])

  if (numbers.length === 0) return <></>

  const numberKind = (number: Types.Api.KeyNumber) => {
    if (number.kind === "currency") {
      return currency
    } else if (number.kind === "percent") {
      return "%"
    } else {
      return ""
    }

    return ""
  }

  return (
    <div className={sectionStyles.content}>
      <div className={css.keyNumbers}>
        {numbers.map((number, index) => (
          <KeyNumber
            key={index}
            title={number.title}
            kindLabel={numberKind(number)}
            number={formatCurrency(parseFloat(number.value))}
          />
        ))}
      </div>
    </div>
  )
}

export default KeyNumbersSection

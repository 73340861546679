import React, { useState, useCallback } from "react"
import {
  Drawer,
  Intent,
  H3,
  Classes,
  Button,
  Tag,
  Spinner,
} from "@blueprintjs/core"
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DraggableProvided,
} from "react-beautiful-dnd"

import formCss from "./forms.module.css"
import { ColumnFormType } from "./AddCustomersTableColumnDrawer"

type CustomerColumn = {
  id: string
  name: string
  definition?: ColumnFormType
  visible: boolean
}

type CustomerColumnsDrawerProps = {
  isOpen: boolean
  onClose: () => void
  columns: CustomerColumn[]
  setColumns: (columns: any) => void
  onEdit: (column: ColumnFormType) => void
  onDelete: (column: ColumnFormType) => Promise<any>
  onAdd: () => void
}

type ColumnCardProps = {
  column: CustomerColumn
  onEdit: (column: ColumnFormType) => void
  onDelete: (column: ColumnFormType, index: number) => void
  toggleVisibility: (column: CustomerColumn) => void
  index: number
  submitting: boolean
}

const ColumnCard = ({
  column,
  index,
  onEdit,
  onDelete,
  submitting,
  toggleVisibility,
}: ColumnCardProps) => {
  const showVisibilityButton = (column: CustomerColumn) => {
    return (
      <Button
        icon={column.visible ? "eye-open" : "eye-off"}
        minimal
        onClick={() => toggleVisibility(column)}
        intent={Intent.PRIMARY}
        disabled={submitting}
      />
    )
  }

  const showEditDeleteButtons = (column: ColumnFormType, index: number) => {
    return (
      <>
        <Button
          icon="edit"
          minimal
          onClick={() => onEdit(column)}
          intent={Intent.PRIMARY}
          disabled={submitting}
        />
        <Button
          icon="trash"
          loading={submitting}
          minimal
          onClick={() => onDelete(column, index)}
          intent={Intent.PRIMARY}
        />
      </>
    )
  }

  return (
    <div key={index} className={formCss.fieldsRow}>
      <Tag
        fill
        large
        minimal
        icon={
          column.definition?.recalculation_status === "in_progress" && (
            <Spinner size={Spinner.SIZE_SMALL} />
          )
        }
      >
        {column.definition?.recalculation_status === "in_progress" && (
          <>Calculating: </>
        )}
        {column.name}
      </Tag>
      <div className={formCss.fieldsRowActionButton}>
        {showVisibilityButton(column)}
        {column.definition?.recalculation_status === "finished" &&
          showEditDeleteButtons(column.definition, index)}
      </div>
    </div>
  )
}

const CustomerColumnsDrawer = ({
  columns,
  onEdit,
  onDelete,
  onClose,
  isOpen,
  onAdd,
  setColumns,
}: CustomerColumnsDrawerProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [submittingIndex, setSubmittingIndex] = useState(-1)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const deleteColumn = (column: ColumnFormType, index: number) => {
    setSubmittingIndex(index)
    setSubmitting(true)

    onDelete(column)
      .catch(() =>
        setValidationErrors([
          "We could not delete this columnt. Please try again or contact us at support@getprobe.io",
        ])
      )
      .finally(() => {
        setSubmitting(false)
        setSubmittingIndex(-1)
      })
  }

  const moveColumn = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setColumns((prevCards: ColumnFormType[]) => {
        const cols = [...prevCards]
        cols.splice(dragIndex, 1)
        cols.splice(hoverIndex, 0, prevCards[dragIndex] as ColumnFormType)
        return cols
      })
    },
    [setColumns]
  )

  const toggleVisibility = (column: CustomerColumn) => {
    setColumns((prevColumns: CustomerColumn[]) => {
      return prevColumns.map((col) =>
        col.id === column.id ? { ...col, visible: !col.visible } : col
      )
    })
  }

  const renderDraggableColumn = (column: CustomerColumn, index: number) => {
    return (provider: DraggableProvided) => (
      <div
        ref={provider.innerRef}
        {...provider.draggableProps}
        {...provider.dragHandleProps}
      >
        <ColumnCard
          column={column}
          index={index}
          onDelete={deleteColumn}
          onEdit={onEdit}
          submitting={submitting && submittingIndex === index}
          toggleVisibility={toggleVisibility}
        />
      </div>
    )
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    moveColumn(result.source.index, result.destination.index)
  }

  const droppableRender = () => {
    return (provider: DroppableProvided) => {
      return (
        <div ref={provider.innerRef} {...provider.droppableProps}>
          {columns.map((column, index) => (
            <Draggable
              key={column.id}
              draggableId={column.id as string}
              index={index}
            >
              {renderDraggableColumn(column, index)}
            </Draggable>
          ))}
          {provider.placeholder}
        </div>
      )
    }
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="35%"
      onClose={onClose}
      title="Columns"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={formCss.insideDrawer}>
          <H3 className={formCss.title}>Columns</H3>
          <p>
            Create a new calculated column that will be added to your Customer
            table
          </p>
          <div className={formCss.body}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">{droppableRender()}</Droppable>
            </DragDropContext>
            {validationErrors.map((error, index) => (
              <p key={index} className={formCss.errorLabel}>
                {error}
              </p>
            ))}
            <div className={formCss.fieldsRow}>
              <Button
                outlined
                intent={Intent.PRIMARY}
                text="Add column"
                onClick={onAdd}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            text="Close"
            intent={Intent.PRIMARY}
            outlined
            onClick={onClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default CustomerColumnsDrawer

import React, { useContext, useState } from "react"
import { Button, Classes, Card, H5, Intent } from "@blueprintjs/core"
import { UserContext } from "../UserContext"
import { Section } from "../Content"
import { AppToaster } from "../AppToaster"
import { CardsLoadingState } from "../NonIdealState"
import css from "./SettingsSegmenters.module.css"
import SegmenterDefinitionApi, { Definition } from "../api/SegmenterDefinition"
import useSegmenterDefinitions from "../api/useSegmenterDefinitions"
import SegmenterDrawerForm from "./SegmenterDrawerForm"
import useSegmenterTypes from "../api/useSegmenterTypes"
import useObjectAttributes from "../api/useObjectAttributes"

const SettingsSegmenters = () => {
  const userContext = useContext(UserContext)
  const segmenterTypes = useSegmenterTypes()
  const [objectAttributes] = useObjectAttributes()

  const [segmenterDefinitions, segmentersLoading, fetchDefinitions] =
    useSegmenterDefinitions()

  const [isSegmenterFormOpen, setIsSegmenterFormOpen] = useState(false)

  const [editedSegmenter, setEditedSegmenter] = useState<Definition>()

  const displayableDefinitions = segmenterDefinitions.filter(
    (definition) => definition.type_name !== "total"
  )

  const buildNewSegmentsFromAttributes = (
    segmenterType: Types.Api.SegmenterType,
    object: string
  ) => {
    return objectAttributes
      .filter((item) => item.object === object)
      .map((item) => ({
        ...segmenterType,
        label: item.name,
        segment_id: item.id,
      }))
  }

  const expandSegmenterTypesWithObjectAttributes = () => {
    return segmenterTypes.flatMap(
      (
        segmenterType: Types.Api.SegmenterType
      ): Types.Api.SegmenterType | Types.Api.SegmenterType[] => {
        if (segmenterType.name === "customer_segment") {
          return buildNewSegmentsFromAttributes(segmenterType, "customer")
        }

        if (segmenterType.name === "subscription_segment") {
          return buildNewSegmentsFromAttributes(segmenterType, "subscription")
        }

        if (segmenterType.name === "cost_segment") {
          return buildNewSegmentsFromAttributes(segmenterType, "cost")
        }

        return segmenterType
      }
    )
  }

  const startEditingSegmenter = (definition: Definition) => {
    setEditedSegmenter(definition)
    setIsSegmenterFormOpen(true)
  }

  const typeNameToUserDescription = (typeName: Definition["type_name"]) => {
    return segmenterTypes.find((item) => item.name === typeName)?.label
  }

  const deleteDefinition = (
    event: React.SyntheticEvent,
    definition: Definition
  ) => {
    event.stopPropagation()
    SegmenterDefinitionApi.delete(userContext, definition.id)
      .then(fetchDefinitions)
      .catch((error) => {
        if (error.response.status === 422) {
          error.response.json().then((errorData: any) => {
            AppToaster.showError({ message: errorData.errors[0]?.detail })
          })
        } else {
          AppToaster.showError({
            message: "Cound not remove attribute. Please, try again.",
          })
        }
      })
  }

  const renderDefinition = (definition: Definition) => {
    return (
      <Card
        className={css.card}
        key={definition.id}
        interactive={true}
        onClick={() => startEditingSegmenter(definition)}
      >
        <div className={css.body}>
          <H5>{definition.name}</H5>
          <div className={Classes.TEXT_MUTED}>
            {typeNameToUserDescription(definition.type_name)}
          </div>
        </div>
        <div className={css.actions}>
          <span>
            <Button
              icon="trash"
              minimal
              intent="danger"
              onClick={(e) => deleteDefinition(e, definition)}
            />
          </span>
        </div>
      </Card>
    )
  }

  const onAddSegmentationClick = () => {
    setIsSegmenterFormOpen(true)
  }

  const createOrEditSegmentViaApi = (definition: Definition) => {
    if (definition.id) {
      return SegmenterDefinitionApi.update(userContext, definition)
    } else {
      return SegmenterDefinitionApi.create(userContext, definition)
    }
  }

  const onFormSave = (definition: Definition, resetForm: () => void) => {
    createOrEditSegmentViaApi(definition)
      .then(() => {
        fetchDefinitions()
        setIsSegmenterFormOpen(false)
        resetForm()
      })
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({ message: "Could not add segmentation" })
      })
    setIsSegmenterFormOpen(false)
  }

  const onNewSegmenterCancel = () => {
    setIsSegmenterFormOpen(false)
    if (editedSegmenter) setEditedSegmenter(undefined)
  }

  const actionButton = () => (
    <div className={css.actionButtons}>
      <Button
        large
        onClick={onAddSegmentationClick}
        intent={Intent.PRIMARY}
        outlined
        icon="plus"
        text="Add segmentation"
      />
    </div>
  )

  return (
    <>
      <SegmenterDrawerForm
        isOpen={isSegmenterFormOpen}
        onClose={onNewSegmenterCancel}
        defaultValues={editedSegmenter || undefined}
        onSubmit={onFormSave}
        segmenterTypes={expandSegmenterTypesWithObjectAttributes()}
      />
      <Section
        title="Segmentation"
        subtitle="Configure your data segmentation"
        actionButton={actionButton()}
      >
        {segmentersLoading && <CardsLoadingState />}
        {!segmentersLoading && (
          <div className={css.definitionsContainer}>
            {displayableDefinitions.map(renderDefinition)}
          </div>
        )}
      </Section>
    </>
  )
}

export default SettingsSegmenters

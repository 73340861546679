import React, { useContext } from "react"
import { UserContext } from "./UserContext"
import { SelectedType } from "./DataMappingImportStep"
import DataImportWizard from "./DataImportWizard"

const ImportSubscriptionsContainer = () => {
  const userContext = useContext(UserContext)
  const mappingValues: SelectedType[] = [
    {
      id: "customer_public_id_index",
      type: "customer_id",
      name: "Customer ID",
      columnIndex: 0,
      required: true,
    },
    {
      id: "invoice_number_index",
      type: "invoice_number",
      name: "Invoice Number",
      columnIndex: 1,
      required: true,
    },
    {
      id: "started_at_index",
      type: "started_at",
      name: "Started at",
      columnIndex: 2,
      required: true,
    },
    {
      id: "ended_at_index",
      type: "ended_at",
      name: "Ended at",
      columnIndex: 3,
    },
    {
      id: "months_index",
      type: "months",
      name: "Months #",
      columnIndex: 5,
      required: true,
    },
    {
      id: "monthly_amount_index",
      type: "monthly_amount",
      name: "Monthly amount",
      columnIndex: 6,
      required: true,
    },
    {
      id: "subscription_type_index",
      type: "subscription_type",
      name: "Revenue type",
      columnIndex: 7,
    },
    {
      id: "currency_index",
      type: "currency",
      name: "Currency",
      columnIndex: 8,
    },
    {
      id: "product_name_index",
      type: "product_name",
      name: "Product name",
      columnIndex: 9,
    },
    {
      id: "customer_name_index",
      type: "customer_name",
      name: "Customer name",
      columnIndex: 10,
    },
    {
      id: "units_sold_index",
      type: "units_sold",
      name: "Units sold",
      columnIndex: 11,
    },
  ]

  const uploadCsv = (file: File) => {
    const formData = new FormData()
    formData.append("csv", file)

    const requestOptions = {
      method: "POST",
      body: formData,
    }

    return userContext.fetch("/subscriptions/csv_import", requestOptions)
  }

  const isMappingComplete = (selectedMappings: SelectedType[]) => {
    const requiredTypes = [
      "monthly_amount",
      "started_at",
      "customer_id",
      "invoice_number",
      "months",
    ]

    const provided = selectedMappings.filter((mapping) =>
      requiredTypes.includes(mapping.type)
    )

    return provided.length === requiredTypes.length
  }

  const finalizeImport = (
    importId: number,
    skipHeaders: boolean,
    mappings: SelectedType[]
  ) => {
    const formData = new FormData()
    formData.append("skip_headers", skipHeaders.toString())
    mappings.forEach((mapping) =>
      formData.append(mapping.id, mapping.columnIndex.toString())
    )

    const requestOptions = {
      method: "POST",
      body: formData,
    }

    return userContext.fetch(
      `/subscriptions/csv_import/${importId}/finalize`,
      requestOptions
    )
  }

  const renderValidationCallout = () => {
    return <></>
  }

  return (
    <DataImportWizard
      importChannelName="SegmentsImportChannel"
      mappingValues={mappingValues}
      uploadCsv={uploadCsv}
      isMappingComplete={isMappingComplete}
      finalizeImport={finalizeImport}
      renderValidationCallout={renderValidationCallout}
      fullWidthMapping={false}
      title="Import subscriptions"
      statusCheckMode="polling"
    />
  )
}

export default ImportSubscriptionsContainer

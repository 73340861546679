import React from "react"
import { withRouter, Link } from "react-router-dom"
import { Label, InputGroup, H2 } from "@blueprintjs/core"
import queryString from "query-string"
import WiggleButton from "./WiggleButton"
import { UserContext } from "./UserContext"
import { apiErrorsToMessages } from "./apiHelpers"
import ApiErrorCallout from "./ApiErrorCallout"
import Logo from "./Logo"

import css from "./signSection.module.css"

class SignUp extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      failed: false,
      response: null,
    }
  }

  onClick = () => {
    const marketingRef = queryString.parse(
      window.location.hash.split("?")[1]
    ).ref
    return this.context
      .performSignUp(this.state.email, this.state.password, {
        marketing_ref: marketingRef,
      })
      .then(() => {
        this.setState({ failed: false })
        this.props.history.push("/getting-started")
      })
      .catch((error) => {
        error.response.json().then((object) => {
          this.setState({ failed: true, response: object })
        })
        throw error
      })
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    const email = params.get("email")
    if (email) {
      this.setState({ email: email })
      this.passwordField.focus()
    } else {
      this.emailField.focus()
    }
  }

  render() {
    return (
      <form className={css.main}>
        <center>
          <Logo />
        </center>
        <center>
          <H2>Create new account</H2>
        </center>
        {this.state.failed && (
          <ApiErrorCallout
            messages={apiErrorsToMessages(this.state.response)}
          />
        )}
        <div>
          <Label>
            Email
            <InputGroup
              large
              type="text"
              value={this.state.email}
              onChange={this.onEmailChange}
              placeholder="email@example.com"
              inputRef={(input) => {
                this.emailField = input
              }}
            />
          </Label>
          <Label>
            Password
            <InputGroup
              large
              type="password"
              value={this.state.password}
              onChange={this.onPasswordChange}
              placeholder="password"
              inputRef={(input) => {
                this.passwordField = input
              }}
            />
          </Label>
          <WiggleButton
            type="submit"
            rightIcon="arrow-right"
            text="Sign up"
            onClick={this.onClick}
          />
        </div>
        <ul className={css.additionalLinks}>
          <li>
            <Link to="/sign_in">Already have an account? Sign in</Link>
          </li>
          <li>
            <Link to="/reset_password">Forgot password?</Link>
          </li>
        </ul>
      </form>
    )
  }
}

SignUp.contextType = UserContext

export default withRouter(SignUp)

import React, { useState, useCallback } from "react"

// There seems to be no way to get initial state of the legend - aka all keys it will show
// thats why we keep two arrays:
// dontShowKeys - for the case when first click happen with special key and we wanna remove
//                clicked item from representation
// showOnlyKeys - for the case when click with key adds clicked item to representation
const withInteractiveLegend = (WrappedComponent) => (props) => {
  // keys we are adding to representation
  const [showOnlyKeys, setshowOnlyKeys] = useState([])

  // keys we are removing from representation
  const [dontShowKeys, setDontShowKeys] = useState([])

  const displayOnlyOneValue = useCallback(
    (value) => {
      if (dontShowKeys.length > 0) {
        setDontShowKeys([])
      }
      if (showOnlyKeys.length === 1 && showOnlyKeys.includes(value)) {
        setshowOnlyKeys([])
      } else {
        setshowOnlyKeys([value])
      }
    },
    [dontShowKeys, showOnlyKeys]
  )

  const toggleValueFromDisplay = useCallback((newValue, currentValues) => {
    if (currentValues.includes(newValue)) {
      return currentValues.filter((v) => v !== newValue)
    } else {
      return currentValues.concat(newValue)
    }
  }, [])

  const onLegendClick = useCallback(
    (legendItem, _index, event) => {
      if (event.metaKey || event.shiftKey || event.ctrlKey) {
        // If it is the first click with special key
        // all values will be displayed but the clicked one
        // every next click in this mode remove from display clicked value
        // unless it was already removed - than it turnes it back = toogle
        if (showOnlyKeys.length > 0) {
          setshowOnlyKeys(
            toggleValueFromDisplay(legendItem.value, showOnlyKeys)
          )
        } else {
          setDontShowKeys(
            toggleValueFromDisplay(legendItem.value, dontShowKeys)
          )
        }
      } else {
        displayOnlyOneValue(legendItem.value)
      }
    },
    [showOnlyKeys, dontShowKeys, displayOnlyOneValue, toggleValueFromDisplay]
  )

  const isHidden = useCallback(
    (dataKey) => {
      if (dontShowKeys.length > 0) {
        return dontShowKeys.includes(dataKey)
      }

      if (showOnlyKeys.length > 0) {
        return !showOnlyKeys.includes(dataKey)
      }

      return false
    },
    [showOnlyKeys, dontShowKeys]
  )

  return (
    <WrappedComponent
      {...props}
      showOnlyKeys={showOnlyKeys}
      dontShowKeys={dontShowKeys}
      onLegendClick={onLegendClick}
      isHidden={isHidden}
    />
  )
}

export default withInteractiveLegend

import React from "react"
import { ControlGroup, HTMLSelect, Label } from "@blueprintjs/core"
import { UseFormRegisterReturn } from "react-hook-form"

type MonthYearSelectorProps = {
  monthField: UseFormRegisterReturn
  yearField: UseFormRegisterReturn
  label: string
}

const MonthYearSelector = ({
  label,
  monthField,
  yearField,
}: MonthYearSelectorProps) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const YEARS = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2026]

  return (
    <Label>
      {label}
      <ControlGroup fill>
        <HTMLSelect
          elementRef={monthField.ref}
          onChange={monthField.onChange}
          onBlur={monthField.onBlur}
          name={monthField.name}
          defaultValue=""
        >
          <option key="Month" value="">
            Month
          </option>
          {MONTHS.map((value) => (
            <option key={value} value={MONTHS.indexOf(value) + 1}>
              {value}
            </option>
          ))}
        </HTMLSelect>
        <HTMLSelect
          placeholder="End year"
          elementRef={yearField.ref}
          onChange={yearField.onChange}
          onBlur={yearField.onBlur}
          name={yearField.name}
          defaultValue=""
        >
          <option key="Year" value="">
            Year
          </option>
          {YEARS.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </HTMLSelect>
      </ControlGroup>
    </Label>
  )
}

export default MonthYearSelector

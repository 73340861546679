import React, { useState, useContext, useEffect, useCallback } from "react"
import { Intent, Button } from "@blueprintjs/core"
import pluralize from "pluralize"
import SubscriptionsTable from "./SubscriptionsTable"
import { Section } from "./Content"
import { UserContext } from "./UserContext"
import CreateSubscriptionDrawer, {
  defaultSubscription,
} from "./CreateSubscriptionDrawer"
import { convertInputToSubscriptionAttrs } from "./SubscriptionForm"
import SearchInput, { SearchApiDestination } from "./SearchInput"
import { AppToaster } from "./AppToaster"
import { customerWithSegmentsToUpdate } from "./EditCustomerPopover"

import css from "./TableContainer.module.css"
import { LoadingOverlay } from "./NonIdealState"

const SubscriptionsContainer = () => {
  const [formOpen, setFormOpen] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [currentPageInfo, setCurrentPageInfo] = useState({ total_count: 0 })
  const [currentCursor, setCurrentCursor] = useState(null)
  const userContext = useContext(UserContext)
  const [loading, setLoading] = useState(false)

  const updateCurrentPageInfo = (response) => {
    const { total_count, current_range, next, prev } = response
    setCurrentPageInfo({ total_count, current_range, next, prev })
  }

  const fetchSubscriptions = useCallback(
    (cursor, sortBy) => {
      let searchParams = { item_type: "recurring" }
      if (cursor) searchParams.next = cursor
      if (sortBy) searchParams.sort_by = sortBy
      const currency = userContext.user.currency

      setLoading(true)
      userContext
        .fetch(
          `/subscription_histories?currency=${currency}&${new URLSearchParams(
            searchParams
          ).toString()}`,
          {
            method: "GET",
          }
        )
        .then((data) => {
          setSubscriptions(data.items)
          setCurrentCursor(cursor)
          updateCurrentPageInfo(data)
        })
        .catch(() => setSubscriptions([]))
        .finally(() => setLoading(false))
    },
    [userContext]
  )

  useEffect(fetchSubscriptions, [fetchSubscriptions])

  const onSort = useCallback(
    (sortBy) => {
      if (sortBy.length > 0) {
        const { id, desc } = sortBy[0]
        fetchSubscriptions(null, `${id}:${desc ? "desc" : "asc"}`)
      }
    },
    [fetchSubscriptions]
  )

  const onNextPage = useCallback(() => {
    fetchSubscriptions(currentPageInfo.next)
  }, [fetchSubscriptions, currentPageInfo])

  const onPrevPage = useCallback(() => {
    fetchSubscriptions(currentPageInfo.prev)
  }, [fetchSubscriptions, currentPageInfo])

  const showNewSubscriptionForm = () => {
    setFormOpen(true)
  }

  const onFormClose = () => {
    setFormOpen(false)
  }

  const actions = () => {
    return (
      <Button
        onClick={showNewSubscriptionForm}
        text="New payment"
        intent={Intent.PRIMARY}
        large
        outlined
      />
    )
  }

  const createCustomer = (customer) => {
    return new Promise((resolve) => {
      if (customer.id) {
        resolve(customer)
      } else {
        const { id, ...customerFormData } = customer // eslint-disable-line @typescript-eslint/no-unused-vars
        const finalData = customerWithSegmentsToUpdate(customerFormData)

        userContext
          .fetch("/customers", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(finalData),
          })
          .then((data) => resolve(data))
          .catch((error) => {
            console.warn(error)
            AppToaster.showError({
              message: "We could not create new customer. Please try again.",
            })
          })
      }
    })
  }

  const createSubscription = (customerId, subscription) => {
    return userContext.fetch(
      `/subscriptions/history/upsert/${subscription.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          convertInputToSubscriptionAttrs(customerId, subscription)
        ),
      }
    )
  }

  const updateSegments = (subscription, segments) => {
    return new Promise((resolve) => {
      if (!segments || segments.length === 0) return resolve(subscription, null)

      userContext
        .fetch(`/subscriptions/history/${subscription.latest_history_id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ segments }),
        })
        .then((history) => resolve(subscription, history))
    })
  }

  const onSubmit = (customer, subscription) => {
    createCustomer(customer)
      .then((resCustomer) => {
        if (!customer.id) {
          customer = resCustomer
        }
        return createSubscription(customer.id, subscription)
      })
      .then((sub) => updateSegments(sub, subscription.segments))
      .then((sub) => {
        sub.customer = customer
        sub.highlight = true
        setSubscriptions([sub].concat(subscriptions))
      })
  }

  const onSearchSuccess = useCallback((subscriptions) => {
    setSubscriptions(subscriptions)
    updateCurrentPageInfo({ total_count: subscriptions.length })
  }, [])

  const onSearchQueryChange = useCallback(
    (query) => {
      if (query.length === 0) {
        fetchSubscriptions()
      }
    },
    [fetchSubscriptions]
  )

  const onChange = useCallback(() => {
    fetchSubscriptions(currentCursor)
  }, [fetchSubscriptions, currentCursor])

  return (
    <Section
      title="Payments"
      subtitle={`${currentPageInfo.total_count} ${pluralize(
        "payment item",
        currentPageInfo.total_count
      )}`}
    >
      <CreateSubscriptionDrawer
        isOpen={formOpen}
        onClose={onFormClose}
        onSubmit={onSubmit}
        subscriptionFormData={defaultSubscription(userContext.user.currency)}
      />
      <div className={css.tableActions}>
        <div className={css.left}>
          <SearchInput
            apiDestination={SearchApiDestination.SUBSCRIPTION}
            className={css.searchInput}
            onSuccess={onSearchSuccess}
            onQueryChange={onSearchQueryChange}
            small
          />
        </div>
        <div className={css.right}>{actions()}</div>
      </div>
      <div className={css.tableContainer}>
        {loading && <LoadingOverlay />}
        <SubscriptionsTable
          subscriptions={subscriptions}
          onSort={onSort}
          onNextPage={currentPageInfo.next && onNextPage}
          onPrevPage={currentPageInfo.prev && onPrevPage}
          range={currentPageInfo.current_range}
          onCustomerRemove={onChange}
          onSubscriptionRemove={onChange}
        />
      </div>
    </Section>
  )
}

export default SubscriptionsContainer

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import SelectFilterField, { FilterField } from "./FilterFieldSelect"
import { FilterNodeArgument } from "./types"
import {
  Popover,
  Position,
  Tag,
  PopoverInteractionKind,
  Button,
  Classes,
  Intent,
  Label,
  ControlGroup,
  ButtonGroup,
} from "@blueprintjs/core"

import popoverCss from "../popoverForm.module.css"
import formCss from "../forms.module.css"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import FilterValueMultiSelect from "./FilterValueMultiSelect"
import { UserContext } from "../UserContext"

type FilterArgumentFormProps = InjectedHookHelpersProps<FilterArgumentForm> & {
  items: FilterField[]
  index: number
  onRemove?: (index: number) => void
  onSave: (index: number, value: FilterNodeArgument) => void
  defaultValues?: FilterNodeArgument
  target?: React.ReactElement
}

type FilterArgumentForm = {
  argument: FilterNodeArgument
}

const FilterArgumentForm = ({
  items,
  index,
  onRemove,
  onSave,
  defaultValues,
  target,
  validationErrorMsg,
  fieldIntent,
}: FilterArgumentFormProps) => {
  const {
    getValues,
    reset,
    control,
    watch,
    formState,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<FilterArgumentForm>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { argument: defaultValues || {} },
  })
  const userContext = useContext(UserContext)
  const filterArgument = watch("argument")
  const [possibleValues, setPossibleValues] = useState<string[]>([])

  const selectedItem = useMemo(() => {
    return items.find((item) => item.field_id === filterArgument.field_id)
  }, [filterArgument, items])

  const fetchPossibleValues = useCallback(() => {
    if (!selectedItem || selectedItem.type !== "text") return

    setPossibleValues([])
    const params = new URLSearchParams({
      field_id: selectedItem?.field_id || "",
      object: selectedItem?.object || "",
    })
    userContext
      .fetch(`/user_filtered_config/possible_values?${params.toString()}`, {
        method: "GET",
      })
      .then((data) => {
        setPossibleValues(data.items)
      })
  }, [selectedItem, userContext])

  useEffect(() => {
    fetchPossibleValues()
  }, [selectedItem, fetchPossibleValues])

  const argumentOperator = (type: FilterField["type"]) => {
    if (type === "date") return "in_reporting_month"
    if (type === "text") return "in"
    return "eq"
  }

  const argumentDefaultValue = (type: FilterField["type"]) => {
    if (type === "boolean") return true
    if (type === "text") return []
    if (type === "date") return "within_reporting_month"
    return ""
  }

  const onSelectFilterField = (field: any, value: FilterField) => {
    field.onChange({
      type: "filter",
      field: value.field,
      field_id: value.field_id,
      object: value.object,
      operator: argumentOperator(value.type),
    })
    setValue("argument.value", argumentDefaultValue(value.type))
  }

  const renderFilterField = ({ field }: any) => (
    <SelectFilterField
      items={items}
      onItemSelect={(value) => onSelectFilterField(field, value)}
      activeItem={selectedItem}
    />
  )

  const renderDateArgument = () => {
    return <>Within reporting month</>
  }

  const renderMultiselectValues = (field: any) => {
    return (
      <FilterValueMultiSelect
        items={possibleValues}
        selectedItems={(filterArgument?.value || []) as string[]}
        onItemSelect={(item: string) => onItemToggle(item, field)}
        onRemove={(item: string) => onItemToggle(item, field)}
        onClear={() => onClear(field)}
        intent={fieldIntent(errors, field.name)}
      />
    )
  }

  const renderBooleanArgument = (field: any) => {
    return (
      <ControlGroup fill>
        <Label>
          <ButtonGroup fill>
            <Button
              active={field.value === true}
              outlined
              text="Yes"
              onClick={() => field.onChange(true)}
            />
            <Button
              active={field.value === false}
              outlined
              text="No"
              onClick={() => field.onChange(false)}
            />
          </ButtonGroup>
          {validationErrorMsg(errors, field.name)}
        </Label>
      </ControlGroup>
    )
  }

  const renderConditions = ({ field }: any) => {
    if (!selectedItem) return <></>

    switch (selectedItem.type) {
      case "date":
        return renderDateArgument()
      case "boolean":
        return renderBooleanArgument(field)
      case "text":
        return renderMultiselectValues(field)
      default:
        return <></>
    }
  }

  const onSubmit = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()
        onSave(index, values.argument)
        reset()
      }
    })
  }

  const onCancel = () => {
    reset({ argument: defaultValues || {} })
  }

  const validateArgumentValue = (value: any) => {
    if (value === null || value === "" || value?.length === 0) {
      return "Required"
    }

    return true
  }

  const validateFilterField = (value: any) => {
    if (value?.object) {
      return true
    }

    return false
  }
  const onItemToggle = (item: string, field: any) => {
    const selectedValues = filterArgument.value as string[]
    if (selectedValues.includes(item)) {
      field.onChange(selectedValues.filter((value) => item !== value))
    } else {
      field.onChange(selectedValues.concat([item]))
    }
  }

  const onClear = (field: any) => {
    field.onChange([])
  }

  const renderContent = () => {
    return (
      <div className={popoverCss.popover}>
        <div className={popoverCss.popoverBody}>
          <div className={popoverCss.content}>
            <div className={formCss.fieldsRow}>
              <Controller
                control={control}
                name="argument"
                render={renderFilterField}
                rules={{ validate: validateFilterField }}
              />
            </div>
            <div className={formCss.fieldsRow}>
              <Controller
                control={control}
                name="argument.value"
                render={renderConditions}
                rules={{ validate: validateArgumentValue }}
              />
            </div>
          </div>
        </div>
        <div className={popoverCss.actions}>
          <Button
            outlined
            intent={Intent.PRIMARY}
            text="OK"
            className={Classes.POPOVER_DISMISS}
            onClick={onSubmit}
            disabled={!formState.isValid}
          />
          <Button
            className={Classes.POPOVER_DISMISS}
            minimal
            intent={Intent.DANGER}
            onClick={onCancel}
            text="Cancel"
          />
        </div>
      </div>
    )
  }

  const renderFilterLabel = () => {
    if (!selectedItem) return ""
    switch (selectedItem.type) {
      case "date":
        return <>{filterArgument.field} within reporting month</>
      case "text": {
        const SHOW_TOP_FIVE = 5
        const topValues = (filterArgument.value as string[])
          .slice(0, SHOW_TOP_FIVE)
          .join(", ")
        const moreLabel =
          (filterArgument.value as string[]).length > SHOW_TOP_FIVE ? "..." : ""
        return (
          <>
            {filterArgument.field} one of: {`${topValues} ${moreLabel}`}
          </>
        )
      }
      case "boolean":
        return (
          <>
            {filterArgument.field} {filterArgument.value ? "Yes" : "No"}
          </>
        )
      default:
        return ""
    }
  }

  const renderTarget = () => {
    return (
      <Tag
        large
        minimal
        interactive
        icon={defaultValues ? "chevron-down" : "plus"}
        onRemove={onRemove && defaultValues ? () => onRemove(index) : undefined}
      >
        {renderFilterLabel()}
      </Tag>
    )
  }

  return (
    <Popover
      content={renderContent()}
      position={Position.BOTTOM}
      placement="bottom"
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
    >
      {target ? target : renderTarget()}
    </Popover>
  )
}

export default withHookFormHelpers(FilterArgumentForm)

import React from "react"
import { Button, IconName, MaybeElement } from "@blueprintjs/core"
import { RefreshedAtTag } from "./ReportInfoTags"

import css from "./BusinessReportActionBar.module.css"

export type ActionBarButton = {
  key: string
  text: string
  onClick: () => void
  icon: IconName | "blank" | MaybeElement
  disabled?: boolean
}

interface BusinessReportActionBarProps {
  buttons: Array<ActionBarButton>
  refreshedAt: string
  settingsTag?: React.ReactNode
  rangeTag: React.ReactNode
  filterTag: React.ReactNode
  currencySwitcher: React.ReactNode
}

const BusinessReportActionBar = ({
  buttons,
  refreshedAt,
  settingsTag,
  rangeTag,
  filterTag,
  currencySwitcher,
}: BusinessReportActionBarProps) => {
  const toButton = (button: ActionBarButton) => {
    const onClick = () => button.onClick()
    return <Button {...button} onClick={onClick} minimal large />
  }

  return (
    <div className={css.reportActionBar}>
      <div className={css.reportActionBarContainer}>
        <div className={css.actionContainer}>
          {buttons.map((button) => toButton(button))}
          {filterTag}
        </div>
        <div className={css.settingsContainer}>
          <RefreshedAtTag refreshedAt={refreshedAt} />
          {currencySwitcher}
          {rangeTag}
          {settingsTag}
        </div>
      </div>
    </div>
  )
}

export default BusinessReportActionBar

import React, { useState, useEffect } from "react"
import { Menu, MenuItem, Button } from "@blueprintjs/core"
import { Suggest as BlueprintSuggest } from "@blueprintjs/select"
import { useDebouncedCallback } from "use-debounce"

import css from "./Suggest.module.css"

const ItemListRenderer = ({
  filteredItems,
  query,
  renderCreateItem,
  renderItem,
}) => {
  const hasItemsToShow = filteredItems.length > 0 || query !== ""

  return hasItemsToShow ? (
    <Menu className={css.suggestMenu}>
      {renderCreateItem()}
      {filteredItems.map((item) => renderItem(item))}
    </Menu>
  ) : (
    <></>
  )
}

const Suggest = ({ onItemSelect, defaultValue, fetcher, intent }) => {
  const [suggestions, setSuggestions] = useState([])
  const [query, setQuery] = useState(defaultValue)
  const [selectedItem, setSelectedItem] = useState({ value: defaultValue })

  const debouncedFetch = useDebouncedCallback((query) => {
    fetcher(query).then((items) => setSuggestions(items))
  }, 200)

  useEffect(() => {
    fetcher(query).then((items) => setSuggestions(items))
  }, [fetcher, query])

  const itemRenderer = (item, { handleClick, modifiers }) => {
    const text = item.value
    return (
      <MenuItem
        className={css.suggestMenuItem}
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={text}
        onClick={handleClick}
        text={text}
        shouldDismissPopover={false}
      />
    )
  }

  const inputValueRenderer = (item) => (item ? item.value : "")

  const onQueryChange = (query) => {
    debouncedFetch.callback(query)
    if (query === "") {
      setSelectedItem({ value: "" })
    }
    setQuery(query)
  }

  const createNewItemFromQuery = (query) => ({ value: query })

  const createNewItemRenderer = (query, active, onClick) => (
    <MenuItem
      className={css.suggestMenuItem}
      icon="plus"
      text={`Create "${query}"`}
      active={active}
      onClick={onClick}
      shouldDismissPopover={false}
    />
  )

  const itemsEqual = (a, b) => a.value.toLowerCase() === b.value.toLowerCase()

  const itemListRenderer = (props) => <ItemListRenderer {...props} />

  const onItemSelectInternal = (item) => {
    setSelectedItem(item)
    setQuery(item.value)
    onItemSelect(item)
  }

  const handleClear = () => {
    setSelectedItem({ value: "" })
    setQuery("")
    onItemSelect({ value: "" })
  }

  const onBlur = (event) => {
    onItemSelectInternal({ value: event.target.value })
  }

  const rightElement =
    selectedItem.value !== "" ? (
      <Button icon="cross" minimal onClick={handleClear} />
    ) : null

  return (
    <BlueprintSuggest
      items={suggestions}
      inputProps={{
        large: true,
        rightElement: rightElement,
        intent: intent,
        onBlur: onBlur,
      }}
      itemRenderer={itemRenderer}
      itemsEqual={itemsEqual}
      inputValueRenderer={inputValueRenderer}
      onItemSelect={onItemSelectInternal}
      onQueryChange={onQueryChange}
      query={query}
      itemPredicate={(query, item) =>
        item.value?.toLowerCase().includes(query.toLowerCase())
      }
      createNewItemFromQuery={createNewItemFromQuery}
      createNewItemRenderer={createNewItemRenderer}
      itemListRenderer={itemListRenderer}
      popoverProps={{ minimal: true }}
      selectedItem={selectedItem}
    />
  )
}

export default Suggest

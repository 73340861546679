import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  Button,
  Classes,
  Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Switch,
  Tag,
  Tooltip,
} from "@blueprintjs/core"
import EditReportRangePopover from "./EditReportRangePopover"
import {
  formatTimeDistance,
  formatMonthYearDate,
  dateFromYearAndMonth,
} from "./formatters"
import classnames from "classnames"

import popoverCss from "./popoverForm.module.css"
import css from "./ReportInfoTags.module.css"
import BusinessReportContext from "./BusinessReportContext"

export const periodToRangeLabels = (period) => {
  return {
    startLabel: formatMonthYearDate(
      dateFromYearAndMonth(period.start_year, period.start_month).toISOString()
    ),
    endLabel: formatMonthYearDate(
      dateFromYearAndMonth(period.end_year, period.end_month).toISOString()
    ),
  }
}

export const RefreshedAtTag = ({ refreshedAt }) =>
  refreshedAt && (
    <Tag minimal large>{`${formatTimeDistance(refreshedAt)} old`}</Tag>
  )

export const RangeTag = ({
  period,
  reportId,
  dispatch,
  disabledRangePicker,
}) => {
  const { startLabel, endLabel } = periodToRangeLabels(period)

  const onRangeUpdateSuccess = useCallback(
    (data) => {
      dispatch({ type: "updateReport", attributes: data })
    },
    [dispatch]
  )

  const renderTargetTag = () => (
    <Button
      minimal
      disabled={disabledRangePicker}
      icon={!disabledRangePicker && <Icon icon="edit" iconSize={10} />}
      intent={Intent.NONE}
    >
      {`${startLabel} - ${endLabel}`}
    </Button>
  )

  return (
    <EditReportRangePopover
      onUpdateSuccess={onRangeUpdateSuccess}
      reportId={reportId}
      period={period}
      disabled={disabledRangePicker}
      target={renderTargetTag()}
    />
  )
}

export const SettingsTag = ({ onSettingsChange }) => {
  const { settings } = useContext(BusinessReportContext)

  const [repeatable, setRepeatable] = useState(settings.repeatable)
  const [autoRefresh, setAutoRefresh] = useState(settings.auto_refresh)
  const [subtractForeverDiscount, setSubtractForeverDiscount] = useState(
    settings.subtract_forever_discount_from_mrr
  )
  const [subtractFiniteDiscount, setSubtractFiniteDiscount] = useState(
    settings.subtract_finite_discount_from_mrr
  )
  const [displayQuarters, setDisplayQuarters] = useState(
    settings.display_quarters
  )

  useEffect(() => {
    setRepeatable(settings.repeatable)
  }, [settings.repeatable])

  useEffect(() => {
    setAutoRefresh(settings.auto_refresh)
  }, [settings.auto_refresh])

  useEffect(() => {
    setDisplayQuarters(settings.display_quarters)
  }, [settings.display_quarters])

  useEffect(() => {
    setSubtractFiniteDiscount(settings.subtract_finite_discount_from_mrr)
  }, [settings.subtract_finite_discount_from_mrr])

  useEffect(() => {
    setSubtractForeverDiscount(settings.subtract_forever_discount_from_mrr)
  }, [settings.subtract_forever_discount_from_mrr])

  const renderTargetTag = () => (
    <Button minimal icon={<Icon icon="cog" />} intent={Intent.NONE}></Button>
  )

  const handleRepeatableChange = (event) => setRepeatable(event.target.checked)
  const handleAutorefreshChange = (event) =>
    setAutoRefresh(event.target.checked)
  const handleDisplayQuartersChange = (event) =>
    setDisplayQuarters(event.target.checked)
  const handleSubtractFiniteDiscountsChange = (event) =>
    setSubtractFiniteDiscount(event.target.checked)
  const handleSubtractForeverDiscountChange = (event) =>
    setSubtractForeverDiscount(event.target.checked)

  const onSubmit = () => {
    if (
      repeatable !== settings.repeatable ||
      autoRefresh !== settings.auto_refresh ||
      displayQuarters !== settings.displayQuarters
    ) {
      onSettingsChange({
        repeatable,
        auto_refresh: autoRefresh,
        display_quarters: displayQuarters,
        subtract_finite_discount_from_mrr: subtractFiniteDiscount,
        subtract_forever_discount_from_mrr: subtractForeverDiscount,
      })
    }
  }

  const onCancel = () => {
    setRepeatable(settings.repeatable)
    setAutoRefresh(settings.auto_refresh)
    setDisplayQuarters(settings.display_quarters)
  }

  const renderSettingsContent = () => (
    <div className={classnames(popoverCss.popover, popoverCss.widthAuto)}>
      <div className={popoverCss.popoverBody}>
        <div className={css.switchGroup}>
          <Switch
            label="Create automatically"
            onChange={handleRepeatableChange}
            large
            checked={repeatable}
          />
          <Tooltip
            position={Position.TOP}
            openOnTargetFocus={false}
            popoverClassName={css.infoTooltip}
            content={
              "When enabled a new report will be created automatically on the first day of each month. It will be based on the current report. The report's range will be shifted one month forward."
            }
          >
            <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
          </Tooltip>
        </div>
        <div className={css.switchGroup}>
          <Switch
            label="Refresh automatically"
            onChange={handleAutorefreshChange}
            large
            checked={autoRefresh}
          />
          <Tooltip
            position={Position.TOP}
            openOnTargetFocus={false}
            popoverClassName={css.infoTooltip}
            content={
              "When enabled we will automatically refresh this report once a day. Auto-refresh will be disabled automatically for reports that does not show current month."
            }
          >
            <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
          </Tooltip>
        </div>
        <div className={css.switchGroup}>
          <Switch
            label="Display quarterly"
            onChange={handleDisplayQuartersChange}
            large
            checked={displayQuarters}
          />
          <Tooltip
            position={Position.TOP}
            openOnTargetFocus={false}
            popoverClassName={css.infoTooltip}
            content={
              "When enabled report will be shown in quarter resolution. Some sections do not support quarters and they will be disabled."
            }
          >
            <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
          </Tooltip>
        </div>
        <div className={css.switchGroup}>
          <Switch
            label="Subtract recurring discounts from MRR"
            onChange={handleSubtractForeverDiscountChange}
            large
            checked={subtractForeverDiscount}
          />
          <Tooltip
            position={Position.TOP}
            openOnTargetFocus={false}
            popoverClassName={css.infoTooltip}
            content={
              "When enabled all discounts that are applied forever will be subtracted from MRR."
            }
          >
            <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
          </Tooltip>
        </div>
        <div className={css.switchGroup}>
          <Switch
            label="Subtract one-time discounts from MRR"
            onChange={handleSubtractFiniteDiscountsChange}
            large
            checked={subtractFiniteDiscount}
          />
          <Tooltip
            position={Position.TOP}
            openOnTargetFocus={false}
            popoverClassName={css.infoTooltip}
            content={
              "When enabled all one-time discounts will be subtracted from MRR."
            }
          >
            <Icon icon="info-sign" iconSize={14} className={css.infoSign} />
          </Tooltip>
        </div>
      </div>
      <div className={popoverCss.actions}>
        <Button
          outlined
          intent={Intent.PRIMARY}
          text="Save"
          className={Classes.POPOVER_DISMISS}
          onClick={onSubmit}
        />
        <Button
          className={Classes.POPOVER_DISMISS}
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={onCancel}
        />
      </div>
    </div>
  )

  return (
    <Popover
      onClose={onCancel}
      target={renderTargetTag()}
      content={renderSettingsContent()}
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
      boundary="window"
      position={Position.BOTTOM}
    />
  )
}

import React from "react"
import { Callout } from "@blueprintjs/core"
import css from "./ApiErrorCallout.module.css"

const ApiErrorCallout = ({ messages }) => {
  return (
    <Callout intent="danger" icon={null}>
      <ul className={css.container}>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </Callout>
  )
}

export default ApiErrorCallout

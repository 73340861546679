import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import { UseFormReturn, useForm } from "react-hook-form"
import {
  H3,
  Label,
  HTMLSelect,
  Drawer,
  Classes,
  Button,
  Intent,
  Card,
  H5,
  InputGroup,
  Icon,
  Tooltip,
  Position,
} from "@blueprintjs/core"
import { Section } from "../Content"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import { CardsLoadingState } from "../NonIdealState"
import CurrencyRatesForm from "./CurrencyRatesForm"

import formCss from "../forms.module.css"

import css from "./AccountSettings.module.css"
import { formatTime, jsDateToISOString } from "../formatters"
import parseISO from "date-fns/parseISO"

export type AccountSettingsFormType = {
  currency: string
  anonymous_access_allowed: number
  churn_window_seconds: number
  take_exchange_rates_from:
    | "subscription_started_at"
    | "beginning_of_month"
    | "account"
  account_exchange_rates: Types.Api.Account["account_exchange_rates"]
  match_movements_by_currency: string
  corrections_handling: Types.Api.Account["corrections_handling"]
}

type AccountSettingsFormProps =
  InjectedHookHelpersProps<AccountSettingsFormType> & {
    useFormMethods: UseFormReturn<AccountSettingsFormType>
    account: Types.Api.Account
  }

const AccountSettingsForm = withHookFormHelpers(
  ({
    useFormMethods,
    validationErrorMsg,
    fieldIntent,
    account,
  }: AccountSettingsFormProps) => {
    const {
      register,
      formState: { errors },
      watch,
    } = useFormMethods
    const [currencies, setCurrencies] = useState<string[]>([account.currency])
    const userContext = useContext(UserContext)
    const takeRatesFrom = [
      { label: "Subscription started at", value: "subscription_started_at" },
      { label: "First day of the month", value: "beginning_of_month" },
      { label: "Manually specified", value: "account" },
    ]
    const matchMovementsByCurrency = [
      {
        label: "Show exchange rates differences in contraction / expansion",
        value: "false",
      },
      {
        label: "Remove exchange rates differences from contraction / expansion",
        value: "true",
      },
    ]
    const correctionsHandling = [
      {
        label: "Change original invoice when correction invoice is applied",
        value: "change_original",
      },
      {
        label: "Apply correction invoices to the month of correction",
        value: "when_occurred",
      },
    ]
    const watchTakeRatesFrom = watch("take_exchange_rates_from")

    const fetchAllowedCurrencies = () => {
      userContext
        .fetch("/currencies", {
          method: "GET",
        })
        .then((data) => {
          setCurrencies(data.items)
        })
        .catch((error) => {
          console.warn(error)
          setCurrencies([account.currency])
        })
    }

    const renderChurnWindowHelp = () => (
      <Tooltip
        position={Position.TOP}
        openOnTargetFocus={false}
        popoverClassName={css.helpPopover}
        content={
          "Probe will treat customers with overdue payments as paying customers for the specified number of days. They will be included into churn only when overdue is bigger than specified churn window. Initial expected payment date will be used as a churn date."
        }
      >
        <Icon icon="info-sign" iconSize={14} />
      </Tooltip>
    )

    useEffect(fetchAllowedCurrencies, [userContext, account.currency])

    const currencyField = register("currency", { required: "Required" })
    const accessField = register("anonymous_access_allowed", {
      required: "Required",
      valueAsNumber: true,
    })
    const churnWindowField = register("churn_window_seconds", {
      required: "Required",
      pattern: {
        value: /^\d*$/,
        message: "Value should be a number ex. 12",
      },
    })
    const ratesFromField = register("take_exchange_rates_from", {
      required: "Required",
    })
    const matchMovementsField = register("match_movements_by_currency", {
      required: "Required",
    })
    const correctionsHandlingField = register("corrections_handling", {
      required: "Required",
    })

    const possibleCurrencies = useMemo(() => {
      if (
        (account.used_currencies.length === 1 &&
          account.used_currencies[0] === account.currency) ||
        account.used_currencies.length === 0
      ) {
        return ["usd", "eur", "rub"].filter(
          (iso: string) => iso !== account.currency
        )
      }

      return account.used_currencies
    }, [account])

    return (
      <div className={formCss.insideDrawer}>
        <div className={formCss.body}>
          <H3 className={formCss.title}>Account</H3>
          <Label>
            Default account currency
            <HTMLSelect
              name="currency"
              large
              elementRef={currencyField.ref}
              onChange={currencyField.onChange}
              onBlur={currencyField.onBlur}
              options={currencies}
            />
            {validationErrorMsg(errors, "currency")}
          </Label>
          <Label>
            Account data access
            <HTMLSelect
              name="anonymous_access_allowed"
              large
              elementRef={accessField.ref}
              onChange={accessField.onChange}
              onBlur={accessField.onBlur}
              options={[
                { label: "Users must log in to access my reports", value: 0 },
                {
                  label: "Anyone with secret url can access my reports",
                  value: 1,
                },
              ]}
            />
            {validationErrorMsg(errors, "anonymous_access_allowed")}
          </Label>
          <Label>
            <div className={css.labelWithHelp}>
              Churn window (days)&nbsp;{renderChurnWindowHelp()}
            </div>
            <InputGroup
              fill
              large
              name="churn_window_seconds"
              intent={fieldIntent(errors, "churn_window_seconds")}
              inputRef={churnWindowField.ref}
              onChange={churnWindowField.onChange}
              onBlur={churnWindowField.onBlur}
            />
            {validationErrorMsg(errors, "churn_window_seconds")}
          </Label>
          <Label>
            Correction invoices
            <HTMLSelect
              name={correctionsHandlingField.name}
              large
              elementRef={correctionsHandlingField.ref}
              onChange={correctionsHandlingField.onChange}
              onBlur={correctionsHandlingField.onBlur}
              options={correctionsHandling}
            />
            {validationErrorMsg(errors, "take_exchange_rates_from")}
          </Label>
          <Label>
            Exchange rates differences in contraction / expansion
            <HTMLSelect
              name={matchMovementsField.name}
              large
              elementRef={matchMovementsField.ref}
              onChange={matchMovementsField.onChange}
              onBlur={matchMovementsField.onBlur}
              options={matchMovementsByCurrency}
            />
            {validationErrorMsg(errors, "take_exchange_rates_from")}
          </Label>
          <Label>
            Exchange rates
            <HTMLSelect
              name="take_exchange_rates_from"
              large
              elementRef={ratesFromField.ref}
              onChange={ratesFromField.onChange}
              onBlur={ratesFromField.onBlur}
              options={takeRatesFrom}
            />
            {validationErrorMsg(errors, "take_exchange_rates_from")}
          </Label>
          {watchTakeRatesFrom === "account" && (
            <CurrencyRatesForm
              useFormMethods={useFormMethods}
              usedCurrencies={possibleCurrencies}
              accountCurrency={account.currency}
            />
          )}
        </div>
      </div>
    )
  }
)

type AccountSettingsDrawerProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (formData: AccountSettingsFormType) => void
  account: Types.Api.Account
}

const AccountSettingsDrawer = ({
  isOpen,
  onClose,
  onSubmit,
  account,
}: AccountSettingsDrawerProps) => {
  const secondsToDays = useCallback(
    (seconds: number) => Math.round(seconds / 60 / 60 / 24),
    []
  )

  const defaultFormValues = useMemo(() => {
    return {
      ...account,
      anonymous_access_allowed: account.anonymous_access_allowed ? 1 : 0,
      churn_window_seconds: secondsToDays(account.churn_window_seconds),
      account_exchange_rates: account.account_exchange_rates.map((rate) => ({
        ...rate,
        since: parseISO(rate.since as string),
      })),
      match_movements_by_currency:
        account.match_movements_by_currency.toString(),
    }
  }, [account, secondsToDays])

  const useFormMethods = useForm<AccountSettingsFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
  })

  const { trigger, formState, getValues, reset, setError } = useFormMethods

  const onFormSave = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()

        if (
          values.take_exchange_rates_from === "account" &&
          (!values.account_exchange_rates ||
            values.account_exchange_rates.length === 0)
        ) {
          setError(`account_exchange_rates`, {
            type: "custom",
            message: "At least one row of exchange rates must be provided",
          })
          return
        }

        const finalValues = {
          ...values,
          account_exchange_rates: values.account_exchange_rates.map((rate) => ({
            ...rate,
            since: formatTime(
              jsDateToISOString(rate.since as Date),
              "yyyy-MM-dd"
            ),
          })),
        }

        onSubmit(finalValues)
        reset({
          ...values,
        })
        onClose()
      }
    })
  }

  const onDrawerClose = () => {
    reset(defaultFormValues)
    onClose()
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="50%"
      onClose={onDrawerClose}
      title="Account settings"
    >
      <div className={Classes.DRAWER_BODY}>
        <AccountSettingsForm
          useFormMethods={useFormMethods}
          account={account}
        />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            disabled={!formState.isValid}
            text="Save"
            outlined
            onClick={onFormSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onDrawerClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

type AccountCardProps = {
  account: Types.Api.Account
  onClick: () => void
}

const AccountCard = ({ account, onClick }: AccountCardProps) => (
  <Card interactive onClick={onClick} className={css.accountCard}>
    <H5>Account</H5>
    <span>
      Currency: <b>{account.currency}</b>
    </span>
  </Card>
)

const AccountSettings = () => {
  const daysToSeconds = (days: number) => days * 24 * 60 * 60
  const [formOpen, setFormOpen] = useState(false)
  const userContext = useContext(UserContext)
  const [account, setAccount] = useState<Types.Api.Account>()
  const [accountLoading, setAccountLoading] = useState(false)

  const fetchAccount = () => {
    setAccountLoading(true)
    userContext
      .fetch("/account_settings", { method: "GET" })
      .then((data) => setAccount(data))
      .catch(() =>
        AppToaster.showError({
          message: "We could not fetch your account settings",
        })
      )
      .finally(() => setAccountLoading(false))
  }

  useEffect(fetchAccount, [userContext])

  const onClose = () => setFormOpen(false)
  const openForm = () => setFormOpen(true)

  const onSaveAccountSettings = (formData: AccountSettingsFormType) => {
    userContext
      .fetch("/account_settings", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...formData,
          anonymous_access_allowed:
            formData.anonymous_access_allowed === 1 ? true : false,
          churn_window_seconds: daysToSeconds(formData.churn_window_seconds),
          match_movements_by_currency:
            formData.match_movements_by_currency === "true" ? true : false,
        }),
      })
      .then((data) => setAccount(data))
      .catch(() =>
        AppToaster.showError({
          message: "We could not save your changed, please try again",
        })
      )
  }

  return (
    <Section
      title="Account"
      subtitle="Select your default currency and data access level"
    >
      {account && !accountLoading && (
        <div className={css.accountSettings}>
          <AccountSettingsDrawer
            account={account}
            isOpen={formOpen}
            onClose={onClose}
            onSubmit={onSaveAccountSettings}
          />
          <AccountCard account={account} onClick={openForm} />
        </div>
      )}
      {accountLoading && <CardsLoadingState />}
    </Section>
  )
}

export default AccountSettings

import React, { useEffect, useMemo, useRef } from "react"
import {
  Button,
  Classes,
  Drawer,
  H3,
  InputGroup,
  Intent,
  Label,
  ControlGroup,
} from "@blueprintjs/core"
import classnames from "classnames"
import {
  useForm,
  useFieldArray,
  Controller,
  UseFormReturn,
} from "react-hook-form"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import NumberRangeSelector from "../NumberRangeSelectorForm"

import css from "./SettingsSegmenters.module.css"
import formsCss from "../forms.module.css"
import { Definition } from "../api/SegmenterDefinition"
import SelectValue, { ValueObject } from "../components/SelectValue"
import useAttributePossibleValues from "../api/useAttributePossibleValues"

type ProductSelectorFormProps = InjectedHookHelpersProps<Definition> & {
  useFormMethods: UseFormReturn<Definition>
}

const ProductSelectorForm = withHookFormHelpers(
  ({ useFormMethods, validationErrorMsg }: ProductSelectorFormProps) => {
    const {
      control,
      formState: { errors },
      watch,
    } = useFormMethods
    const productValues = useAttributePossibleValues(
      "subscription",
      "product_key"
    )

    const selectedProduct = watch("products")

    const possibleProducts = useMemo(
      () => productValues.map((item) => ({ label: item, value: item })),
      [productValues]
    )

    const findActiveItem = () =>
      possibleProducts.find((item) => item.value === selectedProduct?.at(0))

    const onProductSelect = (field: any, item: ValueObject) => {
      field.onChange([item.value])
    }

    const onProductRemove = (field: any) => field.onChange(undefined)

    const renderProductSelect = ({ field }: any) => (
      <SelectValue
        activeItem={findActiveItem()}
        items={possibleProducts}
        onItemSelect={(item: any) => onProductSelect(field, item)}
        labelKey="label"
        searchBy={["label"]}
        placeholder="Select product..."
        onRemove={() => onProductRemove(field)}
      />
    )

    return (
      <>
        <H3 className={formsCss.title}>Had product in lifecycle</H3>
        <p>
          This type of segmentation allows you to split you customer base
          depending on if they bought a selected product from you.
        </p>
        <div className={formsCss.body}>
          <div className={formsCss.fieldsRow}>
            <Controller
              control={control}
              name="products"
              render={renderProductSelect}
              rules={{
                required:
                  "You must select a product to create this segmentation",
              }}
            />
          </div>
          <div>{validationErrorMsg(errors, "products")}</div>
        </div>
      </>
    )
  }
)

type SegmenterFormMappingsProps = InjectedHookHelpersProps<Definition> & {
  prefillOptions: string[]
  useFormMethods: UseFormReturn<Definition>
}

const SegmenterFormMappingsForm = withHookFormHelpers(
  ({
    prefillOptions,
    useFormMethods,
    validationErrorMsg,
    fieldIntent,
  }: SegmenterFormMappingsProps) => {
    const {
      control,
      register,
      formState: { errors },
    } = useFormMethods
    const { fields, append, remove } = useFieldArray({
      control,
      name: "internalMappings",
    })

    useEffect(() => {
      prefillOptions.forEach((key) => {
        if (
          !fields.find(
            (field) => field.valueKey.toLowerCase() === key.toLowerCase()
          )
        ) {
          append({ valueKey: key, valueValue: "" })
        }
      })
    }, [prefillOptions, append, fields])

    const renderValueMapping = (field: any, index: number) => {
      const existingValue = prefillOptions.indexOf(field.valueKey) !== -1

      const valueKeyField = register(`internalMappings.${index}.valueKey`, {
        required: "Required",
      })

      const valueField = register(`internalMappings.${index}.valueValue`)
      return (
        <>
          <div className={formsCss.fieldsRow}>
            <div className={formsCss.fieldsRowActionButton}>
              <Button
                icon="trash"
                minimal
                onClick={() => remove(index)}
                intent={Intent.PRIMARY}
                disabled={existingValue}
              />
            </div>
            <ControlGroup fill>
              <Label>
                {index === 0 && "Existing value"}
                <InputGroup
                  id={field.id}
                  intent={fieldIntent(
                    errors,
                    `internalMappings.${index}.valueKey`
                  )}
                  name={`internalMappings.${index}.valueKey`}
                  key={`${index}.left`}
                  defaultValue={field.valueKey}
                  inputRef={valueKeyField.ref}
                  onChange={valueKeyField.onChange}
                  onBlur={valueKeyField.onBlur}
                  disabled={existingValue}
                />
              </Label>
              <Label>
                {index === 0 && "Target value"}
                <InputGroup
                  id={`internalMappings.${index}.valueValue`}
                  name={`internalMappings.${index}.valueValue`}
                  key={`${index}.right`}
                  defaultValue={field.valueValue}
                  inputRef={valueField.ref}
                  onChange={valueField.onChange}
                  onBlur={valueField.onBlur}
                />
              </Label>
            </ControlGroup>
          </div>
          <div className={css.validationMsg}>
            {validationErrorMsg(errors, `internalMappings.${index}.valueKey`)}
          </div>
        </>
      )
    }

    return (
      <>
        <H3 className={formsCss.title}>Segment values mapping</H3>
        <p>
          Map existing segment values to new strings in data visualization. You
          can map several values to the same string if you want to group them.
        </p>
        <div className={formsCss.body}>
          {fields.map((field, index) => renderValueMapping(field, index))}
          <div
            className={classnames(formsCss.fieldsRow, formsCss.justifyFlexEnd)}
          >
            <div className={formsCss.fieldsRowActionButton}>
              <Button
                icon="add"
                text="Add mapping"
                minimal
                onClick={() => append({ valueKey: "", valueValue: "" })}
                intent={Intent.PRIMARY}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
)

type SegmenterFormNameProps = {
  control: any
  nameError: string | undefined
}

const SegmenterFormName = ({ control, nameError }: SegmenterFormNameProps) => (
  <ControlGroup fill>
    <Label>
      Segmentation name
      <Controller
        control={control}
        name="name"
        rules={{
          required: {
            value: true,
            message: "Name is required",
          },
          minLength: {
            value: 3,
            message: "At least 3 characters required",
          },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <InputGroup
            placeholder="Name"
            value={value}
            onChange={(event) => onChange(event.target.value)}
            onBlur={onBlur}
            name="name"
            autoFocus={true}
            intent={nameError ? Intent.DANGER : Intent.NONE}
            inputRef={ref}
            fill
            large
            autoComplete="off"
          />
        )}
      ></Controller>
      {nameError && <p className={formsCss.errorLabel}>{nameError}</p>}
    </Label>
  </ControlGroup>
)

type SegmenterFormProps = {
  useFormMethods: UseFormReturn<Definition>
  segmenterTypes: Types.Api.SegmenterType[]
}

const SegmenterForm = ({
  useFormMethods,
  segmenterTypes,
}: SegmenterFormProps) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormMethods

  const targetRef = useRef<any>(null)
  const allowedConfiguration = watch("allowed_configuration")
  const typeName = watch("type_name")
  const segmentId = watch("segment_id")

  const findActiveItem = () => {
    return segmenterTypes.find((item) => {
      if (item.segment_id) {
        return item.segment_id === segmentId
      }

      return item.name === typeName
    })
  }

  const activeItemAttributeName = () => {
    const item = findActiveItem()

    if (!item || !item.allowed_configuration.includes("mappings")) return

    if (item.segment_id) {
      return item.label
    }

    return item.name
  }

  const activeItemObjectName = () => {
    const item = findActiveItem()

    if (!item || !item.allowed_configuration.includes("mappings")) return

    return item.group_label.toLowerCase()
  }

  const attributeValues = useAttributePossibleValues(
    activeItemObjectName(),
    activeItemAttributeName()
  )

  const onSegmentAttributeSelect = (field: any, item: any) => {
    field.onChange(item.name)
    setValue("allowed_configuration", item.allowed_configuration)
    if (item.segment_id) {
      setValue("segment_id", item.segment_id)
    }
  }

  const renderSegmentAttributeSelect = ({ field }: any) => (
    <SelectValue
      activeItem={findActiveItem()}
      items={segmenterTypes as ValueObject[]}
      onItemSelect={(item: any) => onSegmentAttributeSelect(field, item)}
      labelKey="label"
      searchBy={["group_label", "label"]}
      groupBy="group_label"
      targetRef={targetRef}
      placeholder="Select segmentation attribute"
    />
  )

  return (
    <div className={formsCss.insideDrawer}>
      <H3 className={formsCss.title}>Segmentation</H3>
      <p>
        You can base your data segmentation on any attribute that is avaliable
        on payment, customer or cost. Some attributes allow you to specify a
        mapping between values or group values together with ranges.
      </p>
      <div className={formsCss.body}>
        <div className={formsCss.fieldsRow}>
          <SegmenterFormName
            control={control}
            nameError={errors?.name?.message}
          />
        </div>
        <div className={formsCss.fieldsRow}>
          <Controller
            control={control}
            name="type_name"
            render={renderSegmentAttributeSelect}
            rules={{ required: "Attribute is required" }}
          />
        </div>
      </div>
      {allowedConfiguration?.includes("mappings") && (
        <SegmenterFormMappingsForm
          useFormMethods={useFormMethods}
          prefillOptions={attributeValues || []}
        />
      )}
      {allowedConfiguration?.includes("buckets") && (
        <NumberRangeSelector useFormMethods={useFormMethods} />
      )}
      {allowedConfiguration?.includes("products") && (
        <ProductSelectorForm useFormMethods={useFormMethods} />
      )}
    </div>
  )
}

type SegmenterDrawerFormProps = {
  isOpen: boolean
  defaultValues?: Definition
  onClose: () => void
  onSubmit: (segmenter: Definition, formReset: () => void) => void
  segmenterTypes: Types.Api.SegmenterType[]
}

const SegmenterDrawerForm = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  segmenterTypes,
}: SegmenterDrawerFormProps) => {
  const useFormMethods = useForm<Definition>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  })

  const emptyDefinition = useMemo(
    () => ({
      internalBuckets: [],
      internalMappings: [],
      name: "",
      mappings: {},
      buckets: [],
      segment_id: null,
      allowed_configuration: [],
    }),
    []
  )

  const { trigger, getValues, reset } = useFormMethods

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues)
    } else {
      reset(emptyDefinition)
    }
  }, [defaultValues, reset, emptyDefinition])

  const handleSubmit = () => {
    trigger().then((success) => {
      if (success) {
        onSubmit(getValues(), () => reset(emptyDefinition))
      }
    })
  }

  const onCancel = () => {
    reset(emptyDefinition)
    onClose()
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="40%"
      onClose={onClose}
      title={defaultValues?.id ? defaultValues.name : "New segmentation"}
      enforceFocus={false}
    >
      <div className={Classes.DRAWER_BODY}>
        <SegmenterForm
          useFormMethods={useFormMethods}
          segmenterTypes={segmenterTypes}
        />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formsCss.actions}>
          <Button
            disabled={!useFormMethods.formState.isValid}
            text={defaultValues?.id ? "Save" : "Create"}
            outlined
            onClick={handleSubmit}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onCancel}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

export default SegmenterDrawerForm

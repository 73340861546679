import numeral from "numeral"
import {
  formatCurrency,
  isoTimeToUnixSeconds,
  unixSecondsToString,
  formatTime,
  formatPercent,
} from "./formatters"
import { capitalize } from "./textHelpers"
import fromUnixTime from "date-fns/fromUnixTime"

Object.assign(numeral.localeData("en"), {
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
})
numeral.locale("en")

const replaceUnderscore = (text: string) => {
  return text.replace(/_/g, " ")
}

const TOP_SEGMENTS_COUNT = 3

const normalizeLabelOther = (segmentNames: string[]) => {
  const topSegments = segmentNames.slice(0, TOP_SEGMENTS_COUNT) // Top 3 segments
  const countPart =
    segmentNames.length > TOP_SEGMENTS_COUNT
      ? " and " +
        (segmentNames.length - TOP_SEGMENTS_COUNT).toString() +
        " more"
      : ""

  return "Other (" + topSegments.join(", ") + countPart + ")"
}

const normalizeName = (name: string | string[], shortOther?: boolean) => {
  if (Array.isArray(name)) {
    if (shortOther) {
      return "Other"
    } else {
      return normalizeLabelOther(name.map(replaceUnderscore).map(capitalize))
    }
  } else {
    return replaceUnderscore(capitalize(name))
  }
}

const mergeSeriesAsSegments = (
  series: Types.Api.Serie[]
): ChartSegmentData[] => {
  const groupedByTime = {} as any
  series.forEach((serie) => {
    serie.data_points.forEach((dataPoint) => {
      if (!groupedByTime[dataPoint.time])
        groupedByTime[dataPoint.time] = {
          values: {},
          counts: {},
          quantities: {},
        }
      groupedByTime[dataPoint.time].values[serie.name] = dataPoint.values.total
      if (dataPoint.counts && dataPoint.counts.total)
        groupedByTime[dataPoint.time].counts[serie.name] =
          dataPoint.counts.total

      if (dataPoint.quantities && dataPoint.quantities.total)
        groupedByTime[dataPoint.time].quantities[serie.name] =
          dataPoint.quantities.total
    })
  })

  const artificialDatapointsWithoutTime = Object.entries(groupedByTime)
    .sort((a: any, b: any) => a[0] - b[0])
    .map((entry) => {
      const [time, dataPoint]: any = entry
      return {
        time,
        values: dataPoint.values,
        counts: dataPoint.counts,
        quantities: dataPoint.quantities,
      }
    })

  return buildChartSegmentData(artificialDatapointsWithoutTime)
}

const calculateMonthOverMonthRetention = (
  previousDataPoint: Types.Api.DataPoint | null,
  dataPoint: Types.Api.DataPoint
) => {
  const segmentValues = Object.keys(dataPoint.values)
  const monthOverMonth: { [key: string]: number } = {}

  segmentValues.forEach((segmentValue) => {
    if (!previousDataPoint) {
      return
    }

    const previousValue = previousDataPoint.values[segmentValue]
    if (previousValue === 0) {
      monthOverMonth[segmentValue] = 0.0
      return
    }

    monthOverMonth[segmentValue] =
      (dataPoint.values[segmentValue] / previousValue) * 100.0
  })

  return monthOverMonth
}

const calculateMonthOverMonth = (
  previousDataPoint: Types.Api.DataPoint | null,
  dataPoint: Types.Api.DataPoint
) => {
  const segmentValues = Object.keys(dataPoint.values)
  const monthOverMonth: { [key: string]: number } = {}

  segmentValues.forEach((segmentValue) => {
    if (!previousDataPoint) {
      return
    }

    const previousValue = previousDataPoint.values[segmentValue]
    if (previousValue === 0) {
      monthOverMonth[segmentValue] = 0.0
      return
    }

    monthOverMonth[segmentValue] =
      ((dataPoint.values[segmentValue] - previousValue) / previousValue) * 100.0
  })

  return monthOverMonth
}

const calculate3mMovingAverage = (
  dataPoints: Types.Api.DataPoint[],
  dataPoint: Types.Api.DataPoint,
  index: number
) => {
  const moving_average_size = 3

  const segmentValues = Object.keys(dataPoint.values)
  const movingAverage: { [key: string]: number } = {}

  const pointsInAverage =
    index + 1 < moving_average_size
      ? []
      : dataPoints.slice(index - (moving_average_size - 1), index + 1)

  segmentValues.forEach((segmentValue) => {
    if (pointsInAverage.length === 0) {
      movingAverage[segmentValue] = NaN
      return
    }

    movingAverage[segmentValue] =
      pointsInAverage
        .map((point) => point.values[segmentValue])
        .reduce((point, sum) => sum + point, 0) / moving_average_size
  })

  return movingAverage
}

const buildChartSegmentData = (
  dataPoints: Types.Api.DataPoint[]
): ChartSegmentData[] =>
  dataPoints.map((dataPoint, index) => ({
    ...dataPoint,
    name: isoTimeToUnixSeconds(dataPoint.time),
    month_over_month: calculateMonthOverMonth(
      index > 0 ? dataPoints[index - 1] : null,
      dataPoint
    ),
    month_over_month_retention: calculateMonthOverMonthRetention(
      index > 0 ? dataPoints[index - 1] : null,
      dataPoint
    ),
    moving_average: calculate3mMovingAverage(dataPoints, dataPoint, index),
  }))

const convertApiDataToCohort = (
  dataPoints: any[],
  segmentValue: string,
  category: Types.Api.DataCategory["category"]
) =>
  dataPoints.reduce((map, dataPoint) => {
    let values = []
    switch (category) {
      case "values":
        values = dataPoint.values
        break
      case "counts":
        values = dataPoint.counts
        break
      case "units":
        values = dataPoint.quantities
        break
      default:
        console.error(`Unknown category: ${category}`)
    }

    if (!values[segmentValue]) {
      return {}
    }

    map[formatTime(dataPoint.time, "MMM yy")] = values[segmentValue].map(
      (value: number) => Math.round(value)
    )
    return map
  }, {})

export const tooltipFormatterFactory = (options: {
  useDecimal?: boolean
  groupedSegmentNames?: string[]
  category: Types.Api.DataCategory["category"]
  withPercent?: boolean
}) => {
  const formatterOptions = options.useDecimal
    ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    : { maximumFractionDigits: 0 }
  return (value: any, name: string, props: any) => {
    let percentPart = ""

    if (options.withPercent && props) {
      const cattegoryAttrName = getCategoryAttributeName(options.category)
      const entries = Object.entries(props.payload[cattegoryAttrName])

      if (entries.length > 1) {
        const total = entries.reduce((sum, [, value]: any) => {
          return sum + value
        }, 0)
        percentPart =
          total > 0 ? " (" + formatPercent((value / total) * 100) + ")" : ""
      }
    }

    const shortenOther = true
    const nameOrNames = name === "_grouped" ? options.groupedSegmentNames : name
    const finalName = nameOrNames
      ? normalizeName(nameOrNames, shortenOther)
      : nameOrNames
    return [formatCurrency(value, formatterOptions) + percentPart, finalName]
  }
}

export const getTooltipLabelFormatter = (
  resolution: Types.Api.Section["resolution"]
) => {
  if (resolution === "quarter") {
    return (unixSeconds: number) => {
      return (
        getQuarterForUnixSeconds(unixSeconds) +
        " " +
        unixSecondsToString(unixSeconds, "yyyy")
      )
    }
  }

  if (resolution === "week") {
    return (unixSeconds: number) =>
      unixSecondsToString(unixSeconds, "'W'I MMM yyyy")
  }

  return (unixSeconds: number) => unixSecondsToString(unixSeconds, "MMM yyyy")
}

export const getXTickFormatter = (
  xDataPointsLength: number,
  resolution: Types.Api.Section["resolution"]
) => {
  if (resolution === "quarter") {
    return (value: number) => getQuarterForUnixSeconds(value)
  }

  if (resolution === "week") {
    return (value: number, index: number) =>
      xTickFormatter(value, index, "'W'I")
  }

  // For current Chart width Jan, Feb months format stop to fit on XAxis
  return xDataPointsLength <= 20
    ? xTickFormatter
    : (value: number, index: number) => xTickFormatter(value, index, "MM")
}
export const getQuarterForUnixSeconds = (value: number) => {
  const month = fromUnixTime(value).getUTCMonth()
  return getQuarterForMonth(month)
}

export const getQuarterForMonth = (month: number) => {
  const quarter = { 0: "Q1", 3: "Q2", 6: "Q3", 9: "Q4" }[month]

  return quarter || "??"
}

const xTickFormatter = (
  value: number | "auto",
  index: number,
  monthFormat = "MMM"
) => {
  if (value === "auto") {
    return ""
  } // recharts bug https://github.com/recharts/recharts/issues/2593

  const formatted = unixSecondsToString(value, monthFormat)

  if (formatted === "Jan") {
    return unixSecondsToString(value, "yyyy")
  } else {
    return formatted
  }
}

export const xTickValueToDate = (unixSeconds: number) =>
  unixSecondsToString(unixSeconds, "yyyy-MM-dd")

export const yTickFormatter = (value: number) =>
  numeral(value).format("0[.]0 a")

export const legendFormatter =
  (groupedSegmentNames: string[]) => (value: string) =>
    normalizeName(value === "_grouped" ? groupedSegmentNames : value)

export const extractSegmentAttrs = (
  section: Types.Api.Section,
  segmentIndex: number
) => {
  const segment = section.segments[segmentIndex]
  if (segment.series.length === 0) {
    return []
  } else if (segment.series.length > 1) {
    return segment.series.map((serie) => serie.name)
  }

  const dataPoints = section.segments[segmentIndex].series[0].data_points
  return dataPoints.length === 0 ? [] : Object.keys(dataPoints[0].values)
}

export const extractDataPointsRange = (dataPoints: Types.Api.DataPoint[]) => {
  if (!dataPoints || dataPoints.length === 0) {
    return {}
  }

  const startDate = dataPoints[0].time
  const endDate = dataPoints[dataPoints.length - 1].time
  return { startDate, endDate }
}

export const extractDetailed = (segment: Types.Api.SectionSegment) =>
  segment.series.length === 0 ? false : segment.series[0].detailed

export const extractSegmentId = (
  section: Types.Api.Section,
  segmentIndex: number
) => {
  return section.segments[segmentIndex].id
}

export const extractCohortData = (
  section: Types.Api.Section,
  segmentIndex: number,
  segmentValue: string,
  category: Types.Api.DataCategory["category"]
) => {
  const data =
    section.segments.length === 0
      ? {}
      : convertApiDataToCohort(
          section.segments[segmentIndex].series[0].data_points,
          segmentValue,
          category
        )
  const segmentId = section.segments[segmentIndex].id
  const { startDate, endDate } = extractDataPointsRange(
    section.segments[segmentIndex].series[0].data_points
  )
  return { data, segmentId, startDate, endDate }
}

export const isTotalSegment = (
  segment: ChartSegment | Types.Api.SectionSegment
) => segment.is_total

export const isTotalSegmentOnly = (section: Types.Api.Section) =>
  section.segments.length === 1 && isTotalSegment(section.segments[0])

const buildDataPointsForSerieValue = (
  section: Types.Api.Section,
  segment: Types.Api.SectionSegment,
  value: string
): ChartSegmentData[] => {
  const dataPoints = segment.series[0].data_points.map((dataPoint) => ({
    time: dataPoint.time,
    counts: {},
    values: {},
    quantities: {},
  }))
  segment.series.forEach((serie) => {
    serie.data_points.forEach((dataPoint, index) => {
      // @ts-ignore
      dataPoints[index].values[serie.name] = dataPoint.values[value]
      // @ts-ignore
      dataPoints[index].counts[serie.name] = dataPoint.counts[value]
      if (dataPoint.quantities) {
        // @ts-ignore
        dataPoints[index].quantities[serie.name] = dataPoint.quantities[value]
      }
    })
  })

  return buildChartSegmentData(dataPoints)
}

export const tabTitleFromValueInSegment = (
  value: string,
  segment: Types.Api.SectionSegment
) => {
  const displayValue = normalizeName(
    value === "_grouped" ? segment.grouped_segment_names : value
  )
  return segment.is_total ? "Total" : `By ${segment.name}: ${displayValue}`
}

const buildSegmentsFromSegmentedMultiseries = (
  section: Types.Api.Section,
  segment: Types.Api.SectionSegment
): ChartSegment[] => {
  if (segment.series.length === 0) return []

  const dataPoint = segment.series[0].serie_limited_data_points[0]
  if (!dataPoint) return []

  return segment.series.map((serie) => {
    return {
      id: `${segment.id}-${serie.name}`,
      segment_id: segment.id,
      section_id: section.id,
      segment_value: serie.name,
      grouped_segment_names: serie.grouped_segment_names,
      is_total: segment.is_total,
      title: tabTitleFromValueInSegment(serie.name, segment),
      data: buildChartSegmentData(serie.serie_limited_data_points),
      y_reference_line: segment.y_reference_line,
      multi_series: segment.series && segment.series.length > 1,
    }
  })
}

const buildSegmentsFromSegmentedMultiseriesUnnatural = (
  section: Types.Api.Section,
  segment: Types.Api.SectionSegment
): ChartSegment[] => {
  if (segment.series.length === 0) return []

  const dataPoint = segment.series[0].data_points[0]
  if (!dataPoint) return []

  const segmentValues = Object.keys(dataPoint.values)
  return segmentValues.map((value) => {
    const segmentValue =
      value === "_grouped" ? segment.grouped_segment_names : value
    return {
      id: `${segment.id}-${value}`,
      segment_id: segment.id,
      section_id: section.id,
      segment_value: segmentValue,
      grouped_segment_names: segment.grouped_segment_names,
      is_total: segment.is_total,
      title: tabTitleFromValueInSegment(value, segment),
      data: buildDataPointsForSerieValue(section, segment, value),
      y_reference_line: segment.y_reference_line,
      multi_series: segment.series && segment.series.length > 1,
    }
  })
}

const buildSegmentsFromTotalMultiseries = (section: Types.Api.Section) => {
  const segment = section.segments[0]
  const dataPoints = buildChartSegmentData(
    mergeSeriesAsSegments(segment.series)
  )
  return [
    {
      id: segment.id,
      segment_id: segment.id,
      section_id: section.id,
      segment_value: undefined,
      grouped_segment_names: segment.grouped_segment_names,
      title: "",
      is_total: segment.is_total,
      data: dataPoints,
      y_reference_line: segment.y_reference_line,
      multi_series: segment.series && segment.series.length > 1,
    },
  ]
}

type ChartSegmentData = {
  name: number
  time: string
  values: {
    [key: string]: number
  }
  counts: {
    [key: string]: number
  }
  quantities: {
    [key: string]: number
  }
  month_over_month: {
    [key: string]: number
  }
  month_over_month_retention: {
    [key: string]: number
  }
  moving_average: {
    [key: string]: number
  }
}

export type ChartSegment = {
  id: string
  section_id: string
  segment_id: string
  title: string
  grouped_segment_names: string[]
  segment_value?: string | string[]
  is_total: boolean
  data: ChartSegmentData[]
  y_reference_line?: number
  multi_series?: boolean
}

const buildSegmentsFromSingleseries = (
  section: Types.Api.Section
): ChartSegment[] => {
  return section.segments.map((segment) => {
    return buildSegmentFromApiSegment(section, segment)
  })
}

const buildSegmentFromApiSegment = (
  section: Types.Api.Section,
  segment: Types.Api.SectionSegment
): ChartSegment => {
  const dataPoints = segment.series[0] ? segment.series[0].data_points : []

  return {
    id: segment.id,
    segment_id: segment.id,
    section_id: section.id,
    segment_value: undefined,
    grouped_segment_names: segment.grouped_segment_names,
    title: segment.name,
    is_total: segment.is_total,
    data: buildChartSegmentData(dataPoints),
    y_reference_line: segment.y_reference_line,
    multi_series: segment.series && segment.series.length > 1,
  }
}

export const buildSegmentsFromSection = (
  section: Types.Api.Section
): ChartSegment[] => {
  const onlyTotalSegment = section.segments.every((segment) => segment.is_total)
  const hasManySeries = section.segments.some(
    (segment) => segment.series.length > 1
  )

  if (!onlyTotalSegment && hasManySeries) {
    return section.segments.flatMap((segment) => {
      if (segment.series.length > 1) {
        if (section.legacy_series) {
          return buildSegmentsFromSegmentedMultiseriesUnnatural(
            section,
            segment
          )
        } else {
          return buildSegmentsFromSegmentedMultiseries(section, segment)
        }
      } else {
        return [buildSegmentFromApiSegment(section, segment)]
      }
    })
  } else if (onlyTotalSegment && hasManySeries) {
    return buildSegmentsFromTotalMultiseries(section)
  } else {
    return buildSegmentsFromSingleseries(section)
  }
}

export const keysSortedByValue = (values: any) => {
  return Object.keys(values)
    .map((key) => [key, values[key]])
    .sort((left, right) => right[1] - left[1])
    .map((pair) => pair[0])
}

export const getCategoryAttributeName = (
  category: Types.Api.DataCategory["category"]
) => {
  switch (category) {
    case "values":
      return "values"
    case "counts":
      return "counts"
    case "units":
      return "quantities"
    case "month_over_month_growth":
      return "month_over_month"
    case "month_over_month_retention":
      return "month_over_month_retention"
    case "moving_average3":
      return "moving_average"
    default:
      throw new Error(`Unknown category: ${category}`)
  }
}

export const sectionSegmentSupportedInKeyNumbers = (
  section: Types.Api.Section,
  segmentId: string
) => {
  const segment = section.segments.find((segment) => segment.id === segmentId)

  if (!segment || !segment.is_total) return false

  if (!segment.series || segment.series.length > 1) return false

  return true
}

export const sortKeysIfNeeded = (
  sectionId: string,
  segmentId: ChartSegment["segment_id"],
  data: ChartSegment["data"]
) => {
  const latestFullMonthIndex = data.length - 2 > 0 ? data.length - 2 : 0
  if (sectionId === "revenue" && segmentId === "item_type") {
    return Object.keys(data[latestFullMonthIndex].values)
  } else {
    return keysSortedByValue(data[latestFullMonthIndex].values)
  }
}

import React from "react"
// See @alxmiron at https://github.com/palantir/blueprint/issues/3604
import { AppToaster } from "./AppToaster"
import { Tag, Icon, HotkeysTarget2, Button } from "@blueprintjs/core"
import ReportOmnibar from "./ReportOmnibar"

type ReportFilterProps = {
  predefinedSegmenterName: string
  predefinedSegmentValue: string
  disabled: boolean
  onFilterClick?: () => void
  onFilterClear: () => void
  onFilterSelect: (value: Types.Api.BusinessReportSegmentValue) => void
  selectedFilter?: Types.Api.BusinessReportSegmentValue
  items: Types.Api.BusinessReportSegmentValue[]
}

type ReportFilterState = {
  isOpen: boolean
}

class ReportFilter extends React.PureComponent<
  ReportFilterProps,
  ReportFilterState
> {
  constructor(props: ReportFilterProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  onHotkeyCombination = () => {
    if (this.props.predefinedSegmenterName) {
      return
    } else if (this.props.disabled) {
      AppToaster.show({
        timeout: 4000,
        intent: "none",
        message:
          "Report cannot be filtered when in Edit mode or it is being refreshed",
      })
    } else {
      this.setState({ isOpen: true })
    }
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  clearSelectedFilter = () => {
    this.setState({ isOpen: false })
    this.props.onFilterClear()
  }

  selectedFilterTag = ({ large }: { large: boolean }) => {
    if (this.props.predefinedSegmenterName) {
      return (
        <Tag
          minimal
          large={large}
        >{`${this.props.predefinedSegmenterName}: ${this.props.predefinedSegmentValue}`}</Tag>
      )
    }

    if (!this.props.selectedFilter) return undefined

    const label = this.props.selectedFilter.segment.name
    const value = this.props.selectedFilter.value
    return (
      <Tag
        minimal
        large={large}
        onRemove={this.clearSelectedFilter}
      >{`${label}: ${value}`}</Tag>
    )
  }

  onFilterSelect = (item: Types.Api.BusinessReportSegmentValue) => {
    this.props.onFilterSelect(item)
  }

  onFilterClick = () => {
    if (!this.props.disabled) {
      this.setState({ isOpen: true })
    }
  }

  hotkeys = [
    {
      global: true,
      combo: "shift + f",
      label: "Add filter",
      onKeyDown: this.onHotkeyCombination,
      preventDefault: true,
    },
  ]

  render() {
    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <Button
              onClick={this.onFilterClick}
              minimal
              large
              disabled={this.props.disabled}
              rightIcon={
                <span style={{ opacity: "0.5" }}>
                  <Icon icon="key-shift" />
                  <span>F</span>
                </span>
              }
            >
              Filter
            </Button>
            {this.selectedFilterTag({ large: true })}
            <ReportOmnibar
              isOpen={this.state.isOpen}
              onClose={this.onClose}
              rightElement={this.selectedFilterTag({ large: false })}
              {...this.props}
              onFilterSelect={this.onFilterSelect}
            />
          </div>
        )}
      </HotkeysTarget2>
    )
  }
}

export default ReportFilter

import { State as Report } from "./businessReportReducer"
import { UserContextProps } from "./UserContext"

import "./BusinessReport.css"

export const apiPath = (
  secret: string,
  currency: string | null,
  shared: boolean,
  demo: boolean
) => {
  if (demo) {
    return `/demo?token=${secret}`
  } else if (shared) {
    const params = new URLSearchParams()
    params.set("secret", secret)
    if (currency) {
      params.set("currency", currency)
    }

    return `/business_reports/by_shared_secret?${params.toString()}`
  } else {
    const params = new URLSearchParams()
    params.set("secret", secret)
    if (currency) {
      params.set("currency", currency)
    }

    return `/business_reports/by_secret?${params.toString()}`
  }
}

const performFetchReport = (
  path: string,
  userContext: UserContextProps
): Promise<Report> => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }

  return userContext.fetch(path, requestOptions)
}

export const fetchReport = (
  secret: string,
  currency: string | null,
  shared: boolean,
  demo: boolean,
  userContext: UserContextProps
): Promise<Report> => {
  const path = apiPath(secret, currency, shared, demo)
  return performFetchReport(path, userContext)
}

import React, { useMemo } from "react"
import {
  Menu,
  MenuItem,
  MenuDivider,
  Position,
  Popover,
  Icon,
  Tag,
  Intent,
} from "@blueprintjs/core"
import DataTable from "./DataTable"

export type SelectedType = {
  id: string
  type: string
  name: string
  columnIndex: number
  required?: boolean
}

type ColumnTypePickerType = {
  values: SelectedType[]
  columnIndex: number
  selectedType?: SelectedType
  setSelectedType: (selectedType: SelectedType) => void
  cleanSelectedType: (selectedType: SelectedType) => void
  otherSelectedTypes: SelectedType["id"][]
}

const ColumnTypePicker = ({
  values,
  columnIndex,
  selectedType,
  setSelectedType,
  cleanSelectedType,
  otherSelectedTypes,
}: ColumnTypePickerType) => {
  const required = useMemo(() => values.filter((v) => v.required), [values])
  const optional = useMemo(() => values.filter((v) => !v.required), [values])

  const renderValues = (values: SelectedType[]) =>
    values.map((value) => (
      <MenuItem
        icon={otherSelectedTypes.includes(value.id) && "small-tick"}
        intent={otherSelectedTypes.includes(value.id) ? "success" : "none"}
        key={value.name}
        text={value.name}
        onClick={() =>
          setSelectedType({
            ...value,
            columnIndex: columnIndex,
          })
        }
        active={selectedType?.id === value.id}
      />
    ))

  const renderTypeOptions = () => {
    return (
      <Menu>
        {required.length > 0 && (
          <>
            <MenuDivider title="Required" />
            {renderValues(required)}
          </>
        )}
        {optional.length > 0 && (
          <>
            <MenuDivider title="Optional" />
            {renderValues(optional)}
          </>
        )}
      </Menu>
    )
  }

  const onClearChoice = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()

    if (selectedType) {
      cleanSelectedType(selectedType)
    }
  }

  return (
    <Popover
      content={renderTypeOptions()}
      position={Position.BOTTOM}
      placement="bottom"
    >
      <Tag
        icon={<Icon icon="chevron-down" iconSize={10} />}
        minimal
        intent={Intent.PRIMARY}
        interactive={true}
        large
        onRemove={selectedType && onClearChoice}
      >
        {selectedType ? selectedType.name : "Map column"}
      </Tag>
    </Popover>
  )
}

type DataMappingStep = {
  data: Types.Api.CsvPreviewData
  values: SelectedType[]
  selectedTypes: SelectedType[]
  setMapping: (selectedType: SelectedType) => void
  cleanMapping: (selectedType: SelectedType) => void
  fullWidth?: boolean
}

const DataMappingStep = ({
  data,
  values,
  selectedTypes,
  setMapping,
  cleanMapping,
  fullWidth = true,
}: DataMappingStep) => {
  const columns = useMemo(() => {
    const length = data && data[0] ? data[0].length : 0

    return [...Array(length)].map((col, index) => {
      const selected = selectedTypes.find((item) => item.columnIndex === index)

      return {
        id: `column${index + 1}`,
        Header: (
          <ColumnTypePicker
            values={values}
            columnIndex={index}
            selectedType={selected}
            setSelectedType={setMapping}
            cleanSelectedType={cleanMapping}
            otherSelectedTypes={selectedTypes.map((v) => v.id)}
          />
        ),
        accessor: (row: any) => {
          return index < row.length ? row[index] : ""
        },
        disableSortBy: true,
      }
    })
  }, [data, values, selectedTypes, setMapping, cleanMapping])

  return <DataTable columns={columns} data={data} fullWidth={fullWidth} />
}

export default DataMappingStep

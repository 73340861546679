import React, { useState } from "react"
import pluralize from "pluralize"
import { Card, H4, Tag, Collapse, Button, Intent } from "@blueprintjs/core"

import "./ReportTemplatePreview.css"

const Definitions = ({ definitions, isOpen }) => {
  return (
    <Collapse isOpen={isOpen}>
      <div className="definitions">
        {definitions.map((definition) => (
          <Tag
            key={definition.id}
            className="definitionTag"
            large
            round
            minimal
          >
            {definition.name}
          </Tag>
        ))}
      </div>
    </Collapse>
  )
}

const ReportTemplatePreview = ({
  thumbnail,
  name,
  description,
  onClick,
  definitions,
}) => {
  const [isOpen, setOpen] = useState(false)

  const onShowDefinitionsClick = (event) => {
    event.stopPropagation()
    setOpen(!isOpen)
  }

  return (
    <Card interactive className="template" onClick={onClick}>
      <div className="thumbnail">{thumbnail}</div>
      <div className="explanation">
        <H4>{name}</H4>
        <p>{description}</p>
        {definitions.length > 0 && (
          <>
            <Button
              className="showDefinitions"
              onClick={onShowDefinitionsClick}
              minimal
              intent={Intent.PRIMARY}
            >{`${definitions.length} ${pluralize(
              "metric",
              definitions.length
            )}`}</Button>
            <Definitions definitions={definitions} isOpen={isOpen} />
          </>
        )}
      </div>
    </Card>
  )
}

export default ReportTemplatePreview

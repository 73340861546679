import React from "react"
import classnames from "classnames"
import { Button } from "@blueprintjs/core"
import { formatTimeDistance } from "../formatters"
import Avatar from "../Avatar"

import "./Comment.css"

type CommentProps = {
  id: string
  user: { name: string }
  content: string
  date: string
  demo: boolean
  pinned: boolean
  togglePin: (id: string) => void
  allowPin: boolean
}

const Comment = ({
  id,
  user,
  content,
  date,
  demo,
  pinned,
  togglePin,
  allowPin,
}: CommentProps) => {
  const demoAvatarClass = (fullName: string) => {
    const initials = fullName
      .split(" ")
      .map((el) => el[0])
      .join("")
      .toUpperCase()
    return initials === "AK" ? "ak" : "mb"
  }

  const iconName = () => {
    return pinned ? "unpin" : "pin"
  }

  const humanDisplayText = (content: string) =>
    content.split("\n").map((line, index) => <p key={index}>{line}</p>)

  return (
    <div className="comment">
      <div className="author">
        {demo ? (
          <div
            className={classnames("avatar", demoAvatarClass(user.name))}
          ></div>
        ) : (
          <Avatar name={user.name} dark />
        )}
        <div className="details">
          <div className="name">{user.name}</div>
          <div className="timestamp">{formatTimeDistance(date)}</div>
        </div>
        {allowPin && (
          <div className="pin">
            <Button
              onClick={() => togglePin(id)}
              icon={iconName()}
              minimal
              intent={pinned ? "success" : "none"}
            />
          </div>
        )}
      </div>
      <div className="commentText">
        {content ? humanDisplayText(content) : ""}
      </div>
    </div>
  )
}

export default Comment

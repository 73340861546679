const CHART_SETTINGS = {
  animationDuration: 1000,
  height: 400,
  slideModeHeight: 500,
  margin: { top: 5, right: 0, left: 0, bottom: 5 },
  xAxisLinePadding: { left: 22, right: 22 },
  xAxisBarPadding: { left: 10, right: 10 },
  yAxisBarPadding: { top: 10, bottom: 10 },
  lineChartDetailedCursorStrokeWidth: 53,
}

export default CHART_SETTINGS

import React, { useCallback, useState, useMemo } from "react"
import {
  Popover,
  Menu,
  MenuItem,
  Position,
  Intent,
  Button,
  MenuDivider,
} from "@blueprintjs/core"

const withCategorySwitcher = (WrappedComponent) => (props) => {
  const [categoryIndex, setCategoryIndex] = useState(0)

  const categoryLabels = useMemo(
    () => ({
      values: "Money",
      counts: "Customers count",
      units: "Units sold",
      month_over_month_growth: "Month over month growth %",
      month_over_month_retention: "Month over month retention %",
      moving_average3: "3 months moving average",
    }),
    []
  )
  const handleCategoryChange = useCallback(
    (event) => {
      setCategoryIndex(
        props.section.data_categories.indexOf(event.currentTarget.id)
      )
    },
    [setCategoryIndex, props.section.data_categories]
  )

  const renderCategoryOptions = useCallback(
    (additionalItems) => (
      <Menu>
        {props.section.data_categories.length > 1 && (
          <>
            <MenuDivider title="Category" />
            {props.section.data_categories.map((categoryId, index) => (
              <MenuItem
                id={categoryId}
                key={categoryId}
                text={categoryLabels[categoryId]}
                active={index === categoryIndex}
                onClick={handleCategoryChange}
              />
            ))}
            {additionalItems && <MenuDivider title="Actions" />}
          </>
        )}
        {additionalItems}
      </Menu>
    ),
    [
      handleCategoryChange,
      categoryLabels,
      categoryIndex,
      props.section.data_categories,
    ]
  )

  const renderCategorySwitcher = useCallback(
    (additionalItems) => (
      <Popover
        content={renderCategoryOptions(additionalItems)}
        position={Position.DOWN}
        placement="bottom"
      >
        <Button icon="chevron-down" minimal intent={Intent.NONE}>
          {props.section.data_categories.length > 1
            ? categoryLabels[props.section.data_categories[categoryIndex]]
            : "Export"}
        </Button>
      </Popover>
    ),
    [
      renderCategoryOptions,
      categoryIndex,
      categoryLabels,
      props.section.data_categories,
    ]
  )

  return (
    <WrappedComponent
      {...props}
      category={props.section.data_categories[categoryIndex]}
      renderCategorySwitcher={renderCategorySwitcher}
    />
  )
}

export default withCategorySwitcher

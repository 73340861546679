import React from "react"

import {
  IItemRendererProps,
  ItemListRenderer,
  Select,
} from "@blueprintjs/select"
import { Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core"
import { FilterValueArgument, SerieValue } from "./types"

export type FilterField = {
  object: "subscription" | "customer" | "cost"
  value_objects: SerieValue["object"][]
  field: string
  field_id: string
  type: "date" | "text" | "boolean"
}

export type FilterValue = {
  object: "subscription" | "cost"
  attribute: string
  arguments: FilterValueArgument[]
  label: string
}

const FilterFieldSelect = Select.ofType<FilterField>()

type SelectFilterFieldProps = {
  items: FilterField[]
  onItemSelect: (item: FilterField) => void
  activeItem?: FilterField
  targetRef?: any
}

type GroupedItems = {
  [key: string]: React.ReactElement[]
}

const SelectFilterField = ({
  items,
  onItemSelect,
  activeItem,
  targetRef,
}: SelectFilterFieldProps) => {
  const itemPredicate = (query: string, item: FilterField) => {
    if (query.length === 0) return true

    const lowercaseQuery = query.toLowerCase()
    return (
      item.object.toLowerCase().includes(lowercaseQuery) ||
      item.field.toLowerCase().includes(lowercaseQuery)
    )
  }

  const itemRenderer = (
    item: FilterField,
    { handleClick, index, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) return null

    return (
      <MenuItem
        key={`${item.field}_${index}`}
        text={item.field}
        onClick={handleClick}
        active={modifiers.active}
        shouldDismissPopover={false}
      />
    )
  }

  const handleItemSelect = (item: FilterField) => {
    onItemSelect(item)
  }

  const renderMenu: ItemListRenderer<FilterField> = ({
    filteredItems,
    itemsParentRef,
    renderItem,
  }) => {
    if (filteredItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          <MenuItem disabled text="No results" />
        </Menu>
      )
    }

    const groupedItems = filteredItems.reduce(
      (res: GroupedItems, item: FilterField, index: number) => {
        const renderer = renderItem(item, index)
        if (renderer === null) return res

        res[item.object] = res[item.object] || []
        res[item.object].push(renderer)
        return res
      },
      {}
    )

    const renderedItems = Object.keys(groupedItems).flatMap((key, index) => {
      return [<MenuDivider key={index} title={key} />, groupedItems[key]].flat()
    })

    return <Menu ulRef={itemsParentRef}>{renderedItems}</Menu>
  }

  return (
    <FilterFieldSelect
      fill
      activeItem={activeItem}
      items={items}
      itemListRenderer={renderMenu}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      onItemSelect={handleItemSelect}
      popoverProps={{ minimal: true, boundary: "viewport" }}
      filterable
      matchTargetWidth
    >
      <Tag
        rightIcon="double-caret-vertical"
        fill
        large
        interactive
        minimal
        elementRef={targetRef}
      >
        {activeItem
          ? `${activeItem.object}.${activeItem.field}`
          : "Select filter attribute"}
      </Tag>
    </FilterFieldSelect>
  )
}

export default SelectFilterField

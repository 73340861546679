import React, { useCallback } from "react"
import { Intent } from "@blueprintjs/core"
import { get, FieldErrors } from "react-hook-form"
import { Subtract } from "utility-types"

import formCss from "./forms.module.css"

export interface InjectedHookHelpersProps<FT = any> {
  validationErrorMsg: (errors: FieldErrors<FT>, fieldName: string) => any
  fieldIntent: (
    errors: FieldErrors<FT>,
    fieldName: string
  ) => Intent | undefined
}

const withHookFormHelpers =
  <T, P extends InjectedHookHelpersProps<T>>(
    WrappedComponent: React.ComponentType<P>
  ) =>
  (props: Subtract<P, InjectedHookHelpersProps<T>>) => {
    const fieldIntent = useCallback(
      (errors: FieldErrors, fieldName: string) =>
        get(errors, fieldName) ? Intent.DANGER : Intent.NONE,
      []
    )

    const validationErrorMsg = useCallback(
      (errors: FieldErrors, fieldName: string) => {
        const error = get(errors, fieldName)

        return error && <p className={formCss.errorLabel}>{error.message}</p>
      },
      []
    )

    return (
      <WrappedComponent
        {...(props as P)}
        fieldIntent={fieldIntent}
        validationErrorMsg={validationErrorMsg}
      />
    )
  }

export default withHookFormHelpers

import React, { useState, useCallback } from "react"
import { H3, Position, Icon, Popover } from "@blueprintjs/core"
import SearchInput, { SearchApiDestination } from "./SearchInput"

import css from "./CustomerSelect.module.css"
import formCss from "./forms.module.css"

const CustomerSelect = ({
  onCustomerSelected,
  onNewCustomerAdded,
  title = "Customer",
  searchParentsOnly = false,
  withCreate = false,
}) => {
  const [value, setValue] = useState("")
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [searchResults, setSearchResult] = useState([])

  const onQueryChange = (query) => {
    if (query.length > 0) {
      setValue(query)
      setIsPopoverOpen(true)
    } else {
      if (isPopoverOpen) setIsPopoverOpen(false)
    }
  }

  const onSearchSuccess = useCallback((customers) => {
    setSearchResult(customers)
  }, [])

  const onSearchResultItemClick = (customer) => {
    setIsPopoverOpen(false)
    onCustomerSelected(customer)
  }

  const onAddNewCustomerClick = () => {
    setIsPopoverOpen(false)
    onNewCustomerAdded(value)
  }

  const customerToSearchResult = (customer) => {
    const label = customer.name ? customer.name : customer.id

    return (
      <li key={customer.id} onClick={() => onSearchResultItemClick(customer)}>
        {label}
      </li>
    )
  }

  const renderSearchResults = () => {
    return (
      <div className={css.searchResults}>
        <ul>
          {withCreate && (
            <li onClick={onAddNewCustomerClick}>
              <Icon icon="plus" style={{ marginRight: 5 }} />
              {`Create new customer "${value}"`}
            </li>
          )}
          {!withCreate && searchResults.length === 0 && (
            <li className={css.disabled}>Searching ...</li>
          )}
          {searchResults.map(customerToSearchResult)}
        </ul>
      </div>
    )
  }

  return (
    <div className={formCss.body}>
      <H3 className={formCss.title}>{title}</H3>
      <Popover
        fill={true}
        minimal
        isOpen={isPopoverOpen}
        content={renderSearchResults()}
        position={Position.BOTTOM}
        enforceFocus={false}
        autoFocus={false}
      >
        <SearchInput
          apiDestination={SearchApiDestination.CUSTOMER}
          onSuccess={onSearchSuccess}
          onQueryChange={onQueryChange}
          searchParentsOnly={searchParentsOnly}
        />
      </Popover>
    </div>
  )
}

export default CustomerSelect

import React, { useMemo, useCallback } from "react"
import { Intent, Button, Tooltip } from "@blueprintjs/core"
import DataTable, { DataType, formatText } from "./DataTable"
import { formatCurrency } from "./formatters"

import css from "./InvoiceItemsTable.module.css"

export const InvoiceItemsTable = ({
  invoiceItems,
  onSort,
  onNextPage,
  onPrevPage,
  range,
  onOpenDistillerAddDialog,
}) => {
  const currencyCellRenderer = useCallback((props) => {
    return props.cell.value.toUpperCase()
  }, [])

  const addDistillerCellRenderer = useCallback(
    (props) => {
      return (
        <Button
          text="Add import rule"
          onClick={() => onOpenDistillerAddDialog(props.row.original.name)}
          outlined
        />
      )
    },
    [onOpenDistillerAddDialog]
  )

  const nameCellRenderer = useCallback((props) => {
    return (
      <Tooltip minimal content={props.row.original.name}>
        {props.row.original.name}
      </Tooltip>
    )
  }, [])

  const columns = useMemo(
    () => [
      {
        id: "add_distiller",
        Header: "Action",
        className: css.colAddDistiller,
        Cell: addDistillerCellRenderer,
        disableSortBy: true,
      },
      {
        id: "buyer_name",
        Header: "Buyer Name",
        accessor: (row) => formatText(row.invoice.buyer_name),
        className: css.colBuyerName,
        disableSortBy: true,
      },
      {
        id: "invoice_number",
        Header: "Invoice Number",
        accessor: (row) => row.invoice.number,
        className: css.colInvoiceNumber,
        disableSortBy: true,
      },
      {
        id: "name",
        Header: "Name",
        Cell: nameCellRenderer,
        className: css.colName,
        sortType: "basic",
      },
      {
        id: "value",
        Header: "Amount",
        accessor: (row) => formatCurrency(parseFloat(row.value)),
        className: css.colAmount,
        dataType: DataType.CURRENCY,
      },
      {
        id: "currency",
        Header: "Currency",
        accessor: (row) => row.currency,
        className: css.colCurrency,
        Cell: currencyCellRenderer,
        sortType: "basic",
      },
      {
        id: "buyer_id",
        Header: "Buyer Id",
        accessor: (row) => row.invoice.buyer_id,
        className: css.colBuyerId,
        disableSortBy: true,
      },
    ],
    [currencyCellRenderer, addDistillerCellRenderer, nameCellRenderer]
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={invoiceItems}
        onSort={onSort}
        fullWidth
        capitalize
      />
      <div className={css.navigation}>
        <Button
          onClick={onPrevPage}
          disabled={onPrevPage ? false : true}
          text="Previous page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
        {range && <div>{`${range[0]} - ${range[1]}`}</div>}
        <Button
          onClick={onNextPage}
          disabled={onNextPage ? false : true}
          text="Next page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
      </div>
    </>
  )
}

export default InvoiceItemsTable

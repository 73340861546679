import React, { useEffect, useState, useContext } from "react"
import {
  Classes,
  Dialog,
  HTMLTable,
  Button,
  Intent,
  Spinner,
} from "@blueprintjs/core"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import CustomerSegmentInput from "./CustomerSegmentInput"
import classnames from "classnames"
import { monthName } from "./formatters"
import css from "./TimesensitiveSegmentValuesDialog.module.css"

type TimesensitiveSegmentValuesDialogProps = {
  isDialogOpen: boolean
  onDialogClose: () => void
  customerId: string
  customerSegment: Types.Api.CustomerSegment
  updatedSegmentValue: (customerSegment: Types.Api.CustomerSegment) => void
}

type SegmentItem = {
  year: string
  month: string
  value: string
}

const TimesensitiveSegmentValuesDialog = ({
  isDialogOpen,
  onDialogClose,
  customerId,
  customerSegment,
  updatedSegmentValue,
}: TimesensitiveSegmentValuesDialogProps) => {
  const [items, setItems] = useState<
    Types.Api.TimesensitiveSegmentValue[] | null
  >(null)
  const [updatedItems, setUpdatedItems] = useState<{
    [key: string]: Types.Api.TimesensitiveSegmentValue
  }>({})
  const userContext = useContext(UserContext)

  useEffect(() => {
    userContext
      .fetch(
        `/customer_segment_values/${customerId}/${customerSegment.segment.id}`
      )
      .then((data: Types.Api.TimesensitiveSegmentValues) => {
        setItems(transformItems(data.items))
      })
  }, [userContext, customerId, customerSegment.segment.id])

  const transformItems = (items: Types.Api.TimesensitiveSegmentValue[]) =>
    items.slice(-12).reverse()

  const onDialogSave = () => {
    Object.values(updatedItems).forEach((item: any) => {
      updateSegmentItem(item)
    })

    if (Object.keys(updatedItems).length > 0 && items && items.length > 0) {
      const oldValue = items[0]
      const newValue = updatedItems[`${oldValue.year}-${oldValue.month}`]

      if (newValue)
        updatedSegmentValue({ ...customerSegment, value: newValue.value })
    }

    setUpdatedItems({})
    closeDialog()
  }

  const closeDialog = () => {
    setUpdatedItems({})
    onDialogClose()
  }

  const saveUpdatedItem = (item: SegmentItem) => {
    setUpdatedItems({
      ...updatedItems,
      [`${item.year}-${item.month}`]: item,
    })
  }

  const updateSegmentItem = (item: SegmentItem) => {
    const payload = {
      ...item,
      value: item.value.length > 0 ? item.value : null,
    }

    userContext
      .fetch(
        `/customer_segment_values/${customerId}/${customerSegment.segment.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
      .then((data: Types.Api.TimesensitiveSegmentValues) => {
        setItems(transformItems(data.items))
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Sorry, could not update the segments value. Please try again.",
        })
      })
  }

  const loadingSpinner = () => {
    return (
      <Spinner
        className={css.verticalCenter}
        size={30}
        intent={Intent.PRIMARY}
      />
    )
  }

  return (
    <Dialog
      className={css.segmentDialog}
      isOpen={isDialogOpen}
      onClose={onDialogClose}
      title="Segment values"
    >
      <div
        className={classnames(Classes.DIALOG_BODY, css.valuesTableContainer)}
      >
        {!items && loadingSpinner()}
        {items && (
          <HTMLTable className={css.width100}>
            <thead>
              <tr>
                <th className={css.stickyTop}>Year</th>
                <th className={css.stickyTop}>Month</th>
                <th className={css.stickyTop}>Value</th>
              </tr>
            </thead>
            <tbody>
              {items?.map(
                (item: Types.Api.TimesensitiveSegmentValue, index: number) => (
                  <tr key={index}>
                    <td className={css.verticleMiddle}>{item.year}</td>
                    <td className={css.verticleMiddle}>
                      {monthName(parseInt(item.month))}
                    </td>
                    <td>
                      <CustomerSegmentInput
                        customerSegment={customerSegment.segment}
                        onItemSelect={(newValue: { value: string }) => {
                          saveUpdatedItem({
                            year: item.year,
                            month: item.month,
                            value: newValue.value,
                          })
                        }}
                        defaultValue={item.value ? item.value : ""}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </HTMLTable>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            text="Close"
            onClick={closeDialog}
            intent={Intent.NONE}
            outlined
            large
          />
          <Button
            text="Save"
            onClick={onDialogSave}
            intent={Intent.PRIMARY}
            large
          />
        </div>
      </div>
    </Dialog>
  )
}

export default TimesensitiveSegmentValuesDialog

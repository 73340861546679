import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useCosts = (): [
  Types.Api.Cost[],
  boolean,
  () => void,
  (costs: Types.Api.Cost[]) => void
] => {
  const [costs, setCosts] = useState<Types.Api.Cost[]>([])
  const [loading, setLoading] = useState(false)

  const userContext = useContext(UserContext)

  const fetchCosts = useCallback(() => {
    setLoading(true)
    userContext
      .fetch("/costs")
      .then((data) => setCosts(data.items))
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({
          message: "We could not fetch costs",
        })
      })
      .finally(() => setLoading(false))
  }, [userContext])

  useEffect(() => {
    fetchCosts()
  }, [userContext, fetchCosts])

  return [costs, loading, fetchCosts, setCosts]
}

export default useCosts

import React, { useEffect } from "react"
import { useTable, useSortBy, useFilters } from "react-table"
import {
  Icon,
  Button,
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
  InputGroup,
  Intent,
  Classes,
  Label,
} from "@blueprintjs/core"
import classnames from "classnames"
import { formatTimeInTable, formatTimeInTableHeader } from "./formatters"

import css from "./DataTable.module.css"
import popoverCss from "./popoverForm.module.css"
import { useForm } from "react-hook-form"

export const EMPTY_VALUE = "--"
export const DataType = {
  NUMBER: "number",
  CURRENCY: "currency",
  STRING: "string",
  DATE: "date",
}

export const SearchInColumnValueFilter = ({ column }) => {
  const { filterValue, setFilter } = column

  const { getValues, register, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      filterValue,
    },
  })

  useEffect(() => reset({ filterValue }), [filterValue, reset])

  const onFilterSelect = () => {
    const values = getValues()
    setFilter(values.filterValue)
  }

  const onCancel = () => {
    setFilter(undefined)
    reset({ filterValue: "" })
  }

  const filterForm = () => {
    const filterValueInput = register("filterValue")
    return (
      <div className={classnames(popoverCss.popover, popoverCss.widthAuto)}>
        <div className={popoverCss.popoverBody}>
          <Label>
            <InputGroup
              autoFocus
              leftIcon="search"
              name={filterValueInput.name}
              inputRef={filterValueInput.ref}
              onChange={filterValueInput.onChange}
              onBlur={filterValueInput.onBlur}
              defaultValue={filterValue}
            />
          </Label>
        </div>
        <div className={popoverCss.actions}>
          <Button
            outlined
            intent={Intent.PRIMARY}
            text="OK"
            className={Classes.POPOVER_DISMISS}
            onClick={onFilterSelect}
          />
          <Button
            className={Classes.POPOVER_DISMISS}
            minimal
            intent={Intent.DANGER}
            text="Clear filter"
            onClick={onCancel}
          />
        </div>
      </div>
    )
  }

  return (
    <Popover minimal position="bottom" content={filterForm()}>
      <Button
        small
        minimal
        icon="filter"
        intent={filterValue ? "primary" : "none"}
        iconProps={{ iconSize: 12 }}
      />
    </Popover>
  )
}

export const ColumnValuesFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows, id } = column
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const onFilterSelect = (event) => {
    setFilter(event.target.textContent || undefined)
  }

  const filterMenu = () => {
    return (
      <Menu>
        {options.map((option, index) => (
          <MenuItem
            key={`${index}-${option}`}
            active={option === filterValue}
            text={option}
            onClick={onFilterSelect}
          />
        ))}
        <MenuDivider />
        <MenuItem
          text="Clear filter"
          onClick={() => setFilter(undefined)}
          disabled={!filterValue}
        />
      </Menu>
    )
  }

  return (
    <Popover minimal position="bottom" content={filterMenu()}>
      <Button
        small
        minimal
        icon="filter"
        intent={filterValue ? "primary" : "none"}
        iconProps={{ iconSize: 12 }}
      />
    </Popover>
  )
}

export const formatDate = (value) => {
  if (value) {
    return formatTimeInTable(value)
  } else {
    return EMPTY_VALUE
  }
}

export const formatDateInHeader = (value) => {
  if (value) {
    return formatTimeInTableHeader(value)
  } else {
    return EMPTY_VALUE
  }
}

export const formatText = (value) => {
  if (value && value.length > 0) {
    return value
  } else {
    return EMPTY_VALUE
  }
}

const DataTable = ({
  columns,
  data,
  defaultSortBy = [],
  onSort = undefined,
  fullWidth = false,
  withFooter = false,
  compact = false,
  wrapperClassName = "",
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        sortBy: defaultSortBy,
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = tableInstance

  useEffect(() => {
    if (onSort) onSort(sortBy)
  }, [onSort, sortBy])

  const rowProps = (row) =>
    row.original.highlight ? { className: css.highlight } : {}

  const sortIcon = (column) => {
    if (!column.canSort) return <></>

    let iconName = ""
    if (!column.isSorted) {
      iconName = "sort"
    } else {
      if (column.sortType === "basic") {
        iconName = column.isSortedDesc
          ? "sort-alphabetical-desc"
          : "sort-alphabetical"
      } else if (column.sortType === "datetime") {
        iconName = column.isSortedDesc ? "sort-desc" : "sort-asc"
      } else {
        iconName = column.isSortedDesc
          ? "sort-numerical-desc"
          : "sort-numerical"
      }
    }

    return <Icon icon={iconName} iconSize={14} style={{ marginLeft: "5px" }} />
  }

  const dataTypeClass = (column) =>
    column.dataType ? css[`dataType-${column.dataType}`] : ""

  const stickyLeftClass = (column) => (column.stickyLeft ? css.stickyLeft : "")

  const mapHeaderGroup = (headerGroup) => (
    <tr {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column, columnIndex) => {
        return (
          <th
            key={columnIndex}
            {...column.getHeaderProps({
              className: classnames(
                css.stickyTop,
                column.className,
                dataTypeClass(column),
                stickyLeftClass(column)
              ),
              ...column.getSortByToggleProps(),
            })}
          >
            {column.Filter && column.render("Filter")}
            {column.filterValue ? column.filterValue : column.render("Header")}
            <span>{sortIcon(column)}</span>
            {column.filterValue && (
              <Button
                minimal
                small
                icon="cross"
                onClick={() => column.setFilter(undefined)}
              />
            )}
          </th>
        )
      })}
    </tr>
  )

  const mapBodyRow = (row) => {
    prepareRow(row)
    return (
      <tr {...row.getRowProps()} {...rowProps(row)}>
        {row.cells.map((cell, cellIndex) => (
          <td
            key={cellIndex}
            {...cell.getCellProps({
              className: classnames(
                cell.column.className,
                dataTypeClass(cell.column),
                compact ? css.compactPadding : "",
                stickyLeftClass(cell.column)
              ),
            })}
          >
            {cell.render("Cell")}
          </td>
        ))}
      </tr>
    )
  }

  const mapFooterGroup = (group) => (
    <tr {...group.getFooterGroupProps()}>
      {group.headers.map((column, columnIndex) => (
        <td
          key={columnIndex}
          {...column.getFooterProps({
            className: classnames(
              css.stickyBottom,
              dataTypeClass(column),
              stickyLeftClass(column)
            ),
            ...column.getSortByToggleProps(),
          })}
        >
          {column.render("Footer")}
        </td>
      ))}
    </tr>
  )

  return (
    <div className={classnames(css.tableWrapper, wrapperClassName)}>
      <table
        {...getTableProps()}
        className={fullWidth ? css.fullWidth : css.scroll}
      >
        <thead>
          {headerGroups.map((headerGroup) => mapHeaderGroup(headerGroup))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => mapBodyRow(row))}
        </tbody>
        {withFooter && (
          <tfoot>{footerGroups.map((group) => mapFooterGroup(group))}</tfoot>
        )}
      </table>
    </div>
  )
}

export default DataTable

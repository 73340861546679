import React, { SyntheticEvent, ReactElement } from "react"
import {
  Button,
  NonIdealState,
  Classes,
  H1,
  Spinner,
  Intent,
  Card,
  IconName,
} from "@blueprintjs/core"
import classnames from "classnames"
import Logo from "./Logo"
import { BarThumbnail } from "./ChartThumbnails"

import css from "./NonIdealState.module.css"
import sectionCss from "./businessReportSection.module.css"

export const SectionDetailsEmptyState = () => (
  <div>
    <NonIdealState
      icon="list-detail-view"
      title="It looks like there are no details for this time period"
    />
  </div>
)

export const SharedReportsOnlyEmptyState = () => (
  <div className={css.center}>
    <NonIdealState
      icon="info-sign"
      title="You can access specific reports now via share url"
      description="If you already have a link to the report you will be able to view this repor now. Just go to the link you've received. If not, ask an account owner to share their report with you by sending you a link"
    />
  </div>
)

type FailureStateProps = {
  title: string
  inDialog?: boolean
}

export const FailureState = ({ title, inDialog }: FailureStateProps) => (
  <div className={classnames({ center: !inDialog })}>
    <NonIdealState
      icon="error"
      title={title}
      description="Please try again and if the problem persists, get in touch."
      action={<a href="mailto:support@getprobe.io">Get in touch</a>}
    />
  </div>
)

type SuccessStateProps = {
  title: string
  description: string
  inDialog?: boolean
  onAction: () => any
  icon?: IconName
  actionText?: string
}

export const SuccessState = ({
  title,
  description,
  inDialog,
  onAction,
  icon = "tick-circle",
  actionText = "Finish",
}: SuccessStateProps) => (
  <div className={classnames({ center: !inDialog })}>
    <NonIdealState
      icon={icon}
      title={title}
      description={description}
      action={
        <Button
          large
          outlined
          intent={Intent.PRIMARY}
          onClick={onAction}
          text={actionText}
        />
      }
    />
  </div>
)

type NotFoundStateProps = {
  description: string
}

export const NotFoundState = ({ description }: NotFoundStateProps) => (
  <NonIdealState
    icon="search"
    title="No search results"
    description={description}
  />
)

type EmptyBusinessReportStateProps = {
  hasEditRights: boolean
  onAddChart: (event: SyntheticEvent<HTMLElement>) => void
}

export const EmptyBusinessReportState = ({
  hasEditRights,
  onAddChart,
}: EmptyBusinessReportStateProps) => {
  const title = hasEditRights
    ? "Add new chart to the report"
    : "This report's owner has not added any charts yet"

  const children = hasEditRights ? (
    <Button
      onClick={onAddChart}
      large
      minimal
      outlined
      intent="primary"
      icon="plus"
    >
      Add chart
    </Button>
  ) : null

  return (
    <div className={sectionCss.content}>
      <NonIdealState
        title={title}
        icon={<BarThumbnail large={true} small={false} />}
      >
        {children}
      </NonIdealState>
    </div>
  )
}

type LoadingStateProps = {
  withLogo?: boolean
  inDialog?: boolean
}

export const LoadingState = ({ withLogo, inDialog }: LoadingStateProps) => (
  <div className={classnames({ center: !inDialog }, css.loadingState)}>
    {withLogo && <Logo />}
    <div className={classnames("Title", Classes.SKELETON)}>
      <H1>April 2020, monthly business report</H1>
    </div>
    <div>
      <div
        className={classnames(
          Classes.TEXT_LARGE,
          Classes.RUNNING_TEXT,
          Classes.SKELETON
        )}
      >
        Hi there, welcome to Probe demo report. Probe generates your monthly
        business report automatically. All you need to do is to add context to
        your data and share it with your colleagues or investors. Usually, this
        section will contain an executive summary, but here is a couple of key
        features of this report: 1. Click ‘Edit’ button above to add a
        description to your data or edit this section 2. You will be able to
        leave a comment and have a discussion attached to each graph 3. Copy
        sharable URL and send to your colleagues Data in the report are for demo
        purposes only and may not sum up together.
      </div>
    </div>
  </div>
)

export const CardsLoadingState = () => {
  return (
    <div className={classnames(css.loadingState, css.cardsContainer)}>
      <Card className={classnames(Classes.SKELETON)}>
        <H1>User Name</H1>
      </Card>
      <Card className={classnames(Classes.SKELETON)}>
        <H1>User Name</H1>
      </Card>
      <Card className={classnames(Classes.SKELETON)}>
        <H1>User Name</H1>
      </Card>
    </div>
  )
}

export const LoadingOverlay = () => (
  <div className={css.loadingOverlay}>
    <Spinner size={30} intent={Intent.PRIMARY} />
  </div>
)

type NoDataOverlayProps = {
  text: ReactElement
}

export const NoDataOverlay = ({ text }: NoDataOverlayProps) => (
  <div className={css.loadingOverlay}>
    <p
      className={classnames(Classes.TEXT_LARGE, css.marginAuto, css.centerText)}
    >
      {text}
    </p>
  </div>
)

import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useObjectAttributes = () => {
  const [attributes, setAttributes] = useState<Types.Api.ObjectAttribute[]>([])
  const userContext = useContext(UserContext)
  const fetchAttributes = useCallback(() => {
    userContext
      .fetch("/object_attributes")
      .then((data) => setAttributes(data.items))
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({ message: "Could not fetch object attributes" })
      })
  }, [userContext])

  useEffect(() => {
    fetchAttributes()
  }, [userContext, fetchAttributes])

  return [attributes, fetchAttributes] as const
}

export default useObjectAttributes

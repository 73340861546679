import React, { useState, ReactElement } from "react"
import { useHistory } from "react-router-dom"
import { H5, Card, Intent, Collapse, AnchorButton } from "@blueprintjs/core"
import {
  LineThumbnail,
  MultiLineThumbnail,
  BarThumbnail,
  MultiBarThumbnail,
  StackBarThumbnail,
  CohortThumbnail,
} from "./ChartThumbnails"
import classnames from "classnames"
import pluralize from "pluralize"

import "./SectionPreview.css"

const thumbnailForSectionDefinition = (
  sectionDefinition: Types.Api.SectionDefinition
) => {
  if (sectionDefinition.data_type === "line_chart") {
    return sectionDefinition.default_multiple_data ? (
      <MultiLineThumbnail />
    ) : (
      <LineThumbnail />
    )
  } else if (sectionDefinition.data_type === "bar_chart") {
    return sectionDefinition.default_multiple_data ? (
      <MultiBarThumbnail />
    ) : (
      <BarThumbnail />
    )
  } else if (sectionDefinition.data_type === "stacked_bar_chart") {
    return <StackBarThumbnail />
  } else if (sectionDefinition.data_type === "cohort") {
    return <CohortThumbnail />
  }
}

const thumbnailForSectionSegmenter = (
  sectionDefinition: Types.Api.SectionDefinition
) => {
  if (sectionDefinition.data_type === "line_chart") {
    return <MultiLineThumbnail small />
  } else if (sectionDefinition.data_type === "bar_chart") {
    return <MultiBarThumbnail small />
  } else if (sectionDefinition.data_type === "stacked_bar_chart") {
    return <StackBarThumbnail small />
  } else if (sectionDefinition.data_type === "cohort") {
    return <CohortThumbnail small />
  }
}

type PreviewActionsBarProps = {
  primaryButton?: ReactElement
  secondaryButton?: ReactElement
}

const PreviewActionsBar = ({
  primaryButton,
  secondaryButton,
}: PreviewActionsBarProps) => (
  <div className="actionButtons">
    <div className="primaryActions">{primaryButton}</div>
    <div className="secondaryActions">{secondaryButton}</div>
  </div>
)

type SectionPreviewProps = {
  sectionDefinition: Types.Api.SectionDefinition
  onClick: (
    sectionId: Types.Api.SectionDefinition["id"],
    segmenter: Types.Api.SectionSegment
  ) => void
  selected: boolean
  selectedSegments: Types.Api.SectionDefinition["segmenters"]
}

type SegmentsListProps = {
  isOpen: boolean
  segmenters: Types.Api.SectionDefinition["segmenters"]
  sectionDefinition: Types.Api.SectionDefinition
  selectedSegments: Types.Api.SectionDefinition["segmenters"]
  onClick: SectionPreviewProps["onClick"]
}

const SegmentsList = ({
  isOpen,
  segmenters,
  sectionDefinition,
  selectedSegments,
  onClick,
}: SegmentsListProps) => {
  const history = useHistory()

  const isSegmentSelected = (segmenter: Types.Api.SectionSegment) => {
    return selectedSegments && selectedSegments.includes(segmenter)
  }

  const navigateToDataSourcesButton = () => (
    <AnchorButton
      minimal
      intent={Intent.PRIMARY}
      onClick={() => history.push("/settings")}
      text={`Connect ${sectionDefinition.segmenter_call_to_action} to enable additional segmentations`}
    />
  )

  return (
    <Collapse className="segmentList" isOpen={isOpen}>
      {segmenters.map((segmenter, index) => (
        <SectionSegmentPreview
          key={`${segmenter.id}-${index}`}
          sectionDefinition={sectionDefinition}
          segmenter={segmenter}
          onClick={() => onClick(sectionDefinition.id, segmenter)}
          selected={isSegmentSelected(segmenter)}
        />
      ))}
      <PreviewActionsBar
        primaryButton={undefined}
        secondaryButton={
          sectionDefinition.segmenter_call_to_action
            ? navigateToDataSourcesButton()
            : undefined
        }
      />
    </Collapse>
  )
}

type SectionSegmentPreviewProps = {
  sectionDefinition: Types.Api.SectionDefinition
  segmenter: Types.Api.SectionSegment
  selected: boolean
  onClick: () => void
}

export const SectionSegmentPreview = ({
  sectionDefinition,
  segmenter,
  selected,
  onClick,
}: SectionSegmentPreviewProps) => {
  const onSegmentCardClick = (event: React.FormEvent<HTMLElement>) => {
    event.stopPropagation()
    onClick()
  }

  return (
    <Card
      interactive={true}
      className={classnames("segmentPreview", { selected: selected })}
      onClick={onSegmentCardClick}
    >
      <div className="thumbnail">
        {thumbnailForSectionSegmenter(sectionDefinition)}
      </div>
      <div>
        <H5>{segmenter.name}</H5>
        <p></p>
      </div>
    </Card>
  )
}

export const SectionPreview = ({
  sectionDefinition,
  onClick,
  selected,
  selectedSegments,
}: SectionPreviewProps) => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const totalSegmenter = sectionDefinition.segmenters.filter(
    (segmenter) => segmenter.is_total
  )[0]
  const unavailable = !sectionDefinition.available || !totalSegmenter
  const segmenters = sectionDefinition.segmenters.filter(
    (segmenter) => !segmenter.is_total
  )

  const onSectionCardClick = (event: React.FormEvent<HTMLElement>) => {
    event.stopPropagation()
    if (!unavailable) {
      onClick(sectionDefinition.id, totalSegmenter)
    }
  }

  const showSegmentsButton = () => {
    return (
      <AnchorButton
        minimal
        intent={Intent.PRIMARY}
        onClick={(event) => {
          event.stopPropagation()
          setIsOpen(!isOpen)
        }}
        text={`${segmenters.length} ${pluralize(
          "segment",
          segmenters.length
        )} more`}
        icon={isOpen ? "chevron-down" : "chevron-right"}
      />
    )
  }

  const connectDataSourceButton = () => {
    return (
      <AnchorButton
        minimal
        intent={Intent.PRIMARY}
        onClick={() => history.push("/settings")}
        text={`Connect ${sectionDefinition.default_call_to_action} to enable this section`}
      />
    )
  }

  return (
    <div className="cardPreview">
      <Card
        interactive={!unavailable}
        className={classnames({ selected: selected })}
        onClick={onSectionCardClick}
      >
        <div className={classnames("sectionPreview", { unavailable })}>
          <div className="thumbnail">
            {thumbnailForSectionDefinition(sectionDefinition)}
          </div>
          <div>
            <H5>{sectionDefinition.name}</H5>
            <p>{sectionDefinition.description}</p>
          </div>
        </div>
        <PreviewActionsBar
          primaryButton={
            segmenters.length > 0 ? showSegmentsButton() : undefined
          }
          secondaryButton={unavailable ? connectDataSourceButton() : undefined}
        />
      </Card>
      {segmenters.length > 0 && (
        <SegmentsList
          isOpen={isOpen}
          segmenters={segmenters}
          sectionDefinition={sectionDefinition}
          selectedSegments={selectedSegments}
          onClick={onClick}
        />
      )}
    </div>
  )
}

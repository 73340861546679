import React from "react"
import { H4, HTMLTable } from "@blueprintjs/core"
import { ClickedChartDetails } from "../BusinessReportChartSection"
import { capitalize } from "../textHelpers"
import { formatCurrency } from "../formatters"
import pluralize from "pluralize"
import { formatDate } from "../DataTable"
import css from "./SubscriptionBased.module.css"

type SegmentDetailsTableProp = {
  details: Types.Api.SegmentDetailValue
  segmentName: string
  withHeader?: boolean
}

const SegmentDetailsTable = ({
  details,
  segmentName,
  withHeader,
}: SegmentDetailsTableProp) => {
  const headerFromSegmentName = (name: string) =>
    name === "_grouped" ? "Other" : capitalize(name)

  const fullDataShown = (segmentData: Types.Api.SegmentDetailValue) =>
    segmentData.rest.count === 0

  const renderSegmentDescription = (
    segmentData: Types.Api.SegmentDetailValue
  ) => {
    if (segmentData.total_count === 0) {
      return <p>No costs</p>
    } else {
      return (
        <>
          <p>
            <b>{segmentData.total_count}</b>{" "}
            {pluralize("cost record", segmentData.total_count)} with total value
            of <b>{formatCurrency(parseFloat(segmentData.total_value))}</b>
          </p>
          {!fullDataShown(segmentData) && (
            <p>
              <b>
                Top {segmentData.top_costs.length}{" "}
                {pluralize("cost", segmentData.top_costs.length)}
              </b>
            </p>
          )}
        </>
      )
    }
  }

  return (
    <div className={css.segmentDetails}>
      {withHeader && <H4>{headerFromSegmentName(segmentName)}</H4>}
      {renderSegmentDescription(details)}
      {details.total_count > 0 && (
        <div className={css.detailsTable}>
          <HTMLTable>
            <thead>
              <tr>
                <th>Category</th>
                <th>Date</th>
                <th>Invoice Number</th>
                <th className={css.numberCol}>Value</th>
              </tr>
            </thead>
            <tbody>
              {details.top_costs.map(
                (costDetail: Types.Api.SegmentDetailTopCost) => (
                  <tr key={costDetail.cost.id}>
                    <td className={css.customerNameCol}>{costDetail.name}</td>
                    <td>{formatDate(costDetail.cost.date)}</td>
                    <td>
                      {costDetail.cost.invoice_number
                        ? costDetail.cost.invoice_number
                        : "--"}
                    </td>
                    <td className={css.numberCol}>
                      {formatCurrency(parseFloat(costDetail.value))}
                    </td>
                  </tr>
                )
              )}
              {!fullDataShown(details) && (
                <tr>
                  <td>
                    {details.rest.count} more{" "}
                    {pluralize("cost record", details.rest.count)}
                  </td>
                  <td colSpan={4} className={css.numberCol}>
                    {formatCurrency(parseFloat(details.rest.value))}
                  </td>
                </tr>
              )}
            </tbody>
          </HTMLTable>
        </div>
      )}
    </div>
  )
}

type CostBasedDetailsProps = {
  detailedData: Types.Api.ActualDetail
  context: ClickedChartDetails
  keysToDisplay: string[]
}

const CostBasedDetails = ({
  context,
  detailedData,
  keysToDisplay,
}: CostBasedDetailsProps) => {
  return (
    <>
      {keysToDisplay.map((key) => (
        <SegmentDetailsTable
          key={`${context.segment_id}_${key}`}
          details={detailedData[key]}
          segmentName={key}
          withHeader={Object.keys(detailedData).length > 1}
        />
      ))}
    </>
  )
}

export default CostBasedDetails

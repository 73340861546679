import React, { useState } from "react"
import classNames from "classnames"
import Cohort from "./Cohort"
import BusinessReportChartSection from "./BusinessReportChartSection"
import {
  extractCohortData,
  isTotalSegmentOnly,
  tabTitleFromValueInSegment,
  extractSegmentAttrs,
} from "./chartHelpers"
import { Tab, Tabs } from "@blueprintjs/core"
import withCategorySwitcher from "./withCategorySwitcher"

import css from "./BusinessReportChartSection.module.css"

const CohortSection = ({
  section,
  renderCategorySwitcher,
  category,
  onShowDetails,
  canAccessDetails,
  filter,
  slideMode,
  withDetails,
  buildExportItems,
  renderSectionSettings,
  resolution,
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const tabsData = {}
  const shadeColor = ["churn_cohort"].includes(section.definition_id)
    ? "#ffa600"
    : "#82ca9d"

  const sectionToCohort = (section, segmentIndex, segmentValue) => {
    const { data, segmentId, startDate, endDate } = extractCohortData(
      section,
      segmentIndex,
      segmentValue,
      category
    )
    var onClick = null

    if (withDetails) {
      onClick = (date, cohortDate, cohortReferenceValue) =>
        onShowDetails({
          date,
          section_id: section.id,
          segment_id: segmentId,
          legacy_series: section.legacy_series,
          cohort_date: cohortDate,
          segment_value: filter ? filter.value : segmentValue,
          filter_details: true,
          filter_by: filter ? filter.value : segmentValue,
          start_date: startDate,
          end_date: endDate,
          cohortReferenceValue: cohortReferenceValue,
          noSummary: true,
          section_definition_id: section.definition_id,
        })
    }

    return (
      <Cohort
        data={data}
        shadeColor={shadeColor}
        onClick={onClick}
        referenceValue={section.reference_value}
        resolution={resolution}
      />
    )
  }

  const createTabTitle = (segmentValue, section, segment) => {
    if (isTotalSegmentOnly(section)) {
      return ""
    }

    return tabTitleFromValueInSegment(segmentValue, segment)
  }

  const renderExportSection = (tabData) => {
    if (!tabData || !canAccessDetails || slideMode) {
      return
    }

    return buildExportItems({
      segmentId: tabData.segmentId,
      category: category,
      segmentValue: tabData.segmentValue,
    })
  }

  const renderInTabs = () => {
    var count = -1
    const tabs = section.segments
      .map((segment, index) => {
        return extractSegmentAttrs(section, index).map((segmentValue) => {
          count++
          const key = `section.key_${index}_${segmentValue}`
          const tabTitle = createTabTitle(segmentValue, section, segment)
          tabsData[count] = segment.is_total
            ? { segmentId: segment.id }
            : { segmentId: segment.id, segmentValue: segmentValue }
          return (
            <Tab
              key={key}
              id={count}
              title={tabTitle}
              panelClassName="tabPanel"
              panel={sectionToCohort(section, index, segmentValue)}
            />
          )
        })
      })
      .flat()

    // setState is async function, and we need value immidiately
    // useEffect can not be use because it called after render
    let activeTabIndex = tabIndex
    if (
      (section.filtered && tabIndex !== 0) ||
      (tabIndex !== 0 && tabIndex >= tabs.length)
    ) {
      activeTabIndex = 0
      setTabIndex(0)
    }

    return (
      <Tabs
        key={`${section.id}-${section.filtered}`}
        id={section.id}
        animate
        className="tabs"
        onChange={(index) => setTabIndex(index)}
        defaultSelectedTabId={0}
        selectedTabId={activeTabIndex}
      >
        {tabs}
        <Tabs.Expander />
        <div className={classNames(css.alignCenter, css.sectionActions)}>
          {renderCategorySwitcher(
            renderExportSection(tabsData[activeTabIndex])
          )}
          {renderSectionSettings(tabsData[activeTabIndex]?.segmentId)}
        </div>
      </Tabs>
    )
  }

  return renderInTabs()
}

export default BusinessReportChartSection(withCategorySwitcher(CohortSection))

import React from "react"
import { H3, Tooltip, Icon, Position } from "@blueprintjs/core"
import classnames from "classnames"

import "./Content.css"

type ContentProps = {
  withSideMargins: boolean
  withTopMargin: boolean
  withBottomMargin: boolean
  doubleActionBarTopMargin: boolean
  children: React.ReactNode
}

export const Content = (props: ContentProps) => {
  const { children, withSideMargins } = props
  const withTopMargin =
    props.withTopMargin !== undefined ? props.withTopMargin : true
  const withBottomMargin =
    props.withBottomMargin !== undefined ? props.withBottomMargin : true

  return (
    <div
      className={classnames(
        withSideMargins ? "sideMargin" : "",
        withTopMargin ? "topMargin" : "",
        withBottomMargin ? "bottomMargin" : "",
        props.doubleActionBarTopMargin ? "doubleActionBarTopMargin" : ""
      )}
    >
      {children}
    </div>
  )
}

type SectionProps = {
  title: string
  helpText?: string
  subtitle?: string
  actionButton?: React.ReactNode
  children: React.ReactNode
  small?: boolean
}

export const Section = ({
  title,
  helpText,
  actionButton,
  subtitle,
  children,
  small,
}: SectionProps) => (
  <div className={small ? "smallSectionContent" : ""}>
    <div className="sectionHeader">
      <div className="captions">
        <div className="titleGroup">
          <H3 className="sectionTitle">{title}</H3>
          {helpText && (
            <Tooltip
              position={Position.TOP}
              openOnTargetFocus={false}
              popoverClassName="titleTooltip"
              content={helpText}
            >
              <Icon
                icon="info-sign"
                iconSize={14}
                className="titleTooltipSign"
              />
            </Tooltip>
          )}
        </div>
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>
      {actionButton}
    </div>
    {children}
  </div>
)

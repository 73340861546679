import React, { useState, ReactElement } from "react"
import { Menu, MenuItem } from "@blueprintjs/core"
import { IconName } from "@blueprintjs/icons"
import { Omnibar, IItemRendererProps } from "@blueprintjs/select"

type Item = Types.Api.BusinessReportSegmentValue

type ItemListRendererProps = {
  filteredItems: Item[]
  renderItem: (item: Item, index: number) => void
}

const ItemListRenderer = ({
  filteredItems,
  renderItem,
}: ItemListRendererProps) => {
  const VISIBLE_ITEMS_COUNT = 10
  const hasItemsToShow = filteredItems.length > 0

  if (hasItemsToShow) {
    const visibleItems = filteredItems.slice(0, VISIBLE_ITEMS_COUNT)
    const theRest = filteredItems.slice(VISIBLE_ITEMS_COUNT)

    return (
      <Menu>
        {visibleItems.map((item, index) => renderItem(item, index))}
        {theRest.length > 0 && (
          <MenuItem
            key="id-more"
            disabled={true}
            text={`${theRest.length} more, start typing segment name`}
          />
        )}
      </Menu>
    )
  } else {
    return (
      <Menu>
        <MenuItem
          key="id-noresults"
          disabled={true}
          text="Nothing found. Edit report and add missing segments."
        />
      </Menu>
    )
  }
}

type ReportOmnibarProps = {
  items: Item[]
  isOpen: boolean
  onClose: () => void
  onFilterSelect: (item: Item) => void
  rightElement?: ReactElement
}

const ReportOmnibar = ({
  items,
  isOpen,
  onClose,
  onFilterSelect,
  rightElement,
}: ReportOmnibarProps) => {
  const [query, setQuery] = useState("")

  const itemListRenderer = (props: ItemListRendererProps) => (
    <ItemListRenderer {...props} />
  )

  const itemRenderer = (
    item: Item,
    { handleClick, modifiers }: IItemRendererProps
  ) => {
    const text = item.value
    return (
      <MenuItem
        active={modifiers.active}
        label={item.segment.name}
        disabled={modifiers.disabled}
        key={`${item.segment.id}-${item.value}`}
        onClick={handleClick}
        text={text}
        shouldDismissPopover={false}
      />
    )
  }

  const itemPredicate = (query: string, item: Item) => {
    return (
      item.value.toLowerCase().includes(query.toLowerCase()) ||
      item.segment.name.toLowerCase().includes(query.toLowerCase())
    )
  }

  const onQueryChange = (query: string) => {
    setQuery(query)
  }

  const onItemSelect = (item: Item) => {
    setQuery("")
    onClose()
    onFilterSelect(item)
  }

  const inputProps = () => ({
    leftIcon: "filter" as IconName,
    rightElement: rightElement,
  })

  return (
    <Omnibar
      isOpen={isOpen}
      onClose={onClose}
      onItemSelect={onItemSelect}
      items={items}
      itemRenderer={itemRenderer}
      itemPredicate={itemPredicate}
      itemListRenderer={itemListRenderer}
      onQueryChange={onQueryChange}
      query={query}
      inputProps={inputProps()}
    />
  )
}

export default ReportOmnibar

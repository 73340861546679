import React, { useState } from "react"
import { HashLink as Link } from "react-router-hash-link"
import { useLocation } from "react-router-dom"
import { Collapse, Button, Intent } from "@blueprintjs/core"

import css from "./ReportContentTable.module.css"

const ReportContentTable = ({ report }) => {
  const [isOpen, setOpen] = useState(false)

  const location = useLocation()

  const scrollWithOffset = (el) => {
    const offset = 120
    const elementPosition = el.offsetTop - offset

    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    })
  }

  const toggleOpen = () => setOpen(!isOpen)

  return report.sections.length > 0 ? (
    <div className={css.contentTable}>
      <ul>
        <li>
          <Button
            icon={isOpen ? "chevron-down" : "chevron-right"}
            minimal
            intent={Intent.NONE}
            onClick={toggleOpen}
            text="Content"
          />
        </li>
        <Collapse
          isOpen={isOpen}
          keepChildrenMounted
          className={css.contentTableBody}
        >
          {report.sections.map((section, index) => (
            <li key={index}>
              <Link
                smooth
                to={`${location.pathname}${location.search}#${section.definition_id}`}
                scroll={scrollWithOffset}
              >{`${index + 1}. ${section.title}`}</Link>
            </li>
          ))}
        </Collapse>
      </ul>
    </div>
  ) : (
    <></>
  )
}

export default ReportContentTable

import React, { useContext, useState, useEffect, SyntheticEvent } from "react"
import { UserContext } from "./UserContext"
import {
  Button,
  Intent,
  Classes,
  Spinner,
  Dialog,
  ControlGroup,
  HTMLSelect,
} from "@blueprintjs/core"
import { AppToaster } from "./AppToaster"
import { writeToClipboard } from "./utils"
import Config from "./config"
import css from "./BusinessReportShareDialog.module.css"
import BusinessReportContext from "./BusinessReportContext"

type BusinessReportShareDialogProps = {
  isDialogOpen: boolean
  onDialogClose: () => void
  selectedFilter?: Types.Api.BusinessReportSegmentValue
  reportId: string
  currency: string
}

const BusinessReportShareDialog = ({
  isDialogOpen,
  onDialogClose,
  selectedFilter,
  reportId,
  currency,
}: BusinessReportShareDialogProps) => {
  const userContext = useContext(UserContext)
  const [url, setUrl] = useState(
    selectedFilter === null ? window.location.href : null
  )
  const [groupUrl, setGroupUrl] = useState<string | null>(null)
  const { settings } = useContext(BusinessReportContext)
  const [shareGroupLink, setShareGroupLink] = useState(false)

  const onCopyToClipboard = () => {
    if (shareGroupLink) {
      if (!groupUrl) return
      writeToClipboard(groupUrl, "Group link copied to clipboard")
    } else {
      if (!url) return
      writeToClipboard(url, "Link copied to clipboard")
    }
    onDialogClose()
  }

  useEffect(() => {
    if (!isDialogOpen) {
      setUrl(null)
      return
    }

    if (!selectedFilter) {
      setUrl(window.location.href)
      return
    }

    userContext
      .fetch(`/business_reports/${reportId}/share_secret`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          segmenter_id: selectedFilter.segment.id,
          segment_value: selectedFilter.value,
        }),
      })
      .then((data: Types.Api.ReportShareSecret) => {
        setUrl(
          `${window.location.origin}/#/reports/shared/${data.secret}?currency=${currency}`
        )
      })
      .catch((error: Error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Something went wrong and we could not generate share url for report",
        })
      })
  }, [selectedFilter, reportId, userContext, isDialogOpen, currency])

  useEffect(() => {
    if (!isDialogOpen || selectedFilter) {
      setGroupUrl(null)
      return
    }

    userContext
      .fetch(`/business_reports/${reportId}/group_secret`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      })
      .then((data: Types.Api.ReportGroupSecret) => {
        setGroupUrl(
          `${window.location.origin}/#/reports/group/${data.secret}?currency=${currency}`
        )
      })
      .catch((error: Error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Something went wrong and we could not generate group url for report",
        })
      })
  }, [isDialogOpen, reportId, userContext, selectedFilter, currency])

  const isLoading = () => {
    if (selectedFilter) {
      return !url
    }
    if (settings.repeatable) {
      return !groupUrl
    }
    return false
  }

  const loadingSpinner = () => {
    return <Spinner size={30} intent={Intent.PRIMARY} />
  }

  const sharedFilterContainer = () => {
    return (
      <div className={css.container}>
        <div>
          Anyone with this link can view report filtered by{" "}
          <b>
            {selectedFilter!.segment.name} {selectedFilter!.value}
          </b>
        </div>
        <Button
          text="Copy link"
          onClick={onCopyToClipboard}
          intent={Intent.PRIMARY}
          outlined
          className={css.button}
        />
      </div>
    )
  }

  const onShareLinkTypeChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    setShareGroupLink(event.currentTarget.value === "group")
  }

  const reportShareSingle = () => {
    return (
      <>
        Anyone with this link can view this report
        <Button
          text="Copy link"
          onClick={onCopyToClipboard}
          intent={Intent.PRIMARY}
          outlined
          className={css.button}
        />
      </>
    )
  }

  const reportSelect = () => {
    return (
      <>
        Anyone with this link can view
        <ControlGroup id="share-select-group">
          <HTMLSelect
            onChange={onShareLinkTypeChange}
            defaultValue={shareGroupLink ? "group" : "report"}
            large
          >
            <option value="report">this report</option>
            <option value="group">always newest report</option>
          </HTMLSelect>
          <Button
            text="Copy link"
            onClick={onCopyToClipboard}
            intent={Intent.PRIMARY}
            outlined
            className={css.button}
          />
        </ControlGroup>
      </>
    )
  }

  const sharedReportContainer = () => {
    return (
      <div className={css.container}>
        {settings.repeatable ? reportSelect() : reportShareSingle()}
      </div>
    )
  }

  const loading = isLoading()
  return (
    <Dialog
      isOpen={isDialogOpen}
      isCloseButtonShown={true}
      className={css.shareDialog}
      canOutsideClickClose={Config.canOutsideClickCloseDialog}
      onClose={onDialogClose}
      title="Share report"
      icon="link"
    >
      <div className={Classes.DIALOG_BODY}>
        {loading && loadingSpinner()}
        {!loading && selectedFilter && sharedFilterContainer()}
        {!loading && !selectedFilter && sharedReportContainer()}
      </div>
    </Dialog>
  )
}

export default BusinessReportShareDialog

import React, { useState, useContext, useEffect } from "react"
import {
  Drawer,
  Classes,
  Intent,
  Button,
  Icon,
  Callout,
} from "@blueprintjs/core"
import classnames from "classnames"
import CustomerSelect from "./CustomerSelect"
import CustomerInfo from "./CustomerInfo"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

import css from "./CustomerMergeDrawer.module.css"
import formCss from "./forms.module.css"

type CustomerMergeDrawerProps = {
  targetCustomer: Types.Api.Customer
  isOpen: boolean
  onSuccess: (customer: Types.Api.Customer) => any
  onCancel: () => any
}

const CustomerMergeDrawer = ({
  targetCustomer,
  onSuccess,
  onCancel,
  isOpen,
}: CustomerMergeDrawerProps) => {
  const [sourceCustomer, setSourceCustomer] =
    useState<Types.Api.Customer | null>(null)
  const userContext = useContext(UserContext)

  const onCustomerSelected = (selectedCustomer: Types.Api.Customer) => {
    setSourceCustomer(selectedCustomer)
  }

  useEffect(() => {
    if (!isOpen) {
      setSourceCustomer(null)
    }
  }, [isOpen, setSourceCustomer])

  const performMerge = (sourceCustomerId: string) => {
    const formData = {
      source_id: sourceCustomerId,
    }

    userContext
      .fetch(`/customers/${targetCustomer.id}/merge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      .then((customer) => onSuccess(customer))
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "We could not perform merge, please try again or contact us.",
        })
      })
  }

  const onMergeCustomer = () => {
    if (sourceCustomer) {
      performMerge(sourceCustomer.id)
    } else {
      AppToaster.showError({ message: "Please select a customer" })
    }
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="60%"
      onClose={onCancel}
      title={`Merge to ${targetCustomer.name}`}
    >
      <div className={classnames(Classes.DRAWER_BODY, css.mergeDrawer)}>
        {sourceCustomer && (
          <Callout intent={Intent.PRIMARY}>
            All payments of <b>{sourceCustomer.name}</b> will be transfered to{" "}
            <b>{targetCustomer.name}</b> and then customer will be deleted.
            Segment values of <b>{targetCustomer.name}</b> will not be changed.
          </Callout>
        )}
        <div className={css.customerSelection}>
          <div>
            <CustomerSelect
              title="From customer"
              onCustomerSelected={onCustomerSelected}
              onNewCustomerAdded={() => {}}
            />
            {sourceCustomer && <CustomerInfo customer={sourceCustomer} />}
          </div>
          <Icon icon="arrow-right" iconSize={80} color="grey" />
          <CustomerInfo customer={targetCustomer} />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            outlined
            text="Merge customers"
            onClick={onMergeCustomer}
            large
            intent={Intent.PRIMARY}
            disabled={!sourceCustomer}
          />
          <Button
            minimal
            text="Cancel"
            onClick={onCancel}
            large
            intent={Intent.DANGER}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default CustomerMergeDrawer

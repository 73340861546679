import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react"
import { UserContext } from "./UserContext"
import queryString from "query-string"
import { LoadingState, FailureState } from "./NonIdealState"
import withDetailedCustomer from "./withDetailedCustomer"
import CustomerDetailsDrawer from "./CustomerDetailsDrawer"
import { ClickedChartDetails } from "./BusinessReportChartSection"
import SubscriptionBasedDetails from "./sectionDetails/SubscriptionBased"
import DealBasedDetails from "./sectionDetails/DealBased"
import CostBasedDetails from "./sectionDetails/CostBased"
import css from "./SectionDetails.module.css"

const buildQueryString = (o: object) =>
  queryString.stringify(o, { skipNull: true })

const addUnderlyingDataType = (
  details: Types.Api.ActualDetail,
  underlyingData: Types.Api.SectionDetail["underlying_data"]
): Types.Api.ActualDetail => {
  const finalDetail: Types.Api.ActualDetail = {}
  Object.keys(details).forEach((key) => {
    finalDetail[key] = { ...details[key], underlying_data: underlyingData }
  })
  return finalDetail
}

const apiResponseToDetails = (
  apiResponse: Types.Api.SectionDetails,
  segmentValue: ClickedChartDetails["segment_value"],
  legacySeries: boolean
): Types.Api.ActualDetail => {
  const segmentValueKey = Array.isArray(segmentValue)
    ? "_grouped"
    : segmentValue || "total"
  const series = apiResponse.items
  if (series.length > 1) {
    if (legacySeries) {
      const details = {} as { [key: string]: any }
      series.forEach((serie) => {
        if (serie.details[segmentValueKey]) {
          // There are responses which don't have all series containing all data points.
          // For example predictions have different range than regular series in chart.
          details[serie.name] = {
            ...serie.details[segmentValueKey],
            underlying_data: serie.underlying_data,
          }
        }
      })
      return details
    } else {
      const found = series.find((serie) => serie.name === segmentValueKey)
      if (found) {
        return addUnderlyingDataType(found.details, found.underlying_data)
      }

      return {}
    }
  } else {
    return addUnderlyingDataType(series[0].details, series[0].underlying_data)
  }
}

type DetailedCustomer = object

type SectionDetailsProps = {
  context: ClickedChartDetails
  detailedCustomer: DetailedCustomer
  setDetailedCustomer: (c: DetailedCustomer) => void
  onDetailedCustomerUpdated: (customer: DetailedCustomer) => void
  withExplore?: boolean
  exploreTitle: string
  withPaymentsExport?: boolean
}

const SectionDetails = ({
  context,
  detailedCustomer,
  setDetailedCustomer,
  onDetailedCustomerUpdated,
  exploreTitle,
  withExplore = false,
  withPaymentsExport = false,
}: SectionDetailsProps) => {
  const userContext = useContext(UserContext)
  const [detailedData, setDetailedData] = useState<
    Types.Api.ActualDetail | undefined
  >(undefined)
  const [failedToFetchDetails, setFailedToFetchDetails] = useState(false)
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false)
  const sectionId = context.section_id
  const segmentId = context.segment_id
  const segmentValue = context.segment_value
  const date = context.date
  const cohortDate = context.cohort_date
  const filterDetails = context.filter_details
  const filterBy = context.filter_by

  const performFetchDetails =
    useCallback((): Promise<Types.Api.SectionDetails> => {
      const detailsPath = cohortDate ? "cohort_details" : "details"
      const queryString = buildQueryString({
        date,
        segment_id: segmentId,
        segment_value: segmentValue,
        cohort_date: cohortDate,
      })
      const path = `/business_report/sections/${sectionId}/${detailsPath}?${queryString}`
      return userContext.fetch(path)
    }, [userContext, segmentId, segmentValue, cohortDate, date, sectionId])

  const fetchDetails = () => {
    performFetchDetails()
      .then((data) => {
        setFailedToFetchDetails(false)
        setDetailedData(
          apiResponseToDetails(data, segmentValue, context.legacy_series)
        )
      })
      .catch(() => {
        setFailedToFetchDetails(true)
        setDetailedData(undefined)
      })
  }

  useEffect(fetchDetails, [
    userContext,
    sectionId,
    date,
    segmentId,
    failedToFetchDetails,
    context.legacy_series,
    performFetchDetails,
    segmentValue,
  ])

  const showCustomerDetails = (
    customer: Types.Api.SegmentDetailTopCustomer
  ) => {
    setDetailedCustomer(customer)
    setCustomerDetailsOpen(true)
  }

  const closeCustomerDetails = () => {
    setCustomerDetailsOpen(false)
  }

  const onCustomerRemove = () => {
    fetchDetails()
  }

  const keysToDisplayByDataType = useMemo(() => {
    if (!detailedData) return null

    const keys = Object.keys(detailedData)
      .filter((key) => !filterDetails || !filterBy || filterBy.includes(key))
      .sort(
        (left, right) =>
          parseFloat(detailedData[right].total_value) -
          parseFloat(detailedData[left].total_value)
      )

    if (keys.length === 0) return null

    return keys.reduce((res: any, key: any) => {
      res[detailedData[key].underlying_data] =
        res[detailedData[key].underlying_data] || []
      res[detailedData[key].underlying_data].push(key)
      return res
    }, {})
  }, [filterDetails, filterBy, detailedData])

  const renderDetailsForDatatype = (
    key: number,
    datatype: string,
    keys: string[]
  ) => {
    if (!detailedData) return <></>

    switch (datatype) {
      case "subscriptions":
        return (
          <SubscriptionBasedDetails
            key={key}
            detailedData={detailedData}
            context={context}
            keysToDisplay={keys}
            showCustomerDetails={showCustomerDetails}
            withPaymentsExport={withPaymentsExport}
            withExplore={withExplore}
            exploreTitle={exploreTitle}
          />
        )
      case "costs":
        return (
          <CostBasedDetails
            key={key}
            detailedData={detailedData}
            context={context}
            keysToDisplay={keys}
          />
        )
      case "deals":
        return (
          <DealBasedDetails
            key={key}
            detailedData={detailedData}
            context={context}
            keysToDisplay={keys}
          />
        )
      default:
        return <></>
    }
  }

  const renderDetails = () => {
    if (!detailedData) return <></>
    return (
      <>
        {detailedCustomer && (
          <CustomerDetailsDrawer
            isOpen={customerDetailsOpen}
            onClose={closeCustomerDetails}
            customer={detailedCustomer}
            onCustomerRemove={onCustomerRemove}
            onCustomerUpdated={onDetailedCustomerUpdated}
            onSubscriptionRemove={undefined}
            changeDetailedCustomer={setDetailedCustomer}
          />
        )}
        {keysToDisplayByDataType &&
          Object.keys(keysToDisplayByDataType).map((datatype, index) =>
            renderDetailsForDatatype(
              index,
              datatype,
              keysToDisplayByDataType[datatype]
            )
          )}
      </>
    )
  }

  return (
    <div className={css.detailsContainer}>
      {detailedData ? (
        <>{keysToDisplayByDataType && renderDetails()}</>
      ) : failedToFetchDetails ? (
        <FailureState title="We failed to fetch details : (" inDialog />
      ) : (
        <LoadingState inDialog />
      )}
    </div>
  )
}

export default withDetailedCustomer(SectionDetails)

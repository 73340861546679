import React, { useMemo } from "react"
import DataTable, {
  formatDate,
  DataType,
  ColumnValuesFilter,
  SearchInColumnValueFilter,
} from "./DataTable"
import SubscriptionCellWithActions from "./SubscriptionCellWithActions"
import { formatCurrency } from "./formatters"
import css from "./CustomerDetailsDrawer.module.css"

type DataTableSortBy = {
  id: string
  desc: boolean
}

type CustomerSubscriptionHistoriesTableProps = {
  histories: any[]
  onUpdateSuccess: () => void
  onRemoveSuccess: () => void
  currency: string
  onSort: (sortBy: DataTableSortBy[]) => void
  startedAtDefaultSort: "desc" | "asc"
}

const CustomerSubscriptionHistoriesTable = ({
  histories,
  onUpdateSuccess,
  onRemoveSuccess,
  currency,
  onSort,
  startedAtDefaultSort,
}: CustomerSubscriptionHistoriesTableProps) => {
  const defaultSortBy = useMemo(
    () => [
      {
        id: "started_at",
        desc: startedAtDefaultSort === "desc",
      },
    ],
    [startedAtDefaultSort]
  )
  const columns = useMemo(() => {
    return [
      {
        id: "started_at",
        Header: "Started at",
        accessor: "started_at",
        Cell: (props: any) => (
          <SubscriptionCellWithActions
            formattedValue={formatDate(props.cell.value)}
            subscription={props.row.original}
            onUpdateSuccess={onUpdateSuccess}
            onRemoveSuccess={onRemoveSuccess}
          />
        ),
        className: css.col,
        stickyLeft: true,
        sortType: "datetime",
      },
      {
        id: "invoice_numner",
        Header: "Invoice Number",
        accessor: (row: any) => row.invoice_number || "--",
        className: css.col,
        disableSortBy: true,
        Filter: SearchInColumnValueFilter,
        filter: "includes",
      },
      {
        id: "sub_product",
        Header: "Product",
        accessor: (row: any) => row.items[0].product_key,
        className: css.col,
        disableSortBy: true,
        Filter: ColumnValuesFilter,
        filter: "includes",
      },
      {
        id: "duration_in_months",
        Header: "Months",
        accessor: "duration_in_months",
        className: css.col,
        dataType: DataType.NUMBER,
        disableSortBy: true,
        Filter: ColumnValuesFilter,
        filter: "equals",
      },
      {
        id: "total_value",
        Header: `Total value (${currency})`,
        accessor: (row: any) => formatCurrency(parseFloat(row.total_value)),
        className: css.col,
        dataType: DataType.CURRENCY,
        disableSortBy: true,
      },
      {
        id: "monthly_amount",
        Header: `MRR (${currency})`,
        accessor: (row: any) => formatCurrency(parseFloat(row.monthly_amount)),
        className: css.col,
        dataType: DataType.CURRENCY,
        disableSortBy: true,
      },
      {
        id: "quanity",
        Header: "Units Sold",
        accessor: (row: any) => row.items[0].quantity,
        className: css.col,
        disableSortBy: true,
        dataType: DataType.NUMBER,
      },
    ]
  }, [onUpdateSuccess, onRemoveSuccess, currency])

  return (
    <DataTable
      wrapperClassName={css.timelineTable}
      columns={columns}
      data={histories}
      // @ts-ignore
      onSort={onSort}
      defaultSortBy={defaultSortBy}
    />
  )
}

export default CustomerSubscriptionHistoriesTable

import React, { useState } from "react"
import { Menu, MenuDivider, MenuItem, Tag, Button } from "@blueprintjs/core"
import {
  Select,
  ItemListRenderer,
  IItemRendererProps,
} from "@blueprintjs/select"

import css from "./SelectSegmentValue.module.css"

export type SegmentItem = {
  label: string
  name: string
  value: string
  type: string
  negated?: boolean
}

type SelectSegmentValueProps = {
  items: SegmentItem[]
  onItemSelect: (item: SegmentItem) => void
  activeItem?: SegmentItem
  targetRef?: any
  onItemNegateChange?: (item: SegmentItem) => void
}

type GroupedItems = {
  [key: string]: React.ReactElement[]
}

const SegmentSelect = Select.ofType<SegmentItem>()

type NegateButtonProps = {
  item: SegmentItem
  onNegateChange: (item: SegmentItem) => void
}

const NegateButton = ({ item, onNegateChange }: NegateButtonProps) => {
  const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    onNegateChange(item)
  }

  return (
    <Button
      intent="primary"
      minimal
      small
      onClick={onClick}
      icon={item.negated ? "not-equal-to" : "equals"}
    />
  )
}

const SelectSegmentValue = ({
  items,
  onItemSelect,
  onItemNegateChange,
  activeItem,
  targetRef,
}: SelectSegmentValueProps) => {
  const [selectedItem, setSelectedItem] = useState<SegmentItem | null>(
    activeItem || null
  )

  const itemPredicate = (query: string, item: SegmentItem) => {
    if (query.length === 0) return true

    const lowercaseQuery = query.toLowerCase()

    return (
      item.label.toLowerCase().includes(lowercaseQuery) ||
      item.value.toLowerCase().includes(lowercaseQuery)
    )
  }

  const itemRenderer = (
    item: SegmentItem,
    { handleClick, index, modifiers }: IItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        key={`${item.name}_${index}`}
        text={item.value}
        onClick={handleClick}
        active={modifiers.active}
      />
    )
  }

  const handleItemSelect = (item: SegmentItem) => {
    setSelectedItem(item)
    onItemSelect(item)
  }

  const renderMenu: ItemListRenderer<SegmentItem> = ({
    filteredItems,
    itemsParentRef,
    renderItem,
  }) => {
    if (filteredItems.length === 0) {
      return (
        <Menu ulRef={itemsParentRef}>
          <MenuItem disabled text="No results" />
        </Menu>
      )
    }

    const groupedItems = filteredItems.reduce(
      (res: GroupedItems, item: SegmentItem, index: number) => {
        const rendered = renderItem(item, index)
        if (rendered === null) return res

        res[item.label] = res[item.label] || []
        res[item.label].push(rendered)
        return res
      },
      {}
    )

    const renderedItems = Object.keys(groupedItems).flatMap((key, index) => {
      return [
        <MenuDivider key={index} title={key} className={css.noBorder} />,
        groupedItems[key],
      ].flat()
    })

    return <Menu ulRef={itemsParentRef}>{renderedItems}</Menu>
  }

  const onNegateChange = (item: SegmentItem) => {
    const newItem = {
      ...item,
      negated: !item.negated,
    }
    setSelectedItem(newItem)

    if (onItemNegateChange) onItemNegateChange(newItem)
  }

  return (
    <SegmentSelect
      activeItem={selectedItem}
      items={items}
      itemListRenderer={renderMenu}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      onItemSelect={handleItemSelect}
      fill
      popoverProps={{ minimal: true }}
      inputProps={{ large: true }}
      filterable
      matchTargetWidth
    >
      <Tag
        className={css.segmentValueTag}
        rightIcon="double-caret-vertical"
        large
        interactive
        fill
        minimal
        elementRef={targetRef}
        icon={
          selectedItem &&
          onItemNegateChange && (
            <NegateButton item={selectedItem} onNegateChange={onNegateChange} />
          )
        }
      >
        {selectedItem ? (
          <>
            {selectedItem.label} is {selectedItem.negated ? "not" : ""}{" "}
            <b>{selectedItem.value}</b>
          </>
        ) : (
          "Select value"
        )}
      </Tag>
    </SegmentSelect>
  )
}

export default SelectSegmentValue

import { UserContextProps } from "../UserContext"

const CostApi = {
  emptySegmentsToNull: (cost: Types.Api.Cost) => {
    const segments = cost.segments.map((segment) => {
      if (segment.value === "") {
        return { ...segment, value: null }
      }

      return segment
    })

    return { ...cost, segments }
  },

  upsert: (userContext: UserContextProps, cost: Types.Api.Cost) => {
    return userContext.fetch(`/costs/upsert/${cost.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(CostApi.emptySegmentsToNull(cost)),
    })
  },

  delete: (userContext: UserContextProps, costId: Types.Api.Cost["id"]) => {
    return userContext.fetch(`/costs/${costId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
  },

  create: (userContext: UserContextProps, cost: Types.Api.Cost) => {
    return userContext.fetch(`/costs`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(CostApi.emptySegmentsToNull(cost)),
    })
  },
}

export default CostApi

import React, { useContext, useState, useCallback, Dispatch } from "react"
import { Button, Intent } from "@blueprintjs/core"
import AddComment from "./AddComment"
import CommentsList from "./CommentsList"
import { UserContext } from "../UserContext"
import BusinessReportContext from "../BusinessReportContext"
import "./CommentsContainer.css"
import { Action } from "../businessReportReducer"

type CommentsContainerProps = {
  commentedId: string
  demo: boolean
  comments: Types.Api.Comment[]
  dispatch: Dispatch<Action>
}

const CommentsContainer = React.memo(
  ({ commentedId, demo, comments, dispatch }: CommentsContainerProps) => {
    const [commentsListOpen, setCommentsListOpen] = useState(false)
    const userContext = useContext(UserContext)
    const reportContext = useContext(BusinessReportContext)

    const toggleCommentsListOpen = () => {
      setCommentsListOpen(!commentsListOpen)
    }

    const onCommentAdded = useCallback(
      (comment) => {
        dispatch({ type: "addComment", value: comment, sectionId: commentedId })
      },
      [commentedId, dispatch]
    )

    const toggleCommentPin = useCallback(
      (commentId, pinned) => {
        userContext
          .fetch(`/comments/${commentId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ pinned: pinned }),
          })
          .then((data: Types.Api.Comment) => {
            dispatch({
              type: "updateComment",
              value: data,
              sectionId: commentedId,
            })
          })
      },
      [userContext, dispatch, commentedId]
    )

    const canAddComments =
      !demo &&
      userContext.hasUserLevelAccess() &&
      reportContext.accountId === userContext!.user!.account_id

    return (
      <>
        {comments && (
          <>
            <div className="commentsButtons">
              <Button
                onClick={toggleCommentsListOpen}
                minimal
                intent={Intent.NONE}
                icon="comment"
              >
                {comments.length > 0 ? comments.length : ""}
              </Button>
            </div>
            <CommentsList
              allowPin={false}
              comments={comments}
              isOpen={commentsListOpen}
              toggleCommentPin={toggleCommentPin}
              demo={demo}
            />
            {canAddComments && (
              <AddComment
                commentedId={commentedId}
                isOpen={commentsListOpen}
                onCommentAdded={onCommentAdded}
                onCancel={toggleCommentsListOpen}
              />
            )}
          </>
        )}
      </>
    )
  }
)

export default CommentsContainer

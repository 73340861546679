import React from "react"
import { H4, HTMLTable } from "@blueprintjs/core"
import { ClickedChartDetails } from "../BusinessReportChartSection"
import { capitalize } from "../textHelpers"
import { formatCurrency } from "../formatters"
import pluralize from "pluralize"
import { formatText } from "../DataTable"
import tableCss from "../DataTable.module.css"
import css from "./SubscriptionBased.module.css"

type SegmentDetailsTableProp = {
  details: Types.Api.SegmentDetailValue
  segmentName: string
  withHeader?: boolean
}

const SegmentDetailsTable = ({
  details,
  segmentName,
  withHeader,
}: SegmentDetailsTableProp) => {
  const headerFromSegmentName = (name: string) =>
    name === "_grouped" ? "Other" : capitalize(name)

  const fullDataShown = (segmentData: Types.Api.SegmentDetailValue) =>
    segmentData.rest.count === 0

  const renderDealNameCell = (deal: Types.Api.SegmentDetailTopDeal) => {
    const dealName = formatText(deal.name)
    const onClick = deal.external_url
      ? () => window.open(deal.external_url)
      : undefined

    return (
      <span
        className={deal.external_url ? tableCss.clickableText : ""}
        onClick={onClick}
      >
        {dealName}
      </span>
    )
  }

  const renderSegmentDescription = (
    segmentData: Types.Api.SegmentDetailValue
  ) => {
    if (segmentData.total_count === 0) {
      return <p>No deals</p>
    } else {
      return (
        <>
          <p>
            <b>{segmentData.total_count}</b>{" "}
            {pluralize("deal", segmentData.total_count)} with total value of{" "}
            <b>{formatCurrency(parseFloat(segmentData.total_value))}</b>
          </p>
          {!fullDataShown(segmentData) && (
            <p>
              <b>
                Top {segmentData.top_deals.length}{" "}
                {pluralize("deal", segmentData.top_deals.length)}
              </b>
            </p>
          )}
        </>
      )
    }
  }

  return (
    <div className={css.segmentDetails}>
      {withHeader && <H4>{headerFromSegmentName(segmentName)}</H4>}
      {renderSegmentDescription(details)}
      {details.total_count > 0 && (
        <div className={css.detailsTable}>
          <HTMLTable>
            <thead>
              <tr>
                <th>Deal name</th>
                <th className={css.numberCol}>Days in stage</th>
                <th className={css.numberCol}>Value</th>
              </tr>
            </thead>
            <tbody>
              {details.top_deals.map((deal: Types.Api.SegmentDetailTopDeal) => (
                <tr key={deal.public_id}>
                  <td className={css.customerNameCol}>
                    {renderDealNameCell(deal)}
                  </td>
                  <td className={css.numberCol}>
                    {deal.stage ? deal.stage.days_since_moved_on : "--"}
                  </td>
                  <td className={css.numberCol}>
                    {formatCurrency(parseFloat(deal.value))}
                  </td>
                </tr>
              ))}
              {!fullDataShown(details) && (
                <tr>
                  <td>
                    {details.rest.count} more{" "}
                    {pluralize("deal", details.rest.count)}
                  </td>
                  <td colSpan={4} className={css.numberCol}>
                    {formatCurrency(parseFloat(details.rest.value))}
                  </td>
                </tr>
              )}
            </tbody>
          </HTMLTable>
        </div>
      )}
    </div>
  )
}

type DealBasedDetailsProps = {
  detailedData: Types.Api.ActualDetail
  context: ClickedChartDetails
  keysToDisplay: string[]
}

const DealBasedDetails = ({
  context,
  detailedData,
  keysToDisplay,
}: DealBasedDetailsProps) => {
  return (
    <>
      {keysToDisplay.map((key) => (
        <SegmentDetailsTable
          key={`${context.segment_id}_${key}`}
          details={detailedData[key]}
          segmentName={key}
          withHeader={Object.keys(detailedData).length > 1}
        />
      ))}
    </>
  )
}

export default DealBasedDetails

import React, { useState, useCallback } from "react"

const SubscriptionFormValidationImpl = (WrappedComponent, props) => {
  const [subscriptionFormData, setSubscriptionFormData] = useState(
    props.subscriptionFormData
  )

  const mergeProductKey = (subscriptionFormData, productKey) => {
    const item = subscriptionFormData.items[0]
    const newItem = { ...item, product_key: productKey }
    const items = [newItem].concat(subscriptionFormData.items.slice(1))

    return { ...subscriptionFormData, items: items }
  }

  const onSubscriptionFormFieldChange = useCallback(
    (value, fieldKey) => {
      switch (fieldKey) {
        case "total_value":
          setSubscriptionFormData({
            ...subscriptionFormData,
            total_value: value,
          })
          break
        case "product_key":
          setSubscriptionFormData(mergeProductKey(subscriptionFormData, value))
          break
        case "item_type":
          setSubscriptionFormData({
            ...subscriptionFormData,
            items: [{ ...subscriptionFormData.items[0], type: value }].concat(
              subscriptionFormData.items.slice(1)
            ),
          })
          break
        case "quantity":
          setSubscriptionFormData({
            ...subscriptionFormData,
            items: [
              {
                ...subscriptionFormData.items[0],
                quantity: value.length === 0 ? "" : parseInt(value),
              },
            ].concat(subscriptionFormData.items.slice(1)),
          })
          break
        case "started_at":
          setSubscriptionFormData({
            ...subscriptionFormData,
            started_at: value,
          })
          break
        case "duration_in_months":
          setSubscriptionFormData({
            ...subscriptionFormData,
            duration_in_months: value,
          })
          break
        case "currency":
          setSubscriptionFormData({ ...subscriptionFormData, currency: value })
          break
        default:
          console.log(`Unknown field in new subscription form: ${fieldKey}`)
      }
    },
    [subscriptionFormData]
  )

  const canSubmitSubscription = () => {
    const productKey = subscriptionFormData.items[0].product_key
    const quantity = subscriptionFormData.items[0].quantity
    return (
      subscriptionFormData.total_value &&
      subscriptionFormData.started_at &&
      subscriptionFormData.duration_in_months &&
      productKey &&
      productKey.length > 0 &&
      quantity
    )
  }

  return (
    <WrappedComponent
      {...props}
      onSubscriptionFormFieldChange={onSubscriptionFormFieldChange}
      subscriptionFormData={subscriptionFormData}
      setSubscriptionFormData={setSubscriptionFormData}
      canSubmitSubscription={canSubmitSubscription()}
    />
  )
}

export const withSubscriptionFormValidation = (WrappedComponent) => {
  return (props) => SubscriptionFormValidationImpl(WrappedComponent, props)
}

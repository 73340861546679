import React, { useMemo, useState, useCallback, useContext } from "react"
import { Intent, Button } from "@blueprintjs/core"
import DataTable, { formatDate, formatText, DataType } from "./DataTable"
import { formatCurrency } from "./formatters"
import CustomerDetailsDrawer from "./CustomerDetailsDrawer"
import { UserContext } from "./UserContext"
import withDetailedCustomer from "./withDetailedCustomer"

import css from "./SubscriptionsTable.module.css"
import tableCss from "./DataTable.module.css"

export const SubscriptionsTable = ({
  subscriptions,
  onSort,
  onNextPage,
  onPrevPage,
  range,
  onCustomerRemove,
  onSubscriptionRemove,
  detailedCustomer,
  setDetailedCustomer,
  onDetailedCustomerUpdated,
}) => {
  const userContext = useContext(UserContext)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const onDetails = useCallback(
    (customer) => {
      setDetailedCustomer(customer)
      setIsDetailsOpen(true)
    },
    [setDetailedCustomer]
  )

  const onClose = () => setIsDetailsOpen(false)

  const customerNameCellRender = useCallback(
    (props) => {
      return (
        <span
          className={tableCss.clickableText}
          onClick={() => onDetails(props.row.original.customer)}
        >
          {formatText(props.cell.value)}
        </span>
      )
    },
    [onDetails]
  )

  const columns = useMemo(
    () => [
      {
        id: "customer.name",
        Header: "Customer name",
        accessor: (row) => row.customer.name,
        Cell: customerNameCellRender,
        className: css.colCustomerName,
        sortType: "basic",
      },
      {
        id: "invoice_number",
        Header: "Invoice number",
        accessor: "invoice_number",
        className: css.colInvoiceNumber,
        disableSortBy: true,
        dataType: DataType.STRING,
      },
      {
        id: "started_at",
        Header: "Started at",
        accessor: (row) => formatDate(row.started_at),
        className: css.colStartedAt,
        sortType: "datetime",
      },
      {
        id: "total_value",
        Header: `Total value (${userContext.user.currency})`,
        accessor: (row) => formatCurrency(parseFloat(row.total_value)),
        className: css.colTotalValue,
        dataType: DataType.CURRENCY,
      },
      {
        id: "duration_in_months",
        Header: "Duration (month)",
        accessor: "duration_in_months",
        className: css.colDuration,
        dataType: DataType.NUMBER,
      },
      {
        id: "monthly_amount",
        Header: `Monthly amount (${userContext.user.currency})`,
        accessor: (row) => formatCurrency(parseFloat(row.monthly_amount)),
        className: css.colMonthlyAmount,
        dataType: DataType.CURRENCY,
      },
    ],
    [customerNameCellRender, userContext.user.currency]
  )

  return (
    <>
      <CustomerDetailsDrawer
        isOpen={isDetailsOpen}
        customer={detailedCustomer}
        onCustomerUpdated={onDetailedCustomerUpdated}
        onClose={onClose}
        onCustomerRemove={onCustomerRemove}
        onSubscriptionRemove={onSubscriptionRemove}
        changeDetailedCustomer={setDetailedCustomer}
      />
      <DataTable
        columns={columns}
        data={subscriptions}
        onSort={onSort}
        fullWidth
        capitalize
      />
      <div className={css.navigation}>
        <Button
          onClick={onPrevPage}
          disabled={onPrevPage ? false : true}
          text="Previous page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
        {range && <div>{`${range[0]} - ${range[1]}`}</div>}
        <Button
          onClick={onNextPage}
          disabled={onNextPage ? false : true}
          text="Next page"
          intent={Intent.PRIMARY}
          large
          minimal
        />
      </div>
    </>
  )
}

export default withDetailedCustomer(SubscriptionsTable)

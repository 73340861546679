import React from "react"
import { withRouter } from "react-router-dom"
import classnames from "classnames"

import "./Logo.css"

const Logo = ({ history, whiteLogo }) => {
  const navigateToRoot = () => {
    history.push("/")
  }
  return (
    <div
      className={classnames("logoImg", whiteLogo ? "whiteLogo" : "blueLogo")}
      onClick={navigateToRoot}
    ></div>
  )
}

export default withRouter(Logo)

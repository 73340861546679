import formatDistanceToNow from "date-fns/formatDistanceToNow"
import parseISO from "date-fns/parseISO"
import fnsFormat from "date-fns/format"
import add from "date-fns/add"
import sub from "date-fns/sub"
import getUnixTime from "date-fns/getUnixTime"
import fromUnixTime from "date-fns/fromUnixTime"
import formatISO from "date-fns/formatISO"
import Decimal from "decimal.js"

export const TABLE_DATE_FORMAT = "MMM d, yyyy"
export const TABLE_HEADER_DATE_FORMAT = "MMM `yy"
const MONTH_YEAR_FORMAT = "MMM yyyy"
const YEAR_FORMAT = "yyyy"
const QUARTER_YEAR_FORMAT = "QQQ yyyy"
const WEEK_MONTH_YEAR_FORMAT = "'W'I MMM yyyy"

export const monthName = (number: number) =>
  fnsFormat(dateFromYearAndMonth(2021, number), "LLLL")

export const formatCurrency = (
  value: number,
  options = { maximumFractionDigits: 0 }
) => {
  return value.toLocaleString("en-US", options)
}

export const formatCurrencyForEdit = (value: string) => {
  return new Decimal(value).toDecimalPlaces(2).toString()
}

export const formatTimeDistance = (isoValue: string) => {
  return formatDistanceToNow(parseISO(isoValue))
}

export const formatTime = (isoValue: string, format: string) => {
  if (isoValue.length == 10) {
    // make sure that day-only dates are treated as UTC not user-timezone
    isoValue = `${isoValue}T00:00:00Z`
  }
  const date = parseISO(isoValue)
  const tzMinutes = date.getTimezoneOffset()
  const utcDate = add(parseISO(isoValue), { minutes: tzMinutes })
  return fnsFormat(utcDate, format)
}

export const isoTimeToUnixSeconds = (isoValue: string) => {
  if (isoValue.length == 10) {
    // make sure that day-only dates are treated as UTC not user-timezone
    isoValue = `${isoValue}T00:00:00Z`
  }
  return getUnixTime(parseISO(isoValue))
}

export const unixSecondsToString = (unixSeconds: number, format: string) => {
  const date = fromUnixTime(unixSeconds)
  const tzMinutes = date.getTimezoneOffset()
  const utcDate = add(fromUnixTime(unixSeconds), { minutes: tzMinutes })
  return fnsFormat(utcDate, format)
}

export const jsDateToISOString = (jsDate: Date) => {
  return formatISO(jsDate, { representation: "date" })
}

export const formatTimeInTable = (isoValue: string) => {
  return formatTime(isoValue, TABLE_DATE_FORMAT)
}

export const formatTimeInTableHeader = (isoValue: string) => {
  return formatTime(isoValue, TABLE_HEADER_DATE_FORMAT)
}

export const formatMonthYearISO = (
  isoValue: string,
  resolution: Types.Api.Section["resolution"] = "month"
) => {
  return formatTime(isoValue, resoltuionToFormat(resolution))
}

const resoltuionToFormat = (resolution: Types.Api.Section["resolution"]) => {
  switch (resolution) {
    case "week":
      return WEEK_MONTH_YEAR_FORMAT
      break
    case "month":
      return MONTH_YEAR_FORMAT
      break
    case "quarter":
      return QUARTER_YEAR_FORMAT
      break
    case "year":
      return YEAR_FORMAT
      break
    default:
      return ""
  }
}

export const formatMonthYearDate = (isoValue: string) => {
  return formatTime(isoValue, MONTH_YEAR_FORMAT)
}

export const formatPercent = (value: number) => {
  return new Decimal(value).toDecimalPlaces(1).toString() + "%"
}

export const dateFromYearAndMonth = (year: number, month: number) => {
  const date = new Date(year, month - 1, 1)
  const tzMinutes = date.getTimezoneOffset()
  return sub(date, { minutes: tzMinutes })
}

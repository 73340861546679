import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useSectionDefinitions = () => {
  const [sectionsDefinition, setSectionsDefinition] = useState<
    Types.Api.SectionDefinition[]
  >([])

  const userContext = useContext(UserContext)

  const fetchSections = useCallback(() => {
    if (!userContext.isLoggedIn) return

    userContext
      .fetch("/business_report/section_definitions", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((data: any) => setSectionsDefinition(data.items))
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "We could not fetch section definitions, you will not be able to add a new chart. Try refreshing the page.",
        })
      })
  }, [userContext])

  useEffect(() => {
    fetchSections()
  }, [userContext, fetchSections])

  return sectionsDefinition
}

export default useSectionDefinitions

import React, { useState, useContext } from "react"
import {
  Dialog,
  Classes,
  FileInput,
  Button,
  NonIdealState,
  Spinner,
  Intent,
} from "@blueprintjs/core"
import useInterval from "react-useinterval"
import { AppToaster } from "./AppToaster"
import { UserContext } from "./UserContext"

import "./DataImportDialog.css"

const uploadCsv = (file, userContext, onSuccess, onFailure, onError) => {
  const formData = new FormData()
  formData.append("paying_customers", file)
  formData.append(
    "column_order",
    "customer_id,started_at,ended_at,monthly_amount"
  )

  const requestOptions = {
    method: "POST",
    body: formData,
  }
  userContext
    .fetch("/subscriptions/csv_import", requestOptions)
    .then((data) => onSuccess(data))
    .catch((error) => {
      if (error.response.status === 400) {
        error.response.json().then((data) => onFailure(data))
      } else {
        onError()
      }
    })
}

const DialogActions = ({ onImport, importDisabled, onClose, onTryAgain }) => (
  <div className={Classes.DIALOG_FOOTER}>
    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
      <a href="mailto:support@getprobe.io" className="secondaryAction">
        Let us handle it
      </a>
      {onClose && (
        <Button
          text="Close"
          onClick={onClose}
          intent={Intent.NONE}
          outlined
          large
        />
      )}
      {onTryAgain && (
        <Button
          text="Try again"
          onClick={onTryAgain}
          intent={Intent.PRIMARY}
          large
        />
      )}
      {onImport && (
        <Button
          disabled={importDisabled}
          text="Import"
          onClick={onImport}
          intent={Intent.PRIMARY}
          large
        />
      )}
    </div>
  </div>
)

const CsvImportFailureBody = ({ message, failures }) => (
  <>
    <p>{message}</p>
    <div className="failures">
      {failures.map((failure, index) => (
        <p key={index}>
          <b>Error in csv row number {failure.row_number}: </b>
          {failure.row}
          <br />
          <b>Message: </b>
          {failure.message}
          <br />
        </p>
      ))}
    </div>
  </>
)

const CsvImportFailure = ({ message, failures, tryAgain }) => (
  <>
    <div className={Classes.DIALOG_BODY}>
      <CsvImportFailureBody message={message} failures={failures} />
    </div>
    <DialogActions onTryAgain={tryAgain} />
  </>
)

const CsvImportInProgress = ({ onClose }) => (
  <>
    <div className={Classes.DIALOG_BODY}>
      <p>Your import is in progress, you can close this window now.</p>
      <Spinner className="importSpinner" intent={Intent.PRIMARY} />
    </div>
    <DialogActions onClose={onClose} />
  </>
)

const CsvImportError = ({ tryAgain }) => (
  <>
    <div className={Classes.DIALOG_BODY}>
      <NonIdealState
        icon="error"
        title="We are sorry, something went wrong"
        description={
          <>
            Please <a href="mailto:support@getprobe.io">get in touch</a> or try
            importing again
          </>
        }
      />
    </div>
    <DialogActions onTryAgain={tryAgain} />
  </>
)

const CsvImportCompleted = ({ result, onClose, tryAgain }) => {
  const hasFailures = () => result.failure_count > 0

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <p>{`Your import is completed. ${result.success_count} ${
          result.success_count === 1 ? "row" : "rows"
        } imported succesfully.`}</p>
        {hasFailures() && (
          <CsvImportFailureBody
            message={`We failed to import ${result.failure_count} rows. Please check details bellow. You can fix them and import full file again.`}
            failures={result.failures}
          />
        )}
      </div>
      <DialogActions
        onClose={onClose}
        onTryAgain={hasFailures() ? tryAgain : null}
      />
    </>
  )
}

const CsvImportInput = ({ onClose, submitImport }) => {
  const [selectedFile, setSelectedFile] = useState(null)

  const fileInputText = () => {
    return selectedFile ? selectedFile.name : "Choose file ..."
  }

  const onFileSelect = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <p>Select CSV file with information on your paying customers</p>
        <FileInput
          enabled
          large
          fill
          onInputChange={onFileSelect}
          text={fileInputText()}
          inputProps={{ accept: ".csv" }}
        />
      </div>
      <DialogActions
        onImport={() => submitImport(selectedFile)}
        importDisabled={!selectedFile}
        onClose={onClose}
      />
    </>
  )
}

export const DataImportStatusPolling = ({ importId, onCompleted, onError }) => {
  // What the limit should be for pooling if any?
  const MAX_ATTEMPTS = 1000

  const [isRunning, setIsRunning] = useState(true)
  const [attemptCount, setAttemptCount] = useState(0)
  const userContext = useContext(UserContext)

  const onImportStatusUpdate = (data) => {
    if (data.result.status === "done") {
      onImportCompleted(data)
    }
  }

  const pollingInterval = (count) => {
    if (count <= 20) {
      return 3000
    } else if (count > 20 && count <= 70) {
      return 10000
    } else if (count > 70 && count < 170) {
      return 20000
    } else {
      return 60000
    }
  }

  const onImportCompleted = (data) => {
    setIsRunning(false)
    onCompleted(data)
  }

  const onImportError = (error) => {
    setIsRunning(false)
    onError(error)
  }

  const fetchStatus = () => {
    if (attemptCount >= MAX_ATTEMPTS) {
      setIsRunning(false)
      onImportError("Failed to get status of import, please get in touch")
      return
    }

    setAttemptCount(attemptCount + 1)

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
    userContext
      .fetch(`/subscriptions/csv_import/${importId}`, requestOptions)
      .then((data) => onImportStatusUpdate(data))
      .catch((error) => {
        setIsRunning(false)
        onImportError(error)
      })
  }

  useInterval(
    fetchStatus,
    isRunning ? pollingInterval(attemptCount) : null,
    MAX_ATTEMPTS
  )

  return <></>
}

const DataImportDialog = ({ isOpen, onClose, openImportDialog }) => {
  const IMPORT_STATUS = {
    NOT_STARTED: "not_started",
    VALIDATION_FAILURE: "validation_failure",
    IN_PROGRESS: "in_progress",
    COMPLETED: "completed",
    ERROR: "error",
  }

  const [failures, setFailures] = useState([])
  const [importResult, setImportResult] = useState(null)
  const [importId, setImportId] = useState(localStorage.getItem("import_id"))
  const [importStatus, setImportStatus] = useState(
    importId ? IMPORT_STATUS.IN_PROGRESS : IMPORT_STATUS.NOT_STARTED
  )
  const userContext = useContext(UserContext)

  const onImportCompleted = (result) => {
    if (!isOpen) {
      AppToaster.show({
        icon: "import",
        timeout: 30000,
        intent: Intent.SUCCESS,
        message: "Your import is completed",
        action: {
          onClick: openImportDialog,
          text: "Details",
        },
        onDismiss: clearImportState,
      })
    }

    setImportResult(result)
    setImportStatus(IMPORT_STATUS.COMPLETED)
  }

  const onImportError = () => {
    AppToaster.show({
      icon: "import",
      timeout: 30000,
      intent: Intent.DANGER,
      message: "There were a problem with you import",
      action: {
        onClick: openImportDialog,
        text: "Details",
      },
      onDismiss: clearImportState,
    })
    onError()
  }

  const onUploadSuccess = (response_data) => {
    localStorage.setItem("import_id", response_data.id)
    setImportId(response_data.id)
    setImportStatus(IMPORT_STATUS.IN_PROGRESS)
  }

  const onValidationFailure = (failure_data) => {
    setFailures(failure_data.validation_failures)
    setImportStatus(IMPORT_STATUS.VALIDATION_FAILURE)
  }

  const onError = () => {
    setImportStatus(IMPORT_STATUS.ERROR)
  }

  const submitImport = (file) => {
    uploadCsv(file, userContext, onUploadSuccess, onValidationFailure, onError)
  }

  const tryAgain = () => {
    setImportStatus(IMPORT_STATUS.NOT_STARTED)
  }

  const clearImportState = () => {
    localStorage.removeItem("import_id")
  }

  const onImportDialogClose = () => {
    if (importStatus === IMPORT_STATUS.COMPLETED) {
      clearImportState()
    }
    onClose()
  }

  return (
    <>
      {importStatus === IMPORT_STATUS.IN_PROGRESS && (
        <DataImportStatusPolling
          importId={importId}
          onCompleted={onImportCompleted}
          onError={onImportError}
        />
      )}
      <Dialog
        icon="import"
        title="CSV import"
        isOpen={isOpen}
        onClose={onImportDialogClose}
      >
        {importStatus === IMPORT_STATUS.VALIDATION_FAILURE && (
          <CsvImportFailure
            message="There were some issues with processing your file, please take a look below"
            failures={failures}
            tryAgain={tryAgain}
          />
        )}
        {importStatus === IMPORT_STATUS.ERROR && (
          <CsvImportError tryAgain={tryAgain} />
        )}
        {importStatus === IMPORT_STATUS.NOT_STARTED && (
          <CsvImportInput onClose={onClose} submitImport={submitImport} />
        )}
        {importStatus === IMPORT_STATUS.IN_PROGRESS && (
          <CsvImportInProgress onClose={onClose} />
        )}
        {importStatus === IMPORT_STATUS.COMPLETED && (
          <CsvImportCompleted
            result={importResult}
            onClose={onImportDialogClose}
            tryAgain={tryAgain}
          />
        )}
      </Dialog>
    </>
  )
}

export default DataImportDialog

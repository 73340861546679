import { Tag } from "@blueprintjs/core"
import React from "react"
import { ViewNode, View, ValueNode } from "./ReportViewDrawer"
import sectionStyles from "./businessReportSection.module.css"
import css from "./ReportViewTags.module.css"

type ReportViewTagsProps = {
  filter: View
}

const ReportViewTags = ({ filter }: ReportViewTagsProps) => {
  const viewNodeToTag = (viewNode: ViewNode) => {
    let valueNode = {} as ValueNode
    let negated = false
    if ("operator" in viewNode) {
      negated = true
      valueNode = viewNode.arguments
    } else {
      valueNode = viewNode
    }

    return (
      <Tag large minimal>
        {valueNode.name} is {negated && "NOT"} <b>{valueNode.value}</b>
      </Tag>
    )
  }

  return (
    <div className={css.viewTags}>
      {filter.arguments.length > 0 && <Tag large minimal icon="th-filtered" />}
      {filter.arguments.map((node, index) => (
        <>
          {viewNodeToTag(node)}
          {index !== filter.arguments.length - 1 && (
            <Tag large minimal>
              {filter.operator}
            </Tag>
          )}
        </>
      ))}
    </div>
  )
}

type ReportViewTagsSectionProps = {
  filter: View
}

const ReportViewTagsSection = ({ filter }: ReportViewTagsSectionProps) => {
  return (
    <div className={sectionStyles.content}>
      <ReportViewTags filter={filter} />
    </div>
  )
}

export default ReportViewTagsSection

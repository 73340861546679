import React from "react"
import { H3, Label, InputGroup } from "@blueprintjs/core"
import { useForm } from "react-hook-form"
import { capitalize } from "./textHelpers"
import CustomerSegmentInput from "./CustomerSegmentInput"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "./withHookFormHelpers"

import formCss from "./forms.module.css"

type CustomerFormProps = InjectedHookHelpersProps & {
  customer: Types.Api.Customer
  customerSegments: Types.Api.SegmentDefintion[]
  onFieldChange: (value: string | null, name: string) => void
  onSegmentChange: (segment: Types.Api.SegmentDefintion, value: string) => void
  compact: boolean
}

const CustomerForm = ({
  customer,
  customerSegments,
  onFieldChange,
  onSegmentChange,
  compact,
  fieldIntent,
  validationErrorMsg,
}: CustomerFormProps) => {
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  })

  const handleFieldChange = (event: any) => {
    const name = event.target.name
    const value = event.target.value

    onFieldChange(errors[name] ? null : value, name)
  }

  const renderTextInput = (name: string, defaultValue: string) => (
    <Label key={name}>
      {capitalize(name)}
      <InputGroup
        name={name}
        autoFocus={name === "customer name" ? true : false}
        fill
        large
        onChange={handleFieldChange}
        inputRef={
          register(name, {
            required: "This field is required",
          }).ref
        }
        intent={fieldIntent(errors, name)}
        defaultValue={defaultValue}
        autoComplete="off"
      />
      {validationErrorMsg(errors, name)}
    </Label>
  )

  const findCurrentValue = (segmentName: string) => {
    const existingSegment =
      customer.segments &&
      customer.segments.find((segment) => segment.segment.name === segmentName)
    return existingSegment && existingSegment.value ? existingSegment.value : ""
  }

  const isRelevantSegmentForCustomer = (
    segment: Types.Api.SegmentDefintion,
    customer: Types.Api.Customer
  ) => {
    if (segment.value_source === "parent_and_children") return true
    if (segment.value_source === "parent" && !customer.parent_id) return true
    if (segment.value_source === "children" && customer.parent_id) return true

    return false
  }

  const renderSegmentFields = () =>
    customerSegments
      .filter((segment) => isRelevantSegmentForCustomer(segment, customer))
      .map((segment) =>
        renderSegmentInput(segment, findCurrentValue(segment.name))
      )

  const renderSegmentInput = (
    segment: Types.Api.SegmentDefintion,
    value: string
  ) => (
    <Label key={segment.id}>
      {capitalize(segment.name)}
      <CustomerSegmentInput
        customerSegment={segment}
        onItemSelect={(item: Types.Api.CustomerSegmentSearchSuggestion) => {
          onSegmentChange(segment, item.value)
        }}
        defaultValue={value || ""}
      />
    </Label>
  )

  return (
    <div className={formCss.body}>
      {!compact && <H3 className={formCss.title}>Customer</H3>}
      {renderTextInput("customer name", customer.name)}
      {customerSegments && renderSegmentFields()}
    </div>
  )
}

export default withHookFormHelpers(CustomerForm)

import React, { useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { FilterValueArgument, Serie } from "./types"

import popoverCss from "../popoverForm.module.css"
import formCss from "../forms.module.css"
import {
  Button,
  Classes,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  HTMLSelect,
  InputGroup,
  Label,
  ControlGroup,
  Switch,
} from "@blueprintjs/core"
import { FilterValue } from "./FilterFieldSelect"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"

type SerieValueFormProps = InjectedHookHelpersProps<Serie> & {
  onSave: (serie: Serie) => void
  defaultValues: Serie
  values: FilterValue[]
  target?: React.ReactElement
}

const SerieValueForm = ({
  defaultValues,
  values,
  onSave,
  target,
  fieldIntent,
  validationErrorMsg,
}: SerieValueFormProps) => {
  const {
    getValues,
    control,
    reset,
    register,
    formState,
    formState: { errors },
    trigger,
    unregister,
  } = useForm<Serie>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  })

  const { fields, replace } = useFieldArray({
    control,
    name: `value.arguments`,
  })

  useEffect(() => reset(defaultValues), [reset, defaultValues])

  const onSubmit = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()
        onSave(values)
        reset()
      }
    })
  }

  const onCancel = () => {
    reset()
  }

  const valueOptions = () => {
    return values.map((value, index) => ({
      label: value.label,
      value: index,
    }))
  }

  const onSerieValueChange = (event: any, field: any) => {
    const value = values[event.currentTarget.value]
    const newArguments = value.arguments.map((arg) => ({
      ...arg,
      value: arg.options?.at(0)?.value,
    }))

    field.value.arguments.forEach((_arg: any, index: number) =>
      unregister(`value.arguments.${index}.value`)
    )

    field.onChange(value)
    replace(newArguments)
  }

  const renderValueSelect = ({ field }: any) => {
    return (
      <ControlGroup fill>
        <Label>
          Calculation
          <HTMLSelect
            elementRef={field.ref}
            options={valueOptions()}
            onChange={(event: any) => onSerieValueChange(event, field)}
            value={values.findIndex((val) => val.label === field.value.label)}
          />
        </Label>
      </ControlGroup>
    )
  }

  const renderNumberArgument = (
    { field }: any,
    argument: FilterValueArgument
  ) => {
    return (
      <ControlGroup fill>
        <Label>
          {argument.label}
          <InputGroup
            inputRef={field.ref}
            onBlur={(event) => field.onBlur(parseInt(event.target.value))}
            onChange={(event) => field.onChange(parseInt(event.target.value))}
            intent={fieldIntent(errors, field.name)}
            defaultValue={field.value}
          />
          {validationErrorMsg(errors, field.name)}
        </Label>
      </ControlGroup>
    )
  }

  const renderTextArgument = (
    { field }: any,
    argument: FilterValueArgument
  ) => {
    return (
      <ControlGroup fill>
        <Label>
          {argument.label}
          <InputGroup
            inputRef={field.ref}
            onBlur={field.onBlur}
            onChange={field.onChange}
            intent={fieldIntent(errors, field.name)}
            value={field.value}
          />
          {validationErrorMsg(errors, field.name)}
        </Label>
      </ControlGroup>
    )
  }

  const validateArgument = (argument: FilterValueArgument) => {
    if (argument.type === "number") {
      return (value: string | undefined) => {
        if (value && parseInt(value)) {
          return true
        }

        return "Value must be a number"
      }
    }

    return undefined
  }

  const renderArgumentInput = (
    controlProps: any,
    argument: FilterValueArgument
  ) => {
    switch (argument.type) {
      case "list":
        return renderListArgument(controlProps, argument)
        break
      case "number":
        return renderNumberArgument(controlProps, argument)
        break
      case "text":
        return renderTextArgument(controlProps, argument)
        break
      default:
        console.error(`Unknown argument type: ${argument.type}`)
        return <></>
    }
  }

  const renderHiddenSwitch = ({ field }: any) => {
    return (
      <Switch
        label="Hide serie from the chart"
        onChange={field.onChange}
        large
        checked={field.value}
      />
    )
  }

  const renderListArgument = (
    { field }: any,
    argument: FilterValueArgument
  ) => {
    return (
      <ControlGroup fill>
        <Label>
          {argument.label}
          <HTMLSelect
            elementRef={field.ref}
            options={argument.options}
            onChange={(event: any) => {
              field.onChange(event.target.value)
            }}
            onBlur={(event: any) => {
              field.onBlur(event.target.value)
            }}
            defaultValue={argument.options?.at(0)?.value}
            value={field.value}
          />
        </Label>
      </ControlGroup>
    )
  }

  const nameField = register("name", { required: "Name is required" })
  const renderContent = () => (
    <>
      <div className={popoverCss.popover}>
        <div className={popoverCss.popoverBody}>
          <div className={popoverCss.content}>
            <div className={formCss.fieldsRow}>
              <ControlGroup fill>
                <Label>
                  Serie name
                  <InputGroup
                    autoFocus
                    name={nameField.name}
                    fill
                    inputRef={nameField.ref}
                    onChange={nameField.onChange}
                    onBlur={nameField.onBlur}
                    intent={fieldIntent(errors, nameField.name)}
                  />
                  {validationErrorMsg(errors, nameField.name)}
                </Label>
              </ControlGroup>
            </div>
            <div className={formCss.fieldsRow}>
              <Controller
                control={control}
                name="value"
                render={renderValueSelect}
              />
            </div>
            {fields.map((arg, index) => {
              return (
                <div key={arg.id} className={formCss.fieldsRow}>
                  <Controller
                    control={control}
                    name={`value.arguments.${index}.value`}
                    defaultValue={
                      arg.type === "list" ? arg.options?.at(0)?.value : ""
                    }
                    render={(field) => renderArgumentInput(field, arg)}
                    rules={{
                      required: "Required",
                      validate: validateArgument(arg),
                    }}
                  />
                </div>
              )
            })}
            <div className={formCss.fieldsRow}>
              <Controller
                control={control}
                name="hidden"
                render={renderHiddenSwitch}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={popoverCss.actions}>
        <Button
          outlined
          intent={Intent.PRIMARY}
          text="OK"
          onClick={onSubmit}
          disabled={!formState.isValid}
          className={Classes.POPOVER_DISMISS}
        />
        <Button
          className={Classes.POPOVER_DISMISS}
          minimal
          intent={Intent.DANGER}
          onClick={onCancel}
          text="Cancel"
        />
      </div>
    </>
  )

  return (
    <Popover
      content={renderContent()}
      position={Position.BOTTOM}
      placement="bottom"
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
    >
      {target ? (
        target
      ) : (
        <Button minimal intent="primary" icon="plus" text="Add calculation" />
      )}
    </Popover>
  )
}

export default withHookFormHelpers(SerieValueForm)

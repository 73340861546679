import React from "react"
import {
  Label,
  InputGroup,
  Button,
  ControlGroup,
  Popover,
  Menu,
  MenuItem,
} from "@blueprintjs/core"
import { UseFormReturn, Controller } from "react-hook-form"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"

import formCss from "../forms.module.css"
import DateInputWithValidation from "../DateInputWithValidation"

type CostFormProps = InjectedHookHelpersProps<Types.Api.Cost> & {
  useFormMethods: UseFormReturn<Types.Api.Cost>
}

const CostForm = withHookFormHelpers(
  ({ useFormMethods, fieldIntent }: CostFormProps) => {
    const {
      register,
      formState: { errors },
      control,
      watch,
    } = useFormMethods

    const costSegments = watch("segments")

    const renderCostSegmentFields = () => {
      return costSegments.map((seg, index) => {
        const field = register(`segments.${index}.value`)
        return (
          <div key={index} className={formCss.fieldsRow}>
            <ControlGroup fill>
              <Label>
                {seg.name}
                <InputGroup
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  inputRef={field.ref}
                  intent={fieldIntent(errors, field.name)}
                  autoComplete="off"
                  large
                />
              </Label>
            </ControlGroup>
          </div>
        )
      })
    }

    const renderCurrencyMenu = (props: any) => {
      const onClick = (e: any) => {
        props.field.onChange(e.target.textContent)
      }
      const currentValue = props.field.value
      return (
        <Popover
          className={formCss.noMargin}
          position="bottom"
          content={
            <Menu>
              <MenuItem
                text="usd"
                active={currentValue === "usd"}
                onClick={onClick}
              />
              <MenuItem
                text="pln"
                active={currentValue === "pln"}
                onClick={onClick}
              />
              <MenuItem
                text="eur"
                active={currentValue === "eur"}
                onClick={onClick}
              />
            </Menu>
          }
        >
          <Button rightIcon="caret-down" minimal>
            {currentValue}
          </Button>
        </Popover>
      )
    }

    const valueField = register("value", {
      required: "Cost value is required",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Should be a number ex. 1200.20",
      },
    })

    const dateField = register("date", {
      required: "Date is required",
    })

    const dueDateField = register("due_date")

    const invoiceField = register("invoice_number")

    return (
      <>
        <div className={formCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Total value
              <InputGroup
                autoFocus={true}
                className={formCss.textRight}
                name={valueField.name}
                onChange={valueField.onChange}
                onBlur={valueField.onBlur}
                inputRef={valueField.ref}
                intent={fieldIntent(errors, valueField.name)}
                autoComplete="off"
                large
                rightElement={
                  <Controller
                    name={"currency"}
                    control={control}
                    render={(props) => renderCurrencyMenu(props)}
                  />
                }
              />
            </Label>
          </ControlGroup>
        </div>
        <div className={formCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Date
              <DateInputWithValidation
                control={control}
                fieldName={dateField.name}
                intent={fieldIntent(errors, dateField.name)}
                rules={{ required: "Start date is required" }}
                onFieldChange={() => {}}
              />
            </Label>
          </ControlGroup>
        </div>
        <div className={formCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Due date
              <DateInputWithValidation
                control={control}
                fieldName={dueDateField.name}
                intent={fieldIntent(errors, dueDateField.name)}
                rules={{}}
                onFieldChange={() => {}}
                allowNulls
              />
            </Label>
          </ControlGroup>
        </div>
        <div className={formCss.fieldsRow}>
          <ControlGroup fill>
            <Label>
              Invoice number
              <InputGroup
                name={invoiceField.name}
                onChange={invoiceField.onChange}
                onBlur={invoiceField.onBlur}
                inputRef={invoiceField.ref}
                intent={fieldIntent(errors, invoiceField.name)}
                autoComplete="off"
                large
              />
            </Label>
          </ControlGroup>
        </div>
        {renderCostSegmentFields()}
      </>
    )
  }
)

export default CostForm

import React, { PureComponent } from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts"
import CHART_SETTINGS from "./chartSettings"
import withInteractiveLegend from "./withInteractiveLegend"
import {
  tooltipFormatterFactory,
  legendFormatter,
  getXTickFormatter,
  yTickFormatter,
  xTickValueToDate,
  getTooltipLabelFormatter,
  getCategoryAttributeName,
} from "../chartHelpers"
import schemes from "../colorScheme"

const DetailedCursor = (props) => {
  const { strokeWidth, height } = props

  return (
    <rect
      fill="#ccc"
      stroke="none"
      x={props.points[0].x - strokeWidth / 2}
      y={props.points[0].y}
      width={strokeWidth}
      height={height}
      onClick={() =>
        props.onCursorClick(xTickValueToDate(props.payload[0].payload.name))
      }
      style={{ cursor: "pointer" }}
    />
  )
}

class MultiLineChartImpl extends PureComponent {
  formatter = () =>
    tooltipFormatterFactory({
      useDecimal: this.props.useDecimal,
      groupedSegmentNames: this.props.groupedSegmentNames,
      withPercent: this.props.withPercent,
      category: this.props.category,
    })

  onCursorClick = (date) => {
    this.props.onClick(date, this.props.showOnlyKeys, this.props.dontShowKeys)
  }

  onLineClick = (payload) => {
    this.props.onClick(
      xTickValueToDate(payload.name),
      this.props.showOnlyKeys,
      this.props.dontShowKeys
    )
  }

  detailedTooltipProps = () => {
    if (this.props.detailed) {
      return {
        cursor: (
          <DetailedCursor
            onCursorClick={this.onCursorClick}
            strokeWidth={CHART_SETTINGS.lineChartDetailedCursorStrokeWidth}
          />
        ),
      }
    } else {
      return {}
    }
  }

  detailedLineProps = () => {
    if (this.props.detailed) {
      return {
        style: { cursor: "pointer" },
        onClick: this.onLineClick,
      }
    } else {
      return {}
    }
  }

  renderLines(scheme) {
    return this.props.segmentAttrs.map((attr) => (
      <Line
        {...this.detailedLineProps()}
        dot={false}
        name={attr}
        animationDuration={CHART_SETTINGS.animationDuration}
        key={attr}
        dataKey={(data) =>
          data[getCategoryAttributeName(this.props.category)][attr]
        }
        type="linear"
        strokeWidth="2.5"
        hide={this.props.isHidden(attr)}
        stroke={scheme.next()}
      />
    ))
  }

  itemSorter = (item) => {
    const category = getCategoryAttributeName(this.props.category)

    return -item.payload[category][item.name]
  }

  render() {
    const { yReferenceLine } = this.props
    const scheme = schemes.main()

    return (
      <ResponsiveContainer width="100%" aspect={1.9}>
        <LineChart data={this.props.data} margin={CHART_SETTINGS.margin}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />

          <XAxis
            dataKey="name"
            padding={CHART_SETTINGS.xAxisLinePadding}
            tickFormatter={getXTickFormatter(
              this.props.data.length,
              this.props.resolution
            )}
          />
          <YAxis
            axisLine={false}
            tickFormatter={yTickFormatter}
            orientation="right"
            tickLine={false}
          />
          {yReferenceLine && (
            <ReferenceLine
              y={yReferenceLine.value}
              label={yReferenceLine.label}
              stroke="red"
              strokeDasharray="3 3"
            />
          )}
          <Tooltip
            itemSorter={this.itemSorter}
            {...this.detailedTooltipProps()}
            formatter={this.formatter()}
            labelFormatter={getTooltipLabelFormatter(this.props.resolution)}
          />
          {this.props.showLegend && (
            <Legend
              formatter={legendFormatter(this.props.groupedSegmentNames)}
              onClick={this.props.onLegendClick}
            />
          )}
          {this.renderLines(scheme)}
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

export const MultiLineChart = withInteractiveLegend(MultiLineChartImpl)

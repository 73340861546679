import { UserContextProps } from "../UserContext"

const ObjectAttribute = {
  create: (userContext: UserContextProps, attr: Types.Api.ObjectAttribute) => {
    return userContext.fetch("/object_attributes", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(attr),
    })
  },

  update: (userContext: UserContextProps, attr: Types.Api.ObjectAttribute) => {
    return userContext.fetch(`/object_attributes/${attr.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(attr),
    })
  },

  delete: (
    userContext: UserContextProps,
    attrId: Types.Api.ObjectAttribute["id"]
  ) => {
    return userContext.fetch(`/object_attributes/${attrId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
  },
}

export default ObjectAttribute

import React, { useState, useContext } from "react"
import {
  Dialog,
  Button,
  Intent,
  Classes,
  InputGroup,
  Label,
} from "@blueprintjs/core"
import { useForm } from "react-hook-form"
import { DataSource } from "./Settings"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"
import withHookFormHelpter, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import Config from "../config"

type ChargebeeDataSourceFormType = {
  chargebee_api_key: string
  chargebee_site: string
}

type ChargebeeDataSourceProps =
  InjectedHookHelpersProps<ChargebeeDataSourceFormType> & {
    integration: Types.Api.Integration
    onSuccess: (data: any) => void
  }

const ChargebeeDataSource = ({
  integration,
  onSuccess,
  validationErrorMsg,
  fieldIntent,
}: ChargebeeDataSourceProps) => {
  const userContext = useContext(UserContext)
  const [dialogOpen, setDialogOpen] = useState(false)

  const defaultValues = () => ({
    chargebee_api_key: integration.configuration?.chargebee_api_key,
    chargebee_site: integration.configuration?.chargebee_site,
  })

  const {
    formState: { errors },
    getValues,
    register,
    trigger,
    formState,
    reset,
  } = useForm<ChargebeeDataSourceFormType>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues(),
  })

  const onDialogClose = () => {
    reset(defaultValues())
    setDialogOpen(false)
  }

  const onDialogSave = () => {
    trigger().then((success) => {
      if (success) {
        const formValues = getValues()
        const body = {
          configuration: {
            chargebee_api_key: formValues.chargebee_api_key,
            chargebee_site: formValues.chargebee_site,
          },
        }

        userContext
          .fetch("/integrations/chargebee", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" },
          })
          .then((data) => {
            setDialogOpen(false)
            if (onSuccess) {
              onSuccess(data)
            }
          })
          .catch((error) => {
            error.response.json().then((errorData: any) => {
              console.warn(errorData)
              if (errorData.body.includes("cannot_connect_to_chargebee")) {
                AppToaster.showError({
                  message:
                    "We could not connect to Chargebee, please make sure your Site and API token are correct",
                })
              } else {
                AppToaster.showError({
                  message: "We could not save configuration",
                })
              }
            })
          })
      }
    })
  }

  const onCardClick = () => {
    setDialogOpen(true)
  }

  const apiKeyField = register("chargebee_api_key", {
    required: "ChargeBee API Key cannot be empty",
  })

  const siteField = register("chargebee_site", {
    required: "ChargeBee site cannot be empty",
  })

  return (
    <>
      <Dialog
        isOpen={dialogOpen}
        onClose={onDialogClose}
        title="Chargebee"
        canOutsideClickClose={Config.canOutsideClickCloseDialog}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Provide{" "}
            <a href="https://www.chargebee.com/docs/2.0/api_keys.html">
              ChargeBee Read-only API Key
            </a>{" "}
            and Chargebee Site.
          </p>
          <Label>
            API Key
            <InputGroup
              name="chargebee_api_key"
              autoFocus
              placeholder="API key"
              intent={fieldIntent(errors, "chargebee_api_key")}
              large
              fill
              inputRef={apiKeyField.ref}
              onChange={apiKeyField.onChange}
              onBlur={apiKeyField.onBlur}
            />
            {validationErrorMsg(errors, "chargebee_api_key")}
          </Label>
          <Label>
            Site
            <InputGroup
              name="chargebee_site"
              placeholder="Site"
              intent={fieldIntent(errors, "chargebee_site")}
              large
              fill
              inputRef={siteField.ref}
              onChange={siteField.onChange}
              onBlur={siteField.onBlur}
            />
            {validationErrorMsg(errors, "chargebee_site")}
          </Label>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Close"
              onClick={onDialogClose}
              intent={Intent.NONE}
              outlined
              large
            />
            <Button
              type="submit"
              text="Save"
              onClick={onDialogSave}
              intent={Intent.PRIMARY}
              large
              disabled={!formState.isValid}
            />
          </div>
        </div>
      </Dialog>
      <DataSource
        integration={integration}
        containerClassName="chargebee"
        onClick={onCardClick}
      />
    </>
  )
}

export default withHookFormHelpter(ChargebeeDataSource)

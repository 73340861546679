import { useCallback, useContext, useEffect, useState } from "react"
import { AppToaster } from "../AppToaster"
import { UserContext } from "../UserContext"

const useAttributePossibleValues = (object?: string, attr?: string) => {
  const [values, setValues] = useState<string[]>([])

  const userContext = useContext(UserContext)

  const fetchPossibleValues = useCallback(() => {
    if (!object || !attr) return

    const params = new URLSearchParams({
      field: attr,
      object: object,
    })
    userContext
      .fetch(`/user_filtered_config/possible_values?${params.toString()}`, {
        method: "GET",
      })
      .then((data) => {
        setValues(data.items)
      })
      .catch((error: any) => {
        console.warn(error)
        AppToaster.showError({
          message: "Could not get possible values",
        })
      })
  }, [userContext, object, attr])

  useEffect(() => {
    fetchPossibleValues()
  }, [userContext, fetchPossibleValues])

  return values
}

export default useAttributePossibleValues

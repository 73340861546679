import React, { useState } from "react"
import { Button, Intent, IButtonProps } from "@blueprintjs/core"
import classnames from "classnames"

import css from "./WiggleButton.module.css"

interface WiggleButtonProps extends IButtonProps {
  onClick: () => Promise<any>
}

const WiggleButton = (props: WiggleButtonProps) => {
  const [wiggling, setWiggling] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const intent = wiggling ? Intent.DANGER : props.intent || Intent.SUCCESS

  const buttonDisabled = disabled || props.disabled

  const onClick = () => {
    setDisabled(true)
    props.onClick().catch(() => {
      setDisabled(false)
      setWiggling(true)
      window.setTimeout(() => {
        setWiggling(false)
      }, 1000)
    })
  }

  return (
    <Button
      {...props}
      large
      onClick={onClick}
      disabled={buttonDisabled}
      className={classnames(props.className, wiggling ? css.wiggle : null)}
      intent={intent}
    />
  )
}

export default WiggleButton

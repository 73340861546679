import React, { useContext, useState, useMemo } from "react"
import { Section } from "../Content"
import { UserContext } from "../UserContext"
import CostsTable from "./CostsTable"
import { Button, Intent } from "@blueprintjs/core"
import { AppToaster } from "../AppToaster"
import CostDrawer from "./CostDrawer"
import { LoadingState, SuccessState } from "../NonIdealState"
import useCosts from "../api/useCosts"
import useObjectAttributes from "../api/useObjectAttributes"
import CostApi from "../api/Cost"
import { jsDateToISOString } from "../formatters"
import ConfirmDialog from "../ConfirmDialog"

const CostsContainer = () => {
  const userContext = useContext(UserContext)
  const defaultCost = useMemo(
    () => ({
      date: jsDateToISOString(new Date()),
      due_date: jsDateToISOString(new Date()),
      currency: userContext.user?.currency || "",
      value: "",
      segments: [],
    }),
    [userContext.user?.currency]
  )
  const [costs, loading, , setCosts] = useCosts()
  const [objectAttributes] = useObjectAttributes()
  const [formOpen, setFormOpen] = useState(false)
  const [editCost, setEditCost] = useState<Types.Api.Cost>(defaultCost)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const costAttrs = useMemo(
    () => objectAttributes.filter((o) => o.object === "cost"),
    [objectAttributes]
  )

  const onCostEdit = (cost: Types.Api.Cost) => {
    setEditCost(cost)
    setFormOpen(true)
  }

  const onClose = () => {
    setFormOpen(false)
    if (editCost) {
      setEditCost(defaultCost)
    }
  }

  const openForm = () => setFormOpen(true)
  const onCreateNewCosts = () => {
    setEditCost(defaultCost)
    openForm()
  }

  const onCostSubmit = (cost: Types.Api.Cost) => {
    if (cost.id && cost.id.length > 0) {
      updateCost(cost)
    } else {
      createCost(cost)
    }
  }

  const replaceCost = (newCost: Types.Api.Cost) => {
    const newCosts = costs.map((cost: Types.Api.Cost) => {
      if (cost.id === newCost.id) {
        return newCost
      }

      return cost
    })

    setCosts(newCosts)
  }

  const prependCost = (newCost: Types.Api.Cost) => {
    setCosts([newCost].concat(costs))
  }

  const updateCost = (cost: Types.Api.Cost) => {
    CostApi.upsert(userContext, cost)
      .then((cost: Types.Api.Cost) => replaceCost(cost))
      .catch((error: any) => {
        console.log(error)
        AppToaster.showError({
          message: "We could not update the cost record, please try again.",
        })
      })
      .finally(onClose)
  }

  const createCost = (cost: Types.Api.Cost) => {
    CostApi.create(userContext, cost)
      .then((cost: Types.Api.Cost) => prependCost(cost))
      .catch((error: any) => {
        console.log(error)
        AppToaster.showError({
          message: "We could not create a new cost, please try again.",
        })
      })
      .finally(onClose)
  }

  const onCostDelete = () => {
    setConfirmOpen(true)
  }

  const cancelCostRemove = () => {
    setConfirmOpen(false)
  }

  const confirmCostRemove = () => {
    setConfirmOpen(false)
    CostApi.delete(userContext, editCost?.id)
      .then(() => {
        setCosts(
          costs.filter((cost: Types.Api.Cost) => cost.id !== editCost?.id)
        )
      })
      .catch((error: any) => {
        console.log(error)
        AppToaster.showError({
          message: "We could not delete this cost, please try again.",
        })
      })
      .finally(onClose)
  }

  const enrichWithSegments = (cost: Types.Api.Cost) => {
    const segments = costAttrs.map((attr) => {
      const value = cost.segments.find((seg) => seg.name === attr.name)?.value

      return { name: attr.name, value: value }
    })

    return { ...cost, segments }
  }

  return (
    <>
      <CostDrawer
        isOpen={formOpen}
        onClose={onClose}
        onSubmit={onCostSubmit}
        onDelete={onCostDelete}
        defaultValues={enrichWithSegments(editCost)}
      />
      <ConfirmDialog
        title="Delete cost"
        isOpen={confirmOpen}
        onCancel={cancelCostRemove}
        onConfirm={confirmCostRemove}
      >
        This operation is not reversable. The cost record and its segments will
        be lost. Do you want to proceed?
      </ConfirmDialog>
      <Section
        title="Business Costs"
        actionButton={
          <Button
            large
            outlined
            intent={Intent.PRIMARY}
            onClick={onCreateNewCosts}
            text="New costs"
            disabled={loading}
          />
        }
      >
        {loading && <LoadingState />}
        {!loading && costs.length > 0 && (
          <CostsTable
            costs={costs}
            onCostEdit={onCostEdit}
            costAttrs={costAttrs}
            withFooter
          />
        )}
        {costs.length === 0 && !loading && (
          <SuccessState
            title="Start by adding new costs records"
            description="You will be able to add costs and defined costs categories. This gives you an ability to report on your whole business costs, calculate CAC and CAC/LTV ratios, explore CAC payback cohort."
            onAction={onCreateNewCosts}
            icon="th-list"
            actionText="New costs"
          />
        )}
      </Section>
    </>
  )
}

export default CostsContainer

import React from "react"
import { withRouter, Link } from "react-router-dom"
import { Label, InputGroup, Callout, H2 } from "@blueprintjs/core"
import WiggleButton from "./WiggleButton"
import { UserContext } from "./UserContext"
import Logo from "./Logo"

import css from "./signSection.module.css"

export const REDIRECT_AFTER_SIGN_IN_KEY = "redirectAfterSignIn"

class SignIn extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      failed: false,
    }
  }

  onClick = () => {
    return this.context
      .performSignIn(this.state.email, this.state.password)
      .then(() => {
        this.setState({ failed: false })
        if (localStorage.getItem(REDIRECT_AFTER_SIGN_IN_KEY)) {
          const redirectPath = localStorage.getItem(REDIRECT_AFTER_SIGN_IN_KEY)
          localStorage.removeItem(REDIRECT_AFTER_SIGN_IN_KEY)
          this.props.history.push(redirectPath)
        } else {
          this.props.history.push("/")
        }
      })
      .catch((error) => {
        this.setState({ failed: true })
        throw error
      })
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  componentDidMount() {
    this.emailField.focus()
  }

  render() {
    return (
      <form className={css.main}>
        <center>
          <Logo />
        </center>
        <center>
          <H2>Sign in</H2>
        </center>
        {this.state.failed && (
          <Callout intent="danger">Email or password is invalid</Callout>
        )}
        <div>
          <Label>
            Email
            <InputGroup
              large
              type="text"
              value={this.state.email}
              onChange={this.onEmailChange}
              placeholder="email@example.com"
              inputRef={(input) => {
                this.emailField = input
              }}
            />
          </Label>
          <Label>
            Password
            <InputGroup
              large
              type="password"
              value={this.state.password}
              onChange={this.onPasswordChange}
              placeholder="password"
            />
          </Label>
          <WiggleButton
            type="submit"
            rightIcon="arrow-right"
            text="Sign in"
            onClick={this.onClick}
          />
        </div>
        <ul className={css.additionalLinks}>
          <li>
            <Link to="/sign_up">Don&apos;t have an account? Sign up</Link>
          </li>
          <li>
            <Link to="/reset_password">Forgot password?</Link>
          </li>
        </ul>
      </form>
    )
  }
}

SignIn.contextType = UserContext

export default withRouter(SignIn)

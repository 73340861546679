import React, { useContext } from "react"
import {
  Button,
  Intent,
  Popover,
  Position,
  PopoverInteractionKind,
  Classes,
} from "@blueprintjs/core"
import {
  withCustomerFormValidation,
  withCustomerSegments,
} from "./withCustomerFormValidation"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"
import CustomerForm from "./CustomerForm"

import popoverCss from "./popoverForm.module.css"

type EditCustomerPopoverProps = {
  popoverTarget: any
  customerFormData: Types.Api.Customer
  customer: Types.Api.Customer
  onCustomerSegmentChange: (
    segment: Types.Api.SegmentDefintion,
    value: Types.Api.CustomerSegment["value"]
  ) => void
  onCustomerFormFieldChange: (value: string | null, name: string) => void
  onCustomerUpdated: (customer: Types.Api.CustomerSegment) => void
  canSubmitCustomer: boolean
  customerSegments: Types.Api.SegmentDefintion[]
  setCustomerFormData: (customer: Types.Api.Customer) => void
}

const EditCustomerPopover = ({
  popoverTarget,
  customer,
  customerFormData,
  onCustomerSegmentChange,
  onCustomerFormFieldChange,
  onCustomerUpdated,
  canSubmitCustomer,
  customerSegments,
  setCustomerFormData,
}: EditCustomerPopoverProps) => {
  const userContext = useContext(UserContext)

  const onSegmentsUpdate = () => {
    const finalData = customerWithSegmentsToUpdate(customerFormData)

    userContext
      .fetch(`/customers/${customer.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalData),
      })
      .then((updatedCustomer) => {
        onCustomerUpdated(updatedCustomer)
        setCustomerFormData(updatedCustomer)
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "Sorry, could not update the segments value. Please try again.",
        })
      })
  }

  const onPopoverClose = () => setCustomerFormData(customer)

  const renderSegmentsForm = () => (
    <div className={popoverCss.popover}>
      <div className={popoverCss.popoverBody}>
        <CustomerForm
          customer={customerFormData}
          customerSegments={customerSegments}
          onFieldChange={onCustomerFormFieldChange}
          onSegmentChange={onCustomerSegmentChange}
          compact
        />
      </div>
      <div className={popoverCss.actions}>
        <Button
          disabled={!canSubmitCustomer}
          outlined
          intent={Intent.PRIMARY}
          text="Update"
          className={Classes.POPOVER_DISMISS}
          onClick={onSegmentsUpdate}
        />
        <Button
          className={Classes.POPOVER_DISMISS}
          minimal
          intent={Intent.DANGER}
          text="Cancel"
        />
      </div>
    </div>
  )

  return (
    <Popover
      onClose={onPopoverClose}
      target={popoverTarget}
      content={renderSegmentsForm()}
      interactionKind={PopoverInteractionKind.CLICK_TARGET_ONLY}
      boundary="window"
      position={Position.RIGHT_BOTTOM}
    />
  )
}

export const customerWithSegmentsToUpdate = (customer: Types.Api.Customer) => {
  const segments = customer.segments.map((s) => ({
    name: s.segment.name,
    value: s.value,
  }))

  return { ...customer, segments: segments }
}

export default withCustomerFormValidation(
  withCustomerSegments(EditCustomerPopover)
)

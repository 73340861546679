import React, { useState, useContext, useEffect } from "react"
import {
  Drawer,
  Classes,
  Intent,
  Button,
  Icon,
  Callout,
} from "@blueprintjs/core"
import classnames from "classnames"
import CustomerSelect from "./CustomerSelect"
import CustomerInfo from "./CustomerInfo"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

import css from "./CustomerAssignParentDrawer.module.css"
import formCss from "./forms.module.css"

type CustomerAssignParentDrawerProps = {
  targetCustomer: Types.Api.Customer
  isOpen: boolean
  onSuccess: (customer: Types.Api.Customer) => any
  onCancel: () => any
}

type NewCustomer = {
  id?: string
  name: string
}

const CustomerAssignParentDrawer = ({
  targetCustomer,
  onSuccess,
  onCancel,
  isOpen,
}: CustomerAssignParentDrawerProps) => {
  const [parentCustomer, setParentCustomer] = useState<
    Types.Api.Customer | NewCustomer | null
  >(null)
  const userContext = useContext(UserContext)

  const onCustomerSelected = (selectedCustomer: Types.Api.Customer) => {
    setParentCustomer(selectedCustomer)
  }

  useEffect(() => {
    if (!isOpen) {
      setParentCustomer(null)
    }
  }, [isOpen, setParentCustomer])

  const performAssign = (parentCustomer: Types.Api.Customer) => {
    const formData = {
      parent_id: parentCustomer.id,
    }

    userContext
      .fetch(`/customers/${targetCustomer.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      .then(() => {
        if (parentCustomer) {
          onSuccess(parentCustomer)
        }
      })
      .catch((error) => {
        console.warn(error)
        AppToaster.showError({
          message:
            "We could not assign parent, please try again or contact us.",
        })
      })
  }

  const createNewCustomerAndAssignParent = () => {
    if (parentCustomer?.id) return

    userContext
      .fetch("/customers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(parentCustomer),
      })
      .then((data) => {
        setParentCustomer(data)
        performAssign(data)
      })
  }

  const onAssignParent = () => {
    if (!parentCustomer) {
      AppToaster.showError({ message: "Please select a customer" })
      return
    }

    if (parentCustomer.id) {
      performAssign(parentCustomer as Types.Api.Customer)
    } else {
      createNewCustomerAndAssignParent()
    }
  }

  const onNewCustomerAdded = (name: string) => {
    setParentCustomer({
      name,
    })
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="60%"
      onClose={onCancel}
      title={`Assign parent to ${targetCustomer.name}`}
    >
      <div className={classnames(Classes.DRAWER_BODY, css.assignDrawer)}>
        {parentCustomer && (
          <Callout intent={Intent.PRIMARY}>
            <b>{parentCustomer.name}</b> will be a parent customer to{" "}
            <b>{targetCustomer.name}</b> they will be treated as one entity in
            your reporting.
          </Callout>
        )}
        <div className={css.customerSelection}>
          <div>
            <CustomerSelect
              title="Parent customer"
              onCustomerSelected={onCustomerSelected}
              onNewCustomerAdded={onNewCustomerAdded}
              searchParentsOnly={true}
              withCreate
            />
            {parentCustomer && (
              <CustomerInfo customer={parentCustomer as Types.Api.Customer} />
            )}
          </div>
          <Icon icon="arrow-down" iconSize={80} color="grey" />
          <CustomerInfo customer={targetCustomer} />
        </div>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            outlined
            text="Assign parent"
            onClick={onAssignParent}
            large
            intent={Intent.PRIMARY}
            disabled={!parentCustomer}
          />
          <Button
            minimal
            text="Cancel"
            onClick={onCancel}
            large
            intent={Intent.DANGER}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default CustomerAssignParentDrawer

import { Intent, Position, Toaster, IToaster } from "@blueprintjs/core"
import { IconName } from "@blueprintjs/icons"

interface AppToasterInterface extends IToaster {
  showError: (overwrite?: object) => void
  showSuccess: (overwrite?: object) => void
}

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP,
}) as AppToasterInterface

AppToaster.showError = (overwrite = {}) => {
  const defaultAttributes = {
    icon: "error" as IconName,
    timeout: 10000,
    intent: Intent.DANGER,
    message: "Something went wrong",
  }
  const finalAttributes = { ...defaultAttributes, ...overwrite }
  AppToaster.show(finalAttributes)
}

AppToaster.showSuccess = (overwrite = {}) => {
  const defaultAttributes = {
    timeout: 1000,
    intent: Intent.SUCCESS,
    message: "Operation succeeded",
  }
  const finalAttributes = { ...defaultAttributes, ...overwrite }
  AppToaster.show(finalAttributes)
}

import React, { useContext, useRef, SyntheticEvent } from "react"
import { InputGroup, Button } from "@blueprintjs/core"
import { useDebouncedCallback } from "use-debounce"
import { UserContext } from "./UserContext"

export const SearchApiDestination = {
  CUSTOMER: "/customer/search",
  SUBSCRIPTION: "/subscriptions/histories/search",
}

type SearchInputProps = {
  apiDestination: string
  onSuccess: (items: Types.Api.CustomerSegmentSearchSuggestion[]) => void
  onFailure: (error: any) => void
  onQueryChange: (query: string) => void
  className: string
  small: boolean
  searchParentsOnly?: boolean
}

export const SearchInput = ({
  apiDestination,
  onSuccess,
  onFailure,
  onQueryChange,
  className,
  small,
  searchParentsOnly = false,
}: SearchInputProps) => {
  const userContext = useContext(UserContext)
  const inputRef = useRef<HTMLInputElement>(null)

  const searchCustomersDebounced = useDebouncedCallback((query) => {
    let finalQuery = query
    if (searchParentsOnly) {
      finalQuery = `(${query}) AND parent:true`
    }
    userContext
      .fetch(`${apiDestination}?query=${encodeURIComponent(finalQuery)}`, {
        method: "GET",
      })
      .then((data) => {
        onSuccess(data.items)
      })
      .catch((error) => {
        if (onFailure) onFailure(error)
      })
  }, 200)

  const onInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    if (onQueryChange) onQueryChange(target.value)

    if (target.value.length > 0) {
      searchCustomersDebounced.callback(target.value)
    }
  }

  const clearInput = () => {
    inputRef!.current!.value = ""
    onQueryChange("")
    inputRef!.current!.focus()
  }

  return (
    <InputGroup
      inputRef={inputRef}
      className={className}
      large={!small}
      placeholder="Start typing customer name"
      onChange={onInputChange}
      leftIcon="search"
      round
      autoFocus
      rightElement={<Button icon="cross" minimal onClick={clearInput} />}
    />
  )
}

export default SearchInput

import React, { useContext, useCallback } from "react"
import Suggest from "./components/Suggest"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

const CustomerSegmentInput = ({
  customerSegment,
  onItemSelect,
  defaultValue,
}) => {
  const userContext = useContext(UserContext)

  const suggestionsFetcher = useCallback(
    (query) =>
      userContext
        .fetch(
          `/customer_segments/${customerSegment.id}/search_values?limit=10&query=${query}`
        )
        .then((data) => data.items)
        .catch((error) => {
          console.warn(error)
          AppToaster.showError({
            message: `We could not get segment suggestions for ${customerSegment.name}`,
          })
        }),
    [customerSegment.id, customerSegment.name, userContext]
  )

  return (
    <Suggest
      onItemSelect={onItemSelect}
      defaultValue={defaultValue}
      fetcher={suggestionsFetcher}
    />
  )
}

export default CustomerSegmentInput

import { UserContextProps } from "../UserContext"

export type MappingEntry = { valueKey: string; valueValue: string }
export type InternalBucket = {
  from: string
  to: string
  name: string
}

export type Definition = Types.Api.SegmenterDefinition & {
  id?: string
  internalMappings: MappingEntry[]
  internalBuckets: InternalBucket[]
}

const SegmenterDefinitionApi = {
  convertMappingsToInternal: (
    mappings: Types.Api.SegmenterDefinition["mappings"]
  ): MappingEntry[] => {
    if (!mappings) return []

    return Object.entries(mappings).map(([key, value]) => ({
      valueKey: key,
      valueValue: value,
    }))
  },

  convertApiToInternal: (
    apiDefinition: Types.Api.SegmenterDefinition
  ): Definition => {
    return {
      ...apiDefinition,
      internalMappings: SegmenterDefinitionApi.convertMappingsToInternal(
        apiDefinition.mappings
      ),
      internalBuckets:
        SegmenterDefinitionApi.bucketsToInternal(apiDefinition.buckets) || [],
    }
  },

  internalToMappingsApi: (internal: MappingEntry[]) => {
    if (!internal) return {}

    return internal.reduce((memo, mapping) => {
      if (mapping.valueValue !== "") {
        memo[mapping.valueKey] = mapping.valueValue
      }
      return memo
    }, {} as { [name: string]: string })
  },

  internalBucketsToApi: (
    buckets: InternalBucket[]
  ): Types.Api.SegmentRangeBucket[] | null => {
    if (!buckets || buckets.length === 0) return null

    return buckets
      .map(
        (bucket: InternalBucket) =>
          ({
            name:
              bucket.name === ""
                ? `${bucket.from} - ${bucket.to}`
                : bucket.name,
            from: parseInt(bucket.from) === 0 ? 0 : parseInt(bucket.from),
            to: parseInt(bucket.to),
          } as Types.Api.SegmentRangeBucket)
      )
      .concat([
        {
          name: `> ${buckets[buckets.length - 1].to}`,
          from: parseInt(buckets[buckets.length - 1].to) + 1,
          to: "+infinity",
        } as Types.Api.SegmentRangeBucket,
      ])
  },

  bucketsToInternal: (buckets: Types.Api.SegmenterDefinition["buckets"]) => {
    return buckets
      ?.map((bucket) => ({
        name: bucket.name,
        from: bucket.from === "-infinity" ? "0" : bucket.from.toString(),
        to: bucket.to === "+infinity" ? "infinity" : bucket.to.toString(),
      }))
      .filter((bucket) => bucket.to !== "infinity")
  },

  definitionToSegmenterApi: (definition: Definition) => ({
    ...definition,
    mappings: SegmenterDefinitionApi.internalToMappingsApi(
      definition.internalMappings
    ),
    buckets: SegmenterDefinitionApi.internalBucketsToApi(
      definition.internalBuckets
    ),
  }),

  update: (userContext: UserContextProps, definition: Definition) => {
    const attrs = SegmenterDefinitionApi.definitionToSegmenterApi(definition)
    return userContext.fetch(`/segmenter_definitions/${definition.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(attrs),
    })
  },

  create: (userContext: UserContextProps, definition: Definition) => {
    const attrs = SegmenterDefinitionApi.definitionToSegmenterApi(definition)
    return userContext.fetch(`/segmenter_definitions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(attrs),
    })
  },

  delete: (userContext: UserContextProps, definitionId: Definition["id"]) => {
    return userContext.fetch(`/segmenter_definitions/${definitionId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
  },
}

export default SegmenterDefinitionApi

import React from "react"
import { apiUrl, websocketUrl, prefixWithVersion } from "./apiHelpers"
import { createConsumer } from "@rails/actioncable"
import queryString from "query-string"

const createCable = (authorizationHeader?: string) => {
  const token = authorizationHeader
    ? authorizationHeader.split("Bearer ")[1]
    : undefined
  return createConsumer(websocketUrl(token))
}

type UserContextType = {
  user: Types.Api.User
  authorizationHeader: string
}

export type UserContextProps = {
  user?: Types.Api.User
  authorizationHeader: string | undefined
  performSignIn: (email: string, password: string) => Promise<any>
  performSignUp: (email: string, password: string) => Promise<any>
  performSignOut: () => void
  performSignInViaToken: (token: string) => Promise<any>
  performDownloadWithAuthorization: (url: string) => void
  isLoggedIn: boolean
  fetch: (path: string, options?: object) => Promise<any>
  hasUserLevelAccess: () => boolean
  cable: ActionCable.Consumer
}

export const UserContext = React.createContext<UserContextProps>({
  user: undefined,
  authorizationHeader: undefined,
  performSignIn: (email, password) => new Promise((success) => {}), // eslint-disable-line
  performSignUp: (email, password) => new Promise((success) => {}), // eslint-disable-line
  performSignOut: () => new Promise((success) => {}), // eslint-disable-line
  performSignInViaToken: (token) => new Promise((success) => {}), // eslint-disable-line
  performDownloadWithAuthorization: () => new Promise((success) => {}), // eslint-disable-line
  isLoggedIn: false,
  hasUserLevelAccess: () => false,
  fetch: (destination, options) => new Promise((success) => {}), // eslint-disable-line
  cable: createCable(),
})

const USER_CONTEXT_KEY = "userContext"

export class HTTPError extends Error {
  response: any
  name: string

  constructor(response: any) {
    super(`HTTP${response.status}`)
    this.response = response
    this.name = "HTTPError"
  }
}

type ProviderProps = {}

export const UserContextProvider = (WrappedComponent: any) => {
  return class extends React.PureComponent<ProviderProps, UserContextProps> {
    constructor(props: ProviderProps) {
      super(props)
      this.state = this.loadUserContext()
    }

    loadUserContext = () => {
      const defaultUserContext = {
        performSignIn: this.performSignIn,
        performSignUp: this.performSignUp,
        performSignOut: this.performSignOut,
        performSignInViaToken: this.performSignInViaToken,
        performDownloadWithAuthorization: this.performDownloadWithAuthorization,
        fetch: this.fetch,
        isLoggedIn: false,
        hasUserLevelAccess: this.hasUserLevelAccess,
        user: undefined,
        authorizationHeader: undefined,
      }

      const userContextString = localStorage.getItem(USER_CONTEXT_KEY)
      if (userContextString) {
        const userContext = JSON.parse(userContextString) as UserContextType
        const cable = createCable(userContext.authorizationHeader)
        return Object.assign(defaultUserContext, userContext, {
          isLoggedIn: true,
          cable,
        })
      } else {
        const cable = createCable()
        return Object.assign(defaultUserContext, { cable })
      }
    }

    setAuthorizedStateFromResponseAndHeader = async (
      response: any,
      authorizationHeader: string
    ) => {
      await response.json().then((user: Types.Api.User) => {
        localStorage.setItem(
          USER_CONTEXT_KEY,
          JSON.stringify({ user, authorizationHeader })
        )
        this.setCableState(createCable(authorizationHeader))
        this.setState({
          user,
          authorizationHeader,
          isLoggedIn: true,
        })
      })
    }

    setAuthorizedState = async (response: any) => {
      if (!response.ok) {
        throw new HTTPError(response)
      }

      const authorizationHeader = response.headers.get("Authorization")
      return this.setAuthorizedStateFromResponseAndHeader(
        response,
        authorizationHeader
      )
    }

    hasUserLevelAccess = () =>
      this.state.isLoggedIn && this.state.user?.permission_level === "user"

    performSignIn = (email: string, password: string) => {
      return fetch(apiUrl("/users/sign_in"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "omit",
        body: JSON.stringify({ user: { email, password } }),
      }).then(this.setAuthorizedState)
    }

    performSignUp = (email: string, password: string, query = {}) => {
      const stringQuery = queryString.stringify(query)
      const url =
        stringQuery === "" ? apiUrl("/users") : apiUrl(`/users?${stringQuery}`)
      return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: { email, password } }),
      }).then(this.setAuthorizedState)
    }

    performSignOut = () => {
      fetch(apiUrl("/users/sign_out"), {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: this.state.authorizationHeader as string,
        },
      })
        .then(() => this.handleUnauthorised())
        .catch((error) => console.warn(error))
    }

    performSignInViaToken = (apiToken: string) => {
      const authorizationHeader = `Bearer ${apiToken}`
      return fetch(apiUrl(prefixWithVersion("/users/me")), {
        headers: {
          Accept: "application/json",
          Authorization: authorizationHeader,
        },
      }).then((response) =>
        this.setAuthorizedStateFromResponseAndHeader(
          response,
          authorizationHeader
        )
      )
    }

    performDownloadWithAuthorization = (
      url: string,
      options: { apiVersion?: number } = {}
    ) => {
      const userContextString = localStorage.getItem("userContext") as string
      const userContext = JSON.parse(userContextString) as UserContextType
      const apiToken = userContext.user.api_token
      window.location.href = `${apiUrl(
        prefixWithVersion(url, options.apiVersion)
      )}&authorization=${apiToken}`
    }

    handleUnauthorised = () => {
      // Setting this setState should be the first thing that happens, so the
      // componentsimmediately know that user is unauthorized and don't render
      // anything they should not. For example, if you moved the `setCableState`
      // to the top, the components would rerender thinking they are still
      // authorized, when in fact the token is already revoked by backend.
      this.setState({
        user: undefined,
        authorizationHeader: undefined,
        isLoggedIn: false,
      })
      this.setCableState(createCable())
      localStorage.removeItem(USER_CONTEXT_KEY)

      if (!window.location.hash.includes("sign_in")) {
        localStorage.setItem(
          "redirectAfterSignIn",
          window.location.hash.substring(1)
        )
      }
      window.location.hash = "#/sign_in"
    }

    // Use this method from context to perform fetch requests:
    //  * they already have added authorization headers
    //  * they have common headers like Content-Type and Accept
    //  * the promise already succeeds with response.json() or throws an error
    //    on non-ok request
    fetch = (
      destination: string,
      options: { headers?: object; apiVersion?: number } = {}
    ) => {
      const headers = Object.assign(
        {
          Authorization: this.state.authorizationHeader,
          Accept: "application/json",
        },
        options.headers || {}
      )
      const fetchOptions = {
        headers: headers,
        credentials: "omit",
      }

      const finalOptions = Object.assign({}, options, fetchOptions) as object

      return window
        .fetch(
          apiUrl(prefixWithVersion(destination, options.apiVersion)),
          finalOptions
        )
        .then((response) => {
          if (response.ok) {
            return response.status !== 204 ? response.json() : {}
          } else if (response.status === 401) {
            this.handleUnauthorised()
            throw new HTTPError(response)
          } else {
            throw new HTTPError(response)
          }
        })
    }

    componentDidMount = () => {
      this.checkAuth().catch(this.setUnauthorizedState)
    }

    setUnauthorizedState = () => {
      this.setCableState(createCable())
      this.setState({
        authorizationHeader: undefined,
        isLoggedIn: false,
      })
    }

    checkAuth = () => {
      return fetch(apiUrl(prefixWithVersion("/users/me")), {
        headers: {
          Accept: "application/json",
          Authorization: this.state.authorizationHeader as string,
        },
        credentials: "omit",
      })
    }

    setCableState = (cable: ActionCable.Consumer) => {
      const oldCable = this.state.cable
      this.setState({ cable })
      oldCable.disconnect()
    }

    render() {
      return (
        <UserContext.Provider value={this.state}>
          <WrappedComponent {...this.props} userContext={this.state} />
        </UserContext.Provider>
      )
    }
  }
}

import React, { useContext, useCallback } from "react"
import Suggest from "./components/Suggest"
import { UserContext } from "./UserContext"
import { AppToaster } from "./AppToaster"

const ProductInput = ({ onItemSelect, defaultValue, intent }) => {
  const userContext = useContext(UserContext)
  const productsFetcher = useCallback(
    (query) =>
      userContext
        .fetch(`/product/search?query=${query}`)
        .then((data) => data.items.map((item) => ({ value: item.key })))
        .catch((error) => {
          console.warn(error)
          AppToaster.showError({ message: `We could not fetch products` })
        }),
    [userContext]
  )

  return (
    <Suggest
      onItemSelect={onItemSelect}
      defaultValue={defaultValue}
      fetcher={productsFetcher}
      intent={intent}
    />
  )
}

export default ProductInput

import React from "react"
import { Overlay, HotkeysTarget2, H1 } from "@blueprintjs/core"

import "./PresentMode.css"

class PresentMode extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: props.currentSlide || 0,
    }
  }

  nextSlide = () => {
    if (this.props.isOpen) {
      const currentSlide = this.state.currentSlide

      if (currentSlide < this.props.slides.length - 1) {
        this.setState({ currentSlide: currentSlide + 1 })
      } else {
        this.props.togglePresentMode()
      }
    }
  }

  prevSlide = () => {
    if (this.props.isOpen) {
      const currentSlide = this.state.currentSlide

      if (currentSlide > 0) {
        this.setState({ currentSlide: currentSlide - 1 })
      }
    }
  }

  onClosed = () => {
    this.setState({ currentSlide: 0 })
  }

  hotkeys = [
    {
      global: true,
      combo: "right",
      label: "Next slide",
      onKeyDown: this.nextSlide,
    },
    {
      global: true,
      combo: "space",
      label: "Next slide",
      onKeyDown: this.nextSlide,
    },
    {
      global: true,
      combo: "left",
      label: "Previous slide",
      onKeyDown: this.prevSlide,
    },
  ]

  render() {
    const { slides, togglePresentMode, isOpen } = this.props
    const currentSlide = this.state.currentSlide

    return (
      <HotkeysTarget2 hotkeys={this.hotkeys}>
        {({ handleKeyDown, handleKeyUp }) => (
          <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            <Overlay
              isOpen={isOpen}
              onClose={togglePresentMode}
              onClosed={this.onClosed}
            >
              <div className="presentContainer">
                <div className="slideTitle">
                  <H1>{slides[currentSlide].title}</H1>
                </div>
                <div className="slideBody">
                  <div className="slideContent">
                    {slides[currentSlide].content}
                  </div>
                </div>
              </div>
            </Overlay>
          </div>
        )}
      </HotkeysTarget2>
    )
  }
}

export default PresentMode

import React from "react"
import { DateInput } from "@blueprintjs/datetime"
import isBefore from "date-fns/isBefore"
import isAfter from "date-fns/isAfter"
import subYears from "date-fns/subYears"
import addYears from "date-fns/addYears"
import fnsParse from "date-fns/parse"
import isValid from "date-fns/isValid"
import fnsFormat from "date-fns/format"
import { Controller } from "react-hook-form"
import { jsDateToISOString } from "./formatters"

const DateInputWithValidation = ({
  onFieldChange,
  intent,
  control,
  fieldName,
  rules,
  large = true,
  allowNulls = false,
}) => {
  const MIN_STARTED_AT = subYears(new Date(), 60)
  const MAX_STARTED_AT = addYears(new Date(), 1)

  const isWithinAllowedRange = (date) => {
    return isAfter(date, MIN_STARTED_AT) && isBefore(date, MAX_STARTED_AT)
  }

  const parsePartialDate = (str, field) => {
    const DAY_ONLY_INPUT_LENGTH = 2
    const DAY_AND_MONTH_INPUT_LENGTH = 5

    let format = ""
    const inputLength = str.length

    if (inputLength <= DAY_ONLY_INPUT_LENGTH) {
      format = "dd"
    } else if (inputLength <= DAY_AND_MONTH_INPUT_LENGTH) {
      format = "dd-MM"
    } else {
      format = "dd-MM-yyyy"
    }

    const date = fnsParse(str, format, new Date())

    if (isValid(date) && isWithinAllowedRange(date)) {
      return date
    } else {
      field.onChange(null)
      onFieldChange(null, fieldName)

      return false
    }
  }

  const handleDateInputChange = (value, field) => {
    if (isValid(value)) {
      field.onChange(value)
      onFieldChange(jsDateToISOString(value), fieldName)
    } else {
      field.onChange(null)
      onFieldChange(null, fieldName)
    }
  }

  const handleDateInputError = (field) => {
    field.onChange(null)
    onFieldChange(null, fieldName)
  }

  const defaultDateValue = (value) => {
    if (isValid(value)) return value

    if (allowNulls) return null

    return new Date()
  }

  const dateInputRender = ({ field }) => (
    <DateInput
      formatDate={(date) => fnsFormat(date, "dd-MM-yyyy")}
      parseDate={(str) => parsePartialDate(str, field)}
      placeholder="DD-MM-YYYY"
      onChange={(e) => handleDateInputChange(e, field)}
      onError={() => handleDateInputError(field)}
      inputProps={{ large: large, fill: true, intent: intent }}
      showActionsBar={true}
      value={defaultDateValue(field.value)}
      popoverProps={{ targetTagName: "div" }}
      maxDate={MAX_STARTED_AT}
      minDate={MIN_STARTED_AT}
      invalidDateMessage="invalid date"
    />
  )

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={dateInputRender}
    />
  )
}

export default DateInputWithValidation

import React, { useState, useContext } from "react"
import { Section } from "../Content"
import pluralize from "pluralize"

import {
  H3,
  Label,
  InputGroup,
  Drawer,
  Classes,
  Button,
  Intent,
  Card,
  H5,
} from "@blueprintjs/core"
import { CardsLoadingState } from "../NonIdealState"
import { useForm, UseFormReturn } from "react-hook-form"
import withHookFormHelpers, {
  InjectedHookHelpersProps,
} from "../withHookFormHelpers"
import { UserContext } from "../UserContext"
import { AppToaster } from "../AppToaster"

import { EMAIL_REGEXP } from "../ResetPasswordStart"

import formCss from "../forms.module.css"
import css from "./UsersSettings.module.css"
import Avatar from "../Avatar"
import useUsers from "../api/useUsers"

type AddUserFormType = {
  email: string
  name: string
}

type AddUserFormProps = InjectedHookHelpersProps<AddUserFormType> & {
  useFormMethods: UseFormReturn<AddUserFormType>
}

const AddUserForm = withHookFormHelpers(
  ({ useFormMethods, validationErrorMsg, fieldIntent }: AddUserFormProps) => {
    const {
      register,
      formState: { errors },
    } = useFormMethods

    const nameField = register("name", {
      required: "Required",
    })
    const emailField = register("email", {
      required: "Required",
      pattern: {
        value: EMAIL_REGEXP,
        message: "It does not look like a proper email address",
      },
    })

    return (
      <div className={formCss.insideDrawer}>
        <div className={formCss.body}>
          <H3 className={formCss.title}>User</H3>
          <p>
            Invitation will be sent via email with a link to setup a password
          </p>
          <Label>
            Name
            <InputGroup
              fill
              large
              name="name"
              placeholder="ex. Paul Smith"
              autoFocus
              intent={fieldIntent(errors, "name")}
              inputRef={nameField.ref}
              onChange={nameField.onChange}
              onBlur={nameField.onBlur}
            />
            {validationErrorMsg(errors, "name")}
          </Label>
          <Label>
            Email
            <InputGroup
              fill
              large
              name="email"
              intent={fieldIntent(errors, "email")}
              inputRef={emailField.ref}
              onChange={emailField.onChange}
              onBlur={emailField.onBlur}
            />
            {validationErrorMsg(errors, "email")}
          </Label>
        </div>
      </div>
    )
  }
)

type AddUserDrawerType = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (email: string, name: string) => void
}

const AddUserDrawer = ({ isOpen, onClose, onSubmit }: AddUserDrawerType) => {
  const useFormMethods = useForm<AddUserFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const { trigger, formState, getValues } = useFormMethods

  const onFormSave = () => {
    trigger().then((success) => {
      if (success) {
        const values = getValues()

        onSubmit(values.email, values.name)
        onClose()
      }
    })
  }

  return (
    <Drawer
      hasBackdrop={true}
      canOutsideClickClose={false}
      isOpen={isOpen}
      size="40%"
      onClose={onClose}
      title="Add user"
    >
      <div className={Classes.DRAWER_BODY}>
        <AddUserForm useFormMethods={useFormMethods} />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <div className={formCss.actions}>
          <Button
            disabled={!formState.isValid}
            text="Send invite"
            outlined
            onClick={onFormSave}
            intent={Intent.PRIMARY}
            large
          />
          <Button
            text="Cancel"
            intent={Intent.DANGER}
            minimal
            onClick={onClose}
            large
          />
        </div>
      </div>
    </Drawer>
  )
}

type UserCardProps = {
  user: Types.Api.User
}
const UserCard = ({ user }: UserCardProps) => (
  <Card className={css.userCard}>
    <Avatar name={user.name} dark />
    <div className={css.userInfo}>
      <H5 className={css.clipOverflow}>{user.name}</H5>
      <span className={css.clipOverflow}>{user.email}</span>
    </div>
  </Card>
)

const UsersSettings = () => {
  const [formOpen, setFormOpen] = useState(false)
  const userContext = useContext(UserContext)

  const [users, usersLoading, fetchUsers] = useUsers()

  const onSendInvitation = (email: string, name: string) => {
    const attrs = { email, name }
    userContext
      .fetch("/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(attrs),
      })
      .then(() => {
        AppToaster.showSuccess({ message: "Invitation sent successfully" })
        fetchUsers()
      })
      .catch(() => {
        AppToaster.showError({
          message: "We could not sent invitation, something went wrong",
        })
      })
  }

  const onClose = () => setFormOpen(false)
  const openForm = () => setFormOpen(true)

  const sectionSubtitle = () => {
    let title = "Manage users on your account"
    if (users.length > 0) {
      title =
        title +
        `. You have ${users.length} active ${pluralize("user", users.length)}.`
    }

    return title
  }

  return (
    <Section
      title="Users"
      subtitle={sectionSubtitle()}
      actionButton={
        <Button
          large
          onClick={openForm}
          outlined
          intent={Intent.PRIMARY}
          icon="plus"
          text="Invite user"
        />
      }
    >
      <AddUserDrawer
        isOpen={formOpen}
        onClose={onClose}
        onSubmit={onSendInvitation}
      />
      {usersLoading && <CardsLoadingState />}
      {!usersLoading && (
        <div className={css.users}>
          {users.map((user, index) => (
            <UserCard key={index} user={user} />
          ))}
        </div>
      )}
    </Section>
  )
}

export default UsersSettings
